import React from 'react';
// import { useRouter } from 'next/router';
// import { useUnit } from 'effector-react/ssr';
// import { AccountPagination } from 'features/account-pagination';
// import { NotificationCard } from 'entities/notification-card';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { operatorNotificationsModel } from '../../model';
import { ErrorPage } from '../error-page';
import styles from './styles.module.scss';
import { useUnit } from 'effector-react';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { AccountPagination } from 'layout/layouts/account/account-pagination';
import { NotificationCard } from 'entities/notification-card';
import { useRouter } from '@tanstack/react-router';

export const OperatorNotifications = React.memo(() => {
	const [notifications, getNotifications, loadMoreNotifications, status, lastLoadedPage] = useUnit([
		operatorNotificationsModel.$operatorNotifications,
		operatorNotificationsModel.getOperatorNotifications,
		operatorNotificationsModel.loadMoreNotifications,
		operatorNotificationsModel.$status,
		operatorNotificationsModel.$lastLoadedPage,
	]);
	const router = useRouter();

	if (status === 'fail') {
		return (
			<ErrorPage
				text="Извините, в данный момент уведомления не доступны. Попробуйте обновить или посмотреть уведомления позже"
				onReloadPage={() => getNotifications(lastLoadedPage)}
			/>
		);
	}

	if (!notifications?.data?.length) {
		return null;
	}

	return (
		<section className={styles.notificationPageWrap}>
			<header className={styles.notificationPageHeader}>
				{/* <Button btnType="text" className={styles.goBackButton} onClick={() => router.back()}> */}
				<Button btnType="text" className={styles.goBackButton} onClick={() => router.history.back()}>
					<Icon id="chevron-left" width={20} height={20} />
				</Button>
				<Typo as="h1" design="headline-l">
					Уведомления от операторов
				</Typo>
			</header>
			<div className={styles.notificationsList}>
				{notifications.data.map((elem) => (
					<NotificationCard key={elem.id} {...elem} />
				))}
			</div>
			<AccountPagination
				pageName="notifications"
				onPageChange={getNotifications}
				onLoadMore={loadMoreNotifications}
				total={notifications.total ?? 0}
				perPage={notifications.perPage ?? 0}
				page={notifications.page ?? 0}
				loadedNotifications={notifications.data.length}
				className={styles.notificationPagePagination}
			/>
			<footer className={styles.notificationPageFooter}>
				<Typo as="p" design="text-s">
					Настоящая информация является надлежащей и достаточной, обязательства по информированию
					туристов и заказчиков исполнены полностью
				</Typo>
				<Typo as="p" design="text-s">
					Если Вы не успели дать ответ о своём решении о замене турпродукта на равнозначный, просьба
					связаться с нами по электронной почте{' '}
					<a href="mailto:priem@infoflot.com">priem@infoflot.com</a> или по телефонам наших офисов
					продаж. Наши специалисты помогут подобрать новый круиз или оформить возврат денежных
					средств.
				</Typo>
			</footer>
		</section>
	);
});
