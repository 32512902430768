import { ReactElement } from 'react';
import { OverlayTriggerState } from 'react-stately';
// import { Typo } from 'shared/ui/atoms';
// import { ModalTrigger } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { ModalTrigger } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';

interface ChangePasswordTriggerProps {
	footer?: ReactElement;
	children?: (close?: OverlayTriggerState['close']) => ReactElement;
}

export const ChangePasswordTrigger = ({ children, footer }: ChangePasswordTriggerProps) => (
	<ModalTrigger
		footer={footer}
		className={styles.button}
		verticalAlign="bottom"
		button={<Typo design="button-l">Изменить пароль</Typo>}
	>
		{children}
	</ModalTrigger>
);
