// import { dischargeNumber } from 'shared/lib/utils';

import { dischargeNumber } from "utils/number";

export const getDischarged = (bonus?: string | number) => {
	const num = Number(bonus);

	if (!num || Number.isNaN(num)) {
		return bonus;
	}

	const prefix = num > 0 ? '+ ' : '- ';

	return prefix + dischargeNumber(Math.abs(num));
};
