import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "../constants";
import { ChangePasswordRequest } from "config/types/personal";


export const changePassword = ({ data }: { data: ChangePasswordRequest }) =>
	fetcher({
		url: `${ACCOUNT_URL}/profile/change-password`,
		body: data,
		authorization: true,
		method: 'POST',
	});
