import React from 'react';
import classnames from 'classnames';
// import { CABINS_DECLENSION, SEATS_DECLENSION, TOURISTS_DECLENSION } from 'shared/lib/constants';
// import { Request } from 'shared/lib/types/account/request';
// import { RequestCabin } from 'shared/lib/types/account/request-cabin';
// import { RequestPassenger } from 'shared/lib/types/account/request-passenger';
// import { getNumDeclension } from 'shared/lib/utils';
// import { Price, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { CABINS_DECLENSION, SEATS_DECLENSION, TOURISTS_DECLENSION } from 'config/constants';
import { RequestPassenger } from 'config/types/prices/request-passenger';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';
import { RequestCabin } from 'store/auth/types/account/request-cabin';
import { getNumDeclension } from 'utils/string';
import { Request } from 'store/auth/types/account/request';

interface CabinParamsProps
	extends Pick<RequestCabin, 'placesCount' | 'passengers' | 'amount'>,
		Pick<Request, 'cabins'> {
	theme?: 'default' | 'reverse';
}

export const CabinParams = ({
	cabins,
	placesCount,
	passengers,
	amount,
	theme = 'default',
}: CabinParamsProps) => {
	const uniqueIds = (items: RequestPassenger[] | Request['cabins']) =>
		[...new Set(items?.map((item) => item.id))].length;

	const realPassengers = passengers?.filter(({ type }) => type !== 3) || [];

	return (
		<div className={classnames(styles.cabinHead_paramList, theme && styles[theme])}>
			{cabins?.length ? (
				<div className={styles.cabinHead_param}>
					<Typo design="headline-s">{uniqueIds(cabins)}</Typo>
					<Typo design="text-s">{getNumDeclension(uniqueIds(cabins), CABINS_DECLENSION)}</Typo>
				</div>
			) : null}

			{placesCount ? (
				<div className={styles.cabinHead_param}>
					<Typo design="headline-s">{placesCount}</Typo>
					<Typo design="text-s">{getNumDeclension(placesCount, SEATS_DECLENSION)}</Typo>
				</div>
			) : null}

			{passengers?.length ? (
				<div className={styles.cabinHead_param}>
					<Typo design="headline-s">{uniqueIds(realPassengers)}</Typo>
					<Typo design="text-s">
						{getNumDeclension(uniqueIds(realPassengers), TOURISTS_DECLENSION)}
					</Typo>
				</div>
			) : null}

			{amount ? (
				<Price
					className={styles.cabinHead_param}
					total={amount}
					as="span"
					design="headline-s"
					bold
				/>
			) : null}
		</div>
	);
};
