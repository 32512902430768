/* eslint-disable react/no-array-index-key */
import React, { ButtonHTMLAttributes } from 'react';
import classnames from 'classnames';
import { useOverlayTriggerState } from 'react-stately';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { ShipModel, Service } from 'shared/lib/types/ships';
// import { capitalizeFirstLetter } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { SizeType, Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { Modal } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { Button, SizeType } from 'layout/components/buttons/button';
import { Service, ShipModel } from 'config/types/ships';
import { useViewport } from 'layout/viewport';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
import { Icon } from 'layout/components/icons/icon';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { capitalizeFirstLetter } from 'utils/string';

interface ServicesModalProps extends Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
	title?: string;
	subtitle?: string;
	buttonText?: string;
	buttonIcon?: string | false;
	buttonSize?: SizeType;
	buttonClassName?: string;
	listClassName?: string;
	services: ShipModel.Service[] | Service[];
}

export const ServicesModal = ({
	services,
	title,
	subtitle,
	buttonText = 'Все услуги',
	buttonIcon = 'chevron-down',
	buttonSize = 'large',
	buttonClassName,
	listClassName,
	onClick,
}: ServicesModalProps) => {
	const { isMob } = useViewport();
	const state = useOverlayTriggerState({});
	const shouldMount = useDelayUnmount(state.isOpen, 600);

	const desktopRowsMax = Math.ceil(services.length / 2);
	const desktopRows = desktopRowsMax > 4 ? desktopRowsMax : 4;

	const gridStyle =
		!isMob && Number.isInteger(desktopRows)
			? { gridTemplateRows: `repeat(${desktopRows}, 1fr)`, gridAutoFlow: 'column' }
			: undefined;

	return (
		<>
			<Button
				btnType="text"
				size={buttonSize}
				onClick={(event) => {
					state.toggle();
					onClick?.(event);
				}}
				className={classnames(styles.button, buttonClassName)}
			>
				{buttonText}
				{buttonIcon && <Icon className={styles.button__icon} id="chevron-down" />}
			</Button>
			{shouldMount && (
				<Modal
					state={state}
					isShown={state.isOpen}
					className={styles.modalOverlay}
					classNameModal={styles.modalBox}
					closeButtonClassName={styles.modalBox__closeButton}
					classNameFooter={styles.modalBox__footer}
					footer={
						isMob ? (
							<Button size="large" onClick={state.close}>
								Закрыть
							</Button>
						) : undefined
					}
					verticalAlign="bottom"
					isDismissable
				>
					{title && (
						<Typo className={styles.modalBox__title} design="headline-m">
							{title}
						</Typo>
					)}
					{subtitle && (
						<Typo className={styles.modalBox__subtitle} design="headline-xs">
							{subtitle}
						</Typo>
					)}
					<div
						className={classnames(styles.services, styles.services_grid, listClassName)}
						style={gridStyle}
					>
						{services.map((service, index) => (
							<div key={`${service.id || service.title || ''}-${index}`} className={styles.service}>
								{service.icon ? (
									<Typo className={styles.service__icon} raw={service.icon} />
								) : (
									<Icon id="no-result" width={20} height={20} />
								)}
								<Typo design="text-s">{capitalizeFirstLetter(service.title ?? '')}</Typo>
							</div>
						))}
					</div>
				</Modal>
			)}
		</>
	);
};
