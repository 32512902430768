/* eslint-disable react/jsx-no-useless-fragment */
import { Controller } from 'effector-react-form';
import { cloneElement } from 'react';

interface FieldWrapperProps {
	controller: Controller<any>;
	children: JSX.Element;
	withoutOnBlur?: boolean;
	onBlurValueParser?: (value: any) => any;
}

export const FieldUncontrolled = ({
	controller,
	children,
	withoutOnBlur,
	onBlurValueParser,
}: FieldWrapperProps) => {
	const { input, error, isShowError } = controller();
	const { name, value, onChange, onFocus } = input;

	let { onBlur } = input;

	if (onBlurValueParser) {
		const oldOnBlur = onBlur;
		onBlur = (e) => {
			onChange(onBlurValueParser(e.target.value));
			oldOnBlur(e);
		};
	}

	return (
		<>
			{cloneElement(children, {
				error: error && isShowError ? <>{error}</> : undefined,
				name,
				value,
				onChange,
				onFocus,
				onBlur: !withoutOnBlur ? onBlur : null,
			})}
		</>
	);
};
