import { MutableRefObject, useState } from 'react';
import { AriaButtonProps } from '@react-types/button';
import { useButton } from 'react-aria';
import { isTouchDevice } from 'utils/tests';
// import { isTouchDevice } from 'shared/lib/utils';

interface UseSelectDatesButtonParams {
	isOpen: boolean;
	setOpen: (isOpen: boolean) => void;
	triggerProps: AriaButtonProps<'button'>;
	ref: MutableRefObject<null>;
}

/**
 * Аналогичен useButton из react-aria,
 * но добавляет функционалность закрытия select'а, при повторном клике
 */
const isTouch = isTouchDevice();

export const useSelectDatesButton = ({
	isOpen,
	setOpen,
	triggerProps,
	ref,
}: UseSelectDatesButtonParams) => {
	const [prevIsOpen, setPrevIsOpen] = useState<boolean>(false);

	return useButton(
		{
			...triggerProps,
			onPress: (e) => {
				if (prevIsOpen) {
					setOpen(false);
				} else if (triggerProps.onPress) {
					triggerProps.onPress(e);
				}
			},
			onPressStart: (e) => {
				setPrevIsOpen(isOpen);
				if (!isOpen && !isTouch) {
					setOpen(true);
				}
				if (triggerProps.onPressStart) {
					triggerProps.onPressStart(e);
				}
			},
		},
		ref,
	);
};
