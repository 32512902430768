/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useRef, useState } from 'react';
import { CalendarDate } from '@internationalized/date';
import { Placement } from '@react-types/overlays';
import { useDateRangePicker } from 'react-aria';
import { DateRangePickerStateOptions, useDateRangePickerState } from 'react-stately';
// import { TIMEZONE_NULL } from 'shared/config';
// import { useClickOutside } from 'shared/lib/hooks';
// import { ControlsTheme } from 'shared/lib/types';
// import { formatDate } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Condition, Typo } from 'shared/ui/atoms';
// import { IconId } from 'shared/ui/icons';
// import {
// 	CalendarProps,
// 	CalendarWeekHead,
// 	RangeCalendar,
// 	RangeCalendarMobile,
// } from 'shared/ui/molecules/calendar';
// import { SelectHead, SelectWrap } from 'shared/ui/molecules/select-head';
import { useSelectDatesButton } from '../../lib';
import { CalendarPopover } from '../calendar-popover';
import { ControlsTheme, TIMEZONE_NULL } from 'config/commonConstants';
import { IconId } from 'layout/components/icons/svg-sprite';
import { CalendarWeekHead, RangeCalendarMobile, RangeCalendar, CalendarProps } from 'layout/components/inputs/calendar';
import { SelectWrap, SelectHead } from 'layout/components/inputs/selects/select-head';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';
import { formatDate } from 'utils/date';
import { useClickOutside } from 'utils/hooks/use-click-outside';

export interface RangeDatePickerProps extends DateRangePickerStateOptions {
	label?: string;
	names?: string[];
	placeholder?: string;
	className?: string;
	classNameTrigger?: string;
	classNamePopover?: string;
	size?: 'sm' | 'md' | 'lg';
	placement?: Placement;
	theme?: ControlsTheme;
	icon?: IconId;
	error?: JSX.Element;
	moveIcon?: boolean;
	calendar?: Pick<CalendarProps, 'dateSelector'>;
	mobileModalMode?: boolean;
	mobileModalFixedSize?: boolean;
	mobileMonthsCount?: number;
	mobileMinMonth?: CalendarDate;
	mobileMaxMonth?: CalendarDate;
	resetSelectValue?: boolean;
	searchCalendar?: boolean;
	isQualityControlComponent?: boolean;
}

export const RangeDatePicker = (props: RangeDatePickerProps) => {
	const ref = useRef(null);
	const popoverRef = useRef(null);

	const {
		label,
		placeholder,
		className,
		classNameTrigger,
		isDisabled,
		isRequired,
		theme,
		size = 'lg',
		error,
		placement = 'bottom left',
		icon = 'month',
		moveIcon = false,
		onBlur,
		onFocus,
		calendar,
		mobileModalMode,
		mobileModalFixedSize,
		mobileMonthsCount,
		mobileMinMonth,
		mobileMaxMonth,
		maxValue,
		minValue,
		resetSelectValue,
		searchCalendar,
		isQualityControlComponent,
	} = props;

	const { isMob } = useViewport();
	const modalMode = Boolean(mobileModalMode && isMob);

	const state = useDateRangePickerState({
		...props,
		granularity: 'day',
		shouldCloseOnSelect: false,
	});

	const { isOpen, setOpen, value: range, setValue } = state;

	const {
		labelProps,
		calendarProps,
		groupProps,
		dialogProps,
		buttonProps: triggerProps,
	} = useDateRangePicker(props, state, ref);

	const { buttonProps } = useSelectDatesButton({
		triggerProps,
		ref,
		isOpen,
		setOpen,
	});

	useClickOutside({
		ref: [ref, popoverRef],
		handler: () => state.setOpen(false),
	});
	const desktopMonthCount = mobileMonthsCount ? mobileMonthsCount - 1 : 1;
	const getValueText = (withPrefixes?: boolean) => {
		const start = range?.start?.toDate(TIMEZONE_NULL);
		const end = range?.end?.toDate(TIMEZONE_NULL);

		const startPrefix = withPrefixes ? 'с ' : '';
		const endPrefix = withPrefixes ? 'по ' : '';

		if (start && end) {
			if (`${start}` === `${end}`) {
				return `${formatDate(start, 'DD.MM.YY')}`;
			}

			return [
				`${startPrefix}${formatDate(start, 'DD.MM.YY')}`,
				`${endPrefix}${formatDate(end, 'DD.MM.YY')}`,
			].join(' — ');
		}

		return placeholder;
	};

	const value = getValueText();
	const valueWithPrefixed = getValueText(true);

	const handleReset = () => {
		const resetedValue = {
			start: null,
			end: null,
		};

		// @ts-ignore
		setValue(resetedValue);
	};

	const [innerScrollReachedEnd, setInnerScrollReachedEnd] = useState(false);
	const [innerScrollReachedStart, setInnerScrollReachedStart] = useState(true);

	useEffect(() => {
		if (resetSelectValue) {
			handleReset();
		}
		// eslint-disable-next-line
	}, [resetSelectValue]);

	return (
		<SelectWrap
			size={size}
			theme={theme}
			className={className}
			isFocused={isOpen}
			isDisabled={isDisabled}
			isInvalid={!!error}
			error={error}
			{...groupProps}
		>
			<SelectHead
				ref={ref}
				label={label}
				value={value}
				size={size}
				theme={theme}
				icon={icon}
				labelProps={labelProps}
				buttonProps={{
					...buttonProps,
					onBlur,
					onFocus,
				}}
				isPlaceholder={value === placeholder}
				isDisabled={isDisabled}
				isRequired={isRequired}
				isOpen={isOpen}
				className={classNameTrigger}
				moveIcon={moveIcon}
				onReset={handleReset}
			/>
			<CalendarPopover
				state={state}
				triggerRef={ref}
				searchCalendar={searchCalendar}
				popoverRef={popoverRef}
				dialogProps={dialogProps}
				placement={placement}
				onReset={handleReset}
				modalMode={modalMode}
				modalTitle={label}
				modalFixedSize={mobileModalFixedSize}
				modalScrollReachedEnd={innerScrollReachedEnd}
				modalScrollReachedStart={innerScrollReachedStart}
				modalHeader={
					<>
						{valueWithPrefixed && (
							<Typo design={isMob ? 'headline-sl' : 'headline-xs'} freezeDesktop>
								{valueWithPrefixed}
							</Typo>
						)}
						<CalendarWeekHead isMob={isMob} />
					</>
				}
			>
				<Condition
					value={modalMode}
					then={
						<RangeCalendarMobile
							{...calendarProps}
							{...calendar}
							minValue={minValue}
							maxValue={maxValue}
							months={mobileMonthsCount}
							minMonth={mobileMinMonth}
							maxMonth={mobileMaxMonth}
							onScroll={({ y }, reachedEnd) => {
								setInnerScrollReachedEnd(Boolean(reachedEnd));
								setInnerScrollReachedStart(y === 0);
							}}
						/>
					}
					else={
						<RangeCalendar
							{...calendarProps}
							{...calendar}
							searchCalendar={searchCalendar}
							isQualityControlComponent={isQualityControlComponent}
							months={desktopMonthCount}
							onScroll={({ y }, reachedEnd) => {
								setInnerScrollReachedEnd(Boolean(reachedEnd));
								setInnerScrollReachedStart(y === 0);
							}}
							onChange={(...args) => {
								calendarProps?.onChange?.(...args);
								if (!modalMode) {
									setOpen(false);
								}
							}}
							withTips
						/>
					}
				/>
			</CalendarPopover>
		</SelectWrap>
	);
};
