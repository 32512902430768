// import { DAYS_DECLENSION } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { ProfileModel } from 'shared/lib/types';
// import { Request } from 'shared/lib/types/account/request';
// import {
// 	capitalizeFirstLetter,
// 	formatDate,
// 	getExpirationMessage,
// 	getNumDeclension,
// } from 'shared/lib/utils';

import { DAYS_DECLENSION } from "config/constants";
import { message } from "config/constants/message";
import { ProfileModel } from "config/types/personal";
import { Request } from "store/auth/types/account/request";
import { getExpirationMessage, formatDate } from "utils/date";
import { capitalizeFirstLetter, getNumDeclension } from "utils/string";

export const getPaymentMessage = (
	isPrepaymentAvailable: boolean,
	payed?: number,
	expirationDate?: string,
) => {
	if (!isPrepaymentAvailable || getExpirationMessage(expirationDate)?.type === 'expired') {
		return message.requests.payment.paymentAmount.notAllowedToPay(formatDate(expirationDate) ?? '');
	}

	if (isPrepaymentAvailable && !payed) {
		return message.requests.payment.paymentAmount.allowedToPay;
	}

	if (isPrepaymentAvailable && payed) {
		return message.requests.payment.paymentAmount.payed;
	}

	return null;
};

export const checkIsPayable = (profile: Nullable<ProfileModel>) => {
	if (!profile) {
		return {
			profileDataIsOk: false,
			emailVerified: true,
			isPayable: false,
		};
	}

	const { passportData, email, emailVerified, mailingAddress } = profile;

	const checkMailingAddress = () => {
		if (mailingAddress?.raw && mailingAddress.raw.length > 0) {
			return true;
		}

		return [
			mailingAddress?.buildingNumber,
			mailingAddress?.country,
			mailingAddress?.city,
			mailingAddress?.street,
			mailingAddress?.zip,
		].every((elem) => Boolean(elem));
	};

	const profileDataIsOk =
		[
			passportData.firstName,
			passportData.middleName,
			passportData.lastName,
			passportData.gender,
			passportData.birthday,
			passportData.passportNumber,
			passportData.passportSeries,
			email,
		].every((elem) => elem) && checkMailingAddress();

	return {
		profileDataIsOk,
		emailVerified,
		isPayable: profileDataIsOk && emailVerified,
	};
};

export const getCruiseInfo = (cruise: Request['cruise']) => {
	if (!cruise) {
		return null;
	}

	return `${cruise.isSea ? cruise?.route : cruise.name}${
		cruise.lengthDays
			? ` (${cruise.lengthDays} ${capitalizeFirstLetter(
					getNumDeclension(cruise.lengthDays, DAYS_DECLENSION),
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  )})`
			: ''
	}`;
};

export const isInputDisabled = ({
	prepaymentDate,
	prepayment,
	expirationDate,
}: Pick<Request, 'prepayment' | 'prepaymentDate' | 'expirationDate'>) =>
	Boolean(
		prepayment === 0 ||
			prepayment === undefined ||
			(!prepaymentDate && !prepayment) ||
			// dateIsOverdue(prepaymentDate) ||
			(expirationDate && getExpirationMessage(expirationDate)?.type === 'expired'),
	);

export const isCalculatePrepaymentButtonVisible = ({
	prepaymentDate,
	prepayment,
	payed,
	expirationDate,
}: Pick<Request, 'prepayment' | 'prepaymentDate' | 'payed' | 'expirationDate'>) =>
	Boolean(
		prepaymentDate &&
			prepayment &&
			!payed &&
			expirationDate &&
			getExpirationMessage(expirationDate)?.type !== 'expired',
	);
