import React from 'react';
import dayjs from 'dayjs';
import { useOverlayTriggerState } from 'react-stately';
import { useForm } from 'effector-react-form';
import { useGate, useUnit } from 'effector-react';
// import { AlertFooter } from 'features/edit-passenger/ui/alert-footer';
// import { PassengerServices } from 'entities/passengers/passengers-card/passenger-services';
// import { PLACE_TYPES } from 'shared/config';
// import { RequestPassenger, RequestCruise } from 'shared/lib/types/account/models';
// import { RequestService } from 'shared/lib/types/account/request-service';
// import { Alert, ToggleIos, Typo } from 'shared/ui/atoms';
// import { Modal, PlaceInfo } from 'shared/ui/molecules';
// import { FormField } from 'shared/ui/organisms/form';
import { model } from '../../model';
import { EditPassengerForm } from '../edit-passenger-form';
import styles from './styles.module.scss';
import { PLACE_TYPES } from 'config/constants/cabin';
import { RequestPassenger } from 'config/types/prices/request-passenger';
import { PassengerServices } from 'entities/passengers/passengers-card/passenger-services';
import { FormField } from 'layout/components/inputs/form/field';
import { Modal } from 'layout/components/modal';
import { ToggleIos } from 'layout/components/special/toggle-ios';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { RequestCruise } from 'store/auth/types/account/request-cruise';
import { RequestService } from 'store/auth/types/account/request-service';
import { AlertFooter } from '../alert-footer';
import { PlaceInfo } from 'layout/components/view/place-info';

interface ModalContentProps extends RequestPassenger {
	services?: RequestService[];
	close?: () => void;
	childrenAge?: number;
	cruiseStartDate?: RequestCruise['startDate'];
	isFirstPassenger?: boolean;
}

export const ModalContent = ({
	services,
	close,
	childrenAge,
	cruiseStartDate,
	...props
}: ModalContentProps) => {
	const { placeType, type, amount, mealType, excursionsType, id } = props;
	const { form, Gate, GateModal, $status } = model;
	const { controller } = useForm({ form });

	const state = useOverlayTriggerState({});

	const [{ child }, save, statusSaving] = useUnit([form.$values, model.save, $status]);

	useGate(Gate, props);
	useGate(GateModal, state);

	const minChildBornDate =
		type === 2 && childrenAge
			? dayjs(cruiseStartDate).subtract(childrenAge, 'year').toString()
			: undefined;

	return (
		<>
			<Typo className={styles.title} design="headline-xl">
				Данные туриста
			</Typo>
			<div className={styles.content}>
				<div className={styles.content__block}>
					<PlaceInfo
						childrenAge={childrenAge}
						itemDirection="column"
						placeType={placeType}
						type={child ? 1 : type}
						amount={amount}
					/>
					{(type === 0 || type === 1) && (
						<FormField valueAlias="isSelected" controller={controller} form={form} name="child">
							<ToggleIos size="lg">
								<Typo className={styles.toggleText} design="headline-xs">
									{PLACE_TYPES['1']}
								</Typo>
							</ToggleIos>
						</FormField>
					)}
				</div>

				<div className={styles.content__block}>
					{id != undefined
						? <PassengerServices
							mealType={mealType}
							excursionsType={excursionsType}
							className={styles.services}
							itemClassName={styles.services__item}
							passengerId={id}
						/>
						: null}
					<Alert type="warning">
						<Typo design="input-text-s">
							Внимание! При редактировании данных стоимость круиза может быть изменена. За
							подробностями обратитесь в свой офис.
						</Typo>
					</Alert>
				</div>

				<EditPassengerForm type={type} minChildBornDate={minChildBornDate} />

				{state.isOpen && (
					<Modal
						isShown={state.isOpen}
						footer={<AlertFooter close={state.close} parentClose={close} save={save} />}
						state={state}
					>
						{statusSaving === 'done' ? (
							<Typo design="headline-xl">Данные успешно изменены</Typo>
						) : (
							<div>
								<Typo design="headline-xl">Внимание!</Typo>
								<Typo design="text-m">
									При редактировании данных стоимость круиза может быть изменена. За подробностями
									обратитесь в свой офис.
								</Typo>
							</div>
						)}
					</Modal>
				)}
			</div>
		</>
	);
};
