import React from 'react';
import { OverlayTriggerState } from 'react-stately';
import { useForm } from 'effector-react-form';
// import { model } from 'features/edit-passenger/model';
import styles from '../edit-passenger/styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { model } from './../../model';
// import { Button } from 'shared/ui/atoms';

interface EditPassengerFooterProps {
	close?: OverlayTriggerState['close'];
}

export const EditPassengerFooter = ({ close }: EditPassengerFooterProps) => {
	const { form } = model;
	const { submit } = useForm({ form });

	return (
		<div className={styles.footer}>
			<Button onClick={submit} size="large">
				Сохранить
			</Button>
			<Button onClick={close} btnType="text" size="large">
				Отменить
			</Button>
		</div>
	);
};
