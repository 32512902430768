import { status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { getFailCode } from 'api';
import { modalFactory } from 'layout/components/modal';
import { AddExcursionsRequestPayload, accountRequests } from 'store/account/api/requests';
// import { accountRequests, AddExcursionsRequestPayload } from 'shared/api';
// import { getFailCode } from 'shared/lib/utils';
// import { modalFactory } from 'shared/model';

const addExcursionsRequest = createEvent<AddExcursionsRequestPayload>();
const addExcursionsRequestFx = createEffect(accountRequests.addExcursionsRequest);

const $addExcursionsStatus = status({ effect: addExcursionsRequestFx });
const $addExcursionsErrorCode = createStore<Nullable<number>>(null);

sample({
	clock: addExcursionsRequest,
	filter: ({ data }) => Boolean(data.excursions.length),
	target: addExcursionsRequestFx,
});

sample({
	clock: addExcursionsRequestFx.failData,
	fn: (error) => getFailCode(error) ?? null,
	target: $addExcursionsErrorCode,
});

sample({
	clock: addExcursionsRequestFx.done,
	fn: () => null,
	target: $addExcursionsErrorCode,
});

/* *
 * Message modal
 */
const { ModalGate, openModal } = modalFactory();

sample({
	clock: [addExcursionsRequestFx.done, addExcursionsRequestFx.fail],
	target: openModal,
});

export const model = {
	ModalGate,
	addExcursionsRequest,
	$addExcursionsStatus,
	$addExcursionsErrorCode,
};
