import classNames from 'classnames';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
// import { useForm, useError } from 'effector-react-form/scope';
import { bonusSupportModel } from 'entities/bonus-support/model';
// import { ProfileModel } from 'shared/lib/types';
// import { dischargeNumber } from 'shared/lib/utils';
// import { Typo, InputMultiRow, Alert } from 'shared/ui/atoms';
// import { Select, StaticFormField } from 'shared/ui/molecules';
// import { Form, FormField } from 'shared/ui/organisms/form';
import { bonusSupportScheme } from '../../lib';
import styles from './styles.module.scss';
import { ProfileModel } from 'config/types/personal';
import { useForm, useError } from 'effector-react-form';
import { FormField } from 'layout/components/inputs/form/field';
import { Form } from 'layout/components/inputs/form/form';
import { InputMultiRow } from 'layout/components/inputs/input/input-multi-row';
import { Select } from 'layout/components/inputs/selects/select';
import { StaticFormField } from 'layout/components/inputs/static-form-field';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { dischargeNumber } from 'utils/number';

interface BonusSupportFormProps {
	profile: ProfileModel;
	cardNumber?: string;
}

export const BonusSupportForm = ({ profile, cardNumber }: BonusSupportFormProps) => {
	const {
		phone,
		passportData: { lastName, firstName, middleName },
	} = profile;
	const supportTypes = useUnit(bonusSupportModel.$supportTypes);

	const { form } = bonusSupportModel;
	const { controller, handleSubmit, setValue } = useForm({ form });
	const { error, isShowError } = useError({ form, name: 'formError' });

	return (
		<>
			<Typo className={styles.title} design="headline-xl">
				Обращение в клиентскую поддержку
			</Typo>

			<Typo design="text-m">
				По всем вопросам регистрации, участия в бонусной программе, начисления баллов Вы можете
				направить сообщение с помощью электронной формы:
			</Typo>
			<Form
				error={error}
				isShowError={isShowError}
				handleSubmit={handleSubmit}
				formClassName={styles.form}
				errorClassName={styles.errors}
			>
				{lastName || firstName || middleName ? (
					<StaticFormField caption="ФИО" value={classNames(lastName, firstName, middleName)} />
				) : (
					<StaticFormField caption="Номер телефона" value={`+${phone}`} />
				)}

				<StaticFormField
					caption="Номер бонусного счета"
					value={Number(cardNumber) ? dischargeNumber(Number(cardNumber)) : 'нет данных'}
				/>

				<FormField
					wrap="nowrap"
					controller={controller}
					form={form}
					name="type"
					validation={bonusSupportScheme.type}
				>
					<Select
						theme="divider"
						isRequired
						size="lg"
						className={styles.select}
						label="Тип обращения"
						selectionMode="single"
						onSelectionChange={(keys) => {
							const keysArray = keys instanceof Set ? ([...keys] as string[]) : [keys];
							const value = keysArray.length ? keysArray[0] : '';

							setValue({
								field: 'type',
								value,
							});
						}}
					>
						{supportTypes.map(({ type, name }) => (
							<Item key={type} aria-label={name}>
								{name}
							</Item>
						))}
					</Select>
				</FormField>
				<FormField
					form={form}
					controller={controller}
					name="message"
					validation={bonusSupportScheme.message}
					placeholder="Сообщение"
					secondPlaceholder="Введите текст"
					size="lg"
					isRequired
					withLabel
				>
					<InputMultiRow />
				</FormField>

				<Alert className={styles.alert} design="text">
					Опишите суть вашего обращения, не более 2000 символов
				</Alert>
			</Form>
		</>
	);
};
