import React, { useEffect } from 'react';
import AnimateHeight from 'react-animate-height';
import { Item, useAsyncList } from 'react-stately';
import { useGate } from 'effector-react';
import { useForm } from 'effector-react-form';
import { useUnit } from 'effector-react';
// import { getAvailableCharactersParams } from 'widgets/personal-data/ui/mailing-address/lib';
// import { changeAddressModel } from 'features/personal';
// import { countriesModel } from 'entities/countries';
// import { daDataRequests } from 'shared/api';
// import { Checkbox, Input } from 'shared/ui/atoms';
// import { InputSearch, Select } from 'shared/ui/molecules';
// import { FormField } from 'shared/ui/organisms/form';
import { DataBlock } from '../data-block';
import styles from './styles.module.scss';
import { changeAddressModel } from 'entities/personal';
import { Checkbox } from 'layout/components/inputs/checkbox';
import { FormField } from 'layout/components/inputs/form/field';
import { Input } from 'layout/components/inputs/input';
import { Select } from 'layout/components/inputs/selects/select';
import { countriesModel } from 'store/countries';
import { getAvailableCharactersParams } from './lib';
import { InputSearch } from 'layout/components/inputs/input-search';
import { daDataRequests } from 'store/other/da-data';

export const MailingAddress = () => {
	const { form, Gate, choosePartsView, $view, changeRaw } = changeAddressModel;
	const { controller } = useForm({ form });

	const [{ raw }, handleCheck, view, countries, onChangeRaw] = useUnit([
		form.$values,
		choosePartsView,
		$view,
		countriesModel.$countries,
		changeRaw,
	]);

	useGate(Gate);

	const addresses = useAsyncList<{ raw: string }>({
		async load({ signal, filterText }) {
			const items = (await daDataRequests.suggestAddress(filterText, signal)).map((name) => ({
				raw: name,
			}));

			return {
				items,
			};
		},
	});

	const viewIsParts = view === 'parts';

	useEffect(() => {
		if (raw && !viewIsParts) {
			addresses.setFilterText(raw);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [view, raw]);

	const inputValue = !viewIsParts ? addresses.filterText : '';

	return (
		<DataBlock
			title="Почтовый адрес"
			text="Если выбор из справочника не доступен или вы не нашли адрес, заполните адрес вручную"
		>
			<InputSearch
				className={styles.autocomplete}
				items={addresses.items}
				inputValue={inputValue}
				onInputChange={(value) => {
					addresses.setFilterText(value);
					onChangeRaw(value);
				}}
				onSelectionChange={(key) => {
					onChangeRaw(`${key}`);
				}}
				placeholder="Почтовый адрес"
				secondPlaceholder="Начните вводить адрес"
				label="Почтовый адрес"
				isDisabled={viewIsParts}
			>
				{inputValue.length
					? addresses.items.map((address) => <Item key={address.raw}>{address.raw}</Item>)
					: []}
			</InputSearch>

			<Checkbox className={styles.checkbox} onChange={handleCheck}>
				Заполнить адрес вручную
			</Checkbox>

			<AnimateHeight height={view === 'parts' ? 'auto' : 0}>
				<div className={styles.grid}>
					<FormField
						onChangeAlias="onSelectionChange"
						onChangeValueParser={(keys) => (keys instanceof Set && keys.size ? [...keys][0] : null)}
						valueAlias="selectedKeys"
						valueParser={(value) => (value ? [value] : [])}
						controller={controller}
						form={form}
						name="country"
					>
						<Select size="lg" label="Страна" selectionMode="single" className={styles.select}>
							{countries.map(({ name }) => (
								<Item key={`${name}`} aria-label={name}>
									{name}
								</Item>
							))}
						</Select>
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="zip"
						{...getAvailableCharactersParams('zip')}
						maxLength={9}
					>
						<Input placeholder="Индекс" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="region"
						maxLength={150}
						{...getAvailableCharactersParams('region')}
					>
						<Input placeholder="Область" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="district"
						maxLength={150}
						{...getAvailableCharactersParams('district')}
					>
						<Input placeholder="Район" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="city"
						maxLength={150}
						{...getAvailableCharactersParams('city')}
					>
						<Input placeholder="Город / Населённый пункт" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="street"
						maxLength={150}
						{...getAvailableCharactersParams('street')}
					>
						<Input placeholder="Улица" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="building"
						maxLength={50}
						{...getAvailableCharactersParams('building')}
					>
						<Input placeholder="Дом" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="buildingNumber"
						maxLength={50}
						{...getAvailableCharactersParams('buildingNumber')}
					>
						<Input placeholder="Корпус / Строение" withLabel size="lg" />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						secondPlaceholder="Не заполнено"
						name="office"
						maxLength={20}
						{...getAvailableCharactersParams('office')}
					>
						<Input placeholder="Квартира / Номер офиса" withLabel size="lg" />
					</FormField>
				</div>
			</AnimateHeight>
		</DataBlock>
	);
};
