import React, { FC, HTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
// import Image from 'next/image';
// import { ImageProps, Image as ImageType, Photo } from 'shared/lib/types';
// import { Img, Typo } from 'shared/ui/atoms';
// import { Slider } from 'shared/ui/molecules';
import { CardMediaDummy } from './card-media-dummy';
import styles from './styles.module.scss';
import { Photo } from 'config/types/ships';
import { ImageProps } from 'config/types/core';
import { Typo } from 'layout/components/typo/ui';
import { Slider } from 'layout/components/special/slider';
import { Image as ImageType } from 'config/types/cabins/models/image';
import { Img } from '../img';


export interface CardMediaProps extends HTMLAttributes<HTMLDivElement> {
	images?: Array<ImageType | Photo>;
	priority?: boolean;
	hasControls?: boolean;
	sliderClassName?: string;
	sliderControlsClassName?: string;
	displayCaptions?: boolean;
	logo?: string;
	onControlsClick?: () => void;
}

export interface CardMediaSlidePictureProps extends ImageProps {
	index: number;
	titre?: Nullable<string>;
}

export const CardMedia: FC<CardMediaProps> = memo(
	({
		images,
		priority,
		hasControls,
		sliderClassName,
		sliderControlsClassName,
		displayCaptions,
		logo,
		onControlsClick,
	}) => {
		const ref = useRef<HTMLDivElement>(null);
		const [inView, setInView] = useState(false);

		useEffect(() => {
			if (!ref.current) {
				return;
			}

			const element = ref.current;
			const observer = new IntersectionObserver(([entry]) => {
				if (entry.isIntersecting) {
					setInView(true);
					observer.unobserve(element);
				}
			});

			observer.observe(element);

			return () => {
				observer.unobserve(element);
			};
		}, []);

		const SlidePicture = ({ src, alt, titre }: CardMediaSlidePictureProps) => (
			<>
				<picture className={styles.fixAspect}>
					{src && (
						<Img src={src} alt={alt} priority={priority} sizes="(max-width: 639px) 640vw, 360vw" />
					)}
				</picture>
				{displayCaptions && titre && (
					<Typo design="text-s" className={styles.titre}>
						{titre}
					</Typo>
				)}
			</>
		);

		const photos = images?.filter((photo): photo is ImageType => !!photo);
		const captions = displayCaptions ? photos?.map((photo) => photo.caption) : undefined;

		const hasImages = photos && photos.length > 0;
		const singleImage = (photos && photos.length === 1) || !inView;

		return (
			<>
				{hasImages && (
					<div ref={ref} className={styles.mediaSlider}>
						{singleImage ? (
							<SlidePicture index={0} titre={photos[0].caption} {...photos[0]} />
						) : (
							<Slider
								items={photos}
								Component={SlidePicture}
								className={classnames(styles.mediaSlider, sliderClassName)}
								hasControls={hasControls}
								controlsClassName={classnames(styles.sliderControls, sliderControlsClassName)}
								prevControlClassName={styles.sliderControl}
								nextControlClassName={styles.sliderControl}
								prevControlIcon="chevron-right"
								nextControlIcon="chevron-left"
								options={{
									arrows: hasControls,
									type: 'loop',
								}}
								captions={captions}
								onPrevClick={onControlsClick}
								onNextClick={onControlsClick}
							/>
						)}
						{logo && (
							<div className={styles.logo}>
								<Img src={logo} width={51} height={51}
								/>
							</div>
						)}
					</div>
				)}
				{!hasImages && (
					<div className={styles.mediaDummy}>
						<div className={styles.mediaDummy__icon}>
							<CardMediaDummy />
						</div>
						{logo && (
							<div className={styles.logo}>
								<Img src={logo} width={51} height={51} />
							</div>
						)}
					</div>
				)}
			</>
		);
	},
);
