/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useTransition } from 'react';
import { AriaCalendarGridProps } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';
import { CalendarGrid } from './calendar-grid';
import { CalendarGridSkeleton } from './calendar-grid-skeleton';
import styles from './style.module.scss';

interface CalendarGridListProps extends AriaCalendarGridProps {
	state: CalendarState | RangeCalendarState;
	months: number;
}

export const CalendarGridList = ({ state, months }: CalendarGridListProps) => {
	const [shouldDisplay, setShouldDisplay] = useState(0);
	const [isPending, startTransition] = useTransition();

	useEffect(() => {
		startTransition(() => setShouldDisplay(months));
	}, [months]);

	if (shouldDisplay === 0 || isPending) {
		return (
			<div className={styles.monthsListHolder}>
				<CalendarGridSkeleton className={styles.monthsListHolder__item} />
				<CalendarGridSkeleton className={styles.monthsListHolder__item} />
			</div>
		);
	}

	return (
		<div className={styles.monthsList}>
			{[...new Array(months).keys()].map((monthIndex) => (
				<CalendarGrid
					key={monthIndex}
					className={styles.monthsList__item}
					state={state}
					variant="large"
					displayMonthTitle
					hideWeekDays
					offset={{ months: monthIndex }}
				/>
			))}
		</div>
	);
};
