import { createFileRoute } from '@tanstack/react-router'
import { AccountLayout } from 'layout/layouts/account'
import { Main } from 'layout/layouts/main'
import { RequestExcursionsCreate } from 'layout/layouts/widgets/account'
import accountStyles from './../../../../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute(
  '/account/my-requests/$bidId/excursions/create/',
)({
  component: () => <CreateExcursionPage />,
})

const CreateExcursionPage = () => {
  return (
    <Main>
      <AccountLayout>
        <section className={accountStyles.marginTopSmMd}>
          {/* <RequestExcursionsCreate /> */}
        </section>
      </AccountLayout>
    </Main>
  )
}

// import type { NextPage } from 'next';
// import { RequestExcursionsCreate } from 'widgets/account';
// import { detailedRequest } from 'widgets/account/model';
// import { AccountLayout } from 'widgets/layouts/account';
// import { Main } from 'widgets/layouts/main';
// import { ISR_REGENERATION_TIME } from 'shared/config';
// import { staticPropsFactory } from 'shared/lib/ssr';

// const RequestExcursionsCreatePage: NextPage = () => (
// 	<Main>
// 		<AccountLayout>
// 			<RequestExcursionsCreate />
// 		</AccountLayout>
// 	</Main>
// );

// export const getStaticProps = staticPropsFactory(
// 	detailedRequest.getData,
// 	ISR_REGENERATION_TIME,
// ).bind({
// 	__logName: 'RequestExcursionsCreatePage',
// });

// export const getStaticPaths = async () => ({
// 	paths: [],
// 	fallback: 'blocking',
// });

// export default RequestExcursionsCreatePage;

// import { createFileRoute } from '@tanstack/react-router'
// import { AccountLayout } from 'layout/layouts/account'
// import { Main } from 'layout/layouts/main'
// import { Requests } from 'layout/layouts/widgets/account'

// export const RequestExcursionsCreatePage = createFileRoute(
//   '/account/my-requests/excursions/create/',
// )({
//   component: () => (
//     <div>/account/my-requests/$bidId/excursions/create</div>
//     // <Main>
//     //   <AccountLayout>
//     //     <Requests />
//     //   </AccountLayout>
//     // </Main>
//   ),
// })
