import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { useUnit } from 'effector-react';
// import { ChangePhone, ChangePassword, AddEmail, verifyEmailModel } from 'features/personal/';
// import { profileModel } from 'entities/profile';
// import { toggleTextEllipsis } from 'shared/lib/utils/dom';
// import { Alert, Button, Tooltip, Typo } from 'shared/ui/atoms';
import { DataBlock } from '../data-block';
import styles from './styles.module.scss';
import { verifyEmailModel, ChangePhone, AddEmail, ChangePassword } from 'entities/personal';
import { profileModel } from 'layout/components/auth/profile';
import { Button } from 'layout/components/buttons/button';
import { Tooltip } from 'layout/components/special/tooltip';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { toggleTextEllipsis } from 'utils/dom/toggle-text-ellipsis';

export const AccountData = () => {
	const [verifyEmail, pending, wasSent, draft] = useUnit([
		verifyEmailModel.verifyEmail,
		verifyEmailModel.pending,
		verifyEmailModel.$wasSent,
		profileModel.$profileDraft,
	]);

	const emailRef = useRef<Nullable<HTMLDivElement>>(null);
	const [emailEllipsis, setEmailEllipsis] = useState(false);

	useEffect(() => {
		if (!emailRef.current) {
			return;
		}

		const observer = new ResizeObserver(([entry]) => {
			setEmailEllipsis(toggleTextEllipsis(entry.target as HTMLElement));
		});

		observer.observe(emailRef.current);

		const { current } = emailRef;

		return () => {
			if (current) {
				observer.unobserve(current);
			}
		};
	}, []);

	if (!draft) {
		return null;
	}

	return (
		<DataBlock
			title="Данные аккаунта"
			text={`Подтверждением номера телефона вы даёте согласие на хранение, обработку и передачу третьим
					лицам ваших персональных данных в соответствии с федеральным законом №272 от 21.07.2014 «О
					внесении изменений в федеральный закон «О связи».`}
		>
			<div className={styles.wrapper}>
				<div className={styles.row}>
					<Typo design="button-l">Телефон</Typo>
					<div className={classnames(styles.row__cell)}>
						<Typo design="headline-s" className={styles.phoneNumber}>
							+{draft.phone}
						</Typo>
						<ChangePhone />
					</div>
				</div>

				<div className={styles.row}>
					<Typo design="button-l">Телефон для смс-рассылки</Typo>
					<div className={classnames(styles.row__cell)}>
						{draft.additionalPhone && (
							<Typo design="headline-s" className={styles.phoneNumber}>
								+{draft.additionalPhone}
							</Typo>
						)}
						<ChangePhone addMode={!draft.additionalPhone} additionalPhone />
					</div>
				</div>

				<div className={styles.row}>
					<Typo design="button-l">E-mail</Typo>
					<div className={classnames(styles.emailWrapper, styles.row__cell)}>
						<div className={styles.emailWrapper__email}>
							{draft.email && (
								<Tooltip
									placement="top"
									classNamePopover={classnames(
										styles.tooltipContent,
										!emailEllipsis && styles.tooltipContent_hidden,
									)}
									trigger={
										<Typo
											ref={emailRef}
											className={styles.emailWrapper__emailText}
											design="headline-s"
										>
											{draft.email}
										</Typo>
									}
								>
									{draft.email}
								</Tooltip>
							)}
							<AddEmail changeMode={Boolean(draft.email)} />
						</div>
						<Alert className={styles.alert} design="text">
							Данный адрес будет использован для отправки уведомлений по вашим заявкам и информации
							о последних акциях, спецпредложениях и бесплатных мероприятиях, посвященных круизам.
						</Alert>
					</div>
				</div>

				{draft.email && !draft.emailVerified && (
					<div className={styles.row}>
						<Alert className={styles.verifyAlertWrapper} type="warning">
							<div className={styles.verifyAlert}>
								<div className={styles.verifyAlert__head}>
									{!wasSent && <Typo design="headline-xs">Подтвердите Ваш E-mail</Typo>}
									<Typo design="text-s">
										{wasSent
											? `Вам было выслано письмо с инструкциями для подтверждения E-mail на адрес ${draft.email}`
											: 'На указанный адрес электронной почты будет отравлено письмо с дальнейшими инструкциями'}
									</Typo>
								</div>
								<Button onClick={verifyEmail} loading={pending} btnType="text">
									{wasSent ? 'Письмо не пришло, отправить еще раз' : 'Подтвердить E-mail'}
								</Button>
							</div>
						</Alert>
					</div>
				)}

				<div className={styles.row}>
					<Typo design="button-l">Пароль</Typo>
					<ChangePassword />
				</div>
			</div>
		</DataBlock>
	);
};
