// import { AccountRequestsStatuses } from 'shared/config';

import { AccountRequestsStatuses } from "store/auth/constants";

export const STATUSES_FOR_ADD_EXCURSIONS = [
	AccountRequestsStatuses.AwaitingConfirmation,
	AccountRequestsStatuses.InProcessing,
	AccountRequestsStatuses.Confirmed,
	AccountRequestsStatuses.SoldByAgency,
];

export const STATUSES_FOR_NEW_EXCURSIONS_REQUEST = [
	AccountRequestsStatuses.Prepayment,
	AccountRequestsStatuses.Paid,
	AccountRequestsStatuses.ForFree,
];
