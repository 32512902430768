import { memo } from 'react';
import { useStoreMap } from 'effector-react';
// import { detailedRequest } from 'widgets/account/model';
// import { TIMEZONE_DEFAULT } from 'shared/config';
// import { EXCURSIONS_TYPES } from 'shared/lib/constants';
// import { formatDate, isEmptyObject, diffTimetable, getTimeRangeText } from 'shared/lib/utils';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon, IconId } from 'shared/ui/icons';
// import { CardMedia } from 'shared/ui/molecules';
import { ModalContentProps } from '../index';
import { CityStopParam } from './city-stop-param';
import styles from './styles.module.scss';
import { TIMEZONE_DEFAULT } from 'config/commonConstants';
import { EXCURSIONS_TYPES } from 'config/constants';
import { detailedRequest } from 'entities/account/model';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Typo } from 'layout/components/typo/ui';
import { CardMedia } from 'layout/components/view/card-media';
import { formatDate, diffTimetable, getTimeRangeText } from 'utils/date';
import { isEmptyObject } from 'utils/object';

interface ExcursionDetailsProps extends ModalContentProps {
	id?: number | null;
}

export const ExcursionDetails = memo(({ onClick, id }: ExcursionDetailsProps) => {
	const excursion = useStoreMap({
		store: detailedRequest.$detailedRequestServices,
		keys: [id],
		fn: (services) =>
			services?.find(({ excursion: serviceExcursion }) => serviceExcursion?.id === id)?.excursion,
	});
	const excursionType = EXCURSIONS_TYPES.find((elem) => elem.id === excursion?.type);

	if (isEmptyObject(excursion)) {
		return null;
	}

	return (
		<div className={styles.excursionDetails_wrap}>
			<Button
				btnType="text"
				className={styles.goBackButton}
				contextClassName={styles.goBackButton_ctx}
				onClick={() => onClick()}
			>
				<Icon id="chevron-left" width={20} height={20} />
				<span>Назад к экскурсиям</span>
			</Button>

			<div className={styles.excursionDetails_content}>
				<div className={styles.excursionDetails_shortInfo}>
					<Typo design="headline-xl" as="h1">
						{excursion?.city}
					</Typo>

					<ul className={styles.stopInfoList}>
						{excursion?.cityStop?.arrivalDate && (
							<CityStopParam
								name="Прибытие"
								value={formatDate(excursion.cityStop.arrivalDate, 'HH:mm', TIMEZONE_DEFAULT, true)}
							/>
						)}
						{excursion?.cityStop?.arrivalDate && excursion?.cityStop?.departureDate && (
							<CityStopParam
								name="Стоянка"
								value={diffTimetable(
									excursion?.cityStop?.departureDate,
									excursion?.cityStop?.arrivalDate,
								)}
							/>
						)}
						{excursion?.cityStop?.departureDate && (
							<CityStopParam
								name="Отправление"
								value={formatDate(
									excursion.cityStop.departureDate,
									'HH:mm',
									TIMEZONE_DEFAULT,
									true,
								)}
							/>
						)}
					</ul>
				</div>

				<div className={styles.excursionDetails_fullInfo}>
					<div className={styles.fullInfo_header}>
						{excursion?.timeEnd && excursion?.timeStart && (
							<Typo design="text-s" className={styles.fullInfo_excursionDuration}>
								{getTimeRangeText(
									`1970-01-01T${excursion.timeStart}`,
									`1970-01-01T${excursion.timeEnd}`,
									true,
								)}
							</Typo>
						)}
						{excursion?.name && <Typo design="text-m">{excursion.name}</Typo>}
					</div>

					{(excursion?.type || excursion?.type === 0) && (
						<div className={styles.fullInfo_excursionType}>
							<Icon id={excursionType?.icon as IconId} width={20} height={20} />
							<Typo>{excursionType?.name}</Typo>
						</div>
					)}

					{excursion?.description && <Typo design="text-m" raw={excursion.description} />}

					{Array.isArray(excursion?.photos) && excursion?.photos?.length ? (
						<CardMedia images={excursion.photos} hasControls />
					) : null}
				</div>
			</div>
		</div>
	);
});
