import React, { useRef } from 'react';
import { DateValue } from '@react-types/datepicker';
import { Placement } from '@react-types/overlays';
import { useDatePicker } from 'react-aria';
import { DatePickerStateOptions, useDatePickerState } from 'react-stately';
// import { TIMEZONE_NULL } from 'shared/config';
// import { ControlsTheme } from 'shared/lib/types';
// import { formatDate } from 'shared/lib/utils';
// import { IconId } from 'shared/ui/icons';
// import { CalendarProps, Calendar } from 'shared/ui/molecules';
// import { SelectHead, SelectWrap } from 'shared/ui/molecules/select-head';
import { useSelectDatesButton } from '../../lib';
import { CalendarPopover } from '../calendar-popover';
import { ControlsTheme, TIMEZONE_NULL } from 'config/commonConstants';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Calendar, CalendarProps } from 'layout/components/inputs/calendar';
import { SelectWrap, SelectHead } from 'layout/components/inputs/selects/select-head';
import { formatDate } from 'utils/date';

export interface SingleDatePickerProps<T extends DateValue = DateValue>
	extends DatePickerStateOptions<T> {
	label?: string;
	names?: string[];
	placeholder?: string;
	className?: string;
	classNameTrigger?: string;
	classNamePopover?: string;
	size?: 'sm' | 'md' | 'lg';
	placement?: Placement;
	icon?: IconId;
	theme?: ControlsTheme;
	error?: JSX.Element;
	calendar?: Pick<CalendarProps, 'dateSelector'>;
	moveIcon?: boolean;
}

export const SingleDatePicker = (props: SingleDatePickerProps) => {
	const ref = useRef(null);
	const {
		label,
		placeholder,
		className,
		classNameTrigger,
		isDisabled,
		isRequired,
		size = 'lg',
		error,
		theme,
		placement = 'bottom left',
		icon = 'month',
		calendar,
		moveIcon = false,
	} = props;

	const state = useDatePickerState(props);
	const { isOpen, setOpen, value: dateValue, setValue } = state;
	const {
		labelProps,
		calendarProps,
		groupProps,
		dialogProps,
		buttonProps: triggerProps,
	} = useDatePicker(props, state, ref);

	const { buttonProps } = useSelectDatesButton({
		triggerProps,
		ref,
		isOpen,
		setOpen,
	});

	const getValueText = () => {
		const date = dateValue?.toDate(TIMEZONE_NULL);

		if (date) {
			return `${formatDate(date, 'DD.MM.YYYY')}`;
		}

		return placeholder;
	};

	const value = getValueText();

	return (
		<SelectWrap
			size={size}
			className={className}
			isFocused={isOpen}
			isDisabled={isDisabled}
			isInvalid={!!error}
			theme={theme}
			error={error}
			{...groupProps}
		>
			<SelectHead
				ref={ref}
				label={label}
				value={value}
				size={size}
				icon={icon}
				theme={theme}
				labelProps={labelProps}
				buttonProps={buttonProps}
				isPlaceholder={value === placeholder}
				isRequired={isRequired}
				isOpen={isOpen}
				className={classNameTrigger}
				moveIcon={moveIcon}
				onReset={() => {
					// @ts-ignore
					setValue(null);
				}}
			/>
			{isOpen && (
				<CalendarPopover
					triggerRef={ref}
					state={state}
					dialogProps={dialogProps}
					placement={placement}
				>
					<Calendar {...calendarProps} {...calendar} />
				</CalendarPopover>
			)}
		</SelectWrap>
	);
};
