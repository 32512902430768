import { ReactNode } from 'react';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface DataBlockProps {
	title?: string;
	text?: string;
	children: ReactNode;
}

export const DataBlock = ({ title, text, children }: DataBlockProps) => (
	<div className={styles.block}>
		<div className={styles.block__head}>
			{title && <Typo design="headline-m">{title}</Typo>}
			{text && <Typo design="text-m">{text}</Typo>}
		</div>
		{children}
	</div>
);
