/**
 * InfoFlot API
 * API for InfoFlot
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RequestCabin } from './request-cabin';
import { RequestCruise } from './request-cruise';
import { RequestDocument } from './request-document';
import { RequestParent } from './request-parent';
import { RequestService } from './request-service';

export interface Request {
	id?: number;
	createdDate?: string;
	status?: number;
	autoComplete?: boolean;
	number?: string;
	amount?: number;
	prepayment?: number;
	additional?: number;
	prepaymentDate?: string;
	expirationDate?: string;
	payed?: number;
	refunded?: number;
	comment?: string;
	billStatus?: RequestBillStatusEnum;
	cruise?: RequestCruise;
	excursionsCount?: number;
	cabins?: Array<RequestCabin>;
	services?: Array<RequestService>;
	documents?: Array<RequestDocument>;
	parentRequest?: RequestParent | null;
	corporationId?: number;
	defaultPrice?: number;
	isPayable?: boolean;
}

export enum RequestBillStatusEnum {
	NOT_REQUIRED = 0,
	REQUIRED = 1,
	BILLED = 2,
}
