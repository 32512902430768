import { fetcher } from 'api/setupAxios';
import { ParsedUrlQuery } from 'querystring';
import { ACCOUNT_URL } from 'store/auth/constants';
import { AddExcursionsRequest } from 'store/auth/types/account/add-excursions-request';
import { Excursion } from 'store/auth/types/account/excursion';
import { LoadRequests200Response } from 'store/auth/types/account/load-requests-200-response';
import { Ship } from 'store/auth/types/account/ship';
import { UpdatePassengerRequest } from 'store/auth/types/account/update-passenger-request';
import { getCruiseUrl } from 'store/cruise/cruises/url';
import { getShipUrl } from 'store/cruise/ships/url';
import { stringifyParsedUrlQuery } from 'utils/object';
// import { ACCOUNT_URL } from 'shared/config';
// import {
// 	AddExcursionsRequest,
// 	Excursion,
// 	LoadRequests200Response,
// 	Request,
// 	Ship,
// 	UpdatePassengerRequest,
// } from 'shared/lib/types/account/models';
// import { stringifyParsedUrlQuery } from 'shared/lib/utils';
// import { fetcher } from '../../config';

export interface GetRequestsParams {
	total?: number;
	perPage?: number;
	filters?: Nullable<ParsedUrlQuery>;
}

export const getRequests = ({ filters, perPage }: GetRequestsParams) => {
	const stringQuery = stringifyParsedUrlQuery({
		...filters,
	});

	return fetcher<LoadRequests200Response>({
		url: `${ACCOUNT_URL}/requests${stringQuery ? `?${stringQuery}` : ''}`,
		authorization: true,
		body: {
			perPage,
		},
	});
};

export const getAvailableShips = () =>
	fetcher<Ship[]>({
		url: `${ACCOUNT_URL}/requests/ships`,
		authorization: true,
	});

export const getDetailedRequest = (id: string) =>
	fetcher<Request>({
		url: `${ACCOUNT_URL}/requests/${id}`,
		authorization: true,
	});

// путь к странице корабля
export const getShipPathRequest = (id: string) =>{
	const res = getShipUrl({ id })
	return res
}

// путь к странице круиза
export const getCruisePathRequest = (id: string) => {
	const res = getCruiseUrl({ id })
	return res
}

export const getDetailedRequestExcursions = (id: string) =>
	fetcher<Excursion[]>({
		url: `${ACCOUNT_URL}/requests/${id}/excursions`,
		authorization: true,
	});

export const cancelRequest = (id: number) =>
	fetcher<{ status: number }>({
		url: `${ACCOUNT_URL}/requests/${id}`,
		method: 'DELETE',
		authorization: true,
	});

export const requestBill = (id: number) =>
	fetcher({
		url: `${ACCOUNT_URL}/requests/${id}/bill`,
		method: 'POST',
		authorization: true,
	});

export const openDetailedRequestDoc = (url: string) =>
	fetcher<Blob>({
		url,
		authorization: true,
		responseType: 'blob',
		headers: new Headers({
			'Content-Type': 'application/pdf',
		}),
	});

export const editPassenger = ({ id, data }: { id: string; data: UpdatePassengerRequest }) =>
	fetcher({
		url: `${ACCOUNT_URL}/passengers/${id}`,
		authorization: true,
		method: 'PUT',
		body: data,
	});

export interface AddExcursionsRequestPayload {
	id: string | number;
	data: AddExcursionsRequest;
}

export const addExcursionsRequest = ({ id, data }: AddExcursionsRequestPayload) =>
	fetcher({
		url: `${ACCOUNT_URL}/requests/${id}/excursions`,
		authorization: true,
		method: 'POST',
		body: data,
	});

export const accountRequests = {
	getRequests,
	getAvailableShips,
	getDetailedRequest,
	getCruisePathRequest,
	getShipPathRequest,
	getDetailedRequestExcursions,
	cancelRequest,
	openDetailedRequestDoc,
	editPassenger,
	requestBill,
	addExcursionsRequest,
};
