import { AxiosError } from 'axios';
import { status } from 'patronum';
import { createEffect, createEvent, sample, createStore } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
// import { profileModel } from 'entities/profile';
// import { bonusesRequests, GetCruisesTransferPointsRequest, handbooksRequests } from 'shared/api';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { Errors } from 'shared/lib/types';
// import {
// 	BonusRecoveryRequest,
// 	BonusRecoveryRequestSellerEnum,
// } from 'shared/lib/types/account/bonus-recovery-request';
// import { Cruise } from 'shared/lib/types/account/models';
// import { Ship } from 'shared/lib/types/account/ship';
// import { calendarDateToISO, trimFactory, setAnalytics } from 'shared/lib/utils';
// import { getValidation } from 'shared/ui/organisms/form/lib/validation';
import { getCruisesParams, getError, BonusRecoveryFormValues, bonusSupportScheme } from '../lib';
import { ANALYTICS_TARGETS } from 'config/constants';
import { message } from 'config/constants/message';
import { Errors } from 'config/types/forms/errors';
import { profileModel } from 'layout/components/auth/profile';
import { getValidation } from 'layout/components/inputs/form/lib/validation';
import { bonusesRequests } from 'store/account/api/bonuses';
import { handbooksRequests, GetCruisesTransferPointsRequest } from 'store/account/api/handbooks';
import { BonusRecoveryRequest, BonusRecoveryRequestSellerEnum } from 'store/auth/types/account/bonus-recovery-request';
import { Cruise } from 'store/auth/types/account/cruise';
import { Ship } from 'store/auth/types/account/ship';
import { calendarDateToISO } from 'utils/date';
import { trimFactory } from 'utils/form';

const ModalGate = createGate<boolean>();

const bonusRecovery = createEvent<BonusRecoveryFormValues>();
const bonusRecoveryFx = createEffect<BonusRecoveryRequest, unknown, AxiosError<Errors.Errors>>(
	bonusesRequests.bonusRecovery,
);

const getListOfShips = createEvent();
const getListOfShipsFx = createEffect(handbooksRequests.getListOfShips);

const getCruises = createEvent<Partial<GetCruisesTransferPointsRequest>>();
const getCruisesFx = createEffect(handbooksRequests.getCruises);

const reset = createEvent();

const $status = status({ effect: bonusRecoveryFx }).reset(reset);

const $listOfShips = createStore<Ship[]>([]);
const $cruises = createStore<Cruise[]>([]);

const $paramsForRequestCruises = createStore<Partial<GetCruisesTransferPointsRequest>>({}).reset(
	reset,
);

const handleBonusRecoveryAnalyticsFx = createEffect(() => {}
	// setAnalytics(ANALYTICS_TARGETS.account.account_points_recovery_sent_form),
);

const form = createForm<BonusRecoveryFormValues>({
	initialValues: {
		seller: BonusRecoveryRequestSellerEnum.infoflot,
		sellerName: '',
		dates: null,
		ship: '',
		cruise: '',
		cabin: '',
		email: '',
	},
	onSubmit: ({ values }) => bonusRecovery(values),
});

sample({
	clock: ModalGate.state,
	filter: (clock) => !clock,
	target: reset,
});

sample({
	clock: ModalGate.state,
	source: {
		profile: profileModel.$profile,
		formValues: form.$values,
	},
	fn: ({ profile, formValues }) => ({
		...formValues,
		email: profile?.email || formValues.email,
	}),
	target: form.setValues,
});

sample({
	clock: bonusRecovery,
	fn: ({ dates, ...rest }) => {
		const dateStart = calendarDateToISO(dates?.start, false) || '';
		const dateEnd = calendarDateToISO(dates?.end, false) || '';

		return {
			dateStart,
			dateEnd,
			...rest,
		};
	},
	target: bonusRecoveryFx,
});

sample({
	clock: bonusRecoveryFx.doneData,
	target: form.reset,
});

sample({
	clock: bonusRecoveryFx.failData,
	fn: () => ({
		field: 'formError',
		error: message.bonusSupport.error,
	}),
	target: form.setOrDeleteError,
});

/**
 * Список теплоходов
 */
sample({
	clock: ModalGate.open,
	target: getListOfShips,
});

sample({
	clock: getListOfShips,
	target: getListOfShipsFx,
});

sample({
	clock: getListOfShipsFx.doneData,
	target: $listOfShips,
});

/**
 * Список круизов
 */
sample({
	source: $paramsForRequestCruises,
	clock: form.$values,
	filter: (source, { ship, dates }) => {
		if (!ship || !dates) {
			return false;
		}

		const params = getCruisesParams({ ship, dates });

		return JSON.stringify(params) !== JSON.stringify(source);
	},
	fn: (_, { ship, dates }) => getCruisesParams({ ship, dates }),
	target: $paramsForRequestCruises,
});

sample({
	clock: $paramsForRequestCruises,
	target: getCruises,
});

sample({
	clock: getCruises,
	target: getCruisesFx,
});

sample({
	clock: getCruisesFx.doneData,
	target: $cruises,
});

sample({
	clock: getCruisesFx.doneData,
	filter: (clock) => !clock.length,
	fn: () => ({
		field: 'formError',
		error: getError('AC5'),
	}),
	target: form.setOrDeleteError,
});

trimFactory(form, ['sellerName', 'cabin']);

const $seller = form.$values.map(({ seller }) => seller);

sample({
	clock: [$seller, reset],
	source: {
		seller: $seller,
		fieldsInline: form.$fieldsInline,
	},
	fn: ({ seller, fieldsInline }) => ({
		field: 'sellerName',
		state: {
			...(fieldsInline.sellerName ?? {}),
			validate: getValidation({ ...bonusSupportScheme.sellerName, required: seller === 'other' }),
		},
	}),
	target: form.setFieldState,
});

/**
 * Обработка аналитики
 */
sample({
	clock: bonusRecoveryFx.doneData,
	target: handleBonusRecoveryAnalyticsFx,
});

export const model = {
	ModalGate,
	form,
	$status,
	$listOfShips,
	$cruises,
};
