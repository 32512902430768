import React, { PropsWithChildren, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { CruiseDuration } from 'entities/cruises/ui';
// import { AccountRoutes } from 'shared/config';
// import { Excursion, Request } from 'shared/lib/types/account/models';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { WarningMessage } from 'shared/ui/molecules';
// import { ShipInfo } from 'shared/ui/organisms';
import { DetailedRequestAnchors } from '../../lib';
import styles from './styles.module.scss';
import { Request } from 'store/auth/types/account/request';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { ShipInfo } from 'layout/components/view/ship-info';
import { WarningMessage } from 'layout/components/view/warning-message';
import { AccountRoutes } from 'store/auth/constants';
import { Excursion } from 'store/auth/types/account/excursion';

interface RequestCruiseProps extends PropsWithChildren {
	id: Request['id'];
	cruise: Request['cruise'];
	excursions: Excursion[] | null;
	excursionsCount: Request['excursionsCount'];
	isEditable?: boolean;
	allowAddExcursions?: boolean;
	cruisePath: string | null;
	cruiseShipPath: string | null;
}

export const RequestCruise = ({
	id,
	cruise,
	excursions,
	excursionsCount,
	isEditable = false,
	allowAddExcursions = false,
	children,
	cruisePath,
	cruiseShipPath,
}: RequestCruiseProps) => {
	const [isExpanded, setExpanded] = useState<boolean>(true);

	return (
		<div className={styles.cruiseInfo_wrap} id={DetailedRequestAnchors.AboutCruise}>
			{children}
			<div className={styles.cruiseInfo}>
				<div className={styles.cruiseInfo_header}>
					<Typo design="headline-m" as="h3">
						О круизе
					</Typo>
					{/* <Button btnType="text" href={`/cruise/${cruise?.id}`} target="_blank"> */}
					<Button btnType="text" href={`${cruisePath}`} target="_blank">
						Подробнее о круизе
					</Button>
				</div>
				<div className={styles.shipInfo_wrap}>
					{cruise?.name && (
						<Typo design="headline-s" className={styles.cruiseInfo_shortRoute}>
							{cruise.name}
						</Typo>
					)}
					<div className={styles.shipInfo_container}>
						<ShipInfo {...cruise?.ship} theme="black" />
						{/* <Button btnType="text" href={`/ship/${cruise?.ship?.id}`} target="_blank"> */}
						<Button btnType="text" href={`${cruiseShipPath}`} target="_blank">
							Подробнее о теплоходе
						</Button>
					</div>
				</div>
				<CruiseDuration
					dateStart={cruise?.startDate}
					dateEnd={cruise?.endDate}
					days={cruise?.lengthDays}
					nights={cruise?.isForeign && cruise.lengthNights}
					className={styles.cruiseRoute_days}
				/>
				{cruise?.route && (
					<div className={styles.cruiseInfo_fullRoute}>
						<Button
							btnType="text"
							className={classnames(styles.toggleButton, { [styles.isCollapsed]: !isExpanded })}
							contextClassName={styles.toggleButton_ctx}
							onClick={() => setExpanded((prev) => !prev)}
						>
							<span>Полный маршрут</span>
							<Icon id="chevron-down" width={12} height={12} />
						</Button>
						<AnimateHeight height={isExpanded ? 'auto' : 0} duration={400} animateOpacity>
							<Typo design="text-s" className={styles.fullRoute_text}>
								{cruise.route}
							</Typo>
						</AnimateHeight>
					</div>
				)}
			</div>
			{excursions?.length ? (
				<div className={styles.excursionInfo} id={DetailedRequestAnchors.AdditionalExcursions}>
					<Typo design="headline-m">Дополнительные экскурсии</Typo>
					<Typo design="text-m" className={styles.excursionInfo_text}>
						Помимо основных экскурсий, включенных в эту заявку, Вы можете добавить дополнительные
						экскурсии для любого туриста
					</Typo>
					{isEditable && (
						<WarningMessage className={styles.excursionInfo_warning}>
							Добавленные экскурсии можно удалить из заказа, обратившись в ваш офис обслуживания.
						</WarningMessage>
					)}
					<div className={styles.excursionInfo_actions}>
						{allowAddExcursions ? (
							<Button
								href={`${AccountRoutes.MyRequests}/${id}/excursions/create`}
								btnType="outline"
								size="large"
								className={styles.addExcursion}
								contextClassName={styles.addExcursion_ctx}
							>
								<Icon id="plus" width={20} height={20} />
								<span>Добавить экскурсию</span>
							</Button>
						) : (
							<WarningMessage className={styles.excursionInfo_warning}>
								Нельзя добавить доп. экскурсию в текущем статусе заявки
							</WarningMessage>
						)}
						{excursionsCount ? (
							<div className={styles.excursionInfo_count}>
								<Typo design="text-m">Добавлено экскурсий</Typo>
								<Typo design="headline-s">{excursionsCount}</Typo>
							</div>
						) : null}
					</div>
				</div>
			) : null}
		</div>
	);
};
