import { FC, useMemo } from 'react';
import { RangeCalendarState } from 'react-stately';
// import { TIMEZONE_NULL } from 'shared/config';
// import { formatDate } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
import style from './style.module.scss';
import { TIMEZONE_NULL } from 'config/commonConstants';
import { Typo } from 'layout/components/typo/ui';
import { formatDate } from 'utils/date';

export interface RangeTipsProps {
	state: RangeCalendarState;
	isQualityControlComponent?: boolean;
}

export const RangeTips: FC<RangeTipsProps> = ({ state, isQualityControlComponent }) => {
	const value = useMemo(() => {
		const start = state?.highlightedRange?.start;
		const end = start &&
			state?.highlightedRange?.end?.compare(start) !== 0 ? state?.highlightedRange?.end : null;

		const startDate = formatDate(start?.toDate(TIMEZONE_NULL), 'DD.MM.YY');
		const endDate = formatDate(end?.toDate(TIMEZONE_NULL), 'DD.MM.YY');

		const showDash =
			!(state?.value?.end && state?.highlightedRange?.start?.compare(state?.value?.end) === 0) ||
			state?.highlightedRange?.start?.compare(state?.highlightedRange?.end) !== 0;

		if (startDate) {
			return (
				<>
					{!isQualityControlComponent && 'Отправление '}
					<div className={style.rangeTips__dates}>
						<span>{startDate}</span>
						{endDate && (
							<>
								{showDash && <span>&mdash;</span>}
								<span>{endDate}</span>
							</>
						)}
					</div>
				</>
			);
		}
		const text = isQualityControlComponent
			? 'Выберите период круиза'
			: 'Выберите диапазон отправления';

		return text;
	}, [state, isQualityControlComponent]);

	return (
		<Typo className={style.rangeTips} design="input-text-s">
			{value}
		</Typo>
	);
};
