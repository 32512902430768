import React from 'react';
import classnames from 'classnames';
// import { Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { ButtonProps } from './lib/types';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';

export const LeftButton = ({ activePage, onPageChange }: ButtonProps) => (
	<li className={styles.paginationItem}>
		<Button
			className={styles.paginationButton}
			onClick={() => onPageChange(activePage - 1)}
			disabled={activePage === 1}
		>
			<Icon id="chevron-left" width={20} height={20} />
		</Button>
	</li>
);

export const RightButton = ({ activePage, onPageChange, pagesNumber }: ButtonProps) => (
	<li className={styles.paginationItem}>
		<Button
			className={styles.paginationButton}
			onClick={() => onPageChange(activePage + 1)}
			disabled={activePage === pagesNumber}
		>
			<Icon id="chevron-right" width={20} height={20} />
		</Button>
	</li>
);

export const EmptyButton = () => (
	<li className={styles.paginationItem}>
		<Button className={classnames(styles.paginationButton, styles.paginationButtonEmpty)}>
			...
		</Button>
	</li>
);

export const PageButton = ({
	activePage,
	onPageChange,
	index,
}: ButtonProps & { index: number }) => (
	<li className={styles.paginationItem}>
		<Button
			onClick={index !== activePage ? () => onPageChange(index) : undefined}
			className={classnames(
				styles.paginationButton,
				index === activePage && styles.paginationButtonActive,
			)}
		>
			{index}
		</Button>
	</li>
);
