import { useViewport } from 'layout/viewport';
import { RefObject, useEffect, useState } from 'react';
// import { useViewport } from 'shared/lib/viewport';

interface UseTopFixedPanelParams {
	shift?: number;
	active?: boolean;
	onScroll?: (showPanel: boolean) => void;
}

export const useTopFixedPanel = (
	ref: RefObject<HTMLElement>,
	{ shift = 0, onScroll, active = true }: UseTopFixedPanelParams,
) => {
	const { isMob } = useViewport();
	const [showFixedPanel, setShowFixedPanel] = useState(false);

	useEffect(() => {
		const panel = ref.current;

		if (active && panel && !isMob) {
			const handleScroll = () => {
				const { bottom } = panel.getBoundingClientRect();
				const showPanel = bottom + shift <= 0;

				setShowFixedPanel(showPanel);
				if (onScroll) {
					onScroll(showPanel);
				}
			};

			window.addEventListener('scroll', handleScroll, { passive: true });
			window.addEventListener('resize', handleScroll);

			return () => {
				window.removeEventListener('scroll', handleScroll);
				window.removeEventListener('resize', handleScroll);
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMob]);

	return { showFixedPanel };
};
