import { spread, status } from 'patronum';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
// import { createForm } from 'effector-react-form/scope';
// import { detailedRequest } from 'widgets/account/model';
// import { RequestPaymentProps, requestPaymentRequests } from 'shared/api';
// import { message } from 'shared/lib/message';
// import { Errors } from 'shared/lib/types';
// import { Request } from 'shared/lib/types/account/request';
// import { getFailCode } from 'shared/lib/utils';
// import { getValidation } from 'shared/ui/organisms/form/lib/validation';
import { requestPaymentValidationScheme } from '../lib';
import { getFailCode } from 'api';
import { message } from 'config/constants/message';
import { Errors } from 'config/types/forms/errors';
import { createForm } from 'effector-react-form';
import { detailedRequest } from 'entities/account/model';
import { getValidation } from 'layout/components/inputs/form/lib/validation';
import { Request } from 'store/auth/types/account/request';
import { RequestPaymentProps, requestPaymentRequests } from 'store/account/api/request-payment';

const resetRequestData = createEvent();

const $requestId = createStore<Nullable<Request['id']>>(null).reset(resetRequestData);
const $requestPayed = createStore<Nullable<Request['payed']>>(null).reset(resetRequestData);
const $requestAmount = createStore<Nullable<Request['amount']>>(null).reset(resetRequestData);
const $requestPrepayment =
	createStore<Nullable<Request['prepayment']>>(null).reset(resetRequestData);
const $requestPrepaymentDate =
	createStore<Nullable<Request['prepaymentDate']>>(null).reset(resetRequestData);

sample({
	clock: detailedRequest.$detailedRequest,
	filter: Boolean,
	target: spread({
		targets: {
			id: $requestId,
			payed: $requestPayed,
			amount: $requestAmount,
			prepayment: $requestPrepayment,
			prepaymentDate: $requestPrepaymentDate,
		},
	}),
});

sample({
	clock: detailedRequest.childRequestGate.close,
	target: resetRequestData,
});

const Gate = createGate<{ id: number }>();
const reset = createEvent();

const submitForm = createEvent<Pick<RequestPaymentProps, 'amount' | 'paymentMethod'>>();

const getRequestPaymentInfo = createEvent<RequestPaymentProps>();
const getRequestPaymentInfoFx = createEffect(requestPaymentRequests.requestPayment);

const openPayment = createEffect((url: string) => {
	const a = document.createElement('a');
	a.style.display = 'none';
	document.body.append(a);
	a.href = url;
	a.target = '_self';
	a.click();
	window.URL.revokeObjectURL(url);
	a.remove();
});

const handleBadResponse = createEvent<number | undefined>();

const $status = status({ effect: getRequestPaymentInfoFx });
const $error = createStore<Nullable<Errors.Errors>>(null).reset(reset);

const form = createForm<Pick<RequestPaymentProps, 'amount' | 'paymentMethod'>>({
	initialValues: {
		amount: 0,
		paymentMethod: 'ac',
	},
	onSubmit: ({ values }) => submitForm(values),
	validate: ({ values }) => {
		const errors: Partial<Record<'amount' | 'formError', string>> = {};
		const payed = $requestPayed.getState() ?? 0;
		const amount = $requestAmount.getState() ?? 0;
		const prepayment = $requestPrepayment.getState() ?? 0;

		const request = detailedRequest.$detailedRequest.getState();

		const cabins =
			request?.cabins?.length === 0 && request.parentRequest
				? request.parentRequest.cabins
				: request?.cabins;

		const passengersPassportValidation = cabins
			?.map((cab) =>
				cab.passengers?.map(
					({ passportNumber, passportSeries, documentType, type }) =>
						((Boolean(passportNumber) || Boolean(passportSeries)) && Boolean(documentType)) ||
						`${type}` === '3',
				),
			)
			.flat(3)
			.every(Boolean);

		if (!passengersPassportValidation) {
			errors.formError = 'Паспортные данные пассажиров должны быть заполнены';
		}

		const min = prepayment - payed;
		const max = amount - payed;

		Object.entries(values).forEach(([key, value]) => {
			if (key === 'amount') {
				const validate = getValidation(requestPaymentValidationScheme(min, max).amount);
				errors.amount = validate(value);
			}
		});

		return errors;
	},
});

const $discount = createStore<Nullable<number>>(null);
const getDiscount = createEvent<number>();
const getDiscountFx = createEffect(requestPaymentRequests.requestPaymentDiscount);

/**
 * request sbp-discount
 */
sample({
	clock: Gate.state,
	filter: ({ id }) => Boolean(id),
	fn: ({ id }) => id,
	target: getDiscount,
});

sample({
	clock: getDiscount,
	target: getDiscountFx,
});

sample({
	clock: getDiscountFx.doneData,
	fn: ({ discount }) => discount,
	target: $discount,
});

/**
 * reset form
 */
sample({
	clock: Gate.close,
	target: reset,
});

/**
 * submit form
 */
sample({
	clock: submitForm,
	source: $requestId,
	fn: (id, params) => ({
		...params,
		id,
		amount: Number(params.amount),
	}),
	target: getRequestPaymentInfo,
});

sample({
	clock: getRequestPaymentInfo,
	target: getRequestPaymentInfoFx,
});

sample({
	clock: getRequestPaymentInfoFx.doneData,
	fn: ({ url }) => url,
	target: openPayment,
});

/**
 * form errors
 */
sample({
	clock: getRequestPaymentInfoFx.failData,
	fn: (error) => getFailCode(error),
	target: handleBadResponse,
});

sample({
	clock: handleBadResponse,
	fn: (code) => ({
		field: 'formError',
		error: message.requests.payment.errorCodes[code || 'error'],
	}),
	target: form.setOrDeleteError,
});

export const model = {
	Gate,
	getRequestPaymentInfo,
	$status,
	form,
	$error,
	$discount,
};
