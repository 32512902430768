import React, { FC } from 'react';
// import { AccountRoutes } from 'shared/config';
// import { RequestCard } from 'shared/lib/types/account/models';
// import { formatDate, getExpirationMessage, getNextPaymentDate } from 'shared/lib/utils';
// import { Button, Price, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';
import { AccountRoutes } from 'store/auth/constants';
import { RequestCard } from 'store/auth/types/account/request-card';
import { getNextPaymentDate, getExpirationMessage, formatDate } from 'utils/date';

export const RequestCardShort: FC<RequestCard> = ({
	id,
	number,
	amount,
	payed,
	expirationDate,
	prepaymentDate,
	prepayment,
}) => {
	const nextPaymentDate = getNextPaymentDate({
		expirationDate,
		prepaymentDate,
		payed,
		prepayment,
	});
	const { message: expirationMessage } = getExpirationMessage(nextPaymentDate) ?? {};
	const restPayment = amount && payed && payed <= amount ? amount - payed : null;

	return (
		<div className={styles.requestCardShort}>
			<div className={styles.requestCardShort__info}>
				{expirationDate && (
					<Typo className={styles.requestCardShort__expiration} design="description-m">
						<Icon id="payment-time" width={12} height={12} />
						{expirationMessage !== null
							? `Срок оплаты истекает через ${expirationMessage} (${formatDate(nextPaymentDate)})`
							: 'Срок оплаты истёк'}
					</Typo>
				)}

				<Typo className={styles.requestCardShort__props} design="text-m">
					{number && (
						<Typo className={styles.requestCardShort__num} design="headline-m">
							№&nbsp;{number}
						</Typo>
					)}

					{typeof amount === 'number' && (
						<div className={styles.requestCardShort__prop}>
							<span>Стоимость</span>
							<Price
								total={amount}
								design="headline-s"
								className={styles.requestCardShort__price}
								bold
							/>
						</div>
					)}

					{typeof restPayment === 'number' && (
						<div className={styles.requestCardShort__prop}>
							<span>Осталось оплатить</span>
							<Price
								total={restPayment}
								design="headline-s"
								className={styles.requestCardShort__price}
								bold
							/>
						</div>
					)}
				</Typo>
			</div>
			<Button
				size="large"
				btnType="outline"
				href={`${AccountRoutes.MyRequests}/${id}`}
				className={styles.requestCardShort__link}
			>
				Перейти к оплате
			</Button>
		</div>
	);
};
