import { createFileRoute } from '@tanstack/react-router'
import { AccountLayout } from 'layout/layouts/account'
import { Main } from 'layout/layouts/main'
import { Requests } from 'layout/layouts/widgets/account'
import accountStyles from './../../../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute(
  '/account/my-requests/$bidId/excursions/$exid',
)({
  component: () => <ExcursionPage />,
})

const ExcursionPage = () => {
  return (
    <Main>
      <AccountLayout>
        <Requests />
      </AccountLayout>
    </Main>
  )
}

// import type { NextPage } from 'next';
// import { RequestExcursionsDetails } from 'widgets/account';
// import { detailedRequest } from 'widgets/account/model';
// import { AccountLayout } from 'widgets/layouts/account';
// import { Main } from 'widgets/layouts/main';
// import { ISR_REGENERATION_TIME } from 'shared/config';
// import { staticPropsFactory } from 'shared/lib/ssr';

// import { createFileRoute } from '@tanstack/react-router'
// import { AccountLayout } from 'layout/layouts/account'
// import { Main } from 'layout/layouts/main'
// import { Requests } from 'layout/layouts/widgets/account'
// import { Main } from "next/document";

// const DetailedRequestExcursionsPage: NextPage = () => (
// 	<Main>
// 		<AccountLayout>
// 			<RequestExcursionsDetails />
// 		</AccountLayout>
// 	</Main>
// );

// export const getStaticProps = staticPropsFactory(
// 	detailedRequest.getData,
// 	ISR_REGENERATION_TIME,
// ).bind({
// 	__logName: 'DetailedRequestExcursionsPage',
// });

// export const getStaticPaths = async () => ({
// 	paths: [],
// 	fallback: 'blocking',
// });

// export default DetailedRequestExcursionsPage;

// export const DetailedRequestPage = createFileRoute('/account/my-requests/$bidId/excursions/$exid')({
// 	component: () => <Main>
// 		<AccountLayout>
// 			<Requests />
// 		</AccountLayout>
// 	</Main>,

// export const DetailedRequestExcursionsPage = createFileRoute(
//   '/account/my-requests/excursions/$exid',
// )({
//   component: () => (
//     <div>/account/my-requests/$bidId/excursions/$exid</div>
//     // <Main>
//     //   <AccountLayout>
//     //     <Requests />
//     //   </AccountLayout>
//     // </Main>
//   ),
// })
