import React, { FC, memo } from 'react';
// import { Request } from 'shared/lib/types/account/request';
import { PaymentInfoItem } from './payment-info-item';
import styles from './styles.module.scss';
import { Request } from 'store/auth/types/account/request';

interface PaymentInfoProps extends Pick<Request, 'amount' | 'payed'> {
	sumLabel?: string;
}

export const PaymentInfo: FC<PaymentInfoProps> = memo(
	({ amount, payed, sumLabel = 'Сумма круиза' }) => (
		<ul className={styles.paymentModal_paymentListInfo}>
			{amount ? <PaymentInfoItem label={sumLabel} value={amount} /> : null}
			{payed ? <PaymentInfoItem label="Внесённая предоплата" value={payed} /> : null}
			{payed && amount ? <PaymentInfoItem label="Итого к оплате" value={amount - payed} /> : null}
		</ul>
	),
);
