import React, { FC } from 'react';
import classnames from 'classnames';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { Typo } from 'shared/ui/atoms';
import style from './style.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Typo } from 'layout/components/typo/ui';

interface CalendarWeekHeadProps extends DefaultStyledProps {
	isMob?: Nullable<boolean>;
}

export const CalendarWeekHead: FC<CalendarWeekHeadProps> = ({ className, isMob }) => (
	<Typo
		design={isMob ? 'text-l' : 'text-s'}
		className={classnames(style.week, style.week_head, className)}
	>
		<span>Пн</span>
		<span>Вт</span>
		<span>Ср</span>
		<span>Чт</span>
		<span>Пт</span>
		<span>Сб</span>
		<span>Вс</span>
	</Typo>
);
