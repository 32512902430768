// import { dischargeNumber } from 'shared/lib/utils';
// import { ValidationOptions } from 'shared/ui/organisms/form/lib/validation';

import { ValidationOptions } from "layout/components/inputs/form/lib/validation";
import { dischargeNumber } from "utils/number";

export const requestPaymentValidationScheme: (
	min?: Nullable<number>,
	max?: Nullable<number>,
) => Record<'amount', ValidationOptions> = (min, max) => ({
	amount: {
		validateType: 'minMax',
		messages: {
			validateType: `Сумма платежа не может быть меньше, чем ${dischargeNumber(
				min ?? 0,
			)} и не больше чем ${dischargeNumber(max ?? 0)}`,
		},
		options: {
			min: min ?? 0,
			max: max ?? 0,
		},
	},
});
