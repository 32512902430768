import { FC, PropsWithChildren, ReactNode } from 'react';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { TypoDesignToken } from 'shared/ui/atoms';
import { AccordionDefault } from './accordion-default';
import { AccordionFilled } from './accordion-filled';
import { AccordionFilledGray } from './accordion-filled-gray';
import { AccordionFoldable } from './accordion-foldable';
import { DefaultStyledProps } from 'config/commonTypes';
import { TypoDesignToken } from 'layout/components/typo/ui';

export interface AccordionProps extends PropsWithChildren, DefaultStyledProps {
	id?: string | number;
	accordionType?: 'default' | 'foldable' | 'filled' | 'filled-gray';
	theme?: 'default' | 'withDivider';
	caption?: string | ReactNode;
	className?: string;
	headClassName?: string;
	classNameContent?: string;
	expanded?: boolean;
	captionDesing?: TypoDesignToken;
	captionDesingFreezeDesktop?: boolean;
	toggleExpanded?: () => void;
	defaultState?: boolean;
	withoutMargins?: boolean;
}

export const Accordion: FC<AccordionProps> = ({ accordionType = 'default', ...rest }) => {
	switch (accordionType) {
		case 'foldable':
			return <AccordionFoldable {...rest} />;
		case 'filled':
			return <AccordionFilled {...rest} />;
		case 'filled-gray':
			return <AccordionFilledGray {...rest} />;
		case 'default':
		default:
			return <AccordionDefault {...rest} />;
	}
};
