/* eslint-disable react/no-array-index-key */
import { FC, memo } from 'react';
import { useUnit } from 'effector-react';
// import { bonusHistoryModel } from 'widgets/account/model';
// import { BonusRecovery } from 'entities/bonus-recovery';
// import { AccountRoutes } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Typo, Button, Skeleton } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { BonusHistoryListItem } from '../bonus-history-list-item';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { bonusHistoryModel } from 'entities/account/model';
import { BonusRecovery } from 'entities/bonus-recovery';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Skeleton } from 'layout/components/special/skeleton';
import { Typo } from 'layout/components/typo/ui';
import { AccountRoutes } from 'store/auth/constants';

interface ILatestBonusesProps {
	noRecovery?: boolean
}

export const LatestBonusesCustom: FC<ILatestBonusesProps> = memo((props) => {
	const [bonushistory, status] = useUnit([
		bonusHistoryModel.$bonushistory,
		bonusHistoryModel.$bonusHistoryStatus,
	]);

	const isLoading = status === 'pending';

	return (
		<section className={styles.latestBonuses}>
			<Typo className={styles.title} design="headline-m">
				Баллы, ожидающие зачисления
			</Typo>
			<div className={styles.wrapper}>
				{!isLoading && !!bonushistory.data?.length && (
					<div className={styles.history}>
						<ul>
							{bonushistory.data?.slice(0, 5).map((item, index) => (
								<BonusHistoryListItem {...item} key={index} />
							))}
						</ul>

						{!!bonushistory?.data?.length && (
							<Button
								href={`${AccountRoutes.MyBonuses}/history`}
								btnType="text"
								size="large"
								className={styles.button}
							// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_bonus_history)}
							>
								Посмотреть всю историю зачислений
								<Icon id="chevron-right" width={20} height={20} />
							</Button>
						)}
					</div>
				)}

				{isLoading && (
					<>
						<Skeleton margin="0 0 16px" width="100%" height={60} />
						<Skeleton margin="0 0 16px" width="100%" height={60} />
						<Skeleton margin="0 0 16px" width="100%" height={60} />
						<Skeleton margin="0 0 16px" width="100%" height={60} />
						<Skeleton margin="0 0 16px" width="100%" height={60} />
					</>
				)}

				{props.noRecovery ? null : <BonusRecovery />}
			</div>
		</section>
	);
});
