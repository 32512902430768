import { useGate, useUnit } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
// import { Typo } from 'shared/ui/atoms';
// import { InputPassword } from 'shared/ui/atoms/input/input-password';
// import { Form, FormField } from 'shared/ui/organisms/form';
import { model } from '../../model';
import styles from './styles.module.scss';
import { FormField } from 'layout/components/inputs/form/field';
import { Form } from 'layout/components/inputs/form/form';
import { InputPassword } from 'layout/components/inputs/input/input-password';
import { Typo } from 'layout/components/typo/ui';

export const ChangePasswordModal = () => {
	const { form, Gate, $passwordChanged } = model;
	const { controller, handleSubmit } = useForm({ form });
	const { error, isShowError } = useError({
		form,
		name: 'formError',
	});

	useGate(Gate);
	const passwordChanged = useUnit($passwordChanged);

	return (
		<div>
			<Typo className={styles.title} design="headline-xl">
				{passwordChanged ? 'Пароль изменен' : 'Смена пароля'}
			</Typo>
			<Typo className={styles.text} design="text-m">
				{passwordChanged
					? 'Вы успешно изменили свой пароль. Войдите в систему Созвездие с новым паролем'
					: 'После изменения пароля потребуется повторно войти в систему'}
			</Typo>
			{!passwordChanged && (
				<Form
					error={error}
					isShowError={isShowError}
					formClassName={styles.form}
					handleSubmit={handleSubmit}
				>
					<FormField
						controller={controller}
						form={form}
						validation={{
							required: true,
						}}
						name="oldPassword"
					>
						<InputPassword size="lg" placeholder="Текущий пароль" withLabel required />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						validation={{
							validateType: 'changePassword',
							required: true,
							reference: {
								form,
								fieldName: 'oldPassword',
							},
						}}
						name="password"
					>
						<InputPassword size="lg" placeholder="Придумайте новый пароль" withLabel required />
					</FormField>
					<FormField
						controller={controller}
						form={form}
						validation={{
							validateType: 'repeatPassword',
							required: true,
							reference: {
								form,
								fieldName: 'password',
							},
						}}
						name="repeatPassword"
					>
						<InputPassword size="lg" placeholder="Повторите новый пароль" withLabel required />
					</FormField>
				</Form>
			)}
		</div>
	);
};
