import React, { FC, PropsWithChildren } from 'react';
// import Link from 'next/link';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
import { PAYMENT_METHODS_INFO, PAYMENT_OPTIONS, PHONE, PHONE_FOR_HREF } from './config';
import styles from './styles.module.scss';
import { Link } from '@tanstack/react-router';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Typo } from 'layout/components/typo/ui';

export const RequestsPageLayout: FC<PropsWithChildren> = ({ children }) => (
	<section className={styles.requestsPage}>
		{/* <Typo as="header" design="headline-l" className={styles.requestsPage_header}>
			Мои заявки
		</Typo> */}
		{children}
		<footer className={styles.requestsPageFooter}>
			<div className={styles.footerInfo}>
				<div className={styles.paymentListWrap}>
					<Typo as="h3" design="headline-s" className={styles.paymentListTitle}>
						Вы можете оплатить круиз любым удобным безналичным способом:
					</Typo>
					<ul className={styles.paymentList}>
						{PAYMENT_OPTIONS.map((option) => (
							<Typo key={option} as="li" design="text-m" className={styles.paymentOption}>
								{option}
							</Typo>
						))}
					</ul>
				</div>
				<div className={styles.phoneInfo}>
					<Typo design="text-m">Телефон для связи по заявке</Typo>
					<a href={`tel:${PHONE_FOR_HREF}`}>
						<Typo as="span" design="headline-s">
							{PHONE}
						</Typo>
					</a>
				</div>
			</div>
			<div className={styles.footerLinks}>
				<Link href={PAYMENT_METHODS_INFO}
				// passHref
				>
					<a
					// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_enquiries_payment)}
					>
						<Typo design="text-m">Подробнее о способах оплаты</Typo>
					</a>
				</Link>
				{/* <Link to={TOUR_OPERATORS_FINANCIAL_SECURITY_INFO} passHref>
					<a onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_enquiries_finance)}>
						<Typo design="text-m">Данные по финансовому обеспечению туроператоров</Typo>
					</a>
				</Link> */}
			</div>
		</footer>
	</section>
);
