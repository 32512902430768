/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import classnames from 'classnames';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { Skeleton, Typo } from 'shared/ui/atoms';
import style from './style.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Skeleton } from 'layout/components/special/skeleton';
import { Typo } from 'layout/components/typo/ui';

export const CalendarGridSkeleton = ({ className }: DefaultStyledProps) => {
	const date = <Skeleton width={16} height={16} margin="8px auto" />;

	return (
		<table className={classnames(style.grid, className)}>
			<Typo className={style.monthCaption} as="caption" design="headline-xs" freezeDesktop>
				<Skeleton width={100} height={16} />
			</Typo>
			<tbody>
				<tr className={style.gridRow}>
					<td />
					<td />
					<td />
					<td>{date}</td>
					<td>{date}</td>
					<td>{date}</td>
					<td>{date}</td>
				</tr>
				{[...new Array(3).keys()].map((weekIndex) => (
					<tr key={weekIndex} className={style.gridRow}>
						<td>{date}</td>
						<td>{date}</td>
						<td>{date}</td>
						<td>{date}</td>
						<td>{date}</td>
						<td>{date}</td>
						<td>{date}</td>
					</tr>
				))}
				<tr className={style.gridRow}>
					<td>{date}</td>
					<td>{date}</td>
					<td>{date}</td>
					<td />
					<td />
					<td />
					<td />
				</tr>
			</tbody>
		</table>
	);
};
