import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { OverlayTriggerState } from 'react-stately';
// import { useRouter } from 'next/router';
import { useGate, useUnit } from 'effector-react';
// import { useTotalPanelOffset } from 'features/cart-widget/lib';
// import { CartCabin } from 'features/cart-widget/ui/cart-cabin';
import { cartModel, OrderIsEmpty } from 'entities/cart';
import { CruiseDuration } from 'entities/cruises/ui';
import { userRoleModel } from 'entities/order';
import { UnauthorizedOrderAttempt, UserRoleModal } from 'entities/order/user-role';
// import { OrderRoutes } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Alert, Button, Condition, MobileView, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { Scroller } from 'shared/ui/molecules';
// import { TotalPrice } from 'shared/ui/organisms';
import { model } from '../../model';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { OrderRoutes } from 'config/constants/order';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Scroller } from 'layout/components/special/scroller';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { MobileView } from 'layout/layouts/device-view';
import { useViewport } from 'layout/viewport';
import { useTotalPanelOffset } from '../../lib';
import { CartCabin } from '../cart-cabin';
import { TotalPrice } from 'layout/components/view/total-price';
import { useLocation, useRouter, useRouterState } from '@tanstack/react-router';
import { detailedRequest } from 'entities/account/model';

interface CartModalProps {
	close?: OverlayTriggerState['close'];
}

export const CartModal = ({ close }: CartModalProps) => {
	const [
		cart,
		activeCruiseInfo,
		totalPrice,
		emptyCart,
		pricePending,
		statusesPending,
		goToOrdering,
		showOrderingDialog,
		cruisePath,
	] = useUnit([
		cartModel.$cart,
		cartModel.$activeCruiseInfo,
		cartModel.$totalPrice,
		cartModel.emptyCartDialog,
		cartModel.pricePending,
		cartModel.statusesPending,
		userRoleModel.goToOrdering,
		userRoleModel.$showDialog,
		cartModel.$cruisePath,
	]);

	// useGate(model.Gate);
	// useGate(userRoleModel.CartModalGate, { close });


	const { isMob } = useViewport();
	// // const { asPath } = useRouter();
	// const asPath = useRouterState().location.pathname
	// const router = useRouter()

	// const asPath = router.state.location.pathname

	const [shadow, setShadow] = useState(false);

	const totalPanelRef = useRef<Nullable<HTMLDivElement>>(null);
	const contentRef = useTotalPanelOffset(totalPanelRef);

	const cartIsEmpty = cart.length === 0;
	const isBonusPayment = cart.some((cartItem) => cartItem.isBonusPayment);

	const allowRemoveCabinFromCart = cart.length > 1 //|| !asPath.includes(OrderRoutes.Tourists);


	return (
		<div className={styles.wrapper}>
			{/* <div>CartModal</div> */}
			<Condition
				value={showOrderingDialog && !isMob}
				then={<UnauthorizedOrderAttempt inCartWidget />}
				else={
					<>
						<header className={classnames(styles.header, shadow && styles.shadow)}>
							<Typo design="headline-m">Заказ</Typo>
							{!cartIsEmpty && (
								<Button
									onClick={emptyCart}
									btnType="text"
									size="large"
									disabled={pricePending || statusesPending}
								>
									Очистить <Icon id="trash" width={20} height={20} />
								</Button>
							)}
						</header>

						{!cartIsEmpty && (
							<>
								<div ref={contentRef} className={styles.contentWrap}>
									<Scroller
										className={styles.scroller}
										onScroll={(scroll) => setShadow(scroll.y > 0)}
										showOnHover
									>
										<div className={styles.content}>
											<Alert size="lg" iconId="timer" className={styles.alert}>
												<Typo design="text-s">
													Завершите заказ по актуальным ценам. Бронь фиксируется только после
													оформления заказа. Внимание! При закрытии сайта информация о выбранных
													каютах не будет сохранена.
												</Typo>
											</Alert>
											{activeCruiseInfo && (
												<div className={styles.activeCruise}>
													<div className={styles.activeCruise__about}>
														<Typo design="headline-s">{activeCruiseInfo.route.short || ''}</Typo>
														<Button
															className={styles.activeCruise__link}
															// href={`/cruises/${activeCruiseInfo.id}`}
															href={`${cruisePath}`}
															size="large"
															btnType="text"
														>
															О круизе <Icon id="chevron-right" width={20} height={20} />
														</Button>
													</div>
													<CruiseDuration
														className={styles.dates}
														displayTime={false}
														dateStart={activeCruiseInfo.date.start}
														dateEnd={activeCruiseInfo.date.end}
														days={activeCruiseInfo.length.days}
														nights={
															activeCruiseInfo.type === 'sea' && activeCruiseInfo.length.nights
														}
														sizeVariant="large"
													/>
												</div>
											)}
											<div className={styles.cabins}>
												{cart.map((cabin) => (
													<CartCabin
														{...cabin}
														key={cabin.id}
														pending={pricePending}
														allowRemove={allowRemoveCabinFromCart}
													/>
												))}
											</div>
										</div>
									</Scroller>
								</div>
								<div ref={totalPanelRef} className={styles.total}>
									{totalPrice && totalPrice.bonus && (
										<TotalPrice
											price={totalPrice}
											bonus={totalPrice.bonus}
											isBonusPayment={isBonusPayment}
										/>
									)}
									<Alert className={styles.total__alert}>
										<Typo design="text-s">
											Окончательный расчет и применение скидок будут осуществлены после бронирования
											и заполнения данных туристов
										</Typo>
									</Alert>
									<Button
										size="large"
										// btnType="success"
										onClick={() => {
											goToOrdering();
											// setAnalytics(ANALYTICS_TARGETS.header.header_cart_reg);
										}}
										disabled={pricePending}
									>
										Перейти к бронированию
									</Button>
								</div>
							</>
						)}

						{cartIsEmpty && <OrderIsEmpty className={styles.emptyContent} onClick={close} />}
					</>
				}
			/>

			<MobileView>
				<UserRoleModal />
			</MobileView>
		</div>
	);
};
