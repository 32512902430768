import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "../constants";


export const verifyEmail = () =>
	fetcher({
		url: `${ACCOUNT_URL}/profile/email/verify`,
		body: {},
		authorization: true,
		method: 'POST',
	});
