import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import { Typo } from 'shared/ui/atoms/typo';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

export interface BonusPaymentProps {
	size?: 'sm';
	className?: string;
	withoutIcon?: boolean;
}

export const BonusPayment: FC<BonusPaymentProps> = memo(({ className, size, withoutIcon }) => (
	<Typo
		className={classnames(className, styles.bonusPayment, size && styles[size])}
		design="description-features"
	>
		<span>
			Можно купить
			<br />
			за баллы!
		</span>
		{!withoutIcon && <Icon id="klk" width={33} height={26} />}
	</Typo>
));
