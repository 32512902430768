import { Mask } from 'maska';
import { createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import {
	DOCUMENT_TYPES_MASK_TOKENS,
	documentTypesModel,
	documentTypesValidationFactory,
} from 'entities/document-types';
import { message } from 'config/constants/message';
import { profileModel, parsePassportData } from 'layout/components/auth/profile';
// import { parsePassportData, profileModel } from 'entities/profile';
// import { message } from 'shared/lib/message';

interface FormValues {
	documentType: number | '';
	passportData: Nullable<string>;
}

const Gate = createGate();

const form = createForm<FormValues>({
	initialValues: {
		documentType: '',
		passportData: '',
	},
	validate: (params) => {
		const errors: Partial<Record<keyof FormValues, string>> = {};

		if (!params.values.documentType) {
			errors.documentType = message.form.validation.required;
		}
		if (!params.values.passportData) {
			errors.passportData = message.form.validation.required;
		}

		return errors;
	},
});

const selectDocumentType = createEvent<Nullable<number>>();

const $pattern = createStore<string>('');
const $id = form.$values.map(({ documentType }) => documentType);

const { $scheme: $documentTypesValidation } = documentTypesValidationFactory({
	field: 'passportData',
	form,
	$pattern,
});

const $passportData = profileModel.$profile.map((profile) => {
	if (!profile) {
		return null;
	}

	const { passportSeries, passportNumber, documentType } = profile.passportData;

	return {
		passportSeries,
		passportNumber,
		documentType,
	};
});

/**
 * Загрузка доступных типов документов
 */
sample({
	clock: Gate.open,
	target: documentTypesModel.fetchDocumentTypes,
});

/**
 * Загрузка начальных данных из профиля
 */
sample({
	clock: [Gate.open, profileModel.resetDraft],
	source: $passportData,
	filter: Boolean,
	fn: ({ passportNumber, passportSeries, documentType }) => ({
		documentType: documentType || ('' as const),
		passportData:
			passportNumber || passportSeries
				? [passportSeries, passportNumber].filter(Boolean).join(' ')
				: null,
	}),
	target: form.setValues,
});

sample({
	clock: selectDocumentType,
	fn: (value) => ({
		field: 'documentType',
		value,
	}),
	target: form.setValue,
});

/**
 * Нахождение паттерна выбранного документа
 */
sample({
	source: {
		activeId: $id,
		types: documentTypesModel.$documentTypes,
	},
	filter: Boolean,
	fn: ({ activeId, types }) => types.find(({ id }) => id === activeId)?.pattern || '',
	target: $pattern,
});

/**
 * Сброс ввёденного значения, при смене типа документа
 */
sample({
	source: {
		passportData: form.$values.map(({ passportData }) => passportData),
		types: documentTypesModel.$documentTypes,
	},
	clock: selectDocumentType,
	filter: ({ passportData, types }, selectedType) => {
		const pattern = types.find(({ id }) => id === selectedType)?.pattern;

		const mask = new Mask({
			mask: pattern,
			tokens: DOCUMENT_TYPES_MASK_TOKENS,
		});

		return (
			passportData === null || selectedType === null || passportData !== mask.masked(passportData)
		);
	},
	fn: () => ({
		field: 'passportData',
		value: '',
	}),
	target: form.setValue,
});

sample({
	clock: form.$values,
	source: profileModel.$profileDraft,
	filter: Boolean,
	fn: (draft, { documentType, passportData }) => ({
		...draft,
		passportData: {
			...draft.passportData,
			documentType: documentType || null,
			...parsePassportData(passportData || ''),
		},
	}),
	target: profileModel.$profileDraft,
});

export const changeIdentityDocumentModel = {
	Gate,
	form,
	$pattern,
	$id,
	selectDocumentType,
	$documentTypesValidation,
};
