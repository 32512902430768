import { useOverlayTriggerState } from 'react-stately';
import { useUnit, useGate } from 'effector-react';
// import { useForm } from 'effector-react-form/scope';
import { bonusSupportModel } from 'entities/bonus-support/model';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { ProfileModel } from 'shared/lib/types';
// import { Button, Typo, Condition } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { BonusSupportForm } from '../bonus-support-form';
import styles from './styles.module.scss';
import { ProfileModel } from 'config/types/personal';
import { useForm } from 'effector-react-form';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

interface BonusSupportProps {
	profile: ProfileModel;
	cardNumber?: string;
}

export const BonusSupportModal = ({ profile, cardNumber }: BonusSupportProps) => {
	const status = useUnit(bonusSupportModel.$status);

	const state = useOverlayTriggerState({});

	const shouldMount = useDelayUnmount(state.isOpen, 600);
	useGate(bonusSupportModel.ModalGate, shouldMount);

	const { form } = bonusSupportModel;
	const { submit } = useForm({ form });

	return (
		<>
			<Button
				className={styles.triggerButton}
				onClick={state.toggle}
				btnType="outline"
				size="large"
			>
				Обратиться в поддержку
			</Button>

			{shouldMount && (
				<Modal
					state={state}
					isShown={state.isOpen}
					classNameModal={styles.bonusSupportModal}
					closeButtonClassName={styles.modalCloseButton}
					verticalAlign="bottom"
					isDismissable
					footer={
						<div className={styles.footer}>
							<Condition
								value={status !== 'done'}
								then={
									<>
										<Button
											type="submit"
											loading={status === 'pending'}
											onClick={submit}
											className={styles.sendButton}
											size="large"
										>
											Отправить
										</Button>
										<Button onClick={state.close} btnType="text" size="large">
											Отменить
										</Button>
									</>
								}
								else={
									<Button onClick={state.close} className={styles.sendButton} size="large">
										Закрыть
									</Button>
								}
							/>
						</div>
					}
				>
					{status !== 'done' && <BonusSupportForm cardNumber={cardNumber} profile={profile} />}
					{status === 'done' && <Typo design="headline-xl">Ваше обращение успешно отправлено</Typo>}
				</Modal>
			)}
		</>
	);
};
