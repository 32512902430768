import React, { FC } from 'react';
import classnames from 'classnames';
// import { Request } from 'shared/lib/types/account/models';
// import { Typo } from 'shared/ui/atoms';
// import { Accordion } from 'shared/ui/molecules/accordion';
import { PassengersCard } from '../../passengers-card';
import { GroupHead } from './group-head';
import styles from './styles.module.scss';
import { Accordion } from 'layout/components/menues/accordion';
import { Typo } from 'layout/components/typo/ui';
import { Request } from 'store/auth/types/account/request';

interface PassengersListProps {
	anchor: string;
	request: Request;
	allowEditPassengers?: boolean;
	className?: string;
}

export const PassengersList: FC<PassengersListProps> = ({
	anchor,
	request,
	allowEditPassengers,
	className,
}) => (
	<div className={classnames(styles.passengersList_wrap, className)} id={anchor}>
		<Typo design="headline-m">Данные туристов</Typo>

		{request.cabins?.map((cabin) => (
			<Accordion
				key={cabin.id}
				id={cabin.id}
				accordionType="filled"
				caption={<GroupHead {...cabin} />}
				className={styles.cabinAccordion}
				headClassName={styles.cabinAccordion_head}
				defaultState
			>
				<div className={styles.cabinAccordion_content}>
					{cabin?.passengers?.map((passenger, index) => {
						const hasPassenger = passenger.type !== 3;

						return (
							<PassengersCard
								key={passenger.id}
								{...passenger}
								childrenAge={cabin.childrenAge}
								cruiseStartDate={request.cruise?.startDate}
								allowEditPassengers={allowEditPassengers && hasPassenger}
								isFirstPassenger={index === 0}
							/>
						);
					})}
				</div>
			</Accordion>
		))}
	</div>
);
