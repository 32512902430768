import { useMemo } from 'react';
import { useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { Button } from 'shared/ui/atoms';
import { changeAdditionalPhoneModel, changeMainPhoneModel } from '../../model';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';

interface ModalFooterProps {
	close?: () => void;
	additionalPhone?: boolean;
}

export const ModalFooter = ({ close, additionalPhone = false }: ModalFooterProps) => {
	const model = additionalPhone ? changeAdditionalPhoneModel : changeMainPhoneModel;
	const { phoneForm, codeForm, $state, $phonePending, $codePending } = model;
	const phoneFormProps = useForm({ form: phoneForm });
	const codeFormProps = useForm({ form: codeForm });

	const [state, phonePending, codePending] = useUnit([$state, $phonePending, $codePending]);

	const primaryButtonOnClick = useMemo(() => {
		switch (state) {
			case 'phone':
				return phoneFormProps.submit;
			case 'code':
				return codeFormProps.submit;
			default:
				return close;
		}
	}, [close, codeFormProps.submit, phoneFormProps.submit, state]);

	return (
		<div className={styles.footer}>
			<Button
				loading={state === 'phone' ? phonePending : codePending}
				size="large"
				className={styles.footer__button}
				onClick={primaryButtonOnClick}
			>
				{state !== 'success' ? 'Продолжить' : 'Закрыть'}
			</Button>
			{state !== 'success' && (
				<Button btnType="text" size="large" onClick={close}>
					Отменить
				</Button>
			)}
		</div>
	);
};
