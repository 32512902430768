import { useRef } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
// import {
// 	changeAddressModel,
// 	changeDataForPurchaseModel,
// 	changeIdentityDocumentModel,
// 	chooseOfficeModel,
// } from 'features/personal';
// import { useScrollToFormWithError } from 'features/scroll-to-form-with-error';
// import { profileModel } from 'entities/profile';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { model } from '../../model';
import { AccountData } from '../account-data';
import { ButtonsBlock } from '../buttons-block';
import { DataForPurchase } from '../data-for-purchase';
import { IdentityDocument } from '../identity-document';
import { MailingAddress } from '../mailing-address';
import { NotificationData } from '../notification-data';
import { OfficeData } from '../office-data';
import styles from './styles.module.scss';
import { changeDataForPurchaseModel, changeIdentityDocumentModel, changeAddressModel, chooseOfficeModel } from 'entities/personal';
import { profileModel } from 'layout/components/auth/profile';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { useScrollToFormWithError } from 'entities/scroll-to-form-with-error';

export const PersonalData = () => {
	const profile = useUnit(profileModel.$profileDraft);

	const state = useOverlayTriggerState({});
	useGate(model.Gate, state);

	const addressRef = useRef<Nullable<HTMLDivElement>>(null);
	const dataForPurchaseRef = useRef<Nullable<HTMLDivElement>>(null);
	const officeRef = useRef<Nullable<HTMLDivElement>>(null);
	const identityDocumentRef = useRef<Nullable<HTMLDivElement>>(null);
	useScrollToFormWithError([
		{
			form: changeDataForPurchaseModel.form,
			ref: dataForPurchaseRef,
		},
		{
			form: changeIdentityDocumentModel.form,
			ref: identityDocumentRef,
		},
		{
			form: changeAddressModel.form,
			ref: addressRef,
		},
		{
			form: chooseOfficeModel.form,
			ref: officeRef,
		},
	]);

	if (!profile) {
		return null;
	}

	return (
		<div className={styles.wrapper}>
			{/* <Typo className={styles.title} design="headline-l">
				Личные данные
			</Typo> */}
			<div className={styles.container}>
				<AccountData />
				<div ref={dataForPurchaseRef}>
					<DataForPurchase />
				</div>
				<div ref={identityDocumentRef}>
					<IdentityDocument />
				</div>
				<div ref={addressRef}>
					<MailingAddress />
				</div>
				{/* <div ref={officeRef}>
					<OfficeData />
				</div>
				<NotificationData /> */}
				<ButtonsBlock />
			</div>
			{state.isOpen && (
				<Modal
					classNameModal={styles.modal}
					closeButtonClassName={styles.closeButton}
					footer={
						<Button onClick={state.close} size="large">
							Закрыть
						</Button>
					}
					isShown={state.isOpen}
					state={state}
				>
					<Typo design="headline-xl">
						Изменения персональных <br /> данных успешно сохранены!
					</Typo>
				</Modal>
			)}
		</div>
	);
};
