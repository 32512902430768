import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { BonusCardGreeting, JoinToBonusProgram, bonusCardModel } from 'entities/bonus-card';
// import { ClubCardSupport } from 'entities/club-card-support';
// import { profileModel } from 'entities/profile';
// import { Condition, Skeleton, Typo } from 'shared/ui/atoms';
import { ClubCardWithPrint } from './club-card-with-print';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';
import { Typo } from 'layout/components/typo/ui';
import { Condition } from 'layout/components/switchers/condition';
import { ClubCardSupport } from 'entities/club-card-support';
import { Skeleton } from 'layout/components/special/skeleton';

interface BonusesClubProps {
	isLoading?: boolean;
}

export const BonusesClub: FC<BonusesClubProps> = ({ isLoading }) => {
	const profile = useUnit(profileModel.$profile);
	const [bonusCardStatus, bonusCardError, onboarded] = useUnit([
		bonusCardModel.$status,
		bonusCardModel.$errorCode,
		bonusCardModel.$onboarded,
	]);

	if (isLoading || bonusCardStatus === 'pending') {
		return <BonusesClubSkeleton />;
	}

	if (!profile) {
		return null;
	}

	return (
		<section className={styles.bonusesClub}>
			<Typo className={styles.bonusesClub__head} design="headline-m">
				Клуб любителей круизов
			</Typo>
			<Condition
				value={bonusCardError === 404}
				then={<JoinToBonusProgram title="Хотите оплатить баллами круиз?" />}
				else={
					<>
						{onboarded === false && <BonusCardGreeting />}
						<div className={styles.bonusesClub__cards}>
							<ClubCardWithPrint />
							<ClubCardSupport />
						</div>
					</>
				}
			/>
		</section>
	);
};

export const BonusesClubSkeleton = () => (
	<section className={styles.bonusesClub}>
		<Skeleton className={styles.bonusesClub__skeletonHead} />
		<div className={styles.bonusesClub__cards}>
			<Skeleton className={styles.bonusesClub__skeletonCard} />
		</div>
	</section>
);
