import { PropsWithChildren, ReactNode, useEffect } from 'react';
// import { AccountNavigation, AccountLogout } from 'features/account-navigation';
// import { useCheckAuth } from 'features/load-profile';
// import { PriceFormatContextProvider } from 'shared/lib/contexts/price-format-context';
// import { PaymentStatusIcons } from 'shared/ui/icons/svg-sprite';
import styles from './styles.module.scss';
import { PriceFormatContextProvider } from 'config/contexts/price-format-context';
import { AccountLogout, AccountNavigation } from '../header/account-navigation';
import { PaymentStatusIcons } from 'layout/components/icons/svg-sprite';
import { useCheckAuth } from 'layout/components/auth/load-profile';
import { AccountRoutes } from 'store/auth/constants';
import { MobileView } from '../device-view';
import { ProfileVerification } from 'layout/components/profile';

interface AccountLayoutProps extends PropsWithChildren {
	sidebarContent?: ReactNode;
}

export const AccountLayout = ({ children, sidebarContent }: AccountLayoutProps) => {
	const { hasToken, authorized, loading } = useCheckAuth();

	useEffect(() => {
		if (hasToken === false) {
			window.location.href = AccountRoutes.LoginPage;
		}
	}, [hasToken]);

	if (!hasToken || (!authorized && !loading)) {
		return null;
	}

	return (
		<PriceFormatContextProvider priceFormat={{ trunc: 2 }}>
			<div className={styles.layout}>
				<div className={styles.sidebar}>
					<AccountNavigation />
					<AccountLogout />
					{sidebarContent}
				</div>
				{children}
			</div>
			<MobileView>
				<aside className={styles.profile__insertion}>
					{/* <ProfileVerification /> */}
					<AccountNavigation />
				</aside>
			</MobileView>
			<PaymentStatusIcons />
		</PriceFormatContextProvider>
	);
};
