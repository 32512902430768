import React, { FC } from 'react';
// import { BONUS_PROGRAMM_PHONE } from 'shared/config';
// import { PHONE_CLEANUP } from 'shared/lib/constants';
// import { BonusPayment, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { PHONE_CLEANUP } from 'config/constants';
import { BONUS_PROGRAMM_PHONE } from 'config/constants/contacts';
import { Typo } from 'layout/components/typo/ui';
import { BonusPayment } from 'layout/components/view/bonus-payment';

interface ClubCardSupportProps {}

export const ClubCardSupport: FC<ClubCardSupportProps> = () => (
	<div className={styles.clubCardSupport}>
		<div className={styles.clubCardSupport__label}>
			<BonusPayment size="sm" />
		</div>

		<Typo design="headline-s">
			Ищите лейбл «Можно купить за&nbsp;баллы» и&nbsp;оплачивайте баллами круизы
		</Typo>

		<Typo className={styles.clubCardSupport__foot} design="text-s">
			Есть вопросы по бонусной программе?{' '}
			<Typo
				as="a"
				design="headline-xs"
				href={`tel:${BONUS_PROGRAMM_PHONE.replace(PHONE_CLEANUP, '')}`}
			>
				{BONUS_PROGRAMM_PHONE}
			</Typo>
		</Typo>
	</div>
);
