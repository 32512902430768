import { FC } from 'react';
// import { AccountRoutes } from 'shared/config';
// import { message } from 'shared/lib/message';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { message } from 'config/constants/message';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';
import { AccountRoutes } from 'store/auth/constants';

interface RequestDetailsErrorProps {
	errorCode?: number;
}

export const RequestDetailsError: FC<RequestDetailsErrorProps> = ({ errorCode }) => (
	<div className={styles.wrapper}>
		<div className={styles.info}>
			{errorCode === 404 || errorCode === 403 ? (
				<Typo design="headline-m">Не достаточно прав доступа</Typo>
			) : (
				<Typo design="headline-m">{message.requests.error}</Typo>
			)}
		</div>
		<Button size="large" href={AccountRoutes.MyRequestsActive}>
			Мои заявки
		</Button>
	</div>
);
