import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { BonusCard, PrintBonusCard, bonusCardModel } from 'entities/bonus-card';
// import { profileModel } from 'entities/profile';
import styles from './styles.module.scss';
import { profileModel } from 'layout/components/auth/profile';

interface ClubCardWithPrintProps {}

export const ClubCardWithPrint: FC<ClubCardWithPrintProps> = () => {
	const profile = useUnit(profileModel.$profile);
	const bonusCard = useUnit(bonusCardModel.$card);

	if (!profile || !bonusCard) {
		return null;
	}

	return (
		<div className={styles.bonusesClub__clubCard}>
			<BonusCard {...bonusCard} passportData={profile.passportData} />
			<PrintBonusCard {...bonusCard} passportData={profile.passportData} />
		</div>
	);
};
