/* eslint-disable react/jsx-no-useless-fragment */
import { Modal } from 'layout/components/modal';
import { Img } from 'layout/components/view/img';
import { useEffect } from 'react';
import { useOverlayTriggerState } from 'react-stately';
import { AccountRequestsStatuses, PROMOCODE_FRIEND } from 'store/auth/constants';
import { Request } from 'store/auth/types/account/request';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
// import Image from 'next/image';
// import { AccountRequestsStatuses, PROMOCODE_FRIEND } from 'shared/config';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Request } from 'shared/lib/types/account/request';
// import { Modal } from 'shared/ui/molecules';

interface PromocodeFriendModalProps {
	request: Request;
}

export const PromocodeFriendModal = ({ request }: PromocodeFriendModalProps) => {
	const state = useOverlayTriggerState({});

	useEffect(() => {
		const isShowModal = localStorage.getItem('promocodeFriendModalShow');
		const minDate = new Date('2023-11-20T00:00:00');
		const maxDate = new Date('2023-12-01T00:00:00');

		const showModal = new Date() > minDate && new Date() < maxDate;

		if (showModal && request.status === AccountRequestsStatuses.Paid && !isShowModal) {
			state.setOpen(true);
			localStorage.setItem('promocodeFriendModalShow', 'true');
		}
	}, [request.status, state]);

	const shouldMount = useDelayUnmount(state.isOpen, 600);

	return (
		<>
			{shouldMount && (
				<Modal state={state} isShown={state.isOpen} verticalAlign="bottom" isDismissable>
					<Img src={PROMOCODE_FRIEND} width={1030} height={1080} alt="Promocode friend" />
				</Modal>
			)}
		</>
	);
};
