import { getFailCode } from 'api';
import { ANALYTICS_TARGETS } from 'config/constants';
import { message } from 'config/constants/message';
import { ChangePhoneRequest, ChangePhoneVerifyRequest } from 'config/types/personal';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { profileModel } from 'layout/components/auth/profile';
import { personalRequests } from 'store/auth/api';
// import { profileModel } from 'entities/profile';
// import { personalRequests } from 'shared/api/';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { ChangePhoneRequest, ChangePhoneVerifyRequest } from 'shared/lib/types';
// import { getFailCode, setAnalytics } from 'shared/lib/utils';

const changePhoneFactory = ({
	changePhoneAnalyticsTarget,
	isAdditional = false,
}: {
	changePhoneAnalyticsTarget: string;
	isAdditional?: boolean;
}) => {
	const Gate = createGate<{ additionalPhone?: boolean }>();

	const phoneFormHandleSubmit = createEvent<{ phone: string }>();
	const phoneFormHandleSubmitFx = createEffect(
		isAdditional ? personalRequests.changeAdditionalPhone : personalRequests.changePhone,
	);
	const phoneForm = createForm<ChangePhoneRequest>({
		initialValues: {
			phone: '',
		},
		onSubmit: ({ values }) => phoneFormHandleSubmit({ phone: values.phone.replace(/\D/g, '') }),
	});

	const codeFormHandleSubmit = createEvent<ChangePhoneVerifyRequest>();
	const codeFormHandleSubmitFx = createEffect(
		isAdditional
			? personalRequests.changeAdditionalPhoneVerify
			: personalRequests.changePhoneVerify,
	);
	const codeForm = createForm<ChangePhoneVerifyRequest>({
		initialValues: {
			code: '',
			verifyToken: '',
		},
		onSubmit: ({ values }) => codeFormHandleSubmit(values),
	});

	const $state = createStore<'phone' | 'code' | 'success'>('phone');

	const handleChangePhoneAnalyticsFx = createEffect(() => {}
		// setAnalytics(changePhoneAnalyticsTarget)
);

	sample({
		clock: Gate.open,
		fn: () => 'phone' as const,
		target: $state,
	});

	sample({
		clock: phoneFormHandleSubmit,
		target: phoneFormHandleSubmitFx,
	});

	sample({
		clock: phoneFormHandleSubmitFx.doneData,
		fn: () => 'code' as const,
		target: $state,
	});

	sample({
		clock: phoneFormHandleSubmitFx.failData,
		fn: (error) => {
			const code = getFailCode(error) || 'error';

			return {
				field: 'formError',
				error: message.profile.changePhone[code] || message.profile.changePhone.error,
			};
		},
		target: [phoneForm.setOrDeleteError, codeForm.setOrDeleteError],
	});

	sample({
		clock: phoneFormHandleSubmitFx.doneData,
		fn: ({ verifyToken }) => ({
			field: 'verifyToken',
			value: verifyToken,
		}),
		target: codeForm.setValue,
	});

	sample({
		clock: codeFormHandleSubmit,
		target: codeFormHandleSubmitFx,
	});

	sample({
		clock: codeFormHandleSubmitFx.doneData,
		fn: () => 'success' as const,
		target: $state,
	});

	sample({
		clock: codeFormHandleSubmitFx.failData,
		fn: (error) => {
			const code = getFailCode(error) || 'error';

			return {
				field: 'formError',
				error: message.profile.changePhoneVerify[code] || message.profile.changePhoneVerify.error,
			};
		},
		target: codeForm.setOrDeleteError,
	});

	sample({
		source: {
			draft: profileModel.$profileDraft,
			form: phoneForm.$values,
		},
		clock: codeFormHandleSubmitFx.doneData,
		fn: ({ draft, form }) => {
			if (!draft) {
				return null;
			}

			return {
				...draft,
				[isAdditional ? 'additionalPhone' : 'phone']: form.phone.replace(/\D/g, ''),
			};
		},
		target: profileModel.$profileDraft,
	});

	/**
	 * Обработка аналитики
	 */
	sample({
		clock: codeFormHandleSubmitFx.doneData,
		target: handleChangePhoneAnalyticsFx,
	});

	return {
		Gate,
		phoneForm,
		codeForm,
		$phonePending: phoneFormHandleSubmitFx.pending,
		$codePending: codeFormHandleSubmitFx.pending,
		$state,
	};
};

export const changeMainPhoneModel = changePhoneFactory({
	changePhoneAnalyticsTarget: ANALYTICS_TARGETS.account.account_phone_changed,
	isAdditional: false,
});
export const changeAdditionalPhoneModel = changePhoneFactory({
	changePhoneAnalyticsTarget: ANALYTICS_TARGETS.account.account_phone_added,
	isAdditional: true,
});
