/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import classnames from 'classnames';
// import { useRouter } from 'next/router';
// import { useEvent, useUnit, useGate } from 'effector-react/ssr';
// import { RequestsFilter, requestsFilterModel } from 'features';
// import { AccountPagination } from 'features/account-pagination';
// import { RequestCard } from 'entities/request-card';
// import { REQUEST_FILTERS } from 'shared/config';
// import {
// 	REQUESTS_DECLENSION,
// 	FOUND_DECLENSION_FEMININE,
// 	ANALYTICS_TARGETS,
// } from 'shared/lib/constants';
// import {
// 	filterObjectKeys,
// 	getNumDeclension,
// 	capitalizeFirstLetter,
// 	setAnalytics,
// } from 'shared/lib/utils';
// import { Button, Condition, Skeleton, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { requestModel } from '../../model';
import { ErrorPage } from '../error-page';
import { EmptyPageContent } from './empty-page-content';
import { RequestsPageLayout } from './requests-page-layout';
import { SkeletonCards } from './skeletons';
import styles from './styles.module.scss';
import { FOUND_DECLENSION_FEMININE, REQUESTS_DECLENSION, ANALYTICS_TARGETS } from 'config/constants';
import { REQUEST_FILTERS } from 'config/constants/requests';
import { useUnit, useEvent, useGate } from 'effector-react';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { requestsFilterModel, RequestsFilter } from 'layout/components/special/requests-filter';
import { Skeleton } from 'layout/components/special/skeleton';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { filterObjectKeys } from 'utils/object';
import { capitalizeFirstLetter, getNumDeclension } from 'utils/string';
import { RequestCard } from 'entities/request-card';
import { AccountPagination } from 'layout/layouts/account/account-pagination';
import { useRouter, useRouterState, useSearch } from '@tanstack/react-router';
import { getPaymentResult } from 'store/account/api/request-payment';
import { StatusPage } from '../status-page';
import { routerModel } from 'utils/router';
import { AccountRoutes } from 'store/auth/constants';

export const Requests = React.memo(() => {
	// const routerState = useRouterState();
	// const router = useRouter();
	const router = useRouter()

	// useEffect(() => {
	// 	console.log('router.state.location',router.state.location)
	// }, [router.state.location])

	const [requests, status, searchParams] = useUnit([
		requestModel.$requests,
		requestModel.$status,
		requestsFilterModel.$searchParams,
	]);
	// const getRequests = useEvent(requestModel.getRequests);
	// const loadMoreRequests = useEvent(requestModel.loadMoreRequests);
	// const setQueryParams = useEvent(requestModel.setQueryParams);
	// const setSearchParams = useEvent(requestsFilterModel.setSearchParams);
	const getRequests = useUnit(requestModel.getRequests);
	const loadMoreRequests = useUnit(requestModel.loadMoreRequests);
	const setQueryParams = useUnit(requestModel.setQueryParams);
	const setSearchParams = useUnit(requestsFilterModel.setSearchParams);

	const search = useSearch({ from: '/account/my-requests/' })

	useEffect(() => {
		// console.log('search params',search)
		getRequests(filterObjectKeys(search, REQUEST_FILTERS))
	}, [search])


	// useGate(requestModel.Gate, filterObjectKeys(router.query, REQUEST_FILTERS));
	useGate(requestModel.Gate, filterObjectKeys(search, REQUEST_FILTERS));

	if (status === 'fail') {
		return (
			<ErrorPage
				text="Извините, в данный момент заявки не доступны. Попробуйте обновить или посмотреть заявки позже"
				// onReloadPage={() => getRequests(filterObjectKeys(router.query, REQUEST_FILTERS))}
				onReloadPage={() => getRequests(filterObjectKeys(search, REQUEST_FILTERS))}
			/>
		);
	}

	if (router.state.location?.searchStr?.length && getPaymentResult(router.state.location.searchStr) === 'success') {
		return (
			<StatusPage
				text="Оплата прошла успешно"
				// onReloadPage={() => getRequests(filterObjectKeys(router.query, REQUEST_FILTERS))}
				onReloadPage={() => routerModel.push(AccountRoutes.MyRequests)}
			/>
		);
	}
	if (router.state.location?.searchStr?.length && getPaymentResult(router.state.location.searchStr) === 'fail') {
		return (
			<StatusPage
				text="Произошла ошибка при оплате. Попробуйте снова или свяжитесь с технической поддержкой."
				// onReloadPage={() => getRequests(filterObjectKeys(router.query, REQUEST_FILTERS))}
				onReloadPage={() => routerModel.push(AccountRoutes.MyRequests)}
			/>
		);
	}

	return (
		<RequestsPageLayout>
			<main className={styles.requestsPageMain}>
				<RequestsFilter onFilter={() => setQueryParams({ page: '1' })} />

				<Condition
					value={!(!requests?.total && status === 'done')}
					then={
						<div className={styles.requestsContent_wrap}>
							<div className={styles.requestsHeader}>
								<Condition
									value={status !== 'pending'}
									then={
										<>
											{requests?.total && (
												<Typo design="headline-s">{`${capitalizeFirstLetter(
													getNumDeclension(requests.total, FOUND_DECLENSION_FEMININE),
												)} ${requests.total} ${getNumDeclension(
													requests.total,
													REQUESTS_DECLENSION,
												)}`}</Typo>
											)}
										</>
									}
									else={<Skeleton width={134} height={20} />}
								/>
								<Button
									btnType="text"
									contextClassName={styles.sortButton}
									onClick={() => {
										const sort =
											!searchParams?.sort || searchParams?.sort === 'asc' ? 'desc' : 'asc';

										setSearchParams({
											key: 'sort',
											value: sort,
										});
										setQueryParams({ sort, page: '1' });
										// setAnalytics(ANALYTICS_TARGETS.account.account_enquiries_sort);
									}}
								>
									<span>
										{!searchParams?.sort || searchParams?.sort === 'desc'
											? 'по убыванию'
											: 'по возрастанию'}
									</span>
									<Icon
										id="chevron-down"
										width={20}
										height={20}
										className={classnames(styles.sortButtonIcon, {
											[styles.sortButtonIconUp]: searchParams?.sort === 'asc',
										})}
									/>
								</Button>
							</div>
							<div className={styles.requestsList}>
								<Condition
									value={status !== 'pending'}
									then={
										<>
											{requests?.data?.map((elem) => (
												<RequestCard key={elem.id} {...elem} />
											))}
										</>
									}
									else={<SkeletonCards />}
								/>
							</div>
							<AccountPagination
								total={requests?.total ?? 0}
								perPage={requests?.perPage ?? 0}
								page={requests?.page ?? 0}
								pageName="requests"
								onPageChange={(activePage) => setQueryParams({ page: activePage.toString() })}
								onLoadMore={(activePage) => loadMoreRequests(activePage)}
								loadedNotifications={requests?.data?.length}
							/>
						</div>
					}
					else={<EmptyPageContent />}
				/>
			</main>
		</RequestsPageLayout>
	);
});
