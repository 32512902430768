/* eslint-disable react/no-array-index-key */
// import Link from 'next/link';
import { useGate, useUnit } from 'effector-react';
// import { bonusHistoryModel } from 'widgets/account/model';
// import { AccountPagination } from 'features/account-pagination';
// import { BonusRecovery } from 'entities/bonus-recovery';
// import { AccountRoutes } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { Tab, Tabs } from 'shared/ui/molecules';
import { BonusHistoryCard } from '../bonus-history-card';
import { BonusHistoryCardSkeleton } from '../bonus-history-card-skeleton';
import styles from './styles.module.scss';
import { Link } from '@tanstack/react-router';
import { ANALYTICS_TARGETS } from 'config/constants';
import { bonusHistoryModel } from 'entities/account/model';
import { BonusRecovery } from 'entities/bonus-recovery';
import { Icon } from 'layout/components/icons/icon';
import { Tabs, Tab } from 'layout/components/switchers/tabs';
import { Typo } from 'layout/components/typo/ui';
import { AccountRoutes } from 'store/auth/constants';
import { AccountPagination } from 'layout/layouts/account/account-pagination';

export const AllBonuses = () => {
	useGate(bonusHistoryModel.BonusHistoryGate);

	const [bonushistory, getBonusHistory, loadMoreBonusHistory, bonusHistoryStatus] = useUnit([
		bonusHistoryModel.$bonushistory,
		bonusHistoryModel.getBonusHistory,
		bonusHistoryModel.loadMoreBonusHistory,
		bonusHistoryModel.$bonusHistoryStatus,
	]);

	const [waitingBonusHistory, waitingBonusHistoryStatus] = useUnit([
		bonusHistoryModel.$waitingBonusHistory,
		bonusHistoryModel.$waitingBonusHistoryStatus,
	]);

	const bonusHistoryLoading = bonusHistoryStatus === 'pending';
	const waitingBonusHistoryLoading = waitingBonusHistoryStatus === 'pending';

	return (
		<section className={styles.allBonuses}>
			<div className={styles.titleWrapper}>
				<Link to={AccountRoutes.MyBonuses}
				// passHref
				>
					<a className={styles.buttonBack}>
						<Icon id="chevron-left" width={20} height={20} />
					</a>
				</Link>

				<Typo className={styles.title} design="headline-m">
					История начисления бонусов
				</Typo>
			</div>

			<Tabs
				className={styles.tabs}
				contentClassName={styles.tabsContent}
				theme="dashed"
				commonTabsContent={
					<Typo className={styles.description} design="text-m">
						Бонусные баллы начисляются автоматически после окончания тура, либо в рамках специальных
						акций.
					</Typo>
				}
			>
				<Tab
					label="Начисления и списания"
					key="bonuses"
					analyticsTarget={ANALYTICS_TARGETS.account.account_bonus_transactions}
				>
					{!bonusHistoryLoading && !!bonushistory.data?.length && (
						<>
							<ul className={styles.bonusHistoryCardList}>
								{bonushistory.data?.map((item, index) => (
									<BonusHistoryCard {...item} key={index} />
								))}
							</ul>

							<AccountPagination
								className={styles.pagination}
								total={bonushistory?.total ?? 0}
								perPage={bonushistory?.perPage ?? 0}
								page={bonushistory?.page ?? 0}
								pageName="bonuses"
								onPageChange={(activePage) => getBonusHistory({ page: activePage })}
								onLoadMore={loadMoreBonusHistory}
								loadedNotifications={bonushistory?.data?.length}
							/>
						</>
					)}

					{bonusHistoryLoading && (
						<ul className={styles.bonusHistoryCardList}>
							<BonusHistoryCardSkeleton />
							<BonusHistoryCardSkeleton />
							<BonusHistoryCardSkeleton />
						</ul>
					)}

					<BonusRecovery />
				</Tab>

				<Tab
					label="Баллы ожидающие начисления"
					key="waiting-bonuses"
					analyticsTarget={ANALYTICS_TARGETS.account.account_bonus_waiting}
				>
					{!waitingBonusHistoryLoading && !!waitingBonusHistory.length && (
						<ul className={styles.bonusHistoryCardList}>
							{waitingBonusHistory?.map((item, index) => (
								<BonusHistoryCard {...item} key={index} isWaitingBonuses />
							))}
						</ul>
					)}

					{waitingBonusHistoryLoading && (
						<ul className={styles.bonusHistoryCardList}>
							<BonusHistoryCardSkeleton />
							<BonusHistoryCardSkeleton />
						</ul>
					)}

					<BonusRecovery />
				</Tab>
			</Tabs>
		</section>
	);
};
