import { FC } from 'react';
import classnames from 'classnames';
// import { DefaultStyledProps } from 'shared/lib/types';
// import { Request } from 'shared/lib/types/account/models';
// import { Typo } from '../../atoms';
// import { Icon, IconId } from '../../icons';
import { requestsStatuses } from './lib';
import styles from './styles.module.scss';
import { DefaultStyledProps } from 'config/commonTypes';
import { Icon } from 'layout/components/icons/icon';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Typo } from 'layout/components/typo/ui';
import { Request } from 'store/auth/types/account/request';

interface RequestStatusProps extends DefaultStyledProps, Pick<Request, 'status'> {}

export const RequestStatus: FC<RequestStatusProps> = ({ status, className }) => {
	const statusParams = requestsStatuses.find((elem) => elem.id === status);

	if ((!status && status !== 0) || !statusParams) {
		return null;
	}

	return (
		<div className={classnames(className, styles.status_wrap, styles[statusParams.className])}>
			<Icon id={statusParams.name as IconId} width={12} height={12} />
			<Typo design="description-features" as="span">
				{statusParams.label}
			</Typo>
		</div>
	);
};
