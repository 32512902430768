import { MutableRefObject, useEffect } from 'react';
import { useUnit } from 'effector-react';
import { Form } from 'effector-react-form';
import { scrollToElement } from 'utils/scroll';
// import { scrollToElement } from 'shared/lib/utils';

export const useScrollToFormWithError = (
	sections: { ref: MutableRefObject<Nullable<HTMLDivElement>>; form: Form }[],
) => {
	const formsStates = useUnit(sections.map(({ form }) => form.$form));

	useEffect(() => {
		const targetIndex = formsStates.findIndex(
			({ submitted, hasError }, i) => submitted && hasError && sections[i].ref.current !== null,
		);
		if (targetIndex !== -1) {
			scrollToElement(sections[targetIndex].ref.current as HTMLElement);
		}
	}, [formsStates, sections]);
};
