export const getComment = (comment: string) => {
	let slicedIndex = 70;
	let visibleComment = '';
	let hiddenComment = '';

	if (comment[71]?.charCodeAt(0) === 32) {
		visibleComment = comment.slice(0, 70);
		hiddenComment = comment.slice(70);
	} else {
		for (let i = 69; i > 0; i--) {
			if (comment[i + 1].charCodeAt(0) === 32) {
				slicedIndex = i + 1;
				break;
			}
		}

		visibleComment = comment.slice(0, slicedIndex);
		hiddenComment = comment.slice(slicedIndex);
	}

	return { visibleComment, hiddenComment };
};
