import classNames from 'classnames';
// import { CABIN_STATUSES } from 'shared/lib/constants';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { CABIN_STATUSES } from 'config/constants';
import { Typo } from 'layout/components/typo/ui';

interface LegendItemProps {
	tagName: 'li' | 'span';
	status: keyof typeof CABIN_STATUSES;
	size?: 'small' | 'normal';
}

export const LegendItem = ({ tagName, status, size = 'normal' }: LegendItemProps) => (
	<Typo
		as={tagName}
		design={size === 'normal' ? 'text-m' : 'text-s'}
		className={classNames(styles.legendItem, styles[status], styles[size])}
	>
		{CABIN_STATUSES[status]}
	</Typo>
);
