import { sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { profileModel } from 'layout/components/auth/profile';
import { countriesModel } from 'store/countries';
import { trimFactory } from 'utils/form';
// import { countriesModel } from 'entities/countries';
// import { profileModel } from 'entities/profile';
// import { trimFactory } from 'shared/lib/utils';

interface FormValues {
	firstName: string;
	middleName: string;
	lastName: string;
	birthday: string;
	gender: 1 | 2 | '';
	citizenship: Nullable<number>;
}

const Gate = createGate();

const $passportData = profileModel.$profile.map((profile) => {
	if (profile) {
		return profile.passportData;
	}

	return null;
});

const form = createForm<FormValues>({
	initialValues: {
		firstName: '',
		middleName: '',
		lastName: '',
		birthday: '',
		gender: '',
		citizenship: null,
	},
});

sample({
	clock: Gate.open,
	target: countriesModel.fetchCountries,
});

trimFactory(form, ['firstName', 'middleName', 'lastName']);

sample({
	source: $passportData,
	clock: [Gate.open, profileModel.resetDraft],
	filter: Boolean,
	fn: ({ firstName, lastName, middleName, birthday, gender, citizenship }) => ({
		firstName: firstName || '',
		middleName: middleName || '',
		lastName: lastName || '',
		birthday: birthday || '',
		gender: gender || ('' as const),
		citizenship,
	}),
	target: form.setValues,
});

sample({
	source: profileModel.$profileDraft,
	filter: Boolean,
	clock: form.$values,
	fn: (draft, values) => ({
		...draft,
		passportData: {
			...draft.passportData,
			...values,
			gender: values.gender === '' ? null : values.gender,
		},
	}),
	target: profileModel.$profileDraft,
});

export const changeDataForPurchaseModel = {
	form,
	Gate,
};
