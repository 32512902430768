import React from 'react';
import { RangeDatePicker, RangeDatePickerProps } from '../range-date-picker';
import { SingleDatePicker, SingleDatePickerProps } from '../single-date-picker';

interface RangeSelectDatesProps extends RangeDatePickerProps {
	selectMode?: 'range';
}

interface SingleSelectDatesProps extends SingleDatePickerProps {
	selectMode: 'single';
}

type SelectDatesProps = RangeSelectDatesProps | SingleSelectDatesProps;

export const SelectDates = (props: SelectDatesProps) => {
	const { selectMode } = props;
	if (selectMode === 'range' || selectMode === undefined) {
		return <RangeDatePicker {...props} />;
	}
	if (selectMode === 'single') {
		return <SingleDatePicker {...props} />;
	}

	return null;
};
