import { Services } from 'entities/services';
// import { SEATS_DECLENSION, CABIN_ICONS } from 'shared/lib/constants';
// import { DetailedCruiseCabin } from 'shared/lib/types';
// import { getNumDeclension } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
// import { CardMedia, CabinParam, LegendItem } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { CABIN_ICONS, SEATS_DECLENSION } from 'config/constants';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { CabinParam } from 'layout/components/cruise/cart-control/cabin-param';
import { Typo } from 'layout/components/typo/ui';
import { CardMedia } from 'layout/components/view/card-media';
import { LegendItem } from 'layout/components/view/legend-item';
import { getNumDeclension } from 'utils/string';

interface CabinInfoProps extends DetailedCruiseCabin.Cabin {}

export const CabinInfoContent = ({
	name,
	status,
	photos,
	places,
	deck,
	type,
	category,
	description,
	services,
}: CabinInfoProps) => (
	// <div>CabinInfoContent</div>
	<div className={styles.aboutCabin}>
		<div className={styles.cabinHeader}>
			<Typo as="h3" design="headline-s">
				Каюта № {name}
			</Typo>
			<LegendItem tagName="span" status={status} />
		</div>

		<CardMedia images={photos} hasControls displayCaptions />

		<div className={styles.cabinParamsGrid}>
			<div className={styles.cabinParamsRow}>
				<CabinParam
					id={CABIN_ICONS.places}
					name={`${places.main} ${getNumDeclension(places.main, SEATS_DECLENSION)}${
						places?.additional ? ` + ${places?.additional} доп` : ''
					}`}
				/>
				<CabinParam id={CABIN_ICONS.deck} name={deck.name} />
			</div>
			<div className={styles.cabinParamsRow}>
				<CabinParam id={CABIN_ICONS.type} name={type.name} />
				<CabinParam id={CABIN_ICONS.category} name={category.name} />
			</div>
		</div>

		<Typo design="text-s" className={styles.description} raw={description} />

		{!!services.length && (
			<Services
				name={category.name}
				services={services}
				visibleServicesCount={4}
				buttonText="Все услуги в каютах"
				className={styles.services}
			/>
		)}
	</div>
);
