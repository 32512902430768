import { AddEmailModal } from '../modal';
import { ModalFooter } from '../modal-footer';
import { AddEmailTrigger } from '../modal-trigger';

interface AddEmailProps {
	changeMode?: boolean;
}

export const AddEmail = ({ changeMode }: AddEmailProps) => (
	<AddEmailTrigger changeMode={changeMode} footer={<ModalFooter />}>
		{() => <AddEmailModal />}
	</AddEmailTrigger>
);
