import React from 'react';
import { getReturnInfoLabel } from 'entities/request-card';
// import { Request } from 'shared/lib/types/account/request';
// import { Price, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Request } from 'store/auth/types/account/request';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';

export const PaymentList = ({
	amount,
	additional,
	prepayment,
	payed,
	refunded,
	status,
}: Pick<Request, 'amount' | 'additional' | 'prepayment' | 'payed' | 'refunded' | 'status'>) => (
	<div className={styles.requestDetails_paymentList}>
		{amount ? (
			<div className={styles.requestDetails_paymentItem}>
				<Typo design="text-s">Стоимость круиза</Typo>
				<Price as="span" design="headline-s" bold total={amount} />
			</div>
		) : null}

		{additional ? (
			<div className={styles.requestDetails_paymentItem}>
				<Typo design="text-s">Дополнительные услуги</Typo>
				<Price as="span" design="headline-s" bold total={additional} />
			</div>
		) : null}

		{prepayment ? (
			<div className={styles.requestDetails_paymentItem}>
				<Typo design="text-s">Сумма предоплаты</Typo>
				<Price as="span" design="headline-s" bold total={prepayment} />
			</div>
		) : null}

		{payed ? (
			<div className={styles.requestDetails_paymentItem}>
				<Typo design="text-s">Оплачено</Typo>
				<Price as="span" design="headline-s" bold total={payed} />
			</div>
		) : null}

		{refunded ? (
			<div className={styles.requestDetails_paymentItem}>
				<Typo design="text-s">{getReturnInfoLabel(status)}</Typo>
				<Price as="span" design="headline-s" bold total={refunded} />
			</div>
		) : null}
	</div>
);
