import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import { Typo } from 'shared/ui/atoms/typo';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { padezhWords } from 'utils/text-endings';

export interface BonusStarsProps {
	sizePx?: number
	totalStars?: number
	selectedStars?: number
	className?: string;
}


export const BonusStars: FC<BonusStarsProps> = memo(({ className, sizePx = 50, totalStars = 5, selectedStars = 4 }) => (
	<Typo className={classnames(className, styles.bonusStarsWrapper)}>
		<Typo className={classnames(className, styles.bonusStarsTitle)}>Ваш статус: {selectedStars} {padezhWords.stars(selectedStars)} из {totalStars}</Typo>
		<Typo
			className={classnames(className, styles.bonusStars)}
			design="description-features"
		>
			{
				(selectedStars <= totalStars)
					? <>
						{
							Array.from(Array(selectedStars).keys()).map(() => <Icon id="star-selected" width={sizePx} height={sizePx} />)
						}
						{
							Array.from(Array(totalStars - selectedStars).keys()).map(() => <Icon id="star-unselected" width={sizePx} height={sizePx} />)
						}
					</>
					: Array.from(Array(totalStars).keys()).map(() => <Icon id="star-selected" width={sizePx} height={sizePx} />)
			}
		</Typo>
	</Typo>
));
