// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { Request } from 'shared/lib/types/account/request';

import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL, AccountRoutes, PAYMENT_FAIL_URL, PAYMENT_SUCCESS_URL } from "store/auth/constants";
import { Request } from "store/auth/types/account/request";
import { stringifyParsedUrlQuery } from "utils/object";

export interface RequestPaymentProps {
	id: Nullable<Request['id']>;
	amount: number;
	paymentMethod: 'ac' | 'sbp';
}

const paymentStatusesArray = ['success', 'fail', 'pending', 'initial']
let paymentStatuses: 'success' | 'fail' | 'pending' | 'initial'
export type TPaymentStatus = typeof paymentStatuses

export const requestPayment = ({ id, amount, paymentMethod }: RequestPaymentProps) => {
	return fetcher<{ url: string }>({
		url: `${ACCOUNT_URL}/requests/${id}/payment`,
		method: 'POST',
		body: { amount, paymentMethod, successUrl: PAYMENT_SUCCESS_URL, failUrl: PAYMENT_FAIL_URL },
		authorization: true,
	});
}

export const getPaymentResult = (urlParameters?: string): TPaymentStatus => {
	let stringQuery = urlParameters ?? stringifyParsedUrlQuery();
	stringQuery = stringQuery.length ? stringQuery.replaceAll('?', '') : stringQuery

	try {
		const res = stringQuery.split('=')
		if (res.length == 2 && res[0] == 'paymentStatus' && paymentStatusesArray.includes(res[1])) {
			return res[1] as TPaymentStatus
		}
		else {
			return 'initial'
		}
	}
	catch {
		return 'initial'
	}
}

export const requestPaymentDiscount = (cruiseId: number) =>
	fetcher<{ discount: number }>({
		url: `${ACCOUNT_URL}/prices/${cruiseId}/sbp-discount`,
		authorization: true,
	});

export const requestPaymentRequests = {
	requestPayment,
	requestPaymentDiscount,
};
