import { AxiosError } from 'axios';
import { combineEvents, status } from 'patronum';
import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { ErrorsInline } from 'effector-react-form';
import { getFailData } from 'api';
import { message } from 'config/constants/message';
import { profileModel } from 'layout/components/auth/profile';
import { personalRequests } from 'store/auth/api';
import { UpdateProfileParams } from 'store/auth/api/profile';
import { isEmptyObject } from 'utils/object';
import { Errors } from 'config/types/forms/errors';
import { changeDataForPurchaseModel, chooseOfficeModel, changeIdentityDocumentModel, changeAddressModel } from 'entities/personal';
// import {
// 	changeAddressModel,
// 	changeDataForPurchaseModel,
// 	changeIdentityDocumentModel,
// 	chooseOfficeModel,
// } from 'features/personal';
// import { profileModel } from 'entities/profile';
// import { personalRequests } from 'shared/api';
// import { UpdateProfileParams } from 'shared/api/requests/personal/profile';
// import { message } from 'shared/lib/message';
// import { Errors } from 'shared/lib/types';
// import { getFailData, isEmptyObject } from 'shared/lib/utils';

const Gate = createGate<OverlayTriggerState>();
const $modalState = createStore<Nullable<OverlayTriggerState>>(null);

const saveData = createEvent();
const updateProfileFx = createEffect<UpdateProfileParams, unknown, AxiosError<Errors.Errors>>(
	personalRequests.updateProfile,
);
const $updateProfileStatus = status({ effect: updateProfileFx });

const successVisibleReset = createEvent();
const $successVisible = createStore(false).reset(successVisibleReset);

/**
 * Добавление ошибок из респонса в соответствующие формы
 * Для паспортных данных создается ошибка формы, а не отдельного поля
 */
const handleErrorsFx = createEffect(({ errors }: Errors.Errors) => {
	errors.forEach((error) => {
		//// eslint-disable-next-line unicorn/prefer-at
		const field = error.field.split('.')[error.field.split('.').length - 1] || '';
		const isPassportData = field === 'passportSeries' || field === 'passportNumber';

		innerForms.forEach((form) => {
			if (isPassportData) {
				if (Object.hasOwn(form.$fieldsInline.getState(), 'passportData')) {
					form.setOrDeleteError({
						field: 'formError',
						error: JSON.stringify([message.profile.changeProfileData.passportData[error.code]]),
					});
				}
			} else if (Object.hasOwn(form.$fieldsInline.getState(), field)) {
				form.setOrDeleteError({
					error: message.profile.changeProfileData[field][error.code],
					field,
				});
			}
		});
	});
});

sample({
	source: Gate.state,
	target: $modalState,
});

const validateFx = createEffect(
	(errors: {
		passportErrors: ErrorsInline;
		officeErrors: ErrorsInline;
		identityDocumentErrors: ErrorsInline;
		addressErrors: ErrorsInline;
	}) => {
		const allErrors = {
			...errors.passportErrors,
			...errors.officeErrors,
			...errors.identityDocumentErrors,
			...errors.addressErrors,
		};
		if (!isEmptyObject(allErrors)) {
			throw new Error('error');
		}
	},
);

const innerForms = [
	changeDataForPurchaseModel.form,
	chooseOfficeModel.form,
	changeIdentityDocumentModel.form,
	changeAddressModel.form,
];
const validationEvents = innerForms.map(({ validateForm, setSubmitted, submit }) => submit);

sample({
	clock: saveData,
	fn: () => true,
	target: validationEvents,
});

sample({
	clock: combineEvents({
		events: [saveData, ...validationEvents],
	}),
	source: {
		passportErrors: changeDataForPurchaseModel.form.$errorsInline,
		officeErrors: chooseOfficeModel.form.$errorsInline,
		identityDocumentErrors: changeIdentityDocumentModel.form.$errorsInline,
		addressErrors: changeAddressModel.form.$errorsInline,
	},
	fn: validateFx,
});

sample({
	clock: validateFx.fail,
	fn: () => true,
	target: [
		changeDataForPurchaseModel.form.setSubmitted,
		chooseOfficeModel.form.setSubmitted,
		changeIdentityDocumentModel.form.setSubmitted,
	],
});

sample({
	source: profileModel.$profileDraft,
	filter: Boolean,
	clock: validateFx.done,
	fn: (data) => ({
		data,
	}),
	target: updateProfileFx,
});

sample({
	source: $modalState,
	filter: Boolean,
	clock: updateProfileFx.doneData,
	fn: ({ open }) => open,
	target: createEffect((open: () => void) => open()),
});

sample({
	clock: updateProfileFx.failData,
	fn: (error) => getFailData(error),
	target: handleErrorsFx,
});

export const model = {
	saveData,
	successVisibleReset,
	$successVisible,
	pending: updateProfileFx.pending,
	Gate,
	innerForms,
	$updateProfileStatus,
};
