import { ReactElement } from 'react';
import { OverlayTriggerState } from 'react-stately';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { ModalTrigger } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { ModalTrigger } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';

interface AddEmailTriggerProps {
	footer?: ReactElement;
	children?: (close?: OverlayTriggerState['close']) => ReactElement;
	changeMode?: boolean;
}

export const AddEmailTrigger = ({ children, footer, changeMode }: AddEmailTriggerProps) => (
	<ModalTrigger
		footer={footer}
		className={styles.button}
		classNameModal={styles.modal}
		verticalAlign="bottom"
		button={
			<Typo design="button-l">
				<div className={styles.button__content}>
					{!changeMode && <Icon id="plus" />}
					{changeMode ? 'Изменить' : 'Добавить e-mail'}
				</div>
			</Typo>
		}
	>
		{children}
	</ModalTrigger>
);
