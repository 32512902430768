import React, { useState, Key } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { useEvent, useUnit } from 'effector-react';
// import { requestModel } from 'widgets/account';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Button, Input, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { REQUEST_STATUES } from '../lib';
import { requestsFilterModel } from '../model';
import { FilterSelect } from './filter-select';
import styles from './styles.module.scss';
import { requestModel } from 'entities/account/model';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button';
import { Input } from 'layout/components/inputs/input';

interface RequestsFilterProps {
	onFilter?: () => void;
}

export const RequestsFilter = ({ onFilter }: RequestsFilterProps) => {
	const [isExpanded, setExpanded] = useState<boolean>(true);
	const [availableShips, searchParams, initialPageRequest, setPageRequest] = useUnit([
		requestsFilterModel.$availableShips,
		requestsFilterModel.$searchParams,
		requestModel.$initialPageRequest,
		requestModel.setPageRequest,
	]);

	const setSearchParams = useEvent(requestsFilterModel.setSearchParams);

	const filterHandler = () => {
		if (initialPageRequest) {
			setPageRequest(false);
		}
		if (onFilter) {
			onFilter();
		}

		// setAnalytics(ANALYTICS_TARGETS.account.account_enquiries_find);
	};

	return (
		<div className={styles.filters_wrap}>
			<div className={styles.filters_title}>
				<Icon id="filter-2" width={20} height={20} className={styles.filters_title__icon} />
				<Typo design="headline-s" as="h3">
					Фильтры для заявок
				</Typo>
				<div className={styles.expand_button__wrap}>
					<Button btnType="text" onClick={() => setExpanded((prev) => !prev)}>
						<Icon
							id="chevron-down"
							width={20}
							height={20}
							className={classnames(styles.expand_button, {
								[styles.collapsed]: !isExpanded,
							})}
						/>
					</Button>
				</div>
			</div>
			<AnimateHeight
				height={isExpanded ? 'auto' : 0}
				duration={400}
				contentClassName={styles.filters_list}
			>
				<FilterSelect
					options={REQUEST_STATUES}
					label="Статусы заявок"
					placeholder="Статусы заявок"
					name="status"
					selectionMode="single"
					selectedKeys={[searchParams?.status] as Iterable<Key>}
					className={styles.filters_item}
				/>
				<Input
					type="text"
					maxLength={10}
					placeholder="Номер заявки"
					classNameWrap={styles.filter_input}
					value={searchParams?.number ?? ''}
					onChange={(e) => {
						const inputValue = e.target.value.replace(/\D/g, '');

						setSearchParams({
							key: 'number',
							value: inputValue,
						});
					}}
				/>
				<FilterSelect
					options={availableShips ?? []}
					label="Доступные теплоходы"
					placeholder="Доступные теплоходы"
					name="ship"
					selectionMode="multiple"
					className={styles.filters_item}
					selectedKeys={(searchParams?.ship as string)?.split(',') as Iterable<Key>}
				/>
				<Button size="large" className={styles.filters_button} onClick={filterHandler}>
					Найти
				</Button>
			</AnimateHeight>
		</div>
	);
};
