import React, { FC, ReactElement, ReactNode } from 'react';
import classnames from 'classnames';
// import Link from 'next/link';
import { useGate, useUnit } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
// import { PRIVACY_POLICY_LINK } from 'shared/config';
// import { Checkbox } from 'shared/ui/atoms';
// import { Input } from 'shared/ui/atoms/input';
// import { Form, FormField, FormProps } from 'shared/ui/organisms/form';
import { callbackValidationScheme } from '../lib';
import { CallbackFormModel } from '../model';
import styles from './styles.module.scss';
import { Form, FormProps } from 'layout/components/inputs/form/form';
import { FormField } from 'layout/components/inputs/form/field';
import { Input } from 'layout/components/inputs/input';
import { Checkbox } from 'layout/components/inputs/checkbox';
import { PRIVACY_POLICY_LINK } from 'config/commonConstants';
import { Link } from '@tanstack/react-router';

interface CallbackFormProps extends Pick<FormProps, 'buttonContent'> {
	model: CallbackFormModel;
	footer?: ReactNode;
	success?: ReactElement;
	layout?: 'horizontal';
}

export const CallbackForm: FC<CallbackFormProps> = ({
	model,
	footer,
	success,
	layout,
	buttonContent,
}) => {
	const { form, $pending, $status, Gate } = model;
	const status = useUnit($status);
	const pending = useUnit($pending);
	const { controller, handleSubmit, setOrDeleteError } = useForm({ form });
	const { error, isShowError } = useError({ form, name: 'formError' });

	useGate(Gate);

	if (status === 'done' && success) {
		return success;
	}

	const showOnlyError = layout === 'horizontal' && isShowError;
	const handleRetry = showOnlyError ? () => setOrDeleteError({ field: 'formError' }) : undefined;

	return (
		<Form
			error={error}
			pending={pending}
			isShowError={isShowError}
			handleSubmit={handleSubmit}
			buttonContent={buttonContent}
			onRetry={handleRetry}
			formClassName={classnames(
				styles.callbackForm,
				layout && styles[layout],
				showOnlyError && styles.withError,
			)}
			submitClassName={styles.callbackForm__submit}
			footerClassName={styles.callbackForm__footer}
			footer={footer}
			footerInForm
		>
			<FormField
				form={form}
				controller={controller}
				name="name"
				validation={callbackValidationScheme.name}
				placeholder="Ваше имя"
				secondPlaceholder="Введите имя"
				size="lg"
				withLabel
			>
				<Input />
			</FormField>
			<FormField
				form={form}
				controller={controller}
				name="phone"
				validation={callbackValidationScheme.phone}
				placeholder="Номер телефона"
				secondPlaceholder="+7"
				mask="+####################"
				masked
				size="lg"
				withLabel
			>
				<Input isRequired />
			</FormField>
			<div className={styles.callbackForm__agreement}>
				<FormField
					controller={controller}
					form={form}
					name="agreeProcessData"
					validation={callbackValidationScheme.agreeProcessData}
				>
					<Checkbox>
						Согласен на обработку{' '}
						<Link to={PRIVACY_POLICY_LINK}>
							{/* <a target="_blank">персональных данных</a> */}
							персональных данных
						</Link>
					</Checkbox>
				</FormField>
			</div>
		</Form>
	);
};
