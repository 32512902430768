import { FC } from 'react';
// import { DesktopView, MobileView, Skeleton } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Skeleton } from 'layout/components/special/skeleton';
import { DesktopView, MobileView } from 'layout/layouts/device-view';

export const CabinInfoSkeleton: FC = () => {
	// const contentSkeletons = (
	// 	<>
	// 		<Skeleton margin="0 0 16px" width="50%" height={24} />
	// 		<Skeleton margin="0 0 16px" width="100%" height={20} />
	// 		<Skeleton margin="0 0 16px" width="100%" height={240} />
	// 		<Skeleton margin="0 0 16px" width="50%" height={32} />
	// 		<Skeleton margin="0 0 8px" width="100%" height={14} />
	// 		<Skeleton margin="0 0 8px" width="100%" height={14} />
	// 		<Skeleton margin="0 0 8px" width="100%" height={14} />
	// 		<Skeleton margin="0 0 16px" width="100%" height={14} />
	// 	</>
	// );

	return (
		<div>CabinInfoSkeleton</div>
		// <>
		// 	<DesktopView>
		// 		<div className={styles.scrollerWrapper}>
		// 			<div className={styles.aboutCabinScroll}>{contentSkeletons}</div>
		// 		</div>
		// 	</DesktopView>

		// 	<MobileView>{contentSkeletons}</MobileView>
		// </>
	);
};
