// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { ProfileModel } from 'shared/lib/types';

import { fetcher } from "api/setupAxios";
import { ProfileModel } from "config/types/personal";
import { ACCOUNT_URL } from "store/auth/constants";

const suggestAddress = (query?: string, signal?: AbortSignal) =>
	fetcher<string[]>({
		url: `${ACCOUNT_URL}/address/suggest?query=${query || '""'}`,
		signal,
	});

const parseAddress = (query?: string) =>
	fetcher<ProfileModel['mailingAddress']>({
		url: `${ACCOUNT_URL}/address/parse?query=${query || '""'}`,
	});

export const daDataRequests = {
	suggestAddress,
	parseAddress,
};
