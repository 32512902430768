// export const API_BASE_URL = 'https://dev2.infoflot.com/';
// export const API_BASE_URL = 'http://sandbox.infoflot.com/';
// export const API_BASE_URL = 'https://infoflot.com/';
export const API_BASE_URL = 'https://s-cruises.vis.center/';
// export const DEVELOPMENT_MODE = process.env.REACT_APP_DEVELOPMENT_MODE ?? false
export const DEVELOPMENT_MODE = false

export const API_GOOGLE_RECAPCHA_PUBLIC_KEY = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V3_KEY ?? '6LdC1ZcjAAAAANproo1nof8fSSxJwDeUa_tWoyzo';
// export const API_GOOGLE_RECAPCHA_PUBLIC_KEY = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V3_KEY ?? '6LcOOmEqAAAAAIfQxcLYAs5NC8B2TxW-r2U3DC2-';
// export const API_GOOGLE_RECAPCHA_PUBLIC_KEY = process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_V3_KEY ?? '6LcOOmEqAAAAALKHkXVfMx4CcL78jo9sCV3v2kf2';

// вписать в ширину страницы

export const ISR_REGENERATION_TIME = 30;
export const TIMEZONE_NULL = 'Europe/London';
export const TIMEZONE_DEFAULT = 'Europe/Moscow';
export const NAV_SCROLL_OFFSET = 80;
export const NAV_SCROLL_OFFSET_MOB = 76;
export const STICKY_HEADER_SIZE = 68;
export const CACKLE_ID = 78_960;
// export const PRIVACY_POLICY_LINK = '/policy';
export const PRIVACY_POLICY_LINK = '/politika-konfidentsialnosti';
// export const KLK_LINK = '/klk';
export const KLK_LINK = 'https://www.infoflot.com/bonus?-s-c';
export const LOYALITY_PROGRAM_LINK = '/loyalty_program';

export const SMS_VERIFICATION_RESEND_TIMEOUT = 45;
export const SMS_VERIFICATION_RESEND_TIMEOUT_TEXT = 'Повторная отправка СМС будет доступна через';

export const DISABLED_OR_DELETED_ERROR_CODE = 403;

export const AGENT_LINK = 'https://old.infoflot.com/';
export const AUTH_REDIRECT_MAP: Record<string, string> = {
	'/order/customer': '/order/confirmation',
};

export type ControlsTheme = 'secondary' | 'primary' | 'divider';