import React, { FC } from 'react';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button';

interface ErrorPageProps {
	text: string;
	onReloadPage: () => void;
}

export const ErrorPage: FC<ErrorPageProps> = React.memo(({ text, onReloadPage }) => (
	<div className={styles.errorPageWrap}>
		<div className={styles.errorPageContainer}>
			<Typo design="description-m">{text}</Typo>
			<Button btnType="text" onClick={onReloadPage}>
				Обновить
			</Button>
		</div>
	</div>
));
