// import { useRouter } from 'next/router';
import { useUnit } from 'effector-react';
// import { AccountRoutes } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
import { accountNavigationModel } from '../../model';
import { AccountNavigationLink } from '../account-navigation-link';
import styles from './styles.module.scss';
import { AccountRoutes } from 'store/auth/constants';
import { Typo } from 'layout/components/typo/ui';
import { useRouter, useLocation } from '@tanstack/react-router';

export const AccountNavigation = () => {
	// const { asPath } = useRouter();
	const location = useLocation();
	const asPath = location.pathname
	
	const pathname = asPath.split('?')[0];

	const [requestsCount, notificationsCount] = useUnit([
		accountNavigationModel.$requestsCount,
		accountNavigationModel.$notificationsCount,
	]);

	return (
		<nav className={styles.navigation}>
			<AccountNavigationLink
				href={AccountRoutes.Profile}
				isActive={asPath.includes(AccountRoutes.Profile)}
				isLock={pathname === AccountRoutes.Profile}
				iconId="profile"
				// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_profile_menu)}
			>
				Профиль
			</AccountNavigationLink>
			<AccountNavigationLink
				href={AccountRoutes.MyRequestsActive}
				isActive={asPath.includes(AccountRoutes.MyRequests)}
				isLock={pathname === AccountRoutes.MyRequests}
				iconId="request"
				// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_enquiries_menu)}
			>
				<Typo as="span" className={styles.textWithIcon}>
					Мои заявки <span className={styles.myRequestsCount}>{` ${requestsCount}`}</span>
				</Typo>
			</AccountNavigationLink>
			<AccountNavigationLink
				href={AccountRoutes.MyBonuses}
				isActive={asPath.includes(AccountRoutes.MyBonuses)}
				isLock={pathname === AccountRoutes.MyBonuses}
				iconId="bonus"
				// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_bonuses_menu)}
			>
				Мои бонусы
			</AccountNavigationLink>
			<AccountNavigationLink
				href={AccountRoutes.PersonalData}
				isActive={asPath.includes(AccountRoutes.PersonalData)}
				isLock={pathname === AccountRoutes.PersonalData}
				iconId="personal-data"
				// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_personal_data)}
			>
				Личные данные
			</AccountNavigationLink>

			{!!notificationsCount && (
				<AccountNavigationLink
					href={AccountRoutes.Notifications}
					isActive={asPath.includes(AccountRoutes.Notifications)}
					isLock={pathname === AccountRoutes.Notifications}
					iconId="notification"
					// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_notification)}
				>
					<span className={styles.textWithIcon}>
						Уведомления от операторов
						<Typo design="button-s" as="span" className={styles.notificationsCount}>
							{notificationsCount}
						</Typo>
					</span>
				</AccountNavigationLink>
			)}
		</nav>
	);
};
