import { fetcher } from 'api/setupAxios';
import { GenericAbortSignal } from 'axios';
import { PRICES_ENDPOINT } from 'config/constants/prices';
import { GetPricePreviewRequest, PricesPreviews } from 'config/types/prices';
// import { fetcher } from 'shared/api/config';
// import { PRICES_ENDPOINT } from 'shared/config';
// import { PricesPreviews } from 'shared/lib/types';
// import { GetPricePreviewRequest } from 'shared/lib/types/prices';

export interface GetPricesPreviewRequest {
	cruiseId: string;
	body: GetPricePreviewRequest;
	signal?: GenericAbortSignal;
}

export const getPricesPreview = async ({ cruiseId, body, signal }: GetPricesPreviewRequest) => {
	return fetcher<PricesPreviews>({
		url: `${PRICES_ENDPOINT}/${cruiseId}/preview`,
		method: 'POST',
		signal,
		body,
		authorization: true,
	});
}
