// import type { NextPage } from 'next';
// import { Bonuses } from 'widgets/account';
// import { profileScreenModel } from 'widgets/account/model';
// import { AccountLayout } from 'widgets/layouts/account';
// import { Main } from 'widgets/layouts/main';
// import { staticPropsFactory } from 'shared/lib/ssr';
// import { BonusIcons } from 'shared/ui/icons/svg-sprite';

// const MyBonuses: NextPage = () => (
// 	<Main>
// 		<AccountLayout>
// 			<Bonuses />
// 			<BonusIcons />
// 		</AccountLayout>
// 	</Main>
// );

// export const getStaticProps = staticPropsFactory(profileScreenModel.getStaticData).bind({
// 	__logName: 'MyBonuses',
// });

// export default MyBonuses;

import { createFileRoute } from '@tanstack/react-router'
import { BonusIcons } from 'layout/components/icons/svg-sprite';
import { AccountLayout } from 'layout/layouts/account';
import { Main } from 'layout/layouts/main';
import { Bonuses } from 'layout/layouts/widgets/account';
import { useEffect } from 'react';
import { findAndChangeInnerHtml } from 'utils/page/pageUtil';
import accountStyles from './../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute('/account/my-bonuses/')({
	// component: () => <div>Hello /account/my-bonuses/!</div>,
	component: () => <MyBonuses />,
})


const MyBonuses = () => {
	useEffect(() => {
		const pageH1 = "Мои бонусы"
		findAndChangeInnerHtml("h1", `<h1 id='h1'>${pageH1}</h1>`)
	}, [])


	return (
		<Main>
			<AccountLayout>
				<section className={accountStyles.marginTopSmMd}>
					<Bonuses />
					<BonusIcons />
				</section>
			</AccountLayout>
		</Main>
	);
}