// import type { NextPage } from 'next';
// import { profileScreenModel } from 'widgets/account/model';
// import { AllBonuses } from 'widgets/account/ui/bonuses';
// import { AccountLayout } from 'widgets/layouts/account';
// import { Main } from 'widgets/layouts/main';
// import { staticPropsFactory } from 'shared/lib/ssr';
// import { BonusIcons } from 'shared/ui/icons/svg-sprite';

// const HistoryBonuses: NextPage = () => (
// 	<Main>
// 		<AccountLayout>
// 			<AllBonuses />
// 			<BonusIcons />
// 		</AccountLayout>
// 	</Main>
// );

// export const getStaticProps = staticPropsFactory(profileScreenModel.getStaticData).bind({
// 	__logName: 'HistoryBonuses',
// });

// export default HistoryBonuses;

import { createFileRoute } from '@tanstack/react-router'
import { BonusIcons } from 'layout/components/icons/svg-sprite';
import { AccountLayout } from 'layout/layouts/account';
import { Main } from 'layout/layouts/main';
import { AllBonuses } from 'layout/layouts/widgets/account/ui/bonuses';
import { useEffect } from 'react';
import { findAndChangeInnerHtml } from 'utils/page/pageUtil';
import accountStyles from './../../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute('/account/my-bonuses/history/')({
  // component: () => <div>/account/my-bonuses/history</div>,
  component: () => <HistoryBonuses />,
})

const HistoryBonuses = () => {
  useEffect(() => {
    const pageH1 = "История начисления бонусов"
    findAndChangeInnerHtml("h1", `<h1 id='h1'>${pageH1}</h1>`)
  }, [])

  return (
    <Main>
      <AccountLayout>
        <section className={accountStyles.marginTopSmMd}>
          <AllBonuses />
          <BonusIcons />
        </section>
      </AccountLayout>
    </Main>
  );
}