/* eslint-disable quote-props */
import React, { FC } from 'react';
// import { Skeleton } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Skeleton } from 'layout/components/special/skeleton';

export const PassengersSkeleton: FC = () => (
	// <div>PassengersSkeleton</div>
	<div className={styles.passengers}>
		<Skeleton margin="0 0 16px" width="50%" height={24} />
		<Skeleton margin="0 0 16px" width="50%" height={24} />
		<Skeleton margin="0 0 16px" width="100%" height={56} />
		<Skeleton margin="0 0 16px" width="100%" height={56} />
		<Skeleton margin="0 0 16px" width="100%" height={56} />
		<Skeleton margin="0 0 8px" width="100%" height={24} />
		<Skeleton margin="0 0 8px" width="100%" height={24} />
	</div>
);
