// import { DECKS_DECLENSION, PASSENGERS_DECLENSION } from 'shared/lib/constants';
// import { Declensions } from 'shared/lib/types';

import { Declensions } from "config/commonTypes";
import { DECKS_DECLENSION, PASSENGERS_DECLENSION } from "./declensions";

export const SHIPS_ENDPOINT = '/api/content/v1/ships';
export const SHIPS_HANDBOOK_ENDPOINT = '/api/content/v1/ships/filters';
export const AJAX_SHIPS = '/ajax/ships';

export const SHIPS_LIMIT = 10;
export const SHIPS_TYPE_KEY = 'typeOfRoute';
export const SHIPS_TYPES_RUS = 'riversRussian';
export const SHIPS_TYPES_FOREIGN = 'riversForeign';
export const SHIPS_TYPES_SEA = 'sea';

export const SHIP_STARS: Record<number, string> = {
	1: '2',
	2: '3',
	3: '4',
	4: '4 плюс',
	5: '5',
	6: '5 luxe',
};

export const SHIP_CLASS: Record<number, string> = {
	1: 'эконом',
	2: 'стандарт',
	3: 'комфорт',
	4: 'комфорт +',
	5: 'премиум',
	6: 'люкс',
};

export const SHIP_SPECS = new Map<string, Declensions>();

SHIP_SPECS.set('палуб', DECKS_DECLENSION);
SHIP_SPECS.set('пассажиров', PASSENGERS_DECLENSION);

export const SHIPS_BREADCRUMBS_RUS = [{ text: 'Речные круизы по России' }];
export const SHIPS_BREADCRUMBS_FOREIGN = [{ text: 'Речные круизы по зарубежью' }];
export const SHIPS_BREADCRUMBS_SEA = [{ text: 'Морские круизы' }];

export const SHIPS_RENTAL_BANNER = '/images/ships/ship-rental.jpg';
export const SHIPS_RENTAL_LINK = '/info/arenda-dlia-meropriiatii';

export const SHIPS_CLASSES_PAGE_SLUG = 'ship-classes';

export const SHIPS_DISPLAY_SPECIFICATIONS = false;
