import React from 'react';
import { Item } from 'react-stately';
import { useGate } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
import { useUnit } from 'effector-react';
// import { changeIdentityDocumentModel } from 'features/personal';
import { DOCUMENT_TYPES_MASK_TOKENS, documentTypesModel } from 'entities/document-types';
// import { Input } from 'shared/ui/atoms';
// import { Select } from 'shared/ui/molecules';
// import { FormField } from 'shared/ui/organisms/form';
// import { ErrorsBlock } from 'shared/ui/organisms/form/ui/errors-block';
import { DataBlock } from '../data-block';
import styles from './styles.module.scss';
import { changeIdentityDocumentModel } from 'entities/personal';
import { ErrorsBlock } from 'layout/components/inputs/form/errors-block';
import { FormField } from 'layout/components/inputs/form/field';
import { Input } from 'layout/components/inputs/input';
import { Select } from 'layout/components/inputs/selects/select';

export const IdentityDocument = () => {
	const { form, Gate, $pattern, $id, $documentTypesValidation } = changeIdentityDocumentModel;
	const { controller } = useForm({ form });
	const [documentTypes, selectDocumentType] = useUnit([
		documentTypesModel.$documentTypes,
		changeIdentityDocumentModel.selectDocumentType,
	]);

	const formError = useError({
		form,
		name: 'formError',
	});
	const { error, isShowError } = useError({
		form,
		name: 'documentType',
	});

	useGate(Gate);

	const [mask, documentTypeId, documentTypesValidation] = useUnit([
		$pattern,
		$id,
		$documentTypesValidation,
	]);

	return (
		<DataBlock
			title="Документ удостоверяющий личность"
			text="Для граждан РФ введите данные паспорта РФ или заграничного паспорта"
		>
			<div className={styles.wrapper}>
				<Select
					size="lg"
					className={styles.selector}
					label="Тип документа"
					theme="divider"
					selectionMode="single"
					selectedKeys={documentTypeId ? [String(documentTypeId)] : []}
					error={error && isShowError ? <div>{error}</div> : undefined}
					isRequired
					onSelectionChange={(keys) => {
						const keysArray = keys instanceof Set ? ([...keys] as string[]) : [keys];
						const value = keysArray.length ? Number(keysArray[0]) : '';

						selectDocumentType(value || null);
					}}
				>
					{documentTypes.map(({ id, name }) => (
						<Item key={`${id}`} aria-label={name}>
							{name}
						</Item>
					))}
				</Select>
				<FormField
					validation={documentTypesValidation}
					controller={controller}
					form={form}
					name="passportData"
					secondPlaceholder={mask}
					required
				>
					<Input
						disabled={!documentTypeId}
						masked
						mask={mask}
						placeholder="Серия / Номер документа"
						size="lg"
						withLabel
						maskOptions={{
							tokens: DOCUMENT_TYPES_MASK_TOKENS,
						}}
					/>
				</FormField>
			</div>

			{formError.isShowError && formError.error && <ErrorsBlock error={formError.error} />}
		</DataBlock>
	);
};
