import React from 'react';
// import Link from 'next/link';
// import { AccountRoutes } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { Request, RequestCardChild } from 'shared/lib/types/account/models';
// import { setAnalytics } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
// import { RequestStatus } from 'shared/ui/molecules';
// import { ShipInfo } from 'shared/ui/organisms';
import { CruiseDuration } from '../../../cruises/ui';
import { AdditionalRequestsList } from '../additional-requests';
import { PaymentInfo } from './payment-info';
import styles from './styles.module.scss';
import { Link, useRouter } from '@tanstack/react-router';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Typo } from 'layout/components/typo/ui';
import { RequestStatus } from 'layout/components/view/request-status';
import { AccountRoutes } from 'store/auth/constants';
import { RequestCardChild } from 'store/auth/types/account/request-card-child';
import { ShipInfo } from 'layout/components/view/ship-info';
import { Request } from 'store/auth/types/account/request';

interface RequestCardProps extends Request {
	additionalRequests?: Array<RequestCardChild>;
}

export const RequestCard = ({
	status,
	number,
	amount,
	payed,
	refunded,
	prepayment,
	prepaymentDate,
	expirationDate,
	cruise,
	id,
	additionalRequests,
}: RequestCardProps) => {
	const router = useRouter()

	return (
		<div className={styles.card_container}>
			<Link
				className={styles.card_wrap_top}
				// href={`${AccountRoutes.MyRequests}/${id}`}
				to={`${AccountRoutes.MyRequests}/${id}`}
			// onClick={() => router.navigate({ to: `${AccountRoutes.MyRequests}/${id}}` })}
			// passHref
			>
				<a
					className={styles.card_wrap}
				// onClick={() => setAnalytics(ANALYTICS_TARGETS.account.account_enquiries_click)}
				>
					<div className={styles.card_header}>
						<div className={styles.card_head}>
							<Typo design="headline-s" className={styles.card_headNumber}>{`№ ${number}`}</Typo>
							<RequestStatus status={status} />
						</div>

						<PaymentInfo
							status={status}
							amount={amount}
							payed={payed}
							refunded={refunded}
							prepayment={prepayment}
							prepaymentDate={prepaymentDate}
							expirationDate={expirationDate}
						/>
					</div>

					<div className={styles.cruise_info}>
						<ShipInfo
							{...cruise?.ship}
							theme="black"
							fontSize="headline-xs"
							shipClassFontSize="description-m"
						/>

						{cruise?.name && (
							<Typo design="text-s" className={styles.cruiseRoute_short}>
								{cruise.name}
							</Typo>
						)}

						<CruiseDuration
							dateStart={cruise?.startDate}
							dateEnd={cruise?.endDate}
							days={cruise?.lengthDays}
							nights={cruise?.isForeign && cruise.lengthNights}
							className={styles.cruiseRoute_days}
						/>
					</div>
				</a>
			</Link>

			{id && (
				<AdditionalRequestsList
					expirationDate={expirationDate}
					parentId={id}
					additionalRequests={additionalRequests}
				/>
			)}
		</div>
	)
};
