import React, { useMemo } from 'react';
import classnames from 'classnames';
// import { useRouter } from 'next/router';
import { useEvent, useGate, useUnit } from 'effector-react';
// import { useEvent, useUnit } from 'effector-react/ssr';
// import { detailedRequest } from 'widgets/account/model';
// import { RequestDocs } from 'widgets/account/ui/detailed-request/ui/request-docs/ui';
// import { checkStatusForExcursions } from 'widgets/account/ui/request-excursions';
// import { AnchorsNav, RequestCancellationModal } from 'features';
// import { requestCancellationModel } from 'features/request-cancellation';
// import { PassengersList } from 'entities/passengers';
// import { AccountRequestsStatuses } from 'shared/config';
// import { CruiseContextProvider } from 'shared/lib/contexts/cruise-context/cruise-context';
// import { dateIsOverdue } from 'shared/lib/utils';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { PageError, RequestStatus } from 'shared/ui/molecules';
import {
	DetailedRequestAnchors,
	detailedRequestAnchors,
	STATUSES_FOR_CANCELLATION,
	STATUSES_FOR_EDIT_PASSENGER,
	STATUSES_FOR_EDIT_PASSENGER_WITH_AUTOCOMPLETE,
} from '../../lib';
import { PromocodeFriendModal } from '../promocode-friend-modal';
import { RequestCruise } from '../request-cruise';
import { RequestDetails } from '../request-details';
import { RequestDetailsError } from '../request-error';
import { RequestInfoMessage } from '../request-info-message';
import styles from './styles.module.scss';
import { useRouter } from '@tanstack/react-router';
import { CruiseContextProvider } from 'config/contexts/cruise-context/cruise-context';
import { detailedRequest } from 'entities/account/model';
import { PassengersList } from 'entities/passengers';
import { requestCancellationModel, RequestCancellationModal } from 'entities/request-cancellation';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { AnchorsNav } from 'layout/components/view/anchors-nav';
import { RequestStatus } from 'layout/components/view/request-status';
import { AccountRequestsStatuses } from 'store/auth/constants';
import { dateIsOverdue } from 'utils/date';
import { checkStatusForExcursions } from '../../../request-excursions';
import { RequestDocs } from '../request-docs';
import { PageError } from 'layout/pages/page-error';

export const DetailedRequest = React.memo(() => {
	const router = useRouter();


	const [requestData, requestExcursions, errorCode, cruisePath, cruiseShipPath] = useUnit([
		detailedRequest.$detailedRequest,
		detailedRequest.$requestExcursions,
		detailedRequest.$errorCode,
		detailedRequest.$cruisePath,
		detailedRequest.$cruiseShipPath,
	]);
	const openCancelModal = useEvent(requestCancellationModel.openCancelModal);

	useGate(detailedRequest.gate);

	const cruiseStarted = dateIsOverdue(requestData?.cruise?.startDate);

	const hasPassengers = useMemo(
		() =>
			requestData?.cabins && requestData.cabins.some((cabin) => (cabin.passengersCount || 0) > 0),
		[requestData],
	);

	const allowEditPassengers =
		typeof requestData?.status === 'number' &&
		(requestData?.autoComplete
			? STATUSES_FOR_EDIT_PASSENGER_WITH_AUTOCOMPLETE.includes(requestData.status)
			: STATUSES_FOR_EDIT_PASSENGER.includes(requestData.status));

	if (errorCode) {
		return (
			<section className={styles.page_wrap}>
				<PageError className={styles.page_error}>
					<RequestDetailsError errorCode={errorCode} />
				</PageError>
			</section>
		);
	}

	if (!requestData) {
		return null;
	}

	const cruiseDate = {
		start: requestData.cruise?.startDate || '',
		end: requestData.cruise?.endDate || '',
	};

	return (
		<>
			<section className={styles.page_wrap}>
				<div className={styles.page_bg} />

				<div className={styles.pageHeader_head}>
					{/* <Button btnType="text" onClick={router.back} className={styles.goBackButton}> */}
					<Button btnType="text" onClick={() => router.history.back()} className={styles.goBackButton}>
						<Icon id="chevron-left" width={20} height={20} />
					</Button>
					<Typo design="headline-l" as="h1" className={styles.request_number}>
						{requestData.number ? `Заявка № ${requestData.number}` : 'Заявка'}
					</Typo>
					<RequestStatus status={requestData.status} />
				</div>

				<div
					className={classnames(styles.pageHeader_docs, {
						[styles.pageHeader_docsMargin]:
							requestData.documents?.length ||
							(requestData.status && STATUSES_FOR_CANCELLATION.includes(requestData.status)),
					})}
				>
					<RequestDocs {...requestData} />

					{requestData.status !== undefined &&
						STATUSES_FOR_CANCELLATION.includes(requestData.status) &&
						!cruiseStarted && (
							<Button
								btnType="text"
								contextClassName={styles.canceled_button}
								onClick={openCancelModal}
							>
								<Icon id="canceled-2" width={20} height={20} />
								<span>Аннулировать заявку</span>
							</Button>
						)}
				</div>

				<AnchorsNav items={detailedRequestAnchors} withDesktopView={false} offset={88} />

				<div
					className={classnames(styles.cruiseInfo, {
						[styles.cruiseInfo_withMessage]:
							requestData?.status === AccountRequestsStatuses.AwaitingConfirmation ||
							requestData?.status === AccountRequestsStatuses.WaitingList ||
							requestData?.status === AccountRequestsStatuses.Confirmed,
					})}
				>
					<RequestCruise
						id={requestData.id}
						cruise={requestData.cruise}
						// excursions={requestExcursions}
						excursions={null}
						excursionsCount={requestData.excursionsCount}
						allowAddExcursions={checkStatusForExcursions(requestData?.status)}
						isEditable={Boolean(requestData.excursionsCount)}
						cruisePath={cruisePath}
						cruiseShipPath={cruiseShipPath}
					>
						<RequestInfoMessage status={requestData?.status} isPayable={requestData?.isPayable} />
					</RequestCruise>

					<RequestDetails className={styles.requestDetails} request={requestData} />

					{hasPassengers && (
						<CruiseContextProvider
							cruiseInfo={{
								date: cruiseDate,
							}}
						>
							<PassengersList
								className={styles.request_passengerList}
								request={requestData}
								anchor={DetailedRequestAnchors.TouristData}
								allowEditPassengers={allowEditPassengers}
							/>
						</CruiseContextProvider>
					)}
				</div>
			</section>

			<RequestCancellationModal requestId={requestData.id} />

			{requestData && <PromocodeFriendModal request={requestData} />}
		</>
	);
});
