import { AxiosError } from 'axios';
import { status } from 'patronum';
import { createEffect, createEvent, sample, createStore } from 'effector';
import { createGate } from 'effector-react';
import { message } from 'config/constants/message';
import { SupportPages } from 'config/constants/support';
import { Errors } from 'config/types/forms/errors';
import { SupportType } from 'config/types/support';
import { createForm } from 'effector-react-form';
import { SendBonusSupportRequest, bonusesRequests } from 'store/account/api/bonuses';
import { supportRequest } from 'store/support';
// import { createForm } from 'effector-react-form/scope';
// import { SendBonusSupportRequest, bonusesRequests, supportRequest } from 'shared/api';
// import { SupportPages } from 'shared/config';
// import { message } from 'shared/lib/message';
// import { Errors, SupportType } from 'shared/lib/types';

const ModalGate = createGate<boolean>();

const sendSupportModel = createEvent<SendBonusSupportRequest>();
const sendSupportModelFx = createEffect<
	SendBonusSupportRequest,
	unknown,
	AxiosError<Errors.Errors>
>(bonusesRequests.sendBonusSupport);

const getSupportTypes = createEvent();
const getSupportTypesFx = createEffect(supportRequest.getSupportTypesHandbook);

const reset = createEvent();

const $error = createStore<Nullable<Errors.Errors>>(null).reset(reset);
const $status = status({ effect: sendSupportModelFx }).reset(reset);
const $supportTypes = createStore<SupportType[]>([]);

const form = createForm<SendBonusSupportRequest>({
	initialValues: {
		message: '',
		type: '',
	},
	onSubmit: ({ values }) => sendSupportModel(values),
});

sample({
	clock: ModalGate.open,
	target: getSupportTypes,
});

sample({
	clock: getSupportTypes,
	fn: () => ({ page: SupportPages.Profile }),
	target: getSupportTypesFx,
});

sample({
	clock: getSupportTypesFx.doneData,
	target: $supportTypes,
});

sample({
	clock: ModalGate.state,
	filter: (clock) => !clock,
	target: reset,
});

sample({
	clock: sendSupportModel,
	target: sendSupportModelFx,
});

sample({
	clock: sendSupportModelFx.doneData,
	target: form.reset,
});

sample({
	clock: sendSupportModelFx.failData,
	fn: () => ({
		field: 'formError',
		error: message.bonusSupport.error,
	}),
	target: form.setOrDeleteError,
});

export const model = {
	ModalGate,
	form,
	$status,
	$error,
	$supportTypes,
};
