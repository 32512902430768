import { sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { officesModel } from 'entities/offices';
import { profileModel } from 'layout/components/auth/profile';
// import { profileModel } from 'entities/profile';

interface FormValues {
	officeId: Nullable<number>;
}

const Gate = createGate();

const $officeId = profileModel.$profile.map((profile) => {
	if (profile) {
		return profile.office.id;
	}

	return null;
});

const form = createForm<FormValues>({
	initialValues: {
		officeId: null,
	},
});

sample({
	clock: Gate.open,
	target: officesModel.fetchOffices,
});

sample({
	clock: [Gate.open, profileModel.resetDraft],
	source: $officeId,
	filter: Boolean,
	fn: (officeId) => ({
		officeId,
	}),
	target: form.setValues,
});

sample({
	source: profileModel.$profileDraft,
	filter: Boolean,
	clock: form.$values,
	fn: (draft, { officeId }) => ({
		...draft,
		officeId,
	}),
	target: profileModel.$profileDraft,
});

export const chooseOfficeModel = {
	form,
	Gate,
};
