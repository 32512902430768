import { useOverlayTriggerState } from 'react-stately';
import { useUnit, useGate } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { setAnalytics } from 'shared/lib/utils';
// import { Button, Typo, Condition } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { model } from '../../model';
import { BonusRecoveryForm } from '../bonus-recovery-form';
import { BonusRecoveryInfo } from '../bonus-recovery-info';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

export const BonusRecoveryModal = () => {
	const status = useUnit(model.$status);

	const state = useOverlayTriggerState({});

	const shouldMount = useDelayUnmount(state.isOpen, 600);
	useGate(model.ModalGate, shouldMount);

	const { form } = model;
	const { submit } = useForm({ form });

	return (
		<>
			<Button
				className={styles.triggerButton}
				onClick={() => {
					state.toggle();
					// setAnalytics(ANALYTICS_TARGETS.account.account_points_recovery_click);
				}}
				btnType="outline"
				size="large"
			>
				Восстановить баллы
			</Button>

			{shouldMount && (
				<Modal
					state={state}
					isShown={state.isOpen}
					classNameModal={styles.bonusRecoveryModal}
					closeButtonClassName={styles.modalCloseButton}
					verticalAlign="bottom"
					isDismissable
					footer={
						<div className={styles.footer}>
							<Condition
								value={status !== 'done'}
								then={
									<>
										<Button
											type="submit"
											loading={status === 'pending'}
											onClick={submit}
											className={styles.sendButton}
											size="large"
										>
											Отправить
										</Button>
										<Button onClick={state.close} btnType="text" size="large">
											Отменить
										</Button>
									</>
								}
								else={
									<Button onClick={state.close} className={styles.sendButton} size="large">
										Закрыть
									</Button>
								}
							/>
						</div>
					}
				>
					<Condition
						value={status !== 'done'}
						then={
							<>
								<BonusRecoveryInfo close={state.close} />
								<BonusRecoveryForm />
							</>
						}
						else={<Typo design="headline-xl">Ваше обращение успешно отправлено</Typo>}
					/>
				</Modal>
			)}
		</>
	);
};
