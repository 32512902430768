import { useState, SyntheticEvent } from 'react';
import { useError, useForm } from 'effector-react-form';
import { useUnit } from 'effector-react';
// import { InputPhone } from 'shared/ui/atoms';
// import { InputPassword } from 'shared/ui/atoms/input/input-password';
// import { Form, FormField } from 'shared/ui/organisms/form';
import { changeAdditionalPhoneModel, changeMainPhoneModel } from '../../model';
import { FormField } from 'layout/components/inputs/form/field';
import { Form } from 'layout/components/inputs/form/form';
import { InputPhone } from 'layout/components/inputs/input';
import { InputPassword } from 'layout/components/inputs/input/input-password';

interface ModalFormProps {
	additionalPhone?: boolean;
}

export const ModalForm = ({ additionalPhone = false }: ModalFormProps) => {
	const model = additionalPhone ? changeAdditionalPhoneModel : changeMainPhoneModel;
	const { phoneForm, codeForm, $state } = model;
	const [phoneInputIsValid, setPhoneInputIsValid] = useState(false);
	const phoneFormProps = useForm({ form: phoneForm });
	const { setValue } = phoneFormProps;
	const codeFormProps = useForm({ form: codeForm });
	const state = useUnit($state);

	const phoneFormError = useError({
		form: phoneForm,
		name: 'formError',
	});

	const codeFormError = useError({
		form: codeForm,
		name: 'formError',
	});

	const onChangePhone = (selection: string, isValid: boolean) => {
		setPhoneInputIsValid(isValid);
		setValue({
			field: 'phone',
			value: selection,
		});
	};

	const handleFormSubmit = (event: SyntheticEvent<HTMLFormElement, Event>) => {
		if (phoneInputIsValid || state !== 'phone') {
			phoneFormProps.handleSubmit(event);
		}
	};

	switch (state) {
		case 'phone':
			return (
				<Form {...phoneFormError} handleSubmit={handleFormSubmit}>
					<FormField
						controller={phoneFormProps.controller}
						form={phoneForm}
						name="phone"
						size="lg"
						validation={{ required: true }}
					>
						<InputPhone onNumberChange={onChangePhone} />
					</FormField>
				</Form>
			);
		case 'code':
			return (
				<Form
					{...codeFormError}
					isShowError={Boolean(codeFormError.error)}
					handleSubmit={codeFormProps.handleSubmit}
				>
					<FormField controller={codeFormProps.controller} form={codeForm} name="code">
						<InputPassword size="lg" placeholder="Код из SMS" withLabel />
					</FormField>
				</Form>
			);
		default:
			return null;
	}
};
