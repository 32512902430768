import { MutableRefObject, PropsWithChildren } from 'react';
import { Placement } from '@react-types/overlays';
import classnames from 'classnames';
import { AriaDialogProps } from 'react-aria';
import { OverlayTriggerState } from 'react-stately';
// import { SelectModalPopoverProps, SelectPopover } from 'shared/ui/molecules/select-head';
import styles from './styles.module.scss';
import { SelectModalPopoverProps, SelectPopover } from 'layout/components/inputs/selects/select-head';

interface CalendarPopoverProps extends PropsWithChildren, SelectModalPopoverProps {
	triggerRef: MutableRefObject<null>;
	popoverRef?: MutableRefObject<null>;
	state: OverlayTriggerState;
	placement?: Placement;
	dialogProps: AriaDialogProps;
	onReset?: () => void;
	searchCalendar?: boolean;
}

export const CalendarPopover = ({
	triggerRef,
	popoverRef,
	state,
	placement,
	dialogProps,
	searchCalendar,
	modalMode,
	modalTitle,
	modalFixedSize,
	modalScrollReachedEnd,
	modalScrollReachedStart,
	modalHeader,
	onReset,
	children,
}: CalendarPopoverProps) => {
	const wrapped = modalMode ? children : <div {...dialogProps}>{children}</div>;

	return (
		<SelectPopover
			state={state}
			popoverRef={popoverRef}
			triggerRef={triggerRef}
			placement={placement}
			modalMode={modalMode}
			modalTitle={modalTitle}
			modalFixedSize={modalFixedSize}
			modalScrollReachedEnd={modalScrollReachedEnd}
			modalScrollReachedStart={modalScrollReachedStart}
			modalHeader={modalHeader}
			onReset={onReset}
			className={classnames(styles.select__popover, searchCalendar && styles.select_searchPanel)}
		>
			{wrapped}
		</SelectPopover>
	);
};
