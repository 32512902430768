import React from 'react';
// import { Skeleton } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Skeleton } from 'layout/components/special/skeleton';

export const SkeletonCards = React.memo(() => (
	<>
		<Skeleton className={styles.cardSkeleton} />
		<Skeleton className={styles.cardSkeleton} />
		<Skeleton className={styles.cardSkeleton} />
		<Skeleton className={styles.cardSkeleton} />
	</>
));
