import React, { FC } from 'react';
// import { RequestsCallbackModal } from 'widgets/account/ui/detailed-request/ui';
// import { AccountRequestsStatuses } from 'shared/config';
// import { Request } from 'shared/lib/types/account/request';
// import { WarningMessage } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { WarningMessage } from 'layout/components/view/warning-message';
import { RequestsCallbackModal } from 'layout/layouts/widgets/account/ui/detailed-request/ui';
import { AccountRequestsStatuses } from 'store/auth/constants';
import { Request } from 'store/auth/types/account/request';

interface WarningsProps {
	status?: Request['status'];
}

export const Warnings: FC<WarningsProps> = ({ status }) => {
	if (!status) {
		return null;
	}

	switch (status) {
		case AccountRequestsStatuses.WaitingList:
			return (
				<WarningMessage className={styles.additionalRequest_warning} design="danger">
					<p>
						Ваша заявка находится в листе ожидания.
						<br />
						За уточнением информации по заявке обратитесь в Ваш офис обслуживания или закажите{' '}
						<RequestsCallbackModal className={styles.callback} />.
					</p>
				</WarningMessage>
			);

		case AccountRequestsStatuses.InProcessing:
			return (
				<WarningMessage className={styles.additionalRequest_warning} design="danger">
					<p>
						Ваша заявка в обработке у менеджера. Поэтому вы пока не можете реадактировать или
						оплачивать заявку. Не волнуйтесь, обработка заявки не займет много времени.
					</p>
				</WarningMessage>
			);

		default:
			return null;
	}
};
