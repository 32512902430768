import React from 'react';
import classnames from 'classnames';
// import Link from 'next/link';
// import { AccountRequestsStatuses, AccountRoutes } from 'shared/config';
// import { RequestCardChild } from 'shared/lib/types/account/request-card-child';
// import { getDaysBetween, getExpirationStatus } from 'shared/lib/utils';
// import { Alert, Condition, Price, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { RequestStatus } from 'shared/ui/molecules';
import { getReturnInfoLabel, STATUSES_FOR_AMOUNT, STATUSES_FOR_RETURN } from './lib';
import { Warnings } from './warnings';
import styles from './styles.module.scss';
import { Link } from '@tanstack/react-router';
import { Icon } from 'layout/components/icons/icon';
import { Condition } from 'layout/components/switchers/condition';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';
import { RequestStatus } from 'layout/components/view/request-status';
import { AccountRequestsStatuses, AccountRoutes } from 'store/auth/constants';
import { RequestCardChild } from 'store/auth/types/account/request-card-child';
import { getDaysBetween, getExpirationStatus } from 'utils/date';

interface AdditionalRequestCardProps extends RequestCardChild {
	index: number;
	parentId: number;
}

export const AdditionalRequestCard = ({
	id,
	number,
	status,
	amount,
	index,
	parentId,
	payed,
	expirationDate,
}: AdditionalRequestCardProps) => {
	const daysUntilExpirationDate = getDaysBetween(
		new Date(),
		expirationDate ? new Date(expirationDate) : null,
	);
	const isExpirationDateApproaching = daysUntilExpirationDate < 3;

	const shouldDisplayAmount = Boolean(status && STATUSES_FOR_AMOUNT.includes(status));
	const shouldDisplayReturnInfo = Boolean(status && STATUSES_FOR_RETURN.includes(status));

	const returnInfoLabel = getReturnInfoLabel(status);
	const expirationMessage = getExpirationStatus(expirationDate);

	return (
		<div className={classnames(styles.additionalRequest_wrap)} style={{ zIndex: index }}>
			<div className={styles.additionalRequest_icon}>
				<Icon id="angle" width={20} height={20} />
			</div>

			<div className={styles.additionalRequest_content}>
				<div className={styles.additionalRequest_contentRow}>
					<Typo
						design="text-s"
						className={styles.additionalRequest_reqNumber}
					>{`№ ${number}`}</Typo>
					<RequestStatus status={status} />
				</div>

				<Typo
					design="headline-xs"
					className={classnames(styles.additionalRequest_contentRow, {
						[styles.additionalRequest_contentTitle]:
							status !== AccountRequestsStatuses.AwaitingPayment,
					})}
				>
					Дополнительная заявка на экскурсии
					{amount ? <Price total={amount} as="span" design="headline-xs" bold /> : null}
				</Typo>

				{shouldDisplayAmount && (
					<div className={styles.amountInfo}>
						<div className={styles.amountInfo__amount}>
							<Typo design="text-s" className={classnames(styles.additionalRequest_contentRow)}>
								Стоимость
								{amount ? <Price as="span" total={amount} /> : null}
							</Typo>
							<Typo design="text-s" className={classnames(styles.additionalRequest_contentRow)}>
								Осталось оплатить
								{amount && payed !== undefined ? <Price as="span" total={amount - payed} /> : null}
							</Typo>
						</div>

						<Condition
							value={isExpirationDateApproaching}
							then={
								<>
									{expirationDate && (
										<div
											className={classnames(
												styles.expiration,
												daysUntilExpirationDate < 1 && styles.warn,
											)}
										>
											<Icon id="payment-time" width={12} height={12} />
											<Typo design="description-features" raw={expirationMessage ?? ''} />
										</div>
									)}
									<Alert size="sm" type="default-red">
										В случае невозможности оплаты в указанный срок, обратитесь в офис за уточнением
										возможности продления.
									</Alert>
								</>
							}
							else={
								<div className={styles.expiration}>
									<Icon id="payment-time" width={12} height={12} />
									<Typo design="description-features" raw={expirationMessage ?? ''} />
								</div>
							}
						/>
					</div>
				)}

				{shouldDisplayReturnInfo && payed !== undefined && payed > 0 && (
					<div className={styles.additionalRequest_contentRow}>
						<Typo design="text-s" className={styles.additionalRequest_reqNumber}>
							{returnInfoLabel}
						</Typo>

						<div>
							<Price total={payed} as="span" design="headline-xs" bold />
						</div>
					</div>
				)}

				<Warnings status={status} />

				<Link to={`${AccountRoutes.MyRequests}/${parentId}/excursions/${id}`}>
					<a className={styles.additionalRequest_link} />
				</Link>
			</div>
		</div>
	);
};
