import React, { FC } from 'react';
import { OverlayTriggerState } from 'react-stately';
import { useStore } from 'effector-react';
import styles from '../edit-passenger/styles.module.scss';
// import { Button } from 'shared/ui/atoms';
import { model } from '../../model';
import { Button } from 'layout/components/buttons/button';

interface AlertFooterProps {
	close?: OverlayTriggerState['close'];
	parentClose?: OverlayTriggerState['close'];
	save: (e?: any) => void;
}

export const AlertFooter: FC<AlertFooterProps> = React.memo(({ close, parentClose, save }) => {
	const status = useStore(model.$status);

	const closeHandler = () => {
		close?.();
		parentClose?.();
	};

	return (
		<div className={styles.footer}>
			{status === 'done' ? (
				<Button
					onClick={() => {
						closeHandler();
					}}
					size="large"
				>
					Закрыть
				</Button>
			) : (
				<>
					<Button
						loading={status === 'pending'}
						onClick={() => {
							save();
						}}
						size="large"
					>
						Сохранить
					</Button>
					<Button onClick={close} btnType="text" size="large">
						Отменить
					</Button>
				</>
			)}
		</div>
	);
});
