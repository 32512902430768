import React, { FC } from 'react';
// import Link from 'next/link';
// import { ShortNews } from 'shared/lib/types';
// import { formatDate } from 'shared/lib/utils';
// import { DesktopView, MobileView, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Link } from '@tanstack/react-router';
import { ShortNews } from 'config/types/news';
import { Typo } from 'layout/components/typo/ui';
import { DesktopView, MobileView } from 'layout/layouts/device-view';
import { formatDate } from 'utils/date';

export const NotificationCard: FC<ShortNews> = React.memo(
	({ id, link, publishStartDate, cardTitle, cardDescription }) => (
		<Link to={link || `/news/${id}`}
			// passHref
		>
			<a>
				<figure className={styles.notificationWrap}>
					<header className={styles.notificationHeader}>
						<Typo as="p" design="text-s">
							{cardTitle}
						</Typo>
						<DesktopView>
							<Typo as="p" design="text-s" className={styles.notificationDate}>
								{formatDate(publishStartDate, 'DD.MM.YYYY, HH:mm')}
							</Typo>
						</DesktopView>
					</header>
					<Typo design="headline-s" className={styles.notificationTitle}>
						{cardTitle}
					</Typo>
					{cardDescription && (
						<Typo design="text-s" className={styles.notificationDescription}>
							{cardDescription}
						</Typo>
					)}
					<MobileView>
						<Typo as="p" design="text-s" className={styles.notificationDate}>
							{formatDate(publishStartDate, 'DD MM YYYY, HH:mm')}
						</Typo>
					</MobileView>
				</figure>
			</a>
		</Link>
	),
);
