import { useForm } from 'effector-react-form';
import { useGate } from 'effector-react';
// import { Button } from 'shared/ui/atoms';
import { model } from '../../model';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';

interface ModalFooterProps {
	close?: () => void;
}

export const ModalFooter = ({ close }: ModalFooterProps) => {
	const { form, Gate } = model;
	const { submit } = useForm({ form });

	useGate(Gate, { close });

	return (
		<div className={styles.footer}>
			<Button
				onClick={(e) => {
					submit(e);
				}}
				className={styles.footer__button}
				size="large"
			>
				Сохранить
			</Button>
			<Button onClick={close} btnType="text" size="large">
				Отменить
			</Button>
		</div>
	);
};
