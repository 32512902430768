import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
// import { useUnit } from 'effector-react/ssr';
// import { AccountModal } from 'entities/account-modal';
// import { Alert, LoadSpinner, Typo } from 'shared/ui/atoms';
import { requestCancellationModel } from '../model';
import { ModalFooter } from './modal-footer';
import styles from './styles.module.scss';
import { AccountModal } from 'layout/components/auth/account-modal';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { LoadSpinner } from 'layout/components/view/load-spinner';

export interface RequestCancellationModalProps {
	requestId?: number;
}

export const RequestCancellationModal = ({ requestId }: RequestCancellationModalProps) => {
	const [status, cancelStatus] = useUnit([
		requestCancellationModel.$status,
		requestCancellationModel.$cancelStatus,
	]);
	const state = useOverlayTriggerState({});

	useGate(requestCancellationModel.Gate, state);

	return (
		<AccountModal
			state={state}
			verticalAlign="middle"
			classNameModal={styles.requestCancellationModal}
			classNameModalContent={styles.requestCancellationModal_content}
			classNameFooter={styles.requestCancellationModal_footer}
			footer={<ModalFooter requestId={requestId} status={status} />}
		>
			{() => (
				<>
					{status === 'initial' && (
						<Typo design="headline-xl">Вы действительно хотите аннулировать эту заявку?</Typo>
					)}
					{status === 'pending' && (
						<div className={styles.requestCancellationModal_contentPending}>
							<LoadSpinner />
						</div>
					)}
					{status === 'done' && cancelStatus === 12 && (
						<div className={styles.requestCancellationModal_contentWrap}>
							<Typo design="headline-xl">Запрос на аннулирование отправлен</Typo>
							<Typo design="text-m">
								Менеджеры компании рассмотрят запрос и скоро свяжутся с вами.
							</Typo>
						</div>
					)}
					{status === 'done' && cancelStatus === 0 && (
						<Typo design="headline-xl">Ваша заявка успешно аннулирована!</Typo>
					)}
					{status === 'fail' && (
						<div className={styles.requestCancellationModal_contentErrorWrap}>
							<Typo design="headline-xl">Вы действительно хотите аннулировать эту заявку?</Typo>
							<Alert type="error" className={styles.requestCancellationModal_errorMessage}>
								Вы всё сделали правильно, но аннулирование завершилось ошибкой.
								<br /> Попробуйте ещё раз
							</Alert>
						</div>
					)}
				</>
			)}
		</AccountModal>
	);
};
