import { useError, useForm } from 'effector-react-form';
// import { Input, Typo } from 'shared/ui/atoms';
// import { Form, FormField } from 'shared/ui/organisms/form';
// import { emailMask } from 'shared/ui/organisms/form/lib/masks';
import { model } from '../../model';
import styles from './styles.module.scss';
import { FormField } from 'layout/components/inputs/form/field';
import { Form } from 'layout/components/inputs/form/form';
import { emailMask } from 'layout/components/inputs/form/masks';
import { Input } from 'layout/components/inputs/input';
import { Typo } from 'layout/components/typo/ui';

export const AddEmailModal = () => {
	const { form } = model;
	const { controller, handleSubmit } = useForm({ form });
	const { error, isShowError } = useError({
		form,
		name: 'formError',
	});

	return (
		<div>
			<Typo className={styles.title} design="headline-xl">
				Изменение E-mail
			</Typo>
			<Typo className={styles.text} design="text-m">
				Укажите новый адрес электронной почты. На этот адрес Вам будет выслано письмо с инструкциями
				для подтверждения E-mail.
			</Typo>
			<Form handleSubmit={handleSubmit} isShowError={isShowError} error={error}>
				<FormField
					validation={{
						validateType: 'email',
						required: true,
					}}
					controller={controller}
					onChangeValueParser={(e) => e.target.value.toLowerCase()}
					form={form}
					name="email"
				>
					<Input {...emailMask} size="lg" placeholder="E-mail" withLabel />
				</FormField>
			</Form>
		</div>
	);
};
