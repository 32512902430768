import React from 'react';
import { Item } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { changeDataForPurchaseModel } from 'features/personal';
// import { countriesModel } from 'entities/countries';
// import { dateToCalendarDate, deltaDate } from 'shared/lib/utils';
// import {
// 	Input,
// 	InputDate,
// 	inputDateValueParser,
// 	inputDateOnBlurValueParser,
// 	inputDateOnChangeValueParser,
// 	Typo,
// } from 'shared/ui/atoms';
// import { Radio } from 'shared/ui/atoms/radio';
// import { RadioGroup, Select } from 'shared/ui/molecules';
// import { FormField } from 'shared/ui/organisms/form';
// import { nameMask } from 'shared/ui/organisms/form/lib/masks';
import { DataBlock } from '../data-block';
import styles from './styles.module.scss';
import { changeDataForPurchaseModel } from 'entities/personal';
import { countriesModel } from 'store/countries';
import { dateToCalendarDate, deltaDate } from 'utils/date';
import { FormField } from 'layout/components/inputs/form/field';
import { nameMask } from 'layout/components/inputs/form/masks';
import { Input, inputDateValueParser, inputDateOnChangeValueParser, inputDateOnBlurValueParser } from 'layout/components/inputs/input';
import { InputDate } from 'layout/components/inputs/input/input-date';
import { Radio } from 'layout/components/inputs/radio';
import { RadioGroup } from 'layout/components/inputs/radio-group';
import { Select } from 'layout/components/inputs/selects/select';
import { Typo } from 'layout/components/typo/ui';

export const DataForPurchase = () => {
	const { form, Gate } = changeDataForPurchaseModel;
	const [{ citizenship }, countries] = useUnit([form.$values, countriesModel.$countries]);
	const { controller, setValue } = useForm({ form });

	const minBirthdayDate = dateToCalendarDate(new Date('01.01.1902'));
	const maxBirthdayDate = dateToCalendarDate(
		deltaDate({
			toDate: new Date(),
			years: -18,
			months: 0,
			days: 0,
		}),
	);

	useGate(Gate);

	const onChangeValueParser = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = target;

		return value.trimStart();
	};

	return (
		<DataBlock title="Персональные данные для покупки круиза">
			<div className={styles.grid}>
				<Select
					size="lg"
					className={styles.selector}
					theme="divider"
					label="Гражданство"
					selectionMode="single"
					searchPlaceholder="Поиск значения"
					withSearch
					alwaysShowChevron
					selectedKeys={citizenship ? [`${citizenship}`] : []}
					onSelectionChange={(keys) => {
						if (keys instanceof Set && keys.size) {
							const value = [...keys][0].toString();
							setValue({
								field: 'citizenship',
								value,
							});
						}
					}}
				>
					{countries.map(({ id, name }) => (
						<Item key={`${id}`} aria-label={name}>
							{name}
						</Item>
					))}
				</Select>
				<FormField
					maxLength={255}
					controller={controller}
					form={form}
					name="lastName"
					validation={{
						required: true,
					}}
					onChangeValueParser={onChangeValueParser}
					{...nameMask}
					secondPlaceholder="Введите фамилию"
				>
					<Input required placeholder="Фамилия" withLabel size="lg" />
				</FormField>
				<FormField
					maxLength={255}
					validation={{
						required: true,
					}}
					controller={controller}
					form={form}
					name="firstName"
					onChangeValueParser={onChangeValueParser}
					{...nameMask}
					secondPlaceholder="Введите имя"
				>
					<Input required placeholder="Имя" withLabel size="lg" />
				</FormField>
				<FormField
					maxLength={255}
					controller={controller}
					form={form}
					name="middleName"
					onChangeValueParser={onChangeValueParser}
					{...nameMask}
					secondPlaceholder="Введите отчество"
				>
					<Input placeholder="Отчество" withLabel size="lg" />
				</FormField>
				<FormField
					form={form}
					controller={controller}
					name="birthday"
					validation={{
						required: true,
					}}
					valueParser={(value) => inputDateValueParser(value, true)}
					onChangeValueParser={inputDateOnChangeValueParser}
					onBlurValueParser={(blurValue) =>
						inputDateOnBlurValueParser(blurValue, {
							minValue: minBirthdayDate,
							maxValue: maxBirthdayDate,
						})
					}
					className={styles.passport__selector}
				>
					<InputDate
						placeholder="Дата рождения"
						secondPlaceholder="00.00.0000"
						placement="bottom left"
						size="lg"
						withLabel
						datepicker={{
							minValue: minBirthdayDate,
							maxValue: maxBirthdayDate,
						}}
						calendar={{
							dateSelector: {
								minYear: 1902,
								maxYear: maxBirthdayDate.year,
							},
						}}
						required
						withoutTimezone
					/>
				</FormField>
				<FormField
					controller={controller}
					form={form}
					name="gender"
					validation={{
						required: true,
					}}
					valueParser={String}
				>
					<RadioGroup showCaption isRequired className={styles.radioGroup} label="Пол">
						<Radio value="1">
							<Typo design="description-m">Мужской</Typo>
						</Radio>
						<Radio value="2">
							<Typo design="description-m">Женский</Typo>
						</Radio>
					</RadioGroup>
				</FormField>
			</div>
		</DataBlock>
	);
};
