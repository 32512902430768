import React from 'react';
// import { EditPassengerFooter } from 'features/edit-passenger/ui/edit-passenger-footer';
// import { ModalContent } from 'features/edit-passenger/ui/modal-content';
// import { RequestPassenger, RequestCruise } from 'shared/lib/types/account/models';
// import { Icon } from 'shared/ui/icons';
// import { ModalTrigger } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { RequestPassenger } from 'config/types/prices/request-passenger';
import { Icon } from 'layout/components/icons/icon';
import { ModalTrigger } from 'layout/components/modal';
import { RequestCruise } from 'store/auth/types/account/request-cruise';
import { EditPassengerFooter } from '../edit-passenger-footer';
import { ModalContent } from '../modal-content';

interface EditPassengerProps extends RequestPassenger {
	childrenAge?: number;
	cruiseStartDate?: RequestCruise['startDate'];
	isFirstPassenger?: boolean;
}

export const EditPassenger = (passengerProps: EditPassengerProps) => (
	<ModalTrigger
		className={styles.button}
		classNameModal={styles.modal}
		classNameOverlay={styles.modal__wrap}
		classNameModalContent={styles.modal__content}
		verticalAlign="bottom"
		button={
			<>
				<span>Редактировать</span>
				<Icon id="edit" width={20} height={20} />
			</>
		}
		footer={<EditPassengerFooter />}
	>
		{(close) => (
			<div>
				<ModalContent close={close} {...passengerProps} />
			</div>
		)}
	</ModalTrigger>
);
