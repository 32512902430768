import React, { FC, memo } from 'react';
// import { Price, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';

interface PaymentInfoItemProps {
	label: string;
	value: number;
}

export const PaymentInfoItem: FC<PaymentInfoItemProps> = memo(({ label, value }) => (
	<li className={styles.paymentModal_paymentItemInfo}>
		<Typo design="text-s">{label}</Typo>
		<Price as="strong" design="headline-s" total={value} bold />
	</li>
));
