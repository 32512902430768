import { AccountLayout } from 'layout/layouts/account'
import { Main } from 'layout/layouts/main'
import { Requests } from 'layout/layouts/widgets/account'
// import type { NextPage } from 'next';
// import { requestModel, Requests } from 'widgets/account';
// import { AccountLayout } from 'widgets/layouts/account';
// import { Main } from 'widgets/layouts/main';
// import { serverSidePropsFactory } from 'shared/lib/ssr';

import { createFileRoute } from '@tanstack/react-router'
import { useEffect } from 'react'
import { findAndChangeInnerHtml } from 'utils/page/pageUtil'
import accountStyles from './../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute('/account/my-requests/')({
  component: () => <MyRequests />
})

// const MyRequests: NextPage = () => (
// 	<Main>
// 		<AccountLayout>
// 			<Requests />
// 		</AccountLayout>
// 	</Main>
// );
const MyRequests = () => {
  useEffect(() => {
    const pageH1 = "Мои заявки"
    findAndChangeInnerHtml("h1", `<h1 id='h1'>${pageH1}</h1>`)
  }, [])

  return (
    <Main>
      <AccountLayout>
          <Requests />
      </AccountLayout>
    </Main>)
}

// export const getServerSideProps = serverSidePropsFactory(requestModel.getData);

// export default MyRequests;
