import { createEffect, createEvent, createStore, sample } from 'effector';
import { personalRequests } from 'store/auth/api';
// import { personalRequests } from 'shared/api';

const verifyEmail = createEvent();
const verifyEmailFx = createEffect(personalRequests.verifyEmail);

const $wasSent = createStore<boolean>(false);

sample({
	clock: verifyEmail,
	target: verifyEmailFx,
});

sample({
	clock: verifyEmailFx.doneData,
	fn: () => true,
	target: $wasSent,
});

export const verifyEmailModel = {
	verifyEmail,
	pending: verifyEmailFx.pending,
	$wasSent,
};
