/*
 * Обновлет токены
 * Если запросов несколько, то складывает в очередь и резолвит после обновления токена
 */
import { personalRequests } from 'store/auth/api';
import { resetJWT, setJWT } from './jwt';
import { LoginResponse } from 'config/types/personal';
import { AccountRoutes } from 'store/auth/constants';

let refreshInProrgess = false;
let refreshWaiters: Array<(value?: unknown) => void> = [];

export const refreshToken = (token: string) => {
	if (!refreshInProrgess) {
		refreshInProrgess = true;

		return personalRequests.refreshToken({ refreshToken: token }).then((response) => {
			refreshInProrgess = false;

			setJWT({
				access: response?.accessToken,
				refresh: response?.refreshToken,
			});

			refreshWaiters.forEach((resolve) => resolve());
			refreshWaiters = [];

			return response as LoginResponse;
		});
	}

	return new Promise((resolve) => {
		refreshWaiters.push(resolve);
	});
};

export const goToReLogin = () => {
	resetJWT();
	window.location.href = AccountRoutes.LoginPage;
};
