// import { RequestDocument } from 'shared/lib/types/account/request-document';
import { RequestDocument } from 'store/auth/types/account/request-document';
import { RequestDoc } from './request-doc';

interface DownloadBillProps {
	document: RequestDocument;
}
export const DownloadBill = ({ document }: DownloadBillProps) => (
	<RequestDoc document={document} showIcon={false} btnType="text" size="large" />
);
