// import { message } from 'shared/lib/message';
// import { ValidationOptions } from 'shared/ui/organisms/form/lib/validation';
import { message } from 'config/constants/message';
import { ValidationOptions } from 'layout/components/inputs/form/lib/validation';
import { BonusRecoveryFormValues } from './types';

export type BonusRecoveryFormValuesKey = keyof BonusRecoveryFormValues;

export const bonusSupportScheme: Record<BonusRecoveryFormValuesKey, ValidationOptions> = {
	seller: {
		required: true,
		messages: {
			validateType: message.form.validation.required,
		},
	},
	sellerName: {
		required: true,
		messages: {
			validateType: message.form.validation.format.max250,
		},
		validateType: 'max250',
	},
	ship: {
		required: true,
		messages: {
			validateType: message.form.validation.required,
		},
	},
	cruise: {
		required: true,
		messages: {
			validateType: message.form.validation.required,
		},
	},
	cabin: {
		required: true,
		messages: {
			validateType: message.form.validation.required,
		},
	},
	dates: {
		required: true,
		messages: {
			validateType: message.form.validation.required,
		},
	},
	email: {
		required: true,
		messages: {
			validateType: message.form.validation.format.email,
		},
		validateType: 'email',
	},
};
