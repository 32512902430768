import React from 'react';
// import { useUnit } from 'effector-react/ssr';
// import DummySvgIcon from 'shared/assets/images/dummy.svg';
// import { Button, Typo } from 'shared/ui/atoms';
import { requestModel } from '../../model';
import styles from './styles.module.scss';
import { useUnit } from 'effector-react';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';

export const EmptyPageContent = React.memo(() => {
	const initialPageRequest = useUnit(requestModel.$initialPageRequest);

	return (
		<section className={styles.skeleton__wrap}>
			{/* <DummySvgIcon /> */}
			{initialPageRequest && (
				<>
					<Typo design="headline-xs" className={styles.filter__results}>
						У вас нет оформленных заявок
					</Typo>
					<Button className={styles.skeleton__button} href="/">
						Найти круиз
					</Button>
				</>
			)}
			{!initialPageRequest && (
				<Typo design="headline-xs" className={styles.filter__results}>
					Поиск не дал результатов. Попробуйте искать по другим параметрам.
				</Typo>
			)}
		</section>
	);
});
