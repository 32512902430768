import { FC } from 'react';
// import { AccountRequestsStatuses } from 'shared/config';
// import { Alert, Typo } from 'shared/ui/atoms';
import { CallBackModal } from '../callback-modal';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { AccountRequestsStatuses } from 'store/auth/constants';

interface RequestInfoMessageProps {
	status?: AccountRequestsStatuses;
	isPayable?: boolean;
}

export const RequestInfoMessage: FC<RequestInfoMessageProps> = ({ status, isPayable }) => {
	const isConfirmed =
		status === AccountRequestsStatuses.Confirmed || status === AccountRequestsStatuses.SoldByAgency;

	return (
		<>
			{status === AccountRequestsStatuses.AwaitingConfirmation ||
				(isConfirmed && !isPayable && (
					<Alert type="warning" className={styles.infoMessageWrap}>
						<div className={styles.infoMessage}>
							<Typo design="headline-xs" freezeDesktop>
								Ваша заявка ожидает подтверждения
							</Typo>
							<Typo design="text-s">
								Стандартное время проверки и подтверждения заявки - один рабочий день. За уточнением
								информации по заявке обратитесь в Ваш офис обслуживания или закажите{' '}
								<CallBackModal />.
							</Typo>
						</div>
					</Alert>
				))}

			{status === AccountRequestsStatuses.WaitingList && (
				<Alert type="warning" className={styles.infoMessageWrap}>
					<div className={styles.infoMessage}>
						<Typo design="headline-xs" freezeDesktop>
							Ваша заявка находится в листе ожидания
						</Typo>
						<Typo design="text-s">
							За уточнением информации по заявке обратитесь в Ваш офис обслуживания или закажите{' '}
							<CallBackModal />.
						</Typo>
					</div>
				</Alert>
			)}

			{isConfirmed && isPayable && (
				<Alert type="warning" className={styles.infoMessageWrap}>
					<div className={styles.infoMessage}>
						<Typo design="headline-xs" freezeDesktop>
							Ваша заявка подтверждена
						</Typo>
						<Typo design="text-s">
							Проверьте данные заявки перед оплатой. Если у вас остались вопросы по заявке
							обратитесь в Ваш офис обслуживания или закажите <CallBackModal />.
						</Typo>
					</div>
				</Alert>
			)}
		</>
	);
};
