import { ChildrenFormValidationParams, PassengerFormValidationParams } from 'entities/passengers';

export const editPassengerValidationScheme: PassengerFormValidationParams = {
	lastName: { required: true },
	firstName: { required: true },
	birthday: { required: true },
	gender: { required: true },
	documentType: { required: true },
	passportData: { required: true },
	citizenship: { required: true },
	passportIssued: { required: false },
	passportIssuedDate: { required: false },
};

export const editChildrenValidationScheme: ChildrenFormValidationParams = {
	name: { required: true },
	birthday: { required: true },
	document: { required: true },
};
