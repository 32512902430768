import React, { FC, memo } from 'react';
import classnames from 'classnames';
// import { DISCOUNT_POINTS_ACCUSATIVE_DECLENSION } from 'shared/lib/constants';
// import { dischargeNumber, getNumDeclension } from 'shared/lib/utils';
// import { Typo, TypoDesignToken } from 'shared/ui/atoms/typo';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { DISCOUNT_POINTS_ACCUSATIVE_DECLENSION } from 'config/constants';
import { Icon } from 'layout/components/icons/icon';
import { TypoDesignToken, Typo } from 'layout/components/typo/ui';
import { dischargeNumber } from 'utils/number';
import { getNumDeclension } from 'utils/string';

export interface BonusForBuyProps {
	value?: number;
	className?: string;
	onlyNumber?: boolean;
	withIcon?: boolean;
	desing?: TypoDesignToken;
}

export const BonusForBuy: FC<BonusForBuyProps> = memo(
	({ value, className, desing = 'description-features', withIcon = true, onlyNumber }) => {
		if (!value) {
			return null;
		}

		return (
			<Typo
				className={classnames(className, styles.bonusForBuy, { [styles.short]: !withIcon })}
				design={desing}
			>
				{withIcon && <Icon id="klk-outlined" />}
				{!onlyNumber ? (
					<span>
						+ от <b>{dischargeNumber(value)}</b>{' '}
						{getNumDeclension(value, DISCOUNT_POINTS_ACCUSATIVE_DECLENSION)} после круиза
					</span>
				) : (
					<span>+ {value}</span>
				)}
			</Typo>
		);
	},
);
