import React, { FC, PropsWithChildren } from 'react';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface PassengersCardParamProps {
	title: string;
	value?: string | number;
	className?: string;
}

export const PassengersCardParam: FC<PropsWithChildren<PassengersCardParamProps>> = ({
	title,
	value,
	className,
	children,
}) => (
	<div className={className}>
		<Typo design="text-s" className={styles.infoBlock_title}>
			{title}
		</Typo>
		<p className={styles.infoBlock_value}>{value}</p>
		{children}
	</div>
);
