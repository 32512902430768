import React from 'react';
import classNames from 'classnames';
// import { AccountRequestsStatuses } from 'shared/config';
// import { Request } from 'shared/lib/types/account/request';
// import { formatDate, getExpirationMessage, getNextPaymentDate } from 'shared/lib/utils';
// import { Alert, Price, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { canRequestBePayed, getReturnInfoLabel, STATUSES_FOR_SURCHARGE } from '../../lib';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { Price } from 'layout/components/view/price';
import { AccountRequestsStatuses } from 'store/auth/constants';
import { getExpirationMessage, getNextPaymentDate, formatDate } from 'utils/date';
import { Request } from 'store/auth/types/account/request';

// eslint-disable-next-line complexity
export const PaymentInfo = ({
	status,
	amount,
	payed = 0,
	prepaymentDate,
	expirationDate,
	prepayment,
	refunded = 0,
}: Pick<
	Request,
	'status' | 'amount' | 'payed' | 'prepaymentDate' | 'expirationDate' | 'prepayment' | 'refunded'
>) => {
	const expireStatus = getExpirationMessage(expirationDate);
	const isExpired = expireStatus?.type === 'expired';
	const nextPaymentDate = getNextPaymentDate({
		expirationDate,
		prepaymentDate,
		payed,
		prepayment,
	});
	const isExpiring = isExpired || !canRequestBePayed(nextPaymentDate);

	const showExpireWarnings =
		(status === AccountRequestsStatuses.Confirmed ||
			status === AccountRequestsStatuses.Prepayment ||
			status === AccountRequestsStatuses.AwaitingPayment) &&
		isExpiring;

	const expireMessage = isExpired
		? `Срок оплаты истек ${formatDate(expirationDate, 'DD.MM.YYYY')}`
		: `Срок оплаты истекает через ${getExpirationMessage(nextPaymentDate)?.message} (${formatDate(
				nextPaymentDate,
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  )})`;

	const showRemainingPayment =
		!isExpired &&
		amount &&
		amount > payed &&
		(status === AccountRequestsStatuses.AwaitingPayment ||
			status === AccountRequestsStatuses.Confirmed ||
			status === AccountRequestsStatuses.Prepayment);

	return (
		<div className={styles.paymentInfo_container}>
			{(status === AccountRequestsStatuses.AwaitingReturn ||
				status === AccountRequestsStatuses.Return) &&
			refunded > 0 ? (
				<div className={styles.paymentInfo__sum}>
					<Typo design="text-s">{getReturnInfoLabel(status)}</Typo>
					<Price total={refunded} design="headline-xs" bold />
				</div>
			) : null}

			{status !== undefined && STATUSES_FOR_SURCHARGE.includes(status) && (
				<div className={styles.paymentInfo_wrap}>
					{!isExpiring ? (
						<>
							{amount && amount > payed ? (
								<div className={styles.paymentInfo__sum}>
									<Typo design="text-s">Сумма доплаты</Typo>
									<Price total={amount - payed} design="headline-xs" bold />
								</div>
							) : null}

							{nextPaymentDate ? (
								<div className={styles.paymentInfo_date}>
									<Icon id="payment-time" width={12} height={12} />
									<Typo
										design="description-features"
										as="span"
										className={styles.paymentInfo_label}
									>
										Следующая оплата
									</Typo>
									<Typo design="description-features" as="span">
										{formatDate(nextPaymentDate)}
									</Typo>
								</div>
							) : null}
						</>
					) : null}

					{isExpiring ? (
						<>
							{amount ? (
								<div className={styles.paymentInfo__sum}>
									<Typo design="text-s">Стоимость</Typo>
									<Price total={amount} design="headline-xs" bold />
								</div>
							) : null}

							{showRemainingPayment ? (
								<div className={styles.paymentInfo__sum}>
									<Typo design="text-s">Осталось оплатить</Typo>
									<Price total={amount - payed} design="headline-xs" bold />
								</div>
							) : null}
						</>
					) : null}

					{showExpireWarnings && (
						<>
							<div
								className={classNames(styles.paymentInfo__sum, styles.paymentInfo__sum_expiring)}
							>
								<Icon id="payment-time" width={16} height={16} />
								<Typo design="description-m">{expireMessage}</Typo>
							</div>
							<Alert type="default-red" iconId="info" className={styles.paymentModal_warning}>
								<Typo design="description-features">
									В случае невозможности оплаты в указанный срок, обратитесь в офис за уточнением
									возможности продления.
								</Typo>
							</Alert>
						</>
					)}
				</div>
			)}
		</div>
	);
};
