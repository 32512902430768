import { fetcher } from "api/setupAxios";
import { ChangeEmailRequest } from "../types/account/change-email-request";
import { ACCOUNT_URL } from "../constants";


export const changeEmail = ({ data }: { data: ChangeEmailRequest }) =>
	fetcher({
		url: `${ACCOUNT_URL}/profile/change-email`,
		body: data,
		authorization: true,
		method: 'POST',
	});
