import React, { FC, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
// import { EditPassenger } from 'features/edit-passenger/ui/edit-passenger';
// import { PassengerServices } from 'entities/passengers/passengers-card/passenger-services';
// import { RequestCruise, RequestPassenger } from 'shared/lib/types/account/models';
// import { getStringDate } from 'shared/lib/utils';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { PlaceInfo } from 'shared/ui/molecules';
import { GENDER, usePassengerParam } from './lib';
import styles from './styles.module.scss';
import { RequestPassenger } from 'config/types/prices/request-passenger';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { RequestCruise } from 'store/auth/types/account/request-cruise';
import { getStringDate } from 'utils/date';
import { PassengerServices } from './passenger-services';
import { EditPassenger } from 'layout/components/edit/edit-passenger/ui/edit-passenger';
import { PlaceInfo } from 'layout/components/view/place-info';

interface PassengersCardProps extends RequestPassenger {
	allowEditPassengers?: boolean;
	defaultPassengerName?: string;
	childrenAge?: number;
	cruiseStartDate?: RequestCruise['startDate'];
	isFirstPassenger?: boolean;
}

// eslint-disable-next-line complexity
export const PassengersCard: FC<PassengersCardProps> = ({
	allowEditPassengers,
	defaultPassengerName,
	childrenAge,
	cruiseStartDate,
	...passenger
}) => {
	const {
		id,
		firstName,
		middleName,
		lastName,
		type,
		placeType,
		amount,
		birthday,
		citizenship,
		gender,
		passportSeries,
		passportNumber,
		mealType,
		excursionsType,
		children,
	} = passenger;

	const [isExpanded, setExpanded] = useState<boolean>(false);

	const passengerGender = usePassengerParam(GENDER, gender);

	return (
		<div className={styles.passengersCard_wrap}>
			<div className={styles.passengersCard_infoBlock}>
				<div className={styles.passengersCard_fullName}>
					<Typo design="headline-m">
						{defaultPassengerName || `${lastName} ${firstName} ${middleName}`}
					</Typo>
					{allowEditPassengers && type !== 3 ? (
						<EditPassenger
							{...passenger}
							childrenAge={childrenAge}
							cruiseStartDate={cruiseStartDate}
						/>
					) : (
						<Typo design="text-s" className={styles.passengersCard__editNotAllowed}>
							Редактирование невозможно.
							<br />
							Чтобы изменить данные, обратитесь в офис.
						</Typo>
					)}
				</div>
				<PlaceInfo childrenAge={childrenAge} placeType={placeType} type={type} amount={amount} />
			</div>
			<AnimateHeight
				height={isExpanded ? 'auto' : 0}
				duration={400}
				animateOpacity
				className={styles.passengersCard_animatedBlock}
			>
				<div className={styles.passengersCard_infoBlock}>
					<Typo design="text-s" className={styles.infoBlock_title}>
						Паспортные данные
					</Typo>
					<ul className={styles.passportData}>
						{citizenship?.name && <li>{citizenship.name}</li>}
						{passportSeries && passportNumber && (
							<li>
								Серия и номер {passportSeries} {passportNumber}
							</li>
						)}
						{passengerGender && <li>Пол {passengerGender}</li>}
						{birthday && <li>д.p. {getStringDate(birthday)}</li>}
					</ul>
				</div>
				{id != undefined ? <PassengerServices mealType={mealType} excursionsType={excursionsType} passengerId={id} /> : null}
				{type === 1 && (
					<div className={styles.passengersChild}>
						<Typo design="headline-xs">Данные о ребенке</Typo>
						<div className={styles.passengersChild_info}>
							<div className={styles.passengersChild_nameWrap}>
								{children?.name && <Typo design="headline-m">{children.name}</Typo>}
								<div className={styles.passengersChild_label}>
									<Icon id="baby" width={13} height={12} />
									<Typo design="description-features">Ребенок</Typo>
								</div>
							</div>
							<div className={styles.passengersChild_seatType}>
								<Typo design="text-m">Место</Typo>
								<Typo design="headline-s">Без места</Typo>
							</div>
							{children?.document && (
								<div className={styles.passengersChild_docs}>
									<Typo design="text-s" className={styles.passengersChild_docsTitle}>
										Свидетельство о рождении
									</Typo>
									<Typo design="text-m">
										<span>{children.document}</span>
										{children.birthday && (
											<>
												<span className={styles.passengersChild_docsDivider}>/</span>
												<span>{getStringDate(children.birthday)}</span>
											</>
										)}
									</Typo>
								</div>
							)}
						</div>
					</div>
				)}
			</AnimateHeight>
			<Button
				btnType="text"
				contextClassName={styles.toggleButton}
				onClick={() => setExpanded((prev) => !prev)}
			>
				<span>{isExpanded ? 'Свернуть' : 'Развернуть'}</span>
				<Icon
					id="chevron-down"
					width={20}
					height={20}
					className={classnames(styles.toggleButton_icon, {
						[styles.toggleButton_iconUp]: isExpanded,
					})}
				/>
			</Button>
		</div>
	);
};
