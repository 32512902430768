// import { fetcher } from 'shared/api/config';
// import { shipMock } from 'shared/api/mock/ship';
// import { SHIPS_ENDPOINT } from 'shared/config';
// import { ShipModel } from 'shared/lib/types/ships';

import { fetcher } from "api/setupAxios";
import { AJAX_SHIPS, SHIPS_ENDPOINT } from "config/constants/ships";
import { IShipInfoPath, ShipModel } from "config/types/ships";
import { shipMock, shipPathMock } from "mocks/ship";

export interface GetShipPathInfoRequest {
	id?: string;
	needMock?: boolean;
}

export const getShipUrl = async ({ id, needMock = false }: GetShipPathInfoRequest) => {
	if (needMock) {
		await new Promise((resolve) => {
			setTimeout(resolve, 300);
		});

		return shipPathMock;
	}

	return fetcher<IShipInfoPath>({
		url: `${AJAX_SHIPS}/getShipInfo/?ship=${id}`,
		method: 'GET',
	});
};
