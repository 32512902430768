/**
 * InfoFlot API
 * API for InfoFlot
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface BonusRecoveryRequest {
	seller: BonusRecoveryRequestSellerEnum;
	/**
	 * required if seller = other
	 */
	sellerName?: string;
	ship: string;
	cruise: string;
	cabin: string;
	dateStart: string;
	dateEnd: string;
}
export enum BonusRecoveryRequestSellerEnum {
	infoflot = 'infoflot',
	other = 'other',
}
