import { memo } from 'react';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface CityStopParamProps {
	name: string;
	value: string | null;
}

export const CityStopParam = memo(({ name, value }: CityStopParamProps) => (
	<li className={styles.stopInfoItem}>
		<Typo design="text-s" className={styles.stopInfoItem_name}>
			{name}
		</Typo>
		<Typo design="headline-s">{value}</Typo>
	</li>
));
