import React from 'react';
import classnames from 'classnames';
import { ShipStar } from 'entities/ships';
// import { RequestShip } from 'shared/lib/types/account/request-ship';
// import { Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { RequestShip } from 'store/auth/types/account/request-ship';

interface ShipInfoProps extends RequestShip {
	theme?: 'black' | 'secondary';
	fontSize?: 'text-s' | 'headline-xs';
	shipClassFontSize?: 'description-features' | 'description-m';
}

export const ShipInfo = ({
	name,
	type,
	stars,
	new: isShipNew,
	theme,
	fontSize = 'text-s',
	shipClassFontSize = 'description-features',
}: ShipInfoProps) => (
	<div className={styles.shipInfo}>
		{name && (
			<Typo design={fontSize} className={classnames(styles.shipInfo_title, theme && styles[theme])}>
				{type && (
					<>
						<span>{type}</span>
						<span className={styles.shipInfo_divider} />
					</>
				)}
				<span>{name}</span>
			</Typo>
		)}
		{(stars || isShipNew) && (
			<div className={styles.shipInfo_icons}>
				{stars && <ShipStar stars={stars} />}

				{isShipNew && (
					<Typo className={styles.ship_new} design={shipClassFontSize}>
						New
					</Typo>
				)}
			</div>
		)}
	</div>
);
