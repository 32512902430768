import { ChangePhoneRequest, ChangePhoneVerifyRequest } from "config/types/personal";
import { ACCOUNT_URL } from "../constants";
import { fetcher } from "api/setupAxios";


export const changePhone = (data: ChangePhoneRequest) =>
	fetcher<{ verifyToken: string }>({
		url: `${ACCOUNT_URL}/profile/change-phone`,
		body: data,
		authorization: true,
		method: 'POST',
	});

export const changePhoneVerify = (data: ChangePhoneVerifyRequest) =>
	fetcher({
		url: `${ACCOUNT_URL}/profile/change-phone/verify`,
		body: data,
		authorization: true,
		method: 'POST',
	});

export const changeAdditionalPhone = (data: ChangePhoneRequest) =>
	fetcher<{ verifyToken: string }>({
		url: `${ACCOUNT_URL}/profile/change-additional-phone`,
		body: { additionalPhone: data.phone },
		authorization: true,
		method: 'POST',
	});

export const changeAdditionalPhoneVerify = (data: ChangePhoneVerifyRequest) =>
	fetcher({
		url: `${ACCOUNT_URL}/profile/change-additional-phone/verify`,
		body: data,
		authorization: true,
		method: 'POST',
	});
