import { model as cruiseModel } from './cruise';

// const $isMoscowTime = cruiseModel.$cruise.map((cruise) => cruise?.timetable?.isMoscowTime, {skipVoid: false});
const $isMoscowTime = cruiseModel.$cruise.map((cruise) => cruise?.timetable?.isMoscowTime);

const $timetables = cruiseModel.$cruise.map((cruise) => {
	const timetables = cruise?.timetable?.timetables ?? [];

	return timetables.filter(({ excursions }) => excursions.length > 0);
});

const $paidTimetables = $timetables.map(
	(timetables) =>
		timetables
			.map((item) => ({
				...item,
				excursions: item.excursions.filter((excursion) => excursion.isPaid),
			}))
			.filter(({ excursions }) => excursions.length > 0),
	// eslint-disable-next-line
);

const $paidTimetablesCount = $paidTimetables.map(
	(timetables) => timetables.flatMap(({ excursions }) => excursions).length,
);

const $timetablesWithOptionalExcursions = $paidTimetables.map((timetables) =>
	timetables
		.map((tt) => ({
			...tt,
			excursions: tt.excursions.filter(({ isIncluded }) => !isIncluded),
		}))
		.filter(({ excursions }) => excursions.length > 0),
);

const $optionalExcursionsCount = $timetablesWithOptionalExcursions.map(
	(timetables) => timetables.flatMap(({ excursions }) => excursions).length,
);

export const model = {
	$timetables,
	$paidTimetables,
	$paidTimetablesCount,
	$timetablesWithOptionalExcursions,
	$optionalExcursionsCount,
	$isMoscowTime,
};
