/* eslint-disable quote-props */
import React, { Dispatch, useEffect, useState } from 'react';
import { useGate } from 'effector-react';
import { useUnit } from 'effector-react';
import { detailedCruiseCabinModel } from 'entities/cabin-booking-cards/ui/cabin-description-modal/model';
import { pricesModel } from 'entities/order';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { DetailedCruiseCabin } from 'shared/lib/types';
// import { setAnalytics } from 'shared/lib/utils';
// import { ToggleIos, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { CabinPlaces, TotalPrice } from 'shared/ui/organisms';
import { ScreenType } from '../../model/model';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { CabinPlaces } from 'layout/components/cruise/cabin-places';
import { Icon } from 'layout/components/icons/icon';
import { ToggleIos } from 'layout/components/special/toggle-ios';
import { Typo } from 'layout/components/typo/ui';
import { TotalPrice } from 'layout/components/view/total-price';
import { GetPricePreviewRequestCabinsInnerPassengersInnerTypeEnum } from 'config/types/prices';

interface PassengersProps extends DetailedCruiseCabin.Cabin {
	handleScreen?: Dispatch<ScreenType>;
	cruiseId: number;
	childrenAge?: number;
	hideTitles?: boolean
	hideTotalPrice?: boolean
	toggleAlonePassangerInBottom?: boolean
}
let procesing = false

export const Passengers = ({
	handleScreen,
	places,
	price,
	isBonusPayment,
	bonus,
	cruiseId,
	id,
	childrenAge,

	hideTitles,
	hideTotalPrice,
	toggleAlonePassangerInBottom,
}: PassengersProps) => {
	const totalPlaces = places.main + places.additional;
	const showToggleIos = totalPlaces > 1;

	useGate(detailedCruiseCabinModel.Gate, {
		cruiseId,
		cabinId: id,
		types: price.passengersPrice.map(({ passengerType }) => `${passengerType}`),
	});

	const [changeType, setAlonePassenger, alonePassenger, pricesPreview, pricesPreviewStatus, setAlonePassengerWithPriceUpdate,
		// getPricesPreview
	] =
		useUnit([
			detailedCruiseCabinModel.changeType,
			detailedCruiseCabinModel.setAlonePassenger,
			detailedCruiseCabinModel.$alonePassenger,
			pricesModel.$pricesPreview,
			pricesModel.$pricesPreviewStatus,
			detailedCruiseCabinModel.setAlonePassengerWithPriceUpdate,
			// pricesModel.getPricesPreview
		]);

	const handleChangeType = (params: { index: number; value: Nullable<string> }) => {
		changeType(params);

		if (params.value) {
			// setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_ticket_type_changed);
		} else {
			// setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_ticket_type_removed);
		}
	};

	const handleToggleChange = (isSelected: boolean) => {
		// setAlonePassenger(isSelected);
		setAlonePassengerWithPriceUpdate(isSelected);



		if (isSelected) {
			// setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_one_bed_on);
		} else {
			// setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_one_bed_off);
		}
	};

	return (
		// <div>Passengers</div>
		<div className={styles.passengersReversed}>
			{
				!hideTitles
					? <>
						<div className={styles.title}>
							<button className={styles.buttonBack} onClick={() => handleScreen && handleScreen(0)}>
								<Icon id="chevron-left" width={20} height={20} />
							</button>
							<Typo design="headline-s">Туристы</Typo>
						</div>
						<Typo className={styles.passengersDescription} design="text-s">
							Укажите туристов каюты для детализации цены
						</Typo>
					</>
					: null
			}
			{showToggleIos && (
				<ToggleIos
					size="lg"
					className={styles.toggle}
					isSelected={alonePassenger}
					onChange={handleToggleChange}
				>
					Одноместное размещение
				</ToggleIos>
			)}

			<CabinPlaces
				className={styles.places}
				totalPlaces={totalPlaces}
				price={price}
				passengerPrices={price.passengersPrice.map(({ passengerType, passengerPrices }) => ({
					type: Number(passengerType),
					total: Number(passengerPrices.total),
				}))}
				places={places}
				surcharge={pricesPreview?.surcharge}
				childrenDiscount={pricesPreview?.cabins?.find((cabin) => cabin.id === id)?.childrenDiscount}
				surchargePending={pricesPreviewStatus === 'pending'}
				changeType={handleChangeType}
				childrenAge={childrenAge}
			/>
			{
				!hideTotalPrice
					? <TotalPrice price={price} isBonusPayment={isBonusPayment} bonus={bonus} withInfo />
					: null
			}
		</div>
	);
};
