import { useUnit } from 'effector-react';
// import { profileModel } from 'entities/profile';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { setAnalytics } from 'shared/lib/utils';
// import { Button, Alert } from 'shared/ui/atoms';
import { model } from '../../model';
import styles from './styles.module.scss';
import { ANALYTICS_TARGETS } from 'config/constants';
import { message } from 'config/constants/message';
import { profileModel } from 'layout/components/auth/profile';
import { Button } from 'layout/components/buttons/button';
import { Alert } from 'layout/components/view/alert';

export const ButtonsBlock = () => {
	const [save, pending, updateProfileStatus, reset, enableButtons] = useUnit([
		model.saveData,
		model.pending,
		model.$updateProfileStatus,
		profileModel.resetDraft,
		profileModel.$profileDraftHasBeenChanged,
	]);

	const handleSave = () => {
		save();
		// setAnalytics(ANALYTICS_TARGETS.account.account_saved_data);
	};

	return (
		<>
			{updateProfileStatus === 'fail' && (
				<Alert type="error" className={styles.errorNotification}>
					{message.requests.createRequest.error}
				</Alert>
			)}

			<div className={styles.buttons}>
				<Button loading={pending} disabled={!enableButtons} onClick={handleSave} size="large">
					Сохранить изменения
				</Button>
				<Button onClick={reset} disabled={!enableButtons} btnType="outline" size="large">
					Отменить
				</Button>
			</div>
		</>
	);
};
