import { DEVELOPMENT_MODE, ISR_REGENERATION_TIME } from 'config/commonConstants';
import { allSettled, fork } from 'effector';
import { useGate, useUnit } from 'effector-react';
import { cruiseModel } from 'entities/cruises/model';
import { CartControlButton, cartControlModel } from 'layout/components/cruise/cart-control';
import { Main } from 'layout/layouts/main';
import { Cruise, cruiseContentModel } from 'layout/layouts/widgets/cruise';
import React, { FC, useEffect, useState } from 'react'
import { getCabins } from 'store/cruise/cruises/cabins';

// import { staticPropsFactory } from 'store/ssr';
import styles from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal-footer/styles.module.scss'
import { Footer } from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal/footer';
import { CruiseDetailsModel } from 'config/types/cabins/models/cruise-details';
import { CabinDescriptionModal, detailedCruiseCabinModel } from 'entities/cabin-booking-cards';
import { profileModel } from 'layout/components/auth/profile';
import { cartModel } from 'entities/cart';
import { detailedShipCabinModel } from 'entities/cabin2';
import { GetDetailedShipCabinRequest, detailedShipCabinRequest } from 'store/cruise/cruises/cabins/index';
import { CruiseCabin } from 'config/types/cabins/models/cruise-cabin-booking';
import { getPlaces } from 'entities/cabin-booking-cards/ui/cabin-description-modal/lib/get-places';
import { Typo } from 'layout/components/typo/ui';
import { Condition } from 'layout/components/switchers/condition';
import { Skeleton } from 'layout/components/special/skeleton';
import { Content } from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal/content';
import { Error } from 'entities/cabin-booking-cards/ui/cabin-description-modal/ui/cabin-description-modal/error';
import { pricesModel } from 'entities/order';
import { createPortal } from 'react-dom';
import { TotalPrice } from 'layout/components/view/total-price';

interface ICardProps {
  cruiseId: string
  cabinId: string

  elementPlaceForCabinGuestSelectors?: Element | null
  elementPlaceForButtons?: Element | null
  elementPlaceForPrice?: Element | null
  hideTitle?: boolean
}


// нужно пойти от обратного. получаем каюты по данным о круизе, затем смотрим какие нам нужны массивы, грузим их
// по маршрутам инфофлота можно понять какие компоненты где что отрисовывают. можно отсоединить их от данных 
// (а лучше правильно вовремя заполнить данные и пользоваться встроенным стором)

let initGlobal = false

const Card: FC<ICardProps> = (props) => {
  const [init, setInit] = useState<boolean>(false)
  const [cruise, getCruise, getCabins, cabins] = useUnit([cruiseModel.$cruise, cruiseModel.getCruise, cruiseModel.getCabins, cruiseModel.$cabins])
  const [
    getDetailedCruiseCabinInfo,
    cruiseCabinInfo,
    status,
    statusWithPriceUpdate,
    initialLoadInfo,
    pricesPreview,
    pricesPreviewStatus,
    screen,
    setScreen,
    reloadInfo,
  ] = useUnit(
    [
      detailedCruiseCabinModel.getDetailedCruiseCabinInfo,
      detailedCruiseCabinModel.$detailedCruiseCabinInfo,
      detailedCruiseCabinModel.$status,
      detailedCruiseCabinModel.$statusWithPriceUpdate,
      detailedCruiseCabinModel.initialLoadInfo,
      pricesModel.$pricesPreview,
      pricesModel.$pricesPreviewStatus,
      detailedCruiseCabinModel.$screen,
      detailedCruiseCabinModel.setScreen,
      detailedCruiseCabinModel.reloadInfo,
    ])

  // new code
  const pending = status === 'pending' || statusWithPriceUpdate === "pending";
  const initialPending = status === 'pending' && !cruiseCabinInfo;

  const childrenAge = pricesPreview?.cabins?.find(
    (cabin) => cabin.id === cruiseCabinInfo?.id,
  )?.childrenAge;

  const pricesPreviewPending = pricesPreviewStatus === 'pending';
  // new code

  const profile = useUnit(profileModel.$profile);
  const authorized = Boolean(profile);

  useEffect(() => {
    if (props.cruiseId != undefined && props.cabinId != undefined && !(initGlobal || init)) {
      console.log('request', props.cruiseId, props.cabinId)
      setInit(true)
      initGlobal = true
      getCruise({ id: props.cruiseId })
      getDetailedCruiseCabinInfo({ cabinId: Number(props.cabinId), cruiseId: Number(props.cruiseId) })
    }
  }, [props.cruiseId, props.cabinId])

  useEffect(() => {
    if (init)
      initGlobal = false
  }, [init])

  const cabinPrices = cruiseCabinInfo && cruise && props.elementPlaceForPrice
    ? <TotalPrice price={cruiseCabinInfo.price} isBonusPayment={cruiseCabinInfo.isBonusPayment} bonus={cruiseCabinInfo.bonus} layoutForCabinCard />
    : null

  const cabinButtons = cruiseCabinInfo && cruise
    ? <CartControlButton
      className={`${styles.button} book_btn`}
      fullView
      cabin={{
        id: cruiseCabinInfo.id,
        cruiseId: cruise.id,
        name: cruise.name ?? "",
        category: cruiseCabinInfo.category,
        places: getPlaces(cruiseCabinInfo),
        status: cruiseCabinInfo.status,
        isBonusPayment: false,
      }}
      status={cruiseCabinInfo.status}
      disabled={status == "pending" || statusWithPriceUpdate === "pending" || (!getPlaces(cruiseCabinInfo)?.additional?.length && !getPlaces(cruiseCabinInfo)?.main?.length)}
      onClickAdd={() => {
        // console.log("added to orders")
      }
        // setAnalytics(ANALYTICS_TARGETS.cruisepage.cruisepage_fullcard_add_to_card)
      }
    />
    // : <Typo design="text-m">
    //   Загрузка...
    // </Typo>
    : <Skeleton margin="0 0 16px" width="100%" height={46} />

  const cabinGuestSelectors = cruiseCabinInfo && cruise
    ? <>
      {
        !props.hideTitle
          ? <Condition
            value={initialPending}
            then={<Skeleton margin="0 0 16px" width="50%" height={24} />}
            else={
              <Typo className={styles.title} design="headline-m">
                Описание каюты
              </Typo>
            }
          />
          : null
      }
      <Condition
        // value={status !== 'fail' && statusWithPriceUpdate !== "fail"}
        value={status !== "fail" && statusWithPriceUpdate !== "fail" }
        then={
          <Content
            cruiseId={cruise.id}
            cabinInfo={cruiseCabinInfo}
            pending={pending}
            initialPending={initialPending}
            initialLoadInfo={initialLoadInfo}
            childrenAge={childrenAge}
            pricesPreviewPending={pricesPreviewPending}
            pricesPreviewStatus={pricesPreviewStatus}
            screen={screen}
            setScreen={setScreen}

            hideTitles
            hideCabinInfo
            hideTotalPrice
            toggleAlonePassangerInBottom
          />
        }
        else={<Error reload={reloadInfo} />}
      />
    </>
    : null

  return (
    <>
      {
        DEVELOPMENT_MODE
          ? <div>
            <div>==== DEBUG INFO ====</div>
            <div>ID: {props.cruiseId}  CABIN: {props.cabinId}</div>
            <div>NAME: {cruise?.beautifulName}</div>
            <div>CABINS: main: {cruiseCabinInfo?.places.main} Additional: {cruiseCabinInfo?.places.additional}</div>
            <div>auth tel: {profile?.phone}</div>
            <div>cabin info: {cruiseCabinInfo?.name}</div>
            <div>==== DEBUG INFO ====</div>
          </div>
          : null
      }
      {
        props.elementPlaceForCabinGuestSelectors
          ? createPortal(cabinGuestSelectors, props.elementPlaceForCabinGuestSelectors)
          : cabinGuestSelectors
      }
      {
        props.elementPlaceForButtons
          ? createPortal(cabinButtons, props.elementPlaceForButtons)
          : cabinGuestSelectors
      }
      {
        props.elementPlaceForPrice
          ? createPortal(cabinPrices, props.elementPlaceForPrice)
          : cabinGuestSelectors
      }
    </>
  )
}

export default Card