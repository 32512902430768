import { useUnit } from 'effector-react';
// import { useError } from 'effector-react-form/scope';
// import { requestPaymentModel } from 'features/request-payment/model';
// import { Button } from 'shared/ui/atoms';
import { model } from '../model/model';
import styles from './styles.module.scss';
import { useError } from 'effector-react-form';
import { Button } from 'layout/components/buttons/button';
import { requestPaymentModel } from '../model';

interface ModalFooterProps {
	onSubmit: (e: any) => void;
	onClose: () => void;
}

export const ModalFooter = ({ onSubmit, onClose }: ModalFooterProps) => {
	const status = useUnit(model.$status);
	const { form } = requestPaymentModel;
	const { error, isShowError } = useError({ form, name: 'formError' });

	return (
		<div className={styles.paymentModal_buttons}>
			<Button
				disabled={Boolean(error && isShowError)}
				size="large"
				onClick={onSubmit}
				loading={status === 'pending'}
			>
				Оплатить
			</Button>
			<Button btnType="text" size="large" onClick={onClose}>
				Отменить
			</Button>
			{/* <p onClick={onSubmit}>-</p> */}
		</div>
	);
};
