export const ANALYTICS_TARGETS = {
	/**
	 * Главная страница
	 */
	searchbar: {
		// Выбор даты отправления
		searchbar_date_chosen: 'searchbar_date_chosen',
		// Выбор продолжительности
		searchbar_duration_chosen: 'searchbar_duration_chosen',
		// Выбор города отправления
		searchbar_city_chosen: 'searchbar_city_chosen',
		// Выбор популярного маршрута
		searchbar_route_chosen: 'searchbar_route_chosen',
		// Выбор теплохода
		searchbar_ship_chosen: 'searchbar_ship_chosen',
		// Клик по кнопке Поиск
		searchbar_search_click: 'searchbar_search_click',
		// Клик по иконке чата
		chat_click: 'chat_click',
		// Сообщение в чат
		chat_send: 'chat_send',
		// Выбор категории Реки РФ
		searchbar_cat_1: 'searchbar_cat_1',
		// Выбор категории Круизы из Сочи
		searchbar_cat_2: 'searchbar_cat_2',
		// Выбор категории Круизы по турецкой ривьере
		searchbar_cat_3: 'searchbar_cat_3',
		// Выбор категории Морские круизы
		searchbar_cat_4: 'searchbar_cat_4',
	},
	header: {
		// Клик по номеру телефона (для туристов)
		header_phone_tourist: 'header_phone_tourist',
		// Клик по номеру телефона (для турагентств)
		header_phone_agency: 'header_phone_agency',
		// Переход в WhatsApp
		header_whatsapp_click: 'header_whatsapp_click',
		// Переход в Telegram
		header_telegram_click: 'header_telegram_click',
		// Переход в VK
		header_vk_click: 'header_vk_click',
		// Переход в OK
		header_ok_click: 'header_ok_click',
		// Клик по Клуб любителей круизов
		header_club_click: 'header_club_click',
		// Клик по Личный кабинет
		header_account_click: 'header_account_click',
		// Клик по Корзина
		header_cart_click: 'header_cart_click',
		// Переход к выбору кают
		header_cart_cabin: 'header_cart_cabin',
		// Клик по Продолжить оформление
		header_cart_reg: 'header_cart_reg',
		// Клик по Очистить корзину
		header_cart_empty: 'header_cart_empty',
	},
	footer: {
		// Успешная подписка на рассылку
		footer_subscribe: 'footer_subscribe',
		// Переход в WhatsApp
		footer_whatsapp_click: 'footer_whatsapp_click',
		// Переход в Telegram
		footer_telegram_click: 'footer_telegram_click',
		// Переход в VK
		footer_vk_click: 'footer_vk_click',
		// Переход в OK
		footer_ok_click: 'footer_ok_click',
	},
	/**
	 * Поисковая выдача
	 */
	/**
	 * Карточка круиза в поиске
	 */
	cruisecard: {
		// Клик по Все услуги
		cruisecard_all_services: 'cruisecard_all_services',
		// Клик по названию судна
		cruisecard_ship_name: 'cruisecard_ship_name',
		// Клик по Показать (в кол-ве кают)
		cruisecard_cabins_show: 'cruisecard_cabins_show',
		// Клик по Все скидки
		cruisecard_all_discounts: 'cruisecard_all_discounts',
		// Клик по кнопке Выбрать
		cruisecard_chosen: 'cruisecard_chosen',
	},

	// Страница круиза
	cruisepage: {
		/**
		 * Основная часть
		 */
		// Клик по Все скидки
		cruisepage_all_discounts: 'cruisepage_all_discounts',
		// Клик по названию судна
		cruisepage_ship_name: 'cruisepage_ship_name',
		// Клик по Все услуги
		cruisepage_all_services: 'cruisepage_all_services',
		// Клик по Посмотреть маршрут на карте
		cruisepage_show_route: 'cruisepage_show_route',
		// Выбор тура из подборки похожих
		cruisepage_similar_cruise: 'cruisepage_similar_cruise',
		// Клик по Забронировать
		cruisepage_book: 'cruisepage_book',

		/**
		 * Вкладка в карточке
		 */
		// О круизе
		cruisepage_tab_about: 'cruisepage_tab_about',
		// Маршрут и экскурсии
		cruisepage_tab_route: 'cruisepage_tab_route',
		// О теплоходе
		cruisepage_tab_ship: 'cruisepage_tab_ship',
		// Акции и скидки
		cruisepage_tab_discounts: 'cruisepage_tab_discounts',
		// Цены и бронирование
		cruisepage_tab_prices: 'cruisepage_tab_prices',

		/**
		 * Инфо о туре
		 */
		// Клик по Сводная таблица всех услуг
		cruisepage_info_table: 'cruisepage_info_table',
		// Клик по Концепция питания
		cruisepage_info_food: 'cruisepage_info_food',
		// Клик по Таблица маршрута
		cruisepage_info_route: 'cruisepage_info_route',
		// Клик по Поделиться
		cruisepage_info_share: 'cruisepage_info_share',

		/**
		 * Расписание дней
		 */
		// Клик по Подробнее
		cruisepage_schedule_more: 'cruisepage_schedule_more',
		// Клик по О городе
		cruisepage_schedule_city: 'cruisepage_schedule_city',

		/**
		 * О теплоходе
		 */
		// Клик по Все услуги
		cruisepage_schedule_services: 'cruisepage_schedule_services',
		// Клик по Подробнее о теплоходе
		cruisepage_schedule_ship: 'cruisepage_schedule_ship',

		/**
		 * Акции и скидки
		 */
		// Клик по Показать все
		cruisepage_schedule_show: 'cruisepage_schedule_show',

		/**
		 * Цены и бронирование
		 */
		// Выбор вкладки Списком
		cruisepage_list_click: 'cruisepage_list_click',
		// Выбор кол-ва мест
		cruisepage_list_places: 'cruisepage_list_places',
		// Выбор типа каюты
		cruisepage_list_cabin_type: 'cruisepage_list_cabin_type',
		// Выбор категории каюты
		cruisepage_list_cat_cabin: 'cruisepage_list_cat_cabin',
		// Выбор палубы
		cruisepage_list_deck: 'cruisepage_list_deck',
		// Тогглер Только со свободными местами вкл.
		cruisepage_list_vacant_on: 'cruisepage_list_vacant_on',
		// Тогглер Только со свободными местами выкл.
		cruisepage_list_vacant_off: 'cruisepage_list_vacant_off',
		// Выбор вкладки На схеме
		cruisepage_scheme_click: 'cruisepage_scheme_click',
		// Клик по элементу (не каюта)
		cruisepage_block_click: 'cruisepage_block_click',
		// Клик по каюте
		cruisepage_cabin_click: 'cruisepage_cabin_click',

		/**
		 * Карточка каюты в списке
		 */
		// Клик по Все услуги в каютах
		cruisepage_cabin_all_services: 'cruisepage_cabin_all_services',
		// Клик по стрелке в просмотре фото каюты
		cruisepage_cabin_photos: 'cruisepage_cabin_photos',
		// Клик по Показать каюты
		cruisepage_cabin_show: 'cruisepage_cabin_show',
		// Клик по Скрыть каюты
		cruisepage_cabin_hide: 'cruisepage_cabin_hide',
		// Клик по Добавить в заказ
		cruisepage_cabin_add_to_cart: 'cruisepage_cabin_add_to_cart',
		// Открытие полной карточки
		cruisepage_cabin_full_card: 'cruisepage_cabin_full_card',

		/**
		 * Полная карточка
		 */
		// Тогглер Одноместное размещение вкл.
		cruisepage_one_bed_on: 'cruisepage_one_bed_on',
		// Тогглер Одноместное размещение выкл.
		cruisepage_one_bed_off: 'cruisepage_one_bed_off',
		// Смена типа билета
		cruisepage_ticket_type_changed: 'cruisepage_ticket_type_changed',
		// Удаление типа билета
		cruisepage_ticket_type_removed: 'cruisepage_ticket_type_removed',
		// Клик по Добавить в заказ из полной карточки
		cruisepage_fullcard_add_to_card: 'cruisepage_fullcard_add_to_card',

		/**
		 * Поп-ап после добавления в заказ
		 */
		// Клик по Выбрать еще каюту
		cruisepage_cabin_add: 'cruisepage_cabin_add',
		// Клик по Продолжить оформление
		cruisepage_to_reg: 'cruisepage_to_reg',

		/**
		 * Поп-ап авторизации
		 */
		// Клик по Войти или зарегистрироваться
		cruisepage_enter: 'cruisepage_enter',
		// Клик по Продолжить как гость
		cruisepage_guest: 'cruisepage_guest',

		/**
		 * Блок Нужна помощь в выборе кают?
		 */
		// Клик по кнопке
		cruisepage_cabin_help_click: 'cruisepage_cabin_help_click',
		// Начато заполнение формы
		cruisepage_cabin_help_form_start: 'cruisepage_cabin_help_form_start',
		// Форма успешно отправлена
		cruisepage_cabin_help_form_sent: 'cruisepage_cabin_help_form_sent',
	},

	/**
	 * Авторизация Страница входа
	 */
	signin: {
		// Вкладка Телефон
		signin_tab_phone: 'signin_tab_phone',
		// Вкладка Почта
		signin_tab_email: 'signin_tab_email',
		// Вкладка Логин или номер КЛК
		signin_tab_login: 'signin_tab_login',
		// Вкладка СМС код
		signin_tab_login_by_sms: 'signin_tab_login_by_sms',
		// Успешный вход
		signin_success: 'signin_success',
		// Клик по Забыли пароль
		signin_forgot_pass: 'signin_forgot_pass',
		// Новый пароль запрошен
		signin_pass_request: 'signin_pass_request',
		// Клик по Регистрация
		signin_new_account: 'signin_new_account',
	},

	/**
	 * Авторизация Страница входа
	 */
	signup: {
		// Клик по Войти в Уже есть аккаунт?
		signup_have_account: 'signup_have_account',
		// Клик по регистрации в качестве агентства
		signup_agency: 'signup_agency',
		// Начато заполнение формы
		signup_form_start: 'signup_form_start',
		// Отправлено СМС от сервера
		signup_sms_sent: 'signup_sms_sent',
		// Запрошен новый код
		signup_new_code_request: 'signup_new_code_request',
		// Аккаунт подтвержден (успешная регистрация)
		signup_success: 'signup_success',
	},
	/**
	 * Заявка на бронирование
	 */
	enquiry: {
		/**
		 * Данные туристов
		 */
		// Клик по Удалить каюту
		enquiry_delete_cabin: 'enquiry_delete_cabin',
		// Тогглер Одноместное размещение вкл.
		enquiry_one_bed_on: 'enquiry_one_bed_on',
		// Тогглер Одноместное размещение выкл.
		enquiry_one_bed_off: 'enquiry_one_bed_off',
		// Тогглер Взрослый + ребенок без места вкл.
		enquiry_parents_on: 'enquiry_parents_on',
		// Тогглер Взрослый + ребенок без места выкл.
		enquiry_parents_off: 'enquiry_parents_off',
		// Тогглер Заполнить данными заказчика вкл.
		enquiry_fillin_data_on: 'enquiry_fillin_data_on',
		// Тогглер Заполнить данными заказчика выкл.
		enquiry_fillin_data_off: 'enquiry_fillin_data_off',
		// Клик по Добавить ребенка
		enquiry_add_child: 'enquiry_add_child',
		// Начато заполнение с формой данных ребенка
		enquiry_child_form_start: 'enquiry_child_form_start',
		// Форма ребенка удалена
		enquiry_child_removed: 'enquiry_child_removed',
		// Клик по Освободить место
		enquiry_leave_place: 'enquiry_leave_place',
		// Клик по Добавить туриста
		enquiry_add_tourist: 'enquiry_add_tourist',
		// Клик по Перейти к данным заказчика
		enquiry_2nd_step: 'enquiry_2nd_step',

		/**
		 * Правый сайдбар
		 */
		// Клик по кнопке Входит в стоимость
		enquiry_whats_included: 'enquiry_whats_included',
		// Клик по Полный маршрут
		enquiry_full_route: 'enquiry_full_route',

		/**
		 * Данные заказчика
		 */
		// Выбран вариант Откуда вы о нас узнали
		enquiry_source: 'enquiry_source',
		// Клик по Перейти к подтверждению
		enquiry_3rd_step: 'enquiry_3rd_step',

		/**
		 * Подтверждение заявки
		 */
		// Применен промокод
		enquiry_promocode_applied: 'enquiry_promocode_applied',
		// Тогглер Участвовать в программе вкл.
		enquiry_participate_on: 'enquiry_participate_on',
		// Тогглер Участвовать в программе выкл.
		enquiry_participate_off: 'enquiry_participate_off',
		// Клик по Забронировать (успешная бронь тура)
		enquiry_booking_success: 'enquiry_booking_success',
	},
	/**
	 * Личный кабинет
	 */
	account: {
		/**
		 * Сайдбар
		 */
		// Выбор меню Профиль
		account_profile_menu: 'account_profile_menu',
		// Выбор меню Мои заявки
		account_enquiries_menu: 'account_enquiries_menu',
		// Выбор меню Мои бонусы
		account_bonuses_menu: 'account_bonuses_menu',
		// Выбор меню Личные данные
		account_personal_data: 'account_personal_data',
		// Выбор меню Уведомления от операторов
		account_notification: 'account_notification',
		// Клик по Выйти из профиля
		account_logout: 'account_logout',

		/**
		 * Профиль
		 */
		// Клик по Распечатать бонусную карту
		account_bonus_print: 'account_bonus_print',
		// Клик по Все предложения
		account_all_offers: 'account_all_offers',
		// Клик по Подобрать круиз
		account_pick_cruise: 'account_pick_cruise',

		/**
		 * Мои заявки
		 */
		// Клик по Найти
		account_enquiries_find: 'account_enquiries_find',
		// Включение сортировки
		account_enquiries_sort: 'account_enquiries_sort',
		// Клик по заявке
		account_enquiries_click: 'account_enquiries_click',
		// Клик по Данные по финансовому обеспечению туроператоров
		account_enquiries_finance: 'account_enquiries_finance',
		// Клик по Подробнее о способах оплаты
		account_enquiries_payment: 'account_enquiries_payment',

		/**
		 * Мои бонусы
		 */
		// Клик по Посмотреть всю историю зачислений
		account_bonus_history: 'account_bonus_history',
		// Клик по вкладке Начисления и списания
		account_bonus_transactions: 'account_bonus_transactions',
		// Клик по Баллы, ожидающие начисления
		account_bonus_waiting: 'account_bonus_waiting',
		// Клик по Восстановить баллы
		account_points_recovery_click: 'account_points_recovery_click',

		/**
		 * Форма восстановления баллов
		 */
		// Клик по Условия начисления баллов
		account_points_rules: 'account_points_rules',
		// Форма отправлена
		account_points_recovery_sent_form: 'account_points_recovery_sent_form',

		/**
		 * Личные данные
		 */
		// Телефон изменен
		account_phone_changed: 'account_phone_changed',
		// Добавлен телефон для смс-рассылки
		account_phone_added: 'account_phone_added',
		// Email изменен
		account_email_changed: 'account_email_changed',
		// Пароль изменен
		account_password_changed: 'account_password_changed',
		// Тогглер Я согласен получать SMS-уведомления рекламного характера вкл.
		account_sms_agree_on: 'account_sms_agree_on',
		// Тогглер Я согласен получать SMS-уведомления рекламного характера выкл.
		account_sms_agree_off: 'account_sms_agree_off',
		// Тогглер Я согласен получать e-mail-рассылку рекламного характера вкл.
		account_email_agree_on: 'account_email_agree_on',
		// Тогглер Я согласен получать e-mail-рассылку рекламного характера выкл.
		account_email_agree_off: 'account_email_agree_off',
		// Клик по Сохранить изменения
		account_saved_data: 'account_saved_data',
	},
	/**
	 * Клуб любителей круизов
	 */
	clubpage: {
		// Клик по Правила программы
		clubpage_rules: 'clubpage_rules',
		// Клик по Золотой статус
		clubpage_golden: 'clubpage_golden',
		// Клик по Часто задаваемые вопросы
		clubpage_faq: 'clubpage_faq',
		// Клик по Акции клуба
		clubpage_sales: 'clubpage_sales',
		// Клик по Клиентская поддержка
		clubpage_support: 'clubpage_support',
		// Форма Задайте ваш вопрос отправлена
		clubpage_askform_sent: 'clubpage_askform_sent',
		// Клик по Подобрать круиз
		clubpage_pick_cruise: 'clubpage_pick_cruise',
		// Отправка формы Остались вопросы?
		clubpage_questions_form_sent: 'clubpage_questions_form_sent',
	},

	/**
	 * Страница судна
	 */
	shippage: {
		/**
		 * Основная часть
		 */
		// Клик по кнопке Подобрать круиз
		shippage_pick_cruise: 'shippage_pick_cruise',
		// Клик по стрелкам в фото судна
		shippage_arrows_click: 'shippage_arrows_click',
		// Клик по вкладке О теплоходе
		shippage_tab_about: 'shippage_tab_about',
		// Клик по вкладке Каюты
		shippage_tab_cabins: 'shippage_tab_cabins',
		// Клик по вкладке Акции и скидки
		shippage_tab_sales: 'shippage_tab_sales',
		// Клик по вкладке Расписание круизов и цены
		shippage_tab_schedule: 'shippage_tab_schedule',
		// Клик по вкладке Отзывы
		shippage_tab_testimonials: 'shippage_tab_testimonials',

		/**
		 * Вкладка О теплоходе
		 */
		// Клик по аккордеону Размещение
		shippage_about_placement: 'shippage_about_placement',
		// Клик по аккордеону Питание
		shippage_about_food: 'shippage_about_food',
		// Клик по аккордеону Развлечения
		shippage_about_entertainment: 'shippage_about_entertainment',
		// Клик по аккордеону Для детей
		shippage_about_kids: 'shippage_about_kids',

		/**
		 * Вкладка Каюты
		 */
		// Клик по элементу (кроме кают)
		shippage_cabins_technical: 'shippage_cabins_technical',
		// Клик по каюте
		shippage_cabins_click: 'shippage_cabins_click',

		/**
		 * Вкладка Отзывы
		 */
		// Клик по кнопке Оставить отзыв
		shippage_testimonials_click: 'shippage_testimonials_click',
		// Успешная отправка отзыва
		shippage_testimonials_form_sent: 'shippage_testimonials_form_sent',
	},
};
