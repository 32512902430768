import classNames from 'classnames';
// import { Typo } from 'shared/ui/atoms';
import { BonusRecoveryModal } from '../bonus-recovery-modal';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';

interface BonusRecoveryProps {
	className?: string;
}

export const BonusRecovery = ({ className }: BonusRecoveryProps) => (
	<div className={classNames(styles.bonusRecovery, className)}>
		<div className={styles.leftColumn}>
			<Typo design="headline-m">Не начислены баллы за круиз?</Typo>
			<Typo design="text-m">
				Вы можете отправить запрос на восстановление баллов за круизы купленные после 22.11.2012г.
			</Typo>
		</div>

		<BonusRecoveryModal />
	</div>
);
