// import { Typo } from 'shared/ui/atoms';
import { Typo } from 'layout/components/typo/ui';
import styles from './styles.module.scss';

interface StaticFormFieldProps {
	caption: string;
	value: string;
}

export const StaticFormField = ({ caption, value }: StaticFormFieldProps) => (
	<div className={styles.staticFormField}>
		<Typo as="span" design="description-features" className={styles.caption}>
			{caption}
		</Typo>
		<Typo as="span" design="input-text-m" className={styles.value}>
			{value}
		</Typo>
	</div>
);
