import React, { FC, ReactElement, useRef } from 'react';
import { OverlayTriggerState } from 'react-stately';
// import { useModalState } from 'shared/lib/hooks';
// import { IconId } from 'shared/ui/icons';
import { ModalProps, Modal } from './modal';
import { IconId } from '../icons/svg-sprite';
import { useModalState } from 'utils/hooks/use-modal-state';

export interface ModalTriggerProps {
	children?: (close?: OverlayTriggerState['close']) => ReactElement;
	button?: ReactElement;
	className?: string;
	classNameOverlay?: string;
	classNameModal?: string;
	closeButtonClassName?: string;
	classNameModalContent?: string;
	classNameFooter?: string;
	isDismissible?: boolean;
	verticalAlign?: 'top' | 'bottom';
	footer?: ReactElement;
	onClick?: () => void;
	modalProps?: Partial<ModalProps>;
	closeIconId?: IconId;
}

export const ModalTrigger: FC<ModalTriggerProps> = ({
	children,
	button,
	className,
	classNameOverlay,
	classNameModal,
	closeButtonClassName,
	isDismissible = true,
	verticalAlign,
	footer,
	classNameModalContent,
	classNameFooter,
	onClick,
	modalProps,
	closeIconId = 'close',
}) => {
	const ref = useRef<HTMLButtonElement>(null);
	const modalRef = useRef<HTMLDivElement>(null);

	const { state, buttonProps, overlayProps, shouldMount } = useModalState({ triggerRef: ref });

	return (
		<>
			<button
				ref={ref}
				{...buttonProps}
				onClick={(e) => {
					if (onClick) {
						onClick();
					}
					if (buttonProps.onClick) {
						buttonProps.onClick(e);
					}
				}}
				className={className}
			>
				{button}
			</button>
			{shouldMount && (
				<Modal
					{...modalProps}
					state={state}
					modalRef={modalRef}
					isShown={state.isOpen}
					isDismissable={isDismissible}
					className={classNameOverlay}
					classNameModal={classNameModal}
					closeButtonClassName={closeButtonClassName}
					classNameModalContent={classNameModalContent}
					classNameFooter={classNameFooter}
					verticalAlign={verticalAlign}
					footer={footer && React.cloneElement(footer, { close: state.close })}
					closeIconId={closeIconId}
				>
					{children && React.cloneElement(children(state.close), overlayProps)}
				</Modal>
			)}
		</>
	);
};
