import React, { FC, Key } from 'react';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
// import { Select } from 'shared/ui/molecules';
import { requestsFilterModel } from '../model';
import { Select } from 'layout/components/inputs/selects/select';

interface FilterSelectProps {
	label: string;
	placeholder: string;
	options: { id?: number | string; name?: string }[];
	name: string;
	selectionMode: 'multiple' | 'single';
	selectedKeys?: Iterable<Key>;
	className?: string;
}

export const FilterSelect: FC<FilterSelectProps> = ({
	label,
	placeholder,
	className,
	options,
	selectionMode,
	selectedKeys,
	name,
}) => {
	const setSearchParams = useUnit(requestsFilterModel.setSearchParams);

	return (
		<Select
			size="md"
			className={className}
			label={label}
			customValue={selectedKeys === 'all' ? 'Все теплоходы' : undefined}
			placeholder={placeholder}
			name={name}
			selectionMode={selectionMode}
			selectedKeys={new Set(selectedKeys) as any} // TODO это плохо, конечно, но пока отключим типизацию
			onSelectionChange={(selection) => {
				const keys = selection instanceof Set ? ([...selection].join(',') as string) : selection;

				setSearchParams({
					key: name,
					value: keys.length ? keys : null,
				});
			}}
		>
			{options.map(({ id, name: optionName }) => (
				<Item key={id?.toString()} aria-label={optionName}>
					{optionName}
				</Item>
			))}
		</Select>
	);
};
