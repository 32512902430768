import { ReactNode } from 'react';
import classNames from 'classnames';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';

interface WarningMessageProps {
	children: ReactNode;
	className?: string;
	design?: 'clear' | 'danger';
}

export const WarningMessage = ({ children, className, design }: WarningMessageProps) => (
	<div className={classNames(className, styles.warningMessage, design && styles[design])}>
		<Icon className={styles.icon} id="info" />
		<Typo className={styles.text} design={design === 'danger' ? 'description-features' : 'text-s'}>
			{children}
		</Typo>
	</div>
);
