import React, { useMemo } from 'react';
import classnames from 'classnames';
// import { usePassengersServices } from 'widgets/account';
import { PassengerCardModal } from 'entities/passengers/passenger-card-modal';
import { MEAL_TYPES } from 'entities/passengers/passengers-card/lib';
import { PassengersCardParam } from 'entities/passengers/passengers-card/passenger-card-param';
import styles from 'entities/passengers/passengers-card/styles.module.scss';
import { EXCURSIONS_ACCUSATIVE_DECLENSION } from 'config/constants';
import { RequestPassenger } from 'config/types/prices/request-passenger';
import { usePassengersServices } from 'entities/account/model';
import { Typo } from 'layout/components/typo/ui';
import { getNumDeclension } from 'utils/string';
// import { EXCURSIONS_ACCUSATIVE_DECLENSION } from 'shared/lib/constants';
// import { RequestPassenger } from 'shared/lib/types/account/request-passenger';
// import { getNumDeclension } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';

interface PassengerServicesProps extends Pick<RequestPassenger, 'mealType' | 'excursionsType'> {
	className?: string;
	itemClassName?: string;
	passengerId: number;
}

export const PassengerServices = ({
	mealType,
	excursionsType,
	className,
	itemClassName,
	passengerId,
}: PassengerServicesProps) => {
	const excursionMealType = useMemo(
		() => MEAL_TYPES.find((elem) => elem.id === mealType)?.name,
		[mealType],
	);

	const { passengerServices } = usePassengersServices(passengerId);

	return (
		<div
			className={classnames(
				styles.passengersCard_infoBlock,
				styles.passengersCard_additionalParams,
				className,
			)}
		>
			{excursionMealType && (
				<PassengersCardParam
					className={itemClassName}
					title="Тип питания"
					value={excursionMealType}
				/>
			)}

			{excursionsType && (
				<PassengersCardParam
					className={itemClassName}
					title="Экскурсии и оздоровительные услуги"
					value={excursionsType}
				/>
			)}
			{passengerServices.length ? (
				<div className={itemClassName}>
					<Typo design="text-s" className={styles.infoBlock_title}>
						Дополнительные экскурсии и услуги
					</Typo>
					{passengerServices.length < 2 ? (
						<Typo design="text-m">{passengerServices[0]?.excursion?.name}</Typo>
					) : (
						<PassengerCardModal
							buttonContent={`Посмотреть ${passengerServices?.length} ${getNumDeclension(
								passengerServices.length,
								EXCURSIONS_ACCUSATIVE_DECLENSION,
							)}`}
							modalType="excursions"
							passengerId={passengerId}
						/>
					)}
				</div>
			) : null}
		</div>
	);
};
