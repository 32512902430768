import React, { useMemo } from 'react';
import classnames from 'classnames';
// import { RequestPaymentModal } from 'features';
// import { BillRequest } from 'features/bill-request/ui';
// import { Request } from 'shared/lib/types/account/request';
// import { Typo } from 'shared/ui/atoms';
// import { CabinParams } from 'shared/ui/organisms';
import { DetailedRequestAnchors, UNAVAILABLE_CORPORATION_ID } from '../../../lib';
import { Comment } from './comment';
import { FinalPaymentInfo } from './final-payment-info';
import { PaymentList } from './payment-list';
import styles from './styles.module.scss';
import { RequestPaymentModal } from 'entities/request-payment';
import { CabinParams } from 'layout/components/cruise/cabin-params';
import { Typo } from 'layout/components/typo/ui';
import { Request } from 'store/auth/types/account/request';
import { BillRequest } from 'entities/bill-request/ui';

interface RequestDetailsProps {
	request: Request;
	className?: string;
}

// eslint-disable-next-line complexity
export const RequestDetails = ({ request, className }: RequestDetailsProps) => {
	const {
		id,
		corporationId,
		billStatus,
		payed,
		refunded,
		status,
		comment,
		cabins,
		amount,
		additional,
		prepayment,
		expirationDate,
		prepaymentDate,
		documents,
		isPayable,
	} = request;

	const passengers = useMemo(
		() =>
			cabins
				?.map((item) => (item.passengers?.length ? item.passengers : []))
				.filter((elem) => elem?.length)
				.flat(),
		[cabins],
	);

	const cabinParamsCondition = Boolean(cabins?.length || passengers?.length);
	const paymentListCondition = Boolean(amount || additional || prepayment || payed);
	const finalPaymentCondition = Boolean(status && (payed || additional || amount));
	const corporationIdCondition = !(
		corporationId && UNAVAILABLE_CORPORATION_ID.includes(corporationId)
	);
	// const paymentButtonCondition = Boolean(
	// 	status && STATUSES_TOTAL_PAYABLE.includes(status) && amount && corporationIdCondition && isPayable,
	// );

	const billDocument = documents?.find(({ type }) => type === 'bill');

	return (
		<div
			className={classnames(styles.requestDetails_wrap, className)}
			id={DetailedRequestAnchors.RequestDetails}
		>
			<Typo design="headline-m">Детали заявки</Typo>

			{(cabinParamsCondition || paymentListCondition) && (
				<div className={styles.requestDetails_container}>
					{cabinParamsCondition && (
						<CabinParams cabins={cabins} passengers={passengers} theme="reverse" />
					)}

					{paymentListCondition && (
						<PaymentList
							amount={cabins?.reduce((p, c) => p + (c.amount || 0), 0) || 0}
							additional={additional}
							prepayment={prepayment}
							payed={payed}
							refunded={refunded}
							status={status}
						/>
					)}
				</div>
			)}

			<Comment comment={comment} />

			{finalPaymentCondition && (
				<FinalPaymentInfo
					status={status}
					payed={payed}
					prepayment={prepayment}
					additional={additional}
					amount={amount}
					expirationDate={expirationDate}
					prepaymentDate={prepaymentDate}
				/>
			)}

			<div className={styles.requestDetails_buttons}>
				{isPayable && <RequestPaymentModal buttonContent="Оплатить" size="large" {...request} />}
				{!corporationIdCondition && (
					<Typo className={styles.paymentMessage} design="description-m">
						Для оплаты обратитесь в офис Созвездие
					</Typo>
				)}
				{isPayable && (
					<BillRequest id={id} billStatus={billStatus} status={status} document={billDocument} />
				)}
			</div>
		</div>
	);
};
