import React, { FC } from 'react';
import { useUnit } from 'effector-react';
import { PLACE_TYPES } from 'config/constants/cabin';
import { PassengerForm } from 'entities/passengers';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { model } from './../../model';
import { editPassengerValidationScheme, editChildrenValidationScheme } from '../../lib';
// import {
// 	editChildrenValidationScheme,
// 	editPassengerValidationScheme,
// } from 'features/edit-passenger/lib';
// import { model } from 'features/edit-passenger/model';
// import { PassengerForm } from 'entities/passengers';
// import { PLACE_TYPES } from 'shared/config';
// import { Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';

interface EditPassengerFormProps {
	type?: number;
	minChildBornDate?: string;
}

export const EditPassengerForm: FC<EditPassengerFormProps> = ({ type, minChildBornDate }) => {
	const { form, $pattern, $documentTypesValidation } = model;

	const [
		{ documentType, child },
		setFromProfile,
		setChildValue,
		pattern,
		documentTypeValidationScheme,
	] = useUnit([
		form.$values,
		model.setFromProfile,
		model.setChildValue,
		$pattern,
		$documentTypesValidation,
	]);

	const isNonFreePlace = type === 0 || type === 1;

	return (
		<PassengerForm
			form={form}
			documentType={documentType}
			pattern={pattern}
			setFromProfile={setFromProfile}
			validation={editPassengerValidationScheme}
			childValidation={editChildrenValidationScheme}
			child={child}
			childButton={
				isNonFreePlace ? (
					<Button size="small" btnType="text" onClick={() => setChildValue(true)}>
						<Icon id="plus" width={20} height={20} />
						{PLACE_TYPES['1']}
					</Button>
				) : null
			}
			documentTypeValidationScheme={documentTypeValidationScheme}
			minChildBornDate={minChildBornDate}
			showAgeNotification={type === 2}
			showChildAgeNotification
		/>
	);
};
