import React, { FC, useRef } from 'react';
import classnames from 'classnames';
import { useUnit } from 'effector-react';
// import { CallbackForm, CallbackFormSuccess } from 'features/callback';
// import { useModalState } from 'shared/lib/hooks';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { callBackModel } from '../../../../model';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { CallbackForm, CallbackFormSuccess } from 'layout/components/special/callback';
import { Typo } from 'layout/components/typo/ui';
import { useModalState } from 'utils/hooks/use-modal-state';

interface CallBackModalProps {
	textLabel?: string;
	className?: string;
}

export const CallBackModal: FC<CallBackModalProps> = ({
	className,
	textLabel = 'обратный звонок',
}) => {
	const triggerRef = useRef<HTMLButtonElement>(null);
	const modalRef = useRef<HTMLDivElement>(null);
	const { state, shouldMount } = useModalState({ triggerRef });

	const status = useUnit(callBackModel.$status);

	return (
		<>
			<Button
				btnType="text"
				ref={triggerRef}
				onClick={state.toggle}
				className={classnames(className || styles.callBackModal_button)}
			>
				{textLabel}
			</Button>

			{shouldMount && (
				<Modal
					state={state}
					isShown={state.isOpen}
					modalRef={modalRef}
					classNameModal={styles.callBackModal}
					classNameModalContent={styles.callBackModal_modalContent}
					verticalAlign="bottom"
					isDismissable
				>
					<div className={styles.callBackModal_content}>
						{status !== 'done' && (
							<Typo design="headline-xl" className={styles.callBackModal_title}>
								Заказать обратный звонок
							</Typo>
						)}
						<CallbackForm
							model={callBackModel}
							footer={
								<>
									<Button loading={status === 'pending'} size="large" type="submit">
										Заказать звонок
									</Button>
									<Button size="large" btnType="text" type="button" onClick={state.close}>
										Отменить
									</Button>
								</>
							}
							success={<CallbackFormSuccess close={state.close} />}
						/>
					</div>
				</Modal>
			)}
		</>
	);
};
