import React from 'react';
import classnames from 'classnames';
// import { RequestCabin } from 'shared/lib/types/account/request-cabin';
// import { Typo } from 'shared/ui/atoms';
// import { CabinParams } from 'shared/ui/organisms';
import { geCabinName } from '../lib';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { RequestCabin } from 'store/auth/types/account/request-cabin';
import { CabinParams } from 'layout/components/cruise/cabin-params';

export const GroupHead = ({ name, placesCount, passengers, amount }: RequestCabin) => (
	<div className={styles.cabinHead_wrap}>
		<div className={styles.cabinHead}>
			<Typo
				design="headline-m"
				className={classnames(styles.cabinName, {
					[styles.longCabinName]: name && name.length > 9,
				})}
			>
				{geCabinName(name)}
			</Typo>
			<CabinParams placesCount={placesCount} passengers={passengers} amount={amount} />
		</div>
	</div>
);
