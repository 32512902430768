import { createFileRoute } from '@tanstack/react-router'
import { AccountLayout } from 'layout/layouts/account';
import { Main } from 'layout/layouts/main';
import { OperatorNotifications } from 'layout/layouts/widgets/account';
import accountStyles from './../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute('/account/notifications/')({
	component: () => <Notifications />,
})

const Notifications = () => (
	<Main>
		<AccountLayout>
			<section className={accountStyles.marginTopSmMd}>
				<OperatorNotifications />
			</section>
		</AccountLayout>
	</Main>
);