// import {
// 	STATUSES_FOR_ADD_EXCURSIONS,
// 	STATUSES_FOR_NEW_EXCURSIONS_REQUEST,
// } from 'widgets/account/ui/detailed-request/lib';
// import { AccountRequestsStatuses } from 'shared/config';

import { STATUSES_FOR_NEW_EXCURSIONS_REQUEST, STATUSES_FOR_ADD_EXCURSIONS } from "entities/request-excursions/request-excursions-add/lib";
import { AccountRequestsStatuses } from "store/auth/constants";
// import { STATUSES_FOR_NEW_EXCURSIONS_REQUEST, STATUSES_FOR_ADD_EXCURSIONS } from "../../detailed-request/lib";

export const checkStatusForExcursions = (status?: AccountRequestsStatuses) => {
	if (!status) {
		return false;
	}

	return [...STATUSES_FOR_NEW_EXCURSIONS_REQUEST, ...STATUSES_FOR_ADD_EXCURSIONS].includes(status);
};
