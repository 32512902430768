// import { Skeleton } from 'shared/ui/atoms';
import { Skeleton } from 'layout/components/special/skeleton';
import styles from './styles.module.scss';

export const BonusHistoryCardSkeleton = () => (
	<li className={styles.bonusHistoryCard}>
		<Skeleton margin="0 0 8px" width="40%" height={24} />
		<Skeleton margin="0 0 16px" width="100%" height={48} />
		<Skeleton margin="0 0 8px" width="50%" height={24} />
		<Skeleton margin="0" width="50%" height={24} />
	</li>
);
