import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
// import { useViewport } from 'shared/lib/viewport';
// import { Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { AccordionProps } from './accordion';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { useViewport } from 'layout/viewport';

export const AccordionFoldable: FC<Omit<AccordionProps, 'style'>> = ({
	caption,
	className,
	children,
}) => {
	const minLinesCount = 3;

	const { isMob } = useViewport();
	const duration = isMob ? 0 : 400;

	const ref = useRef(null);

	const [expanded, setExpanded] = useState(false);
	const [displayButton, setDisplayButton] = useState(false);
	const [minHeight, setMinHeight] = useState(24 * minLinesCount);

	const computeHeights = (node?: Nullable<HTMLDivElement>) => {
		if (!node) {
			return;
		}

		let min = 24 * minLinesCount;
		const max = node.offsetHeight;

		if (node.firstElementChild) {
			min = (node.firstElementChild as HTMLElement).offsetHeight;
		}

		if (typeof window !== 'undefined') {
			const cssLineHeightValue = Number.parseInt(
				window.getComputedStyle(node).getPropertyValue('line-height'),
			);

			if (!Number.isNaN(cssLineHeightValue)) {
				min = cssLineHeightValue * 3;
			}
		}

		setMinHeight(min);
		setDisplayButton(max > min);
	};

	useEffect(() => {
		const onResize = () => computeHeights(ref?.current);

		onResize();
		window.addEventListener('resize', onResize);

		return () => {
			window.addEventListener('resize', onResize);
		};
	}, []);

	return (
		<article
			className={classnames(className, styles.accordionFoldable, {
				[styles.isExpanded]: expanded || !displayButton,
			})}
		>
			<AnimateHeight
				duration={duration}
				height={expanded ? 'auto' : minHeight}
				className={styles.accordionFoldable__wrap}
			>
				<div className={styles.accordionFoldable__fade} style={{ minHeight }} />
				<div className={styles.accordionFoldable__content} ref={ref}>
					{React.Children.map(children, (child, index) => {
						if (!React.isValidElement(child)) {
							return null;
						}

						return React.cloneElement(child, {
							...child.props,
						});
					})}
				</div>
			</AnimateHeight>

			{displayButton && (
				<Button
					size="large"
					btnType="text"
					className={styles.accordionFoldable__toggle}
					onClick={() => setExpanded((prev) => !prev)}
				>
					<span>{!expanded ? caption : 'Свернуть текст'}</span>
					<Icon className={styles.accordionFoldable__icon} id="chevron-down" />
				</Button>
			)}
		</article>
	);
};
