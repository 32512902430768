import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
// import { useViewport } from 'shared/lib/viewport';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { AccordionProps } from './accordion';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { useViewport } from 'layout/viewport';

const classes = (className: string, theme: string) => {
	if (theme !== 'default') {
		return `accordion__${className}`;
	}

	return `accordion_default__${className}`;
};

export const AccordionDefault: FC<Omit<AccordionProps, 'accordionType'>> = ({
	theme = 'withDivider',
	caption,
	className,
	headClassName,
	classNameContent,
	children,
	expanded: state,
	toggleExpanded: toggleState,
	captionDesing = 'headline-s',
	captionDesingFreezeDesktop,
	defaultState,
	withoutMargins = false,
}) => {
	const { isMob } = useViewport();
	const duration = isMob ? 0 : 400;

	const [expanded, setExpanded] = useState(defaultState ?? false);
	const unionExpanded = toggleState ? state : expanded;

	useEffect(() => {
		setExpanded(defaultState ?? false);
	}, [defaultState]);

	const handleClick = () => {
		if (toggleState) {
			toggleState();

			return;
		}

		setExpanded((prev) => !prev);
	};

	return (
		<article
			className={classnames(
				className,
				{ [styles.accordion]: theme === 'withDivider' },
				{ [styles.accordion_default]: theme === 'default' },
				{ [styles.isExpanded]: unionExpanded },
				{ [styles.withoutMargins]: withoutMargins },
			)}
		>
			<div
				className={classnames(styles[classes('head', theme)], headClassName)}
				onClick={handleClick}
			>
				<Typo design={captionDesing} freezeDesktop={captionDesingFreezeDesktop}>
					{caption}
				</Typo>
				<Icon className={styles[classes('icon', theme)]} id="chevron-down" />
			</div>
			<AnimateHeight
				duration={duration}
				height={unionExpanded ? 'auto' : 0}
				className={styles.content__wrap}
				animateOpacity={!isMob}
			>
				{(!isMob || unionExpanded) && (
					<div className={classnames(classNameContent, styles[classes('content', theme)])}>
						{children}
					</div>
				)}
			</AnimateHeight>
		</article>
	);
};
