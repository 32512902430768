import classnames from 'classnames';
import { AccordionProps } from './accordion';
import { AccordionDefault } from './accordion-default';
import styles from './styles.module.scss';

export const AccordionFilledGray = ({ className, ...props }: AccordionProps) => (
	<AccordionDefault
		className={classnames(className, styles.accordion_filled, styles.accordion_filledGray)}
		{...props}
	/>
);
