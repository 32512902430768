/* eslint-disable react/no-array-index-key */
import React from 'react';
// import { AccountRequestsStatuses } from 'shared/config';
// import { Request } from 'shared/lib/types/account/request';
// import { RequestDocument } from 'shared/lib/types/account/request-document';
import { RequestDoc } from './request-doc';
import styles from './styles.module.scss';
import { Request } from 'store/auth/types/account/request';
import { AccountRequestsStatuses } from 'store/auth/constants';
import { RequestDocument } from 'store/auth/types/account/request-document';

interface RequestDocsProps extends Pick<Request, 'status' | 'billStatus' | 'id'> {
	documents?: RequestDocument[];
}

export const RequestDocs = ({ documents, status }: RequestDocsProps) => (
	<div className={styles.docs_wrap}>
		{Array.isArray(documents) &&
			documents.map((doc, index) => {
				if (
					!doc.type ||
					doc.type === 'bill' ||
					(doc.type === 'additional-agreement' && status === AccountRequestsStatuses.Paid)
				) {
					return null;
				}

				return <RequestDoc document={doc} key={index} />;
			})}
	</div>
);
