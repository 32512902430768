import { ReactElement } from 'react';
import { OverlayTriggerState } from 'react-stately';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { ModalTrigger } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { ModalTrigger } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';

interface ChangePhoneTriggerProps {
	footer?: ReactElement;
	children?: (close?: OverlayTriggerState['close']) => ReactElement;
	addMode?: boolean;
}

export const ChangePhoneTrigger = ({
	children,
	footer,
	addMode = false,
}: ChangePhoneTriggerProps) => (
	<ModalTrigger
		footer={footer}
		className={styles.button}
		classNameModal={styles.modal}
		verticalAlign="bottom"
		button={
			<Typo design="button-l">
				<div className={styles.button__content}>
					{addMode && <Icon id="plus" />}
					{addMode ? 'Добавить телефон' : 'Изменить'}
				</div>
			</Typo>
		}
	>
		{children}
	</ModalTrigger>
);
