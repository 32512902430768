import { createEffect } from 'effector/effector.umd';
import { condition, debounce, not } from 'patronum';
import { OverlayTriggerState } from 'react-stately';
import { combine, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { detailedCruiseCabinModel } from 'entities/cabin-booking-cards/ui/cabin-description-modal/model';
import { profileModel } from 'layout/components/auth/profile';
import { modalFactory } from 'layout/components/modal';
import { routerModel } from 'utils/router';
import { AccountRoutes } from 'store/auth/constants';
// import { profileModel } from 'entities/profile';
// import { modalFactory, routerModel } from 'shared/model';

/* *
 * Авторизация
 */
const $authorized = combine(
	{
		profile: profileModel.$profile,
		token: profileModel.$accessToken,
	},
	({ profile, token }) => Boolean(profile && token),
);
console.log('error isAuthorized', $authorized)
const $isGuest = not($authorized);
console.log('error isGuest', not($authorized) ? 'not authorized' : 'authorized')

/* *
 * Гейты и базовые сторы
 */
const { ModalGate, openModal } = modalFactory();
const CartModalGate = createGate<Partial<Pick<OverlayTriggerState, 'close'>>>();

const resetUserRoleDialog = createEvent();
const debouncedResetUserRoleDialog = createEvent();

debounce({
	source: debouncedResetUserRoleDialog,
	timeout: 1000,
	target: resetUserRoleDialog,
});

const $showDialog = createStore(false).reset(resetUserRoleDialog);

/* *
 * Переход к оформлению заказа
 */
const transitionToOrdering = createEvent();

const closeModalsFx = createEffect(async () => {
	const modals = [
		ModalGate.state.getState(),
		CartModalGate.state.getState(),
		detailedCruiseCabinModel.ModalGate.state.getState(),
		detailedCruiseCabinModel.SuccessModalGate.state.getState(),
	];

	modals.forEach((state) => {
		if (typeof state?.close === 'function') {
			state.close();
		}
	});
});

sample({
	clock: transitionToOrdering,
	fn: () => '/order',
	target: [
		closeModalsFx,
		debouncedResetUserRoleDialog,
		routerModel.pushAsA,
		// routerModel.push,
		routerModel.debouncedResetAuthRedirectTarget,
	],
});

/* *
 * Вызов диалога для гостя
 */
const callUserRoleDialog = createEvent();

sample({
	clock: callUserRoleDialog,
	target: openModal,
});

sample({
	clock: callUserRoleDialog,
	fn: () => true,
	target: $showDialog,
});

/* *
 * Переход в модуль авторизации
 */
const goToAuth = createEvent();

sample({
	clock: goToAuth,
	fn: () => '/order',
	target: routerModel.setAuthRedirectTarget,
});

sample({
	clock: goToAuth,
	fn: () => AccountRoutes.LoginPage,
	target: [closeModalsFx, debouncedResetUserRoleDialog, routerModel.push],
});

/* *
 * Событие перехода к оформлению заказа
 * с проверкой пользователя
 */
const goToOrdering = createEvent();

condition({
	source: goToOrdering,
	if: $isGuest,
	then: callUserRoleDialog,
	else: transitionToOrdering,
});

export const model = {
	ModalGate,
	CartModalGate,
	goToAuth,
	goToOrdering,
	transitionToOrdering,
	$showDialog,
	resetUserRoleDialog,
	$isGuest,
};
