/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react';
import classnames from 'classnames';
// import { ShipModel } from 'shared/lib/types/ships';
// import { capitalizeFirstLetter } from 'shared/lib/utils';
// import { SizeType, Typo, TypoDesignToken } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { ServicesModal } from './services-modal';
import styles from './styles.module.scss';
import { ShipModel } from 'config/types/ships';
import { SizeType } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { TypoDesignToken, Typo } from 'layout/components/typo/ui';
import { capitalizeFirstLetter } from 'utils/string';

interface ServicesProps {
	services: ShipModel.Service[];
	name?: ShipModel.ShipInfo['name'];
	servicesListTitle?: string;
	servicesListTitleColor?: 'black';
	servicesListTitleDesign?: TypoDesignToken;
	servicesModalSubtitle?: string;
	visibleServicesCount?: number;
	buttonText?: string;
	buttonIcon?: string | false;
	buttonSize?: SizeType;
	className?: string;
	servicesListClassName?: string;
	servicesItemClassName?: string;
	servicesModalListClassName?: string;
	serviceItemTitleDesing?: TypoDesignToken;
	withModal?: boolean;
	withFilterBenefits?: boolean;
	onClickServicesModalButton?: () => void;
}
export const Services = ({
	name,
	services,
	servicesListTitle,
	servicesListTitleColor,
	servicesListTitleDesign = 'headline-xs',
	servicesModalSubtitle = 'Все услуги:',
	visibleServicesCount = 5,
	buttonText,
	buttonIcon,
	buttonSize,
	className,
	servicesListClassName,
	servicesItemClassName,
	serviceItemTitleDesing = 'text-s',
	servicesModalListClassName,
	withModal = true,
	withFilterBenefits,
	onClickServicesModalButton,
}: ServicesProps) => {
	const servicesList = useMemo(() => {
		if (withFilterBenefits) {
			return services.filter(({ title, benefit }) => Boolean(title) && !benefit);
		}

		return services.filter(({ title }) => Boolean(title));
	}, [services, withFilterBenefits]);

	const visibleServices = useMemo(() => {
		if (withModal) {
			return services.slice(0, visibleServicesCount);
		}

		return services;
	}, [withModal, services, visibleServicesCount]);

	if (servicesList.length === 0) {
		return null;
	}

	return (
		<div className={classnames(styles.servicesWrap, className)}>
			{servicesListTitle && (
				<Typo
					className={classnames(
						styles.services_title,
						servicesListTitleColor && styles[`services_title_${servicesListTitleColor}`],
					)}
					design={servicesListTitleDesign}
				>
					{servicesListTitle}
				</Typo>
			)}
			<div className={classnames(styles.services, servicesListClassName)}>
				{visibleServices.map(({ title, icon }, index) => (
					<div key={title + index} className={classnames(styles.service, servicesItemClassName)}>
						{icon ? (
							<Typo className={styles.service__icon} raw={icon} />
						) : (
							<Icon id="no-result" width={20} height={20} />
						)}
						<Typo design={serviceItemTitleDesing}>{capitalizeFirstLetter(title)}</Typo>
					</div>
				))}
			</div>
			{servicesList.length > visibleServicesCount && withModal && (
				<ServicesModal
					title={name}
					subtitle={servicesModalSubtitle}
					services={servicesList}
					buttonText={buttonText}
					buttonIcon={buttonIcon}
					buttonSize={buttonSize}
					listClassName={servicesModalListClassName}
					onClick={onClickServicesModalButton}
				/>
			)}
		</div>
	);
};
