import { createFileRoute } from '@tanstack/react-router'
import { AccountLayout } from 'layout/layouts/account';
import { Main } from 'layout/layouts/main';
import { PersonalData } from 'layout/layouts/widgets/personal-data';
import { useEffect } from 'react';
import { findAndChangeInnerHtml } from 'utils/page/pageUtil';
import accountStyles from './../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute('/account/personal/')({
  component: () => <PersonalDataPage />,
})

const PersonalDataPage = () => {
  useEffect(() => {
    const pageH1 = "Личные данные"
    findAndChangeInnerHtml("h1", `<h1 id='h1'>${pageH1}</h1>`)
  }, [])
  return (
    <Main>
      <AccountLayout>
        <section className={accountStyles.marginTopSmMd}>
          <PersonalData />
        </section>
      </AccountLayout>
    </Main>
  )
}