import React, {
	Dispatch,
	FC,
	forwardRef,
	MouseEventHandler,
	PropsWithChildren,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from 'react';
import classnames from 'classnames';
// import { NAV_SCROLL_OFFSET, NAV_SCROLL_OFFSET_MOB } from 'shared/config';
// import { useTopFixedPanel } from 'shared/lib/hooks';
// import { AnchorProps, DefaultStyledProps } from 'shared/lib/types';
// import { setAnalytics, scrollToElement } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { DesktopView, MobileView, Portal } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { TabsNav, TabButton } from 'shared/ui/molecules';
import styles from './styles.module.scss';
import { NAV_SCROLL_OFFSET_MOB, NAV_SCROLL_OFFSET } from 'config/commonConstants';
import { DefaultStyledProps } from 'config/commonTypes';
import { AnchorProps } from 'config/types/core';
import { Icon } from 'layout/components/icons/icon';
import { Portal } from 'layout/components/portal';
import { TabsNav, TabButton } from 'layout/components/switchers/tabs';
import { MobileView, DesktopView } from 'layout/layouts/device-view';
import { useViewport } from 'layout/viewport';
import { scrollToElement } from 'utils/scroll';
import { useTopFixedPanel } from 'utils/hooks/use-top-fixed-panel';

export interface AnchorsNavProps {
	items?: AnchorProps[];
	withDesktopView?: boolean;
	offset?: number;
	desktopOffset?: number;
	tabsClassName?: string;
}

export const AnchorsNav: FC<AnchorsNavProps> = ({
	withDesktopView = true,
	items,
	offset,
	desktopOffset,
	tabsClassName,
}) => {
	const [active, setActive] = useState<Nullable<number>>(0);
	const stickyPanelRef = useRef<HTMLElement>(null);

	const { showFixedPanel } = useTopFixedPanel(stickyPanelRef, {
		onScroll: (showPanel) =>
			document.documentElement.style.setProperty('--sticky-nav-gap', `${showPanel ? 50 : 0}px`),
	});

	return (
		<>
			<MobileView>
				<nav className={classnames(styles.fixedPanel, tabsClassName)}>
					{items?.map((tab, index) => (
						<Anchor
							key={tab.anchor}
							index={index}
							isLast={items.length - 1 === index}
							active={active}
							setActive={setActive}
							offset={offset}
							{...tab}
						>
							{tab.nameMobile}
						</Anchor>
					))}
				</nav>
			</MobileView>
			{withDesktopView && (
				<DesktopView>
					<AnchorsNavSticky
						ref={stickyPanelRef}
						items={items}
						active={active}
						setActive={setActive}
						desktopOffset={desktopOffset}
						tabsClassName={tabsClassName}
					/>
					<Portal>
						<div
							className={classnames(styles.topFixedPanel, { [styles.isActive]: showFixedPanel })}
						>
							<AnchorsNavSticky
								items={items}
								active={active}
								setActive={setActive}
								desktopOffset={desktopOffset}
								tabsClassName={tabsClassName}
							/>
						</div>
					</Portal>
				</DesktopView>
			)}
		</>
	);
};

export interface AnchorsNavStickyProps
	extends DefaultStyledProps,
		Pick<AnchorsNavProps, 'items' | 'offset' | 'desktopOffset'> {
	active: Nullable<number>;
	setActive: Dispatch<SetStateAction<Nullable<number>>>;
	tabsClassName?: string;
}

export const AnchorsNavSticky = forwardRef<HTMLElement, AnchorsNavStickyProps>(
	({ items, offset, desktopOffset, active, setActive, className, tabsClassName }, ref) => (
		<TabsNav
			ref={ref}
			className={classnames(className, styles.stickyPanel)}
			classNameNav={classnames(styles.stickyPanel__nav, tabsClassName)}
			theme="dashed"
			active={active}
		>
			{items?.map((tab, index) => (
				<Anchor
					key={tab.anchor}
					index={index}
					isLast={items.length - 1 === index}
					active={active}
					setActive={setActive}
					offset={offset}
					desktopOffset={desktopOffset}
					{...tab}
				>
					{tab.name}
				</Anchor>
			))}
		</TabsNav>
	),
);

export interface CruiseNavAnchorProps extends AnchorProps, PropsWithChildren {
	active: Nullable<number>;
	setActive: Dispatch<SetStateAction<Nullable<number>>>;
	index: number;
	isLast?: boolean;
	offset?: number;
	desktopOffset?: number;
}

export const Anchor: FC<CruiseNavAnchorProps> = ({
	anchor,
	iconId,
	active,
	setActive,
	index,
	isLast,
	children,
	offset,
	desktopOffset,
	analyticsTarget,
}) => {
	const { vh, isMob } = useViewport();

	const handleClick: MouseEventHandler = (e) => {
		e.preventDefault();
		const section = document?.querySelector('.main')?.querySelector<HTMLElement>(`#${anchor}`);

		if (section) {
			scrollToElement(
				section,
				isMob ? NAV_SCROLL_OFFSET_MOB + (offset ?? 0) : NAV_SCROLL_OFFSET + (desktopOffset ?? 0),
			);
		}

		if (analyticsTarget) {
			// setAnalytics(analyticsTarget);
		}
	};

	useEffect(() => {
		const section = document?.querySelector('.main')?.querySelector<HTMLElement>(`#${anchor}`);

		if (section) {
			const handleScroll = () => {
				const { top, bottom } = section.getBoundingClientRect();
				const hvh = vh / 2;
				const inView = top - hvh / 2 < 0 || (bottom < hvh && bottom - hvh > 0);
				const reachDocumentEnd = window.innerHeight + window.scrollY >= document.body.scrollHeight;

				if (inView) {
					setActive(index);

					return;
				}

				if (isLast && reachDocumentEnd) {
					setActive(index);

					return;
				}

				if (isLast && bottom < 0) {
					setActive(null);
				}
			};

			window.addEventListener('scroll', handleScroll, { passive: true });

			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		}
	}, [anchor, setActive, index, isLast, vh]);

	return (
		<TabButton
			id={anchor}
			isActive={active === index}
			href={`#${anchor}`}
			className={styles.btn}
			contextClassName={styles.btnContext}
			size="large"
			theme="dashed"
			onClick={handleClick}
		>
			<Icon id={iconId} />
			<span>{children}</span>
		</TabButton>
	);
};
