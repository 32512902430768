import { ChangePhoneModal } from '../modal';
import { ModalFooter } from '../modal-footer';
import { ChangePhoneTrigger } from '../modal-trigger';

interface ChangePhoneProps {
	addMode?: boolean;
	additionalPhone?: boolean;
}

export const ChangePhone = ({ addMode = false, additionalPhone = false }: ChangePhoneProps) => (
	<ChangePhoneTrigger addMode={addMode} footer={<ModalFooter additionalPhone={additionalPhone} />}>
		{() => <ChangePhoneModal additionalPhone={additionalPhone} addMode={addMode} />}
	</ChangePhoneTrigger>
);
