import classNames from 'classnames';
// import { ProfileModel } from 'shared/lib/types';
// import { Typo } from 'shared/ui/atoms';
import { BonusSupportModal } from '../bonus-support-modal';
import styles from './styles.module.scss';
import { ProfileModel } from 'config/types/personal';
import { Typo } from 'layout/components/typo/ui';

interface BonusSupportProps {
	profile: ProfileModel;
	cardNumber?: string;
	className?: string;
}

export const BonusSupport = ({ cardNumber, profile, className }: BonusSupportProps) => (
	<div className={classNames(styles.bonusSupport, className)}>
		<div className={styles.leftColumn}>
			<Typo design="headline-m">Есть вопросы?</Typo>
			<Typo design="text-m">Отправьте свой запрос в службу поддержки</Typo>
		</div>

		<BonusSupportModal cardNumber={cardNumber} profile={profile} />
	</div>
);
