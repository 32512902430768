import React, { useState } from 'react';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
// import { Request } from 'shared/lib/types/account/request';
// import { replaceBreakLinesToTag } from 'shared/lib/utils';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { getComment } from '../lib';
import styles from './styles.module.scss';
import { Request } from 'store/auth/types/account/request';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { replaceBreakLinesToTag } from 'utils/string';
import { getComment } from '../lib';

export const Comment = ({ comment }: Pick<Request, 'comment'>) => {
	const [isExpanded, setExpanded] = useState(false);

	if (!comment) {
		return null;
	}

	return (
		<div className={styles.requestDetails_commentWrap}>
			<Typo design="headline-xs" className={styles.requestDetails_commentTitle}>
				Комментарий:
			</Typo>
			<div className={styles.requestDetails_commentContent}>
				{comment?.length > 70 ? (
					<AnimateHeight height={isExpanded ? 'auto' : 36} duration={400}>
						<Typo
							design="button-m-s"
							as="span"
							raw={replaceBreakLinesToTag(getComment(comment).visibleComment)}
							className={styles.commentChunk}
						/>
						<Typo
							design="button-m-s"
							as="span"
							raw={replaceBreakLinesToTag(getComment(comment).hiddenComment)}
							className={classnames(styles.commentChunk, !isExpanded && styles.fadeComment)}
						/>
					</AnimateHeight>
				) : (
					comment
				)}
			</div>

			{comment?.length > 70 ? (
				<Button
					btnType="text"
					contextClassName={styles.toggleButtonCtx}
					onClick={() => setExpanded((prev) => !prev)}
				>
					<span>{isExpanded ? 'Свернуть' : 'Читать полностью'}</span>
					<Icon
						id="chevron-down"
						width={12}
						height={12}
						className={classnames(styles.toggleButton_icon, {
							[styles.toggleButton_iconUp]: isExpanded,
						})}
					/>
				</Button>
			) : null}
		</div>
	);
};
