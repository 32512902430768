// import { GetCruisesTransferPointsRequest } from 'shared/api';
// import { calendarDateToISO } from 'shared/lib/utils';
import { GetCruisesTransferPointsRequest } from 'store/account/api/handbooks';
import { calendarDateToISO } from 'utils/date';
import { BonusRecoveryFormValues } from './types';

export const getCruisesParams = ({
	ship,
	dates,
}: Pick<BonusRecoveryFormValues, 'ship' | 'dates'>) => {
	const params: Partial<GetCruisesTransferPointsRequest> = {};

	const dateStart = calendarDateToISO(dates?.start, false);
	const dateEnd = calendarDateToISO(dates?.end, false);

	if (ship) {
		params.ship = ship;
	}

	if (dateStart) {
		params.dateStart = dateStart;
	}

	if (dateEnd) {
		params.dateEnd = dateEnd;
	}

	return params;
};
