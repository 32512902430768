// import { RequestCruise } from 'shared/lib/types/account/request-cruise';
// import { formatDate } from 'shared/lib/utils';

import { RequestCruise } from "store/auth/types/account/request-cruise";
import { formatDate } from "utils/date";

interface GetBonusCardTitleProps {
	comment?: string;
	isWaitingBonuses?: boolean;
	cruise?: RequestCruise;
}

export const getBonusCardTitle = ({
	comment,
	isWaitingBonuses,
	cruise,
}: GetBonusCardTitleProps) => {
	if (!isWaitingBonuses) {
		return comment;
	}

	const startDate = cruise?.startDate && formatDate(cruise?.startDate);
	const endDate = cruise?.endDate && formatDate(cruise?.endDate);
	const cruisePeriod = startDate ? ` (${startDate}${endDate ? ` – ${endDate}` : ''})` : '';

	return (
		[cruise?.ship?.type, cruise?.ship?.name, cruise?.name].filter(Boolean).join(', ') + cruisePeriod
	);
};
