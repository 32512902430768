export const PHONE = '8 (800) 707-44-18';
export const PHONE_FOR_HREF = '+78007074418';

export const PAYMENT_METHODS_INFO = '/info/contact_pay';
export const TOUR_OPERATORS_FINANCIAL_SECURITY_INFO =
	'/info/dannye-po-finansovomu-obespeceniiu-turoperatorov';

export const PAYMENT_OPTIONS = [
	'Банковской картой',
	'В отделении банка',
	'В личном кабинете сайта или мобильного приложения «Сбербанк онлайн»',
	'По QR-коду',
];
