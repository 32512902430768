import { EffectState } from 'patronum/status';
// import { useEvent } from 'effector-react/ssr';
// import { Button } from 'shared/ui/atoms';
import { requestCancellationModel } from '../model';
import styles from './styles.module.scss';
import { useEvent } from 'effector-react';
import { Button } from 'layout/components/buttons/button';

interface ModalFooterProps {
	requestId?: number;
	status: EffectState;
}

export const ModalFooter = ({ requestId, status }: ModalFooterProps) => {
	const cancelRequest = useEvent(requestCancellationModel.cancelRequest);
	const closeModal = useEvent(requestCancellationModel.closeCancelModal);

	return (
		<div className={styles.modalFooter}>
			{status !== 'done' && (
				<>
					<Button
						btnType="outline"
						size="large"
						disabled={status === 'pending'}
						onClick={() => requestId && cancelRequest(requestId)}
					>
						Да, аннулировать заявку
					</Button>
					<Button
						size="large"
						disabled={status === 'pending'}
						className={styles.canselButton}
						onClick={closeModal}
					>
						Отменить
					</Button>
				</>
			)}
			{status === 'done' && (
				<Button size="large" className={styles.closeButton} onClick={closeModal}>
					Закрыть
				</Button>
			)}
		</div>
	);
};
