// import { AccountRequestsStatuses } from 'shared/config';
// import { AnchorProps } from 'shared/lib/types';

import { AnchorProps } from "config/types/core";
import { AccountRequestsStatuses } from "store/auth/constants";

// export {
// 	STATUSES_FOR_ADD_EXCURSIONS,
// 	STATUSES_FOR_NEW_EXCURSIONS_REQUEST,
// } from 'features/request-excursions/request-excursions-add/lib';

export enum DetailedRequestAnchors {
	AboutCruise = 'about-cruise',
	AdditionalExcursions = 'additional-excursions',
	TouristData = 'tourist-data',
	RequestDetails = 'request-details',
}

export const detailedRequestAnchors: AnchorProps[] = [
	{
		iconId: 'cabin-type',
		anchor: DetailedRequestAnchors.AboutCruise,
		name: 'О круизе',
		nameMobile: 'О круизе',
	},
	{
		iconId: 'guide-board',
		anchor: DetailedRequestAnchors.AdditionalExcursions,
		name: 'Доп. экскурсии',
		nameMobile: 'Доп. экскурсии',
	},
	{
		iconId: 'user',
		anchor: DetailedRequestAnchors.TouristData,
		name: 'Данные туристов',
		nameMobile: 'Данные туристов',
	},
	{
		iconId: 'rouble',
		anchor: DetailedRequestAnchors.RequestDetails,
		name: 'Детали заявки',
		nameMobile: 'Детали заявки',
	},
];

export const STATUSES_FOR_CANCELLATION = [
	AccountRequestsStatuses.AwaitingConfirmation,
	AccountRequestsStatuses.WaitingList,
	AccountRequestsStatuses.Confirmed,
	AccountRequestsStatuses.Prepayment,
	AccountRequestsStatuses.Paid,
	AccountRequestsStatuses.AwaitingPayment,
	AccountRequestsStatuses.SoldByAgency,
	AccountRequestsStatuses.ForFree,
	AccountRequestsStatuses.InProcessing,
];

export const STATUSES_TOTAL_PAYABLE = [
	AccountRequestsStatuses.Confirmed,
	AccountRequestsStatuses.Prepayment,
	AccountRequestsStatuses.SoldByAgency,
	AccountRequestsStatuses.AwaitingPayment,
];

export const PAYED_STATUSES = [AccountRequestsStatuses.Paid, AccountRequestsStatuses.ForFree];

export const STATUSES_FOR_BILL_REQUEST = [
	AccountRequestsStatuses.Confirmed,
	AccountRequestsStatuses.SoldByAgency,
	AccountRequestsStatuses.Prepayment,
	AccountRequestsStatuses.AwaitingPayment,
];

export const STATUSES_FOR_EDIT_PASSENGER = [AccountRequestsStatuses.AwaitingConfirmation];

export const STATUSES_FOR_EDIT_PASSENGER_WITH_AUTOCOMPLETE = [
	AccountRequestsStatuses.WaitingList,
	AccountRequestsStatuses.Confirmed,
	AccountRequestsStatuses.AwaitingConfirmation,
];

export const STATUSES_FOR_ADDITIONAL_REQUEST_PAYMENT = [
	AccountRequestsStatuses.Confirmed,
	AccountRequestsStatuses.Prepayment,
	AccountRequestsStatuses.SoldByAgency,
	AccountRequestsStatuses.AwaitingPayment,
];

export const UNAVAILABLE_CORPORATION_ID = [145];
