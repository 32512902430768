import React, { FC } from 'react';
// import { message } from 'shared/lib/message';
// import { Button, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { message } from 'config/constants/message';
import { Typo } from 'layout/components/typo/ui';
import { Button } from 'layout/components/buttons/button';

interface CallbackFormSuccessProps {
	close?: () => void;
}

export const CallbackFormSuccess: FC<CallbackFormSuccessProps> = ({ close }) => (
	<div className={styles.callbackFormSuccess}>
		<Typo design="headline-xl" raw={message.callback.successTitle} />
		<Typo
			design="text-m"
			raw={message.callback.successText}
			className={styles.callbackFormSuccess__text}
		/>
		<div className={styles.callbackFormSuccess__footer}>
			<Button size="large" btnType="primary" onClick={close}>
				Закрыть
			</Button>
		</div>
	</div>
);
