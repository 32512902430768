import classnames from 'classnames';
import { Item } from 'react-stately';
import { useUnit } from 'effector-react';
import { useError, useForm } from 'effector-react-form';
import { ShipStar } from 'entities/ships';
// import { BonusRecoveryRequestSellerEnum } from 'shared/lib/types/account/bonus-recovery-request';
// import { formatDate, getCalendarDate } from 'shared/lib/utils';
// import { Input } from 'shared/ui/atoms';
// import { Select, SelectDates } from 'shared/ui/molecules';
// import { FieldUncontrolled, Form } from 'shared/ui/organisms/form';
// import { getValidation } from 'shared/ui/organisms/form/lib/validation';
import { bonusSupportScheme } from '../../lib';
import { model } from '../../model';
import styles from './styles.module.scss';
import { FieldUncontrolled } from 'layout/components/inputs/form/field-uncontrolled';
import { Form } from 'layout/components/inputs/form/form';
import { getValidation } from 'layout/components/inputs/form/lib/validation';
import { Input } from 'layout/components/inputs/input';
import { Select } from 'layout/components/inputs/selects/select';
import { BonusRecoveryRequestSellerEnum } from 'store/auth/types/account/bonus-recovery-request';
import { getCalendarDate, formatDate } from 'utils/date';
import { SelectDates } from 'layout/components/inputs/select-dates';

export const BonusRecoveryForm = () => {
	const [listOfShips, cruises] = useUnit([model.$listOfShips, model.$cruises]);

	const { form } = model;
	const { controller, handleSubmit, setValue } = useForm({ form });
	const { error } = useError({ form, name: 'formError' });

	const values = useUnit(form.$values);
	const displaySellerName = values.seller === 'other';

	return (
		<Form
			error={error}
			isShowError={Boolean(error)}
			handleSubmit={handleSubmit}
			formClassName={styles.form}
			errorClassName={styles.errors}
		>
			<FieldUncontrolled
				controller={controller({
					name: 'seller',
					validate: getValidation(bonusSupportScheme.seller),
				})}
			>
				<Select
					theme="divider"
					isRequired
					size="lg"
					className={styles.selector}
					label="Где приобреталась путевка"
					selectionMode="single"
					defaultSelectedKeys={new Set([BonusRecoveryRequestSellerEnum.infoflot])}
					onSelectionChange={(keys) => {
						const keysArray = keys instanceof Set ? ([...keys] as string[]) : [keys];
						const value = keysArray.length ? keysArray[0] : '';

						setValue({
							field: 'seller',
							value,
						});
					}}
				>
					<Item
						key={BonusRecoveryRequestSellerEnum.infoflot}
						aria-label={BonusRecoveryRequestSellerEnum.infoflot}
					>
						Созвездие
					</Item>
					<Item
						key={BonusRecoveryRequestSellerEnum.other}
						aria-label={BonusRecoveryRequestSellerEnum.other}
					>
						SH Другое агентство
					</Item>
				</Select>
			</FieldUncontrolled>

			<FieldUncontrolled
				controller={controller({
					name: 'email',
					validate: getValidation(bonusSupportScheme.email),
				})}
			>
				<Input
					isRequired
					classNameWrap={styles.fullName}
					placeholder="E-mail"
					secondPlaceholder="Введите e-mail"
					withLabel
					size="lg"
				/>
			</FieldUncontrolled>

			{displaySellerName && (
				<FieldUncontrolled
					controller={controller({
						name: 'sellerName',
						validate: getValidation(bonusSupportScheme.sellerName),
					})}
				>
					<Input
						isRequired
						classNameWrap={styles.fullName}
						placeholder="Укажите агентство"
						withLabel
						size="lg"
					/>
				</FieldUncontrolled>
			)}

			<FieldUncontrolled
				controller={controller({
					name: 'dates',
					validate: getValidation(bonusSupportScheme.dates),
				})}
				withoutOnBlur
			>
				<SelectDates
					isRequired
					theme="divider"
					placement="bottom right"
					className={styles.selector}
					selectMode="range"
					moveIcon
					label="Период круиза"
					calendar={{
						dateSelector: {
							minYear: 2012,
							maxYear: 'today',
						},
					}}
					minValue={getCalendarDate(2012, 11, 22)}
				/>
			</FieldUncontrolled>

			<FieldUncontrolled
				controller={controller({
					name: 'ship',
					validate: getValidation(bonusSupportScheme.ship),
				})}
			>
				<Select
					theme="divider"
					withSearch
					isRequired
					size="lg"
					className={styles.selector}
					label="Выберите теплоход"
					selectionMode="single"
					onSelectionChange={(keys) => {
						const keysArray = keys instanceof Set ? ([...keys] as string[]) : [keys];
						const value = keysArray.length ? keysArray[0] : '';

						setValue({
							field: 'ship',
							value,
						});
					}}
				>
					{listOfShips.map(({ id, name, stars }) => (
						<Item key={id} aria-label={name} textValue={name}>
							<div className={styles.select_option}>
								<span>{name}</span>
								{stars && (
									<div data-hidden="true">
										<ShipStar
											stars={stars}
											className={classnames(styles.optionBadge, !!stars.icon && styles.optionStar)}
										/>
									</div>
								)}
							</div>
						</Item>
					))}
				</Select>
			</FieldUncontrolled>

			<FieldUncontrolled
				controller={controller({
					name: 'cruise',
					validate: getValidation(bonusSupportScheme.cruise),
				})}
			>
				<Select
					theme="divider"
					withSearch
					isRequired
					size="lg"
					className={styles.selector}
					label="Выберите круиз"
					selectionMode="single"
					isDisabled={!cruises.length}
					onSelectionChange={(keys) => {
						const keysArray = keys instanceof Set ? ([...keys] as string[]) : [keys];
						const value = keysArray.length ? keysArray[0] : '';

						setValue({
							field: 'cruise',
							value,
						});
					}}
				>
					{cruises.map(({ id, route, startDate, endDate }) => (
						<Item key={id} aria-label={route}>
							{`${route} ${formatDate(startDate)}-${formatDate(endDate)}`}
						</Item>
					))}
				</Select>
			</FieldUncontrolled>

			<FieldUncontrolled
				controller={controller({
					name: 'cabin',
					validate: getValidation(bonusSupportScheme.cabin),
				})}
			>
				<Input
					isRequired
					classNameWrap={styles.fullName}
					placeholder="Номер каюты"
					secondPlaceholder="0"
					withLabel
					size="lg"
					maxLength={10}
				/>
			</FieldUncontrolled>
		</Form>
	);
};
