/* eslint-disable import/no-extraneous-dependencies */
import React, { useRef, useState } from 'react';
import { createCalendar } from '@internationalized/date';
import { DateValue } from '@react-types/datepicker';
import classnames from 'classnames';
import { AriaRangeCalendarProps, useButton, useRangeCalendar } from 'react-aria';
import { useRangeCalendarState } from 'react-stately';
// import { capitalizeFirstLetter } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { Scroller, ScrollerCallbackFn, CalendarWeekHead } from 'shared/ui/molecules';
import { scrollIntoView } from '../lib';
import { CalendarGrid } from './calendar-grid';
import { CalendarMonthList } from './calendar-month-list';
import { MonthDropdown } from './month-dropdown';
import { RangeTips } from './range-tips';
import { YearDropdown, YearDropdownProps } from './year-dropdown';
import style from './style.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { ScrollerCallbackFn, Scroller } from 'layout/components/special/scroller';
import { Typo } from 'layout/components/typo/ui';
import { capitalizeFirstLetter } from 'utils/string';
import { CalendarWeekHead } from './calendar-week-head';

export interface RangeCalendarProps extends AriaRangeCalendarProps<DateValue> {
	dateSelector?: Omit<YearDropdownProps, 'state'>;
	withTips?: boolean;
	onScroll?: ScrollerCallbackFn;
	searchCalendar?: boolean;
	isQualityControlComponent?: boolean;
	months?: number;
}

export const RangeCalendar = ({
	dateSelector,
	searchCalendar,
	isQualityControlComponent,
	months = 1,
	onScroll,
	...props
}: RangeCalendarProps) => {
	const { withTips, minValue } = props;

	const [buttonTopPosition, setButtonTopPosition] = useState(0);

	const ref = useRef(null);
	const listRef = useRef<HTMLDivElement>(null);
	const monthsRef = useRef<HTMLDivElement>(null);
	const refPrevBtn = useRef(null);
	const refNextBtn = useRef(null);
	const state = useRangeCalendarState({
		...props,
		locale: 'ru-RU',
		createCalendar,
		visibleDuration: { months },
	});
	const onMonthClick = (index: number) => {
		const monthDiv = listRef?.current?.querySelector(`.tabId-${index}`);

		if (monthDiv) {
			scrollIntoView(monthDiv);
		}
	};

	const { title, calendarProps, prevButtonProps, nextButtonProps } = useRangeCalendar(
		props,
		state,
		ref,
	);

	const { buttonProps: buttonPropsPrev } = useButton(prevButtonProps, refPrevBtn);
	const { buttonProps: buttonPropsNext } = useButton(nextButtonProps, refNextBtn);

	return (
		<div
			ref={ref}
			className={classnames(style.calendar, searchCalendar && style.calendar_searchPanel)}
			{...calendarProps}
		>
			{!searchCalendar && (
				<div className={style.header}>
					<button
						{...{
							...buttonPropsPrev,
							onClick: (e) => {
								if (nextButtonProps) {
									// @ts-ignore
									prevButtonProps.onPress?.(e);
								}
							},
							onPointerDown: () => {},
						}}
						className={style.arrow}
					>
						<Icon id="chevron-left" width={20} height={20} />
					</button>
					{!dateSelector && (
						<Typo as="h2" design="headline-xs" className={style.caption}>
							{capitalizeFirstLetter(title.replace(/(\s\d{4})/gm, ',$1').replace('г.', ''))}
						</Typo>
					)}
					{dateSelector && (
						<>
							<MonthDropdown state={state} minYear={dateSelector?.minYear} minValue={minValue ?? undefined} />
							<YearDropdown {...dateSelector} state={state} />
						</>
					)}
					<button
						{...{
							...buttonPropsNext,
							onClick: (e) => {
								if (nextButtonProps) {
									// @ts-ignore
									nextButtonProps.onPress?.(e);
								}
							},
							onPointerDown: () => {},
						}}
						className={style.arrow}
					>
						<Icon id="chevron-right" width={20} height={20} />
					</button>
				</div>
			)}
			{searchCalendar ? (
				<div className={style.calendarBlock}>
					<div className={style.calendarBlock__buttons}>
						<Scroller className={classnames(style.desktopCalendarScrollabel, style.calendarHeight)}>
							<div
								className={style.calendarBlock__buttonsBackGround}
								style={{ top: `${buttonTopPosition}px` }}
							/>
							<div ref={monthsRef}>
								{[...new Array(months).keys()].map((monthIndex) => (
									<CalendarMonthList
										state={state}
										onMonthClick={onMonthClick}
										offset={{ months: monthIndex }}
									/>
								))}
							</div>
						</Scroller>
					</div>
					<div className={style.calendarBlock__list}>
						{withTips && <RangeTips state={state} />}
						<CalendarWeekHead className={style.weekHeadCalendar} />
						<Scroller
							className={classnames(style.desktopCalendarScrollabel, style.calendarlistHeight)}
							onScroll={({ y }) => {
								const listContainer = listRef?.current;
								const monthsContainer = monthsRef?.current;
								const listHeight = listContainer?.getBoundingClientRect().height;
								const monthsHeight = monthsContainer?.getBoundingClientRect().height;
								if (listHeight && monthsHeight) {
									const listPercent = (y / (listHeight - 293)) * 100;
									const monthsTop = Math.ceil(((monthsHeight - 64) * listPercent) / 100);
									const step = Math.round(monthsTop / 32);
									const top = Math.round(32 * step);
									setButtonTopPosition(top);
								}
							}}
						>
							<div ref={listRef} className={style.monthsList}>
								{[...new Array(months).keys()].map((monthIndex) => (
									<CalendarGrid
										key={monthIndex}
										className={style.monthsList__item}
										state={state}
										displayMonthTitle
										hideWeekDays
										offset={{ months: monthIndex }}
									/>
								))}
							</div>
						</Scroller>
					</div>
				</div>
			) : (
				<>
					{withTips && (
						<RangeTips isQualityControlComponent={isQualityControlComponent} state={state} />
					)}
					<CalendarGrid state={state} />
				</>
			)}
		</div>
	);
};
