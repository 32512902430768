import React, { FC } from 'react';
// import { Request } from 'shared/lib/types/account/request';
// import { formatDate } from 'shared/lib/utils';
// import { Price, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { STATUSES_TOTAL_PAYABLE } from '../../../lib';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { Price } from 'layout/components/view/price';
import { formatDate } from 'utils/date';
import { Request } from 'store/auth/types/account/request';

interface FinalPaymentInfoProps
	extends Pick<
		Request,
		| 'status'
		| 'expirationDate'
		| 'prepaymentDate'
		| 'amount'
		| 'additional'
		| 'payed'
		| 'prepayment'
	> {}

export const FinalPaymentInfo: FC<FinalPaymentInfoProps> = ({
	status,
	expirationDate,
	prepaymentDate,
	amount,
	payed,
	prepayment,
}) => {
	const totalPaymentCondition = Boolean(status && STATUSES_TOTAL_PAYABLE.includes(status));
	const totalPayable = (amount ?? 0) - (payed ?? 0);

	return (
		<div className={styles.requestDetails_finalPaymentWrap}>
			{amount ? (
				<div className={styles.requestDetails_finalPaymentInfo}>
					<Typo design="headline-s">Итого к оплате</Typo>
					<Price design="headline-m" as="span" bold total={totalPayable > 0 ? totalPayable : 0} />
				</div>
			) : null}

			{totalPaymentCondition ? (
				<>
					{prepaymentDate && amount !== payed && payed !== prepayment && prepayment !== 0 && (
						<div className={styles.requestDetails_finalPaymentDate}>
							<Icon id="awaiting-payment" width={14} height={14} />
							<Typo design="text-m">Срок предоплаты до</Typo>
							<Typo design="text-m">{formatDate(prepaymentDate)}</Typo>
						</div>
					)}
					{expirationDate && (
						<div className={styles.requestDetails_finalPaymentDate}>
							<Icon id="awaiting-payment" width={14} height={14} />
							<Typo design="text-m">Срок оплаты до</Typo>
							<Typo design="text-m">{formatDate(expirationDate)}</Typo>
						</div>
					)}
				</>
			) : null}
		</div>
	);
};
