import React, { FC, useMemo } from 'react';
import { CruiseDuration } from 'entities/cruises/ui';
// import { Request } from 'shared/lib/types/account/request';
// import { formatDate } from 'shared/lib/utils';
// import { Alert, Typo } from 'shared/ui/atoms';
// import { ShipInfo } from 'shared/ui/organisms';
import { getCruiseInfo } from '../lib';
import { PaymentInfo } from './payment-info';
import styles from './styles.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { ShipInfo } from 'layout/components/view/ship-info';
import { formatDate } from 'utils/date';
import { Request } from 'store/auth/types/account/request';

interface ModalCruiseInfoProps
	extends Pick<Request, 'cruise' | 'expirationDate' | 'amount' | 'payed' | 'cabins'> {
	sumLabel?: string;
}

export const ModalCruiseInfo: FC<ModalCruiseInfoProps> = ({
	cruise,
	expirationDate,
	amount,
	payed,
	cabins,
	sumLabel,
}) => {
	const cabinsWithNames = useMemo(() => cabins?.map((elem) => elem.name).join(', '), [cabins]);

	return (
		<div className={styles.paymentModal_cruiseInfo}>
			<div className={styles.paymentModal_shipInfo}>
				{cruise?.route && <Typo design="headline-s">{getCruiseInfo(cruise)}</Typo>}
				<ShipInfo theme="secondary" {...cruise?.ship} />
			</div>

			<CruiseDuration
				dateStart={cruise?.startDate}
				dateEnd={cruise?.endDate}
				days={cruise?.lengthDays}
				nights={cruise?.isForeign && cruise.lengthNights}
			/>

			{cabinsWithNames ? (
				<div className={styles.paymentModal_cabins}>
					<Typo design="description-features" className={styles.paymentModal_cabinsTitle}>
						Каюты
					</Typo>
					<Typo design="text-m">{cabinsWithNames}</Typo>
				</div>
			) : null}

			{expirationDate && (
				<Alert type="warning" className={styles.paymentModal_warning}>
					<Typo design="text-s">Крайний срок оплаты круиза – {formatDate(expirationDate)}</Typo>
				</Alert>
			)}

			<PaymentInfo amount={amount} payed={payed} sumLabel={sumLabel} />
		</div>
	);
};
