import { Dispatch } from 'react';
// import { DetailedCruiseCabin } from 'shared/lib/types';
// import { DesktopView, MobileView } from 'shared/ui/atoms';
// import { Scroller } from 'shared/ui/molecules';
import { ScreenType } from '../../model/model';
import { CabinInfoContent } from './cabin-info-content';
import styles from './styles.module.scss';
import { DetailedCruiseCabin } from 'config/types/cabins';
import { Scroller } from 'layout/components/special/scroller';
import { DesktopView, MobileView } from 'layout/layouts/device-view';

interface CabinInfoProps extends DetailedCruiseCabin.Cabin {
	handleScreen?: Dispatch<ScreenType>;
}

export const CabinInfo = ({ handleScreen, ...props }: CabinInfoProps) => (
	// <div>CabinInfo</div>
	<>
		<DesktopView>
			<div className={styles.scrollerWrapper}>
				<Scroller className={styles.aboutCabinScroll} showOnHover={false}>
					<CabinInfoContent {...props} />
				</Scroller>
			</div>
		</DesktopView>

		<MobileView>
			<CabinInfoContent {...props} />
		</MobileView>
	</>
);
