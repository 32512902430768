import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { getFailCode } from 'api';
import { ANALYTICS_TARGETS } from 'config/constants';
import { message } from 'config/constants/message';
import { profileModel } from 'layout/components/auth/profile';
import { personalRequests } from 'store/auth/api';
// import { profileModel } from 'entities/profile';
// import { personalRequests } from 'shared/api';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { getFailCode, setAnalytics } from 'shared/lib/utils';

interface FormValues {
	email: string;
}

const Gate = createGate<{ close?: OverlayTriggerState['close'] }>();
const changeEmail = createEvent<FormValues>();
const changeEmailFx = createEffect(personalRequests.changeEmail);

const form = createForm<FormValues>({
	initialValues: {
		email: '',
	},
	onSubmit: ({ values }) => changeEmail(values),
});

const handleAnalyticsChangeEmailFx = createEffect(() => {}
	// setAnalytics(ANALYTICS_TARGETS.account.account_email_changed),
);

sample({
	clock: changeEmail,
	fn: (data) => ({ data }),
	target: changeEmailFx,
});

sample({
	clock: changeEmailFx.failData,
	fn: (error) => {
		const code = getFailCode(error) || 'error';

		return {
			field: 'formError',
			error: message.profile.changeEmail[code] || message.profile.changeEmail.error,
		};
	},
	target: form.setOrDeleteError,
});

sample({
	source: {
		draft: profileModel.$profileDraft,
		values: form.$values,
	},
	filter: Boolean,
	clock: changeEmailFx.done,
	fn: ({ draft, values }) => {
		if (!draft) {
			return null;
		}

		return {
			...draft,
			email: values.email,
			emailVerified: false,
		};
	},
	target: profileModel.$profileDraft,
});

sample({
	source: Gate.state,
	clock: changeEmailFx.done,
	fn: ({ close }) => close,
	target: createEffect((callback: (() => void) | undefined) => {
		if (callback) {
			callback();
		}
	}),
});

/**
 * Обработка аналитики
 */
sample({
	clock: changeEmailFx.doneData,
	target: handleAnalyticsChangeEmailFx,
});

export const model = {
	form,
	Gate,
};
