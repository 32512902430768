import React from 'react';
// import { useError, useForm } from 'effector-react-form/scope';
// import { useUnit } from 'effector-react/ssr';
// import { message } from 'shared/lib/message';
// import { Request } from 'shared/lib/types/account/request';
// import { Button, Input, Radio, Typo } from 'shared/ui/atoms';
// import { RadioGroup } from 'shared/ui/molecules';
// import { Form, FormField } from 'shared/ui/organisms/form';
import {
	isCalculatePrepaymentButtonVisible,
	isInputDisabled,
	requestPaymentValidationScheme,
} from '../lib';
import { requestPaymentModel } from '../model';
import styles from './styles.module.scss';
import { message } from 'config/constants/message';
import { useUnit } from 'effector-react';
import { useForm, useError } from 'effector-react-form';
import { Button } from 'layout/components/buttons/button';
import { FormField } from 'layout/components/inputs/form/field';
import { Form } from 'layout/components/inputs/form/form';
import { Input } from 'layout/components/inputs/input';
import { Radio } from 'layout/components/inputs/radio';
import { RadioGroup } from 'layout/components/inputs/radio-group';
import { Typo } from 'layout/components/typo/ui';
import { Request } from 'store/auth/types/account/request';

export const ModalForm = ({
	prepayment,
	prepaymentDate,
	amount,
	expirationDate,
	payed,
}: Pick<Request, 'prepayment' | 'prepaymentDate' | 'amount' | 'expirationDate' | 'payed'>) => {
	const { form, $discount, $status } = requestPaymentModel;

	const { controller, handleSubmit, setValue } = useForm({ form });
	const { error, isShowError } = useError({ form, name: 'formError' });

	const [discount, status] = useUnit([$discount, $status]);

	const failedPaymentMessage = status === 'fail' ? message.requests.payment.errorCodes.AC5 : null;
	const errorText = error || failedPaymentMessage;
	const shouldShowError = isShowError || status === 'fail';

	const restAmount = (amount ?? 0) - (payed ?? 0);
	const restPrepayment = (prepayment ?? 0) - (payed ?? 0);

	return (
		<Form
			error={errorText}
			isShowError={shouldShowError}
			handleSubmit={handleSubmit}
			formClassName={styles.paymentModal_form}
			errorClassName={styles.errors}
		>
			<div className={styles.paymentModal_amountPayable}>
				<FormField
					controller={controller}
					form={form}
					name="amount"
					validation={requestPaymentValidationScheme(restPrepayment, restAmount).amount}
					onBlurValueParser={(value) => {
						const num = Number(value);

						if (!Number.isNaN(num)) {
							return String(Math.min(restAmount, Math.max(num, restPrepayment)));
						}

						return value;
					}}
				>
					<Input
						placeholder="Сумма к оплате"
						size="lg"
						disabled={isInputDisabled({ prepaymentDate, prepayment, expirationDate })}
						classNameWrap={styles.paymentModal_amountPayableInput}
						mask="9"
						maskOptions={{
							tokens: {
								9: { pattern: /^[\d.]*$/, repeated: true },
							},
						}}
						withLabel
						required
					/>
				</FormField>
				{isCalculatePrepaymentButtonVisible({
					prepaymentDate,
					prepayment,
					payed,
					expirationDate,
				}) && (
					<Button
						btnType="text"
						size="large"
						onClick={() => setValue({ field: 'amount', value: prepayment })}
					>
						Расчитать предоплату
					</Button>
				)}
			</div>
			<FormField controller={controller} form={form} name="paymentMethod">
				<RadioGroup
					customLabel={<Typo design="headline-m">Способы оплаты</Typo>}
					className={styles.paymentModal_paymentMethods}
					showCaption
					columnOptionsList
				>
					<Radio value="ac">Банковская карта</Radio>
					<div className={styles.paymentModal_paymentRadioWrap}>
						<Radio value="sbp">Система быстрых платежей (СБП)</Radio>
						{discount ? (
							<Typo design="description-features" className={styles.paymentModal_paymentRadioSale}>
								{`скидка ${discount}%`}
							</Typo>
						) : null}
					</div>
				</RadioGroup>
			</FormField>
		</Form>
	);
};
