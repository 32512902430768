import React, { memo } from 'react';
import { useUnit } from 'effector-react';
// import { profileModel } from 'entities/profile';
// import { AccountRoutes } from 'shared/config';
// import { Alert, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { Alert } from 'layout/components/view/alert';
import { Typo } from 'layout/components/typo/ui';
import { AccountRoutes } from 'store/auth/constants';
import { profileModel } from 'layout/components/auth/profile';
import { Link } from '@tanstack/react-router';

export const ProfileVerification = memo(() => {
	const profile = useUnit(profileModel.$profile);

	if (!profile || profile?.emailVerified) {
		return null;
	}

	return (
		<Alert className={styles.profile__verification} type="error" size="lg" iconId="alert">
			<Typo className={styles.profile__verificationTitle} design="headline-xs">
				Ваш E-mail не подтвержден
			</Typo>
			<Typo design="input-text-s">
				Подтвердите, пожалуйста, E-mail в разделе{' '}
				<Link to={AccountRoutes.PersonalData}>
					{/* <a>Личные данные</a> */}
					Личные данные
				</Link>
			</Typo>
		</Alert>
	);
});
