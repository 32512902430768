import { useState } from 'react';
import { AllServices } from './all-services';
import { ExcursionDetails } from './excursion-details';

export interface ModalContentProps {
	onClick: (id?: number | null) => void;
}

interface ExcursionsModalContentProps {
	modalName: 'all-services' | 'excursion-details';
	setModalName: (name: 'all-services' | 'excursion-details') => void;
	passengerId: number;
}

export const ExcursionsModalContent = ({
	modalName,
	setModalName,
	passengerId,
}: ExcursionsModalContentProps) => {
	const [excursionId, setExcursionId] = useState<number | null | undefined>(null);

	return (
		<>
			{modalName === 'all-services' && (
				<AllServices
					onClick={(id) => {
						setModalName('excursion-details');
						setExcursionId(id);
					}}
					passengerId={passengerId}
				/>
			)}
			{modalName === 'excursion-details' && (
				<ExcursionDetails onClick={() => setModalName('all-services')} id={excursionId} />
			)}
		</>
	);
};
