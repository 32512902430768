import { ProfileModel } from 'config/types/personal';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { profileModel } from 'layout/components/auth/profile';
import { trimFactory } from 'utils/form';
// import { profileModel } from 'entities/profile';
// import { ProfileModel } from 'shared/lib/types';
// import { trimFactory } from 'shared/lib/utils';

type FormValues = Required<ProfileModel>['mailingAddress'];

const defaultValues: FormValues = {
	country: 'Россия',
	city: null,
	region: null,
	zip: null,
	street: null,
	district: null,
	building: null,
	buildingNumber: null,
	office: null,
	apartment: null,
	raw: null,
};

const Gate = createGate();

const $view = createStore<'autocomplete' | 'parts'>('autocomplete');
const $addressData = profileModel.$profile.map((profile) => {
	if (profile) {
		return profile.mailingAddress;
	}

	return null;
});

const form = createForm<FormValues>({
	initialValues: defaultValues,
});

trimFactory(form, [
	'zip',
	'city',
	'region',
	'street',
	'district',
	'building',
	'buildingNumber',
	'office',
	'apartment',
]);

const $parsedRaw = createStore<Nullable<string>>(null);
const $parts = form.$values.map(({ raw, ...parts }) => ({
	...parts,
}));

const changeRaw = createEvent<string>();

// Индекс, Страна, Область, Район, Населенный пункт, Улица, дом, корпус, квартира
const partsToRawFx = createEffect(
	({
		zip,
		region,
		city,
		street,
		district,
		building,
		buildingNumber,
		office,
		country,
	}: Omit<FormValues, 'raw'>) =>
		[zip, country, region, district, city, street, building, buildingNumber, office]
			.filter(Boolean)
			.join(', '),
);

const choosePartsView = createEvent<boolean>();

/**
 * Загрузка начальных данных из профиля
 */
sample({
	clock: Gate.open,
	source: $addressData,
	filter: Boolean,
	target: form.setValues,
});

/**
 * Запись в форму при выборе из списка адресов
 */
sample({
	clock: changeRaw,
	fn: (value) => ({
		field: 'raw',
		value,
	}),
	target: form.setValue,
});

/**
 * Создание составной строки, при изменении части адреса
 */
sample({
	source: $view,
	filter: (view) => view === 'parts',
	clock: $parts,
	fn: (_, parts) => parts,
	target: partsToRawFx,
});

/**
 * Заполнение формы дефолтными данными при переключении режима ввода адреса
 */
sample({
	clock: $view,
	source: {
		view: $view,
		values: form.$values,
	},
	filter: ({ view }) => view === 'parts',
	fn: ({ values }) => {
		let shouldReturnDefaultData = true;

		// Если заполнено хоть одно поле - не возвращаем дефолтную модель
		for (const [, value] of Object.entries(values)) {
			if (value) {
				shouldReturnDefaultData = false;
				break;
			}
		}

		return shouldReturnDefaultData ? defaultValues : values;
	},
	target: form.setValues,
});

/**
 * Запись составной строки в стор
 */
sample({
	clock: partsToRawFx.doneData,
	target: $parsedRaw,
});

/**
 * Добавление составной строки в форму
 */
sample({
	source: $parsedRaw,
	filter: (raw) => raw !== null,
	fn: (value) => ({
		field: 'raw',
		value,
	}),
	target: form.setValue,
});

/**
 * Переключение вида заполнения (по частям / выбор из списка)
 */
sample({
	clock: choosePartsView,
	fn: (isPartsView) => (isPartsView ? 'parts' : 'autocomplete'),
	target: $view,
});

sample({
	clock: choosePartsView,
	filter: Boolean,
	fn: () => ({
		field: 'raw',
		value: '',
	}),
	target: form.setValue,
});

/**
 * Сохранение данных в черновик профиля
 */
sample({
	clock: form.$values,
	source: profileModel.$profileDraft,
	filter: Boolean,
	fn: (draft, values) => ({
		...draft,
		mailingAddress: { ...values },
	}),
	target: profileModel.$profileDraft,
});

export const changeAddressModel = {
	Gate,
	form,
	choosePartsView,
	$view,
	changeRaw,
};
