import { useGate, useUnit } from 'effector-react';
// import { bonusHistoryModel } from 'widgets/account/model';
// import { loadProfileModel } from 'features/load-profile';
import { bonusCardModel } from 'entities/bonus-card';
// import { BonusSupport } from 'entities/bonus-support';
// import { InviteFriend } from 'entities/invite-friend';
// import { profileModel } from 'entities/profile';
// import { SharePoints } from 'entities/share-points';
// import { RULES_PAGE } from 'shared/config';
// import { Typo, Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { bonusHistoryModel } from 'entities/account/model';
import { BonusSupport } from 'entities/bonus-support';
import { loadProfileModel } from 'layout/components/auth/load-profile';
import { profileModel } from 'layout/components/auth/profile';
import { Typo } from 'layout/components/typo/ui';
import { BonusesClub } from '../bonuses-club';
import { LatestBonuses } from '../latest-bonuses';
import styles from './styles.module.scss';

export const Bonuses = () => {
	useGate(bonusHistoryModel.BonusesGate);

	const [card, profile, status] = useUnit([
		bonusCardModel.$card,
		profileModel.$profile,
		loadProfileModel.$status,
	]);

	const isLoading = status === 'pending';

	return (
		<div className={styles.bonuses}>
			{/* <Typo as="h1" design="headline-l" className={styles.bonuses__headline}>
				Мои бонусы
			</Typo> */}

			<BonusesClub isLoading={isLoading} />

			{card && <LatestBonuses noRecovery />}

			{/* <PremiumCruisesBanner /> */}

			{/* {card && (
				<div className={styles.invites}>
					<InviteFriend cardNumber={card.cardNumber!} />
					<SharePoints />
				</div>
			)} */}

			<div className={styles.text}>
				<Typo as="h2" design="headline-m">
					Как получить больше баллов?
				</Typo>

				<Typo design="text-m">
					В рамках акций от туроператоров сумма баллов к начислению за отдельные круизы может быть
					увеличена. Подпишитесь на наши рассылки в разделе «Личные данные» и будьте в курсе текущих
					выгодных предложений.
				</Typo>
			</div>

			{profile && <BonusSupport cardNumber={card?.cardNumber} profile={profile} />}

			{/* <div className={styles.text}>
				<Typo as="h2" design="headline-m">
					О бонусной программе
				</Typo>

				<Typo className={styles.description} design="text-m">
					<p>
						Бонусные баллы начисляются автоматически после окончания тура, либо в рамках специальных
						акций. В личном кабинета вам доступна История начисления баллов.
					</p>
					<p>
						Если вы были в круизе и вам не начислены баллы, то вы можете Восстановить баллы оставив
						запрос.
					</p>
					<p>
						Вы можете привести друга и получить 10 000 бонусных баллов! Ещё Вы можете передать баллы
						друзьями или родственникам.
					</p>
					<p>
						По всем вопросам начисления баллов Вы можете обратиться в службу поддержку Клуба
						Любителей Круизов «Созвездие»:
					</p>
					<p className={styles.contacts}>
						<span className={styles.contact}>по телефону: 8 (800) 301-91-22</span>
						<span className={styles.contact}>по e-mail: bonus@infoflot.com</span>
					</p>
					<p>
						Часы работы службы поддержки: с 10:00 до 18:30 часов по московскому времени в будние
						дни.
					</p>
					<p>
						Возникли ещё вопросы - оставьте обращение в клиентскую поддержку и мы Вам обязательно
						ответим.
					</p>
					<Button
						className={styles.buttonLink}
						href={RULES_PAGE}
						onClick={window.close}
						btnType="text"
						size="large"
					>
						Подробнее о бонусной программе
						<Icon id="chevron-right" width={20} height={20} />
					</Button>
				</Typo>
			</div> */}
		</div>
	);
};
