// import { Typo } from 'shared/ui/atoms';
// import { Icon, IconId } from 'shared/ui/icons';
import { Icon } from 'layout/components/icons/icon';
import { IconId } from 'layout/components/icons/svg-sprite';
import { Typo } from 'layout/components/typo/ui';
import styles from './styles.module.scss';

interface CabinParamProps {
	id: IconId;
	name: string;
}

export const CabinParam = ({ id, name }: CabinParamProps) => {
	if (!name) {
		return null;
	}

	return (
		<div className={styles.cabinParamsItem}>
			<Icon id={id} width={20} height={20} />
			<Typo design="headline-xs" className={styles.cabinParamsItemName}>
				{name}
			</Typo>
		</div>
	);
};
