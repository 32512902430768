import React from 'react';
// import { message } from 'shared/lib/message';
// import { dischargeNumber } from 'shared/lib/utils';
// import { BonusForBuy, BonusPayment, Price, Tooltip, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import styles from './styles.module.scss';
import { message } from 'config/constants/message';
import { Icon } from 'layout/components/icons/icon';
import { Tooltip } from 'layout/components/special/tooltip';
import { Typo } from 'layout/components/typo/ui';
import { dischargeNumber } from 'utils/number';
import { BonusForBuy } from '../bonus-for-buy';
import { BonusPayment } from '../bonus-payment';
import { Price } from '../price';

interface TotalPriceProps {
	price: {
		discount?: string | number;
		default?: string | number;
		total?: string | number;
	};
	isBonusPayment: boolean;
	bonus: number;
	withInfo?: boolean;
	layoutForCabinCard?: boolean
}

export const TotalPrice = ({ price, isBonusPayment, bonus, withInfo = false, layoutForCabinCard }: TotalPriceProps) => {
	return !layoutForCabinCard
		? (
			<div className={styles.prices}>
				{(!!price.discount || isBonusPayment) && (
					<div className={styles.defaultPrice}>
						{!!price.discount && (
							<div className={styles.price}>
								<Typo className={styles.priceType} design="text-s">
									Без скидки
								</Typo>
								{price.default && (
									<Typo design="headline-s">
										{dischargeNumber(Number(price.default), ' ', 0)}
										<Icon id="rur-bold" className="currency" />
									</Typo>
								)}
							</div>
						)}

						{isBonusPayment && (
							<Tooltip
								placement="left"
								className={styles.bonusPaymentTooltip}
								classNamePopover={styles.tooltipContent}
								trigger={<BonusPayment />}
							>
								<Typo as="p" raw={message.bonusPaymentsNextStage} />
							</Tooltip>
						)}
					</div>
				)}

				{!!price.discount && (
					<div className={styles.price}>
						<Typo className={styles.priceType} design="text-s">
							Сумма скидки
						</Typo>
						<Typo design="headline-s">
							{dischargeNumber(Number(price.discount), ' ', 0)}
							<Icon id="rur-bold" className="currency" />
						</Typo>
					</div>
				)}

				<div className={styles.totalPrice}>
					{!!price.total && (
						<div className={styles.price}>
							<Typo className={styles.priceType} design="headline-s">
								Итого
							</Typo>
							<Typo design="headline-m">
								<Price total={Number(price.total)} bold withInfo={withInfo} design="headline-m" />
							</Typo>
						</div>
					)}

					{!!bonus && (
						<Tooltip
							placement="left"
							className={styles.tooltip}
							classNamePopover={styles.tooltipContent}
							trigger={<BonusForBuy className={styles.bonus} value={bonus} withIcon={false} />}
						>
							<Typo as="p" raw={message.bonusForBuy} />
						</Tooltip>
					)}
				</div>
			</div>
		)
		: (
			<div className={styles.prices}>
				<div className={styles.totalPrice}>
					{!!price.total && (
						<div className={styles.price}>
							<Typo className={styles.priceType} design="headline-s">
								Итого
							</Typo>
							<Typo design="headline-m">
								<Price total={Number(price.total)} bold withInfo={withInfo} design="headline-m" />
							</Typo>
						</div>
					)}
				</div>
			</div>
		)
};
