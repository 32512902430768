import { sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { profileModel } from 'layout/components/auth/profile';
// import { profileModel } from 'entities/profile';

interface FormValues {
	emailNotificationsEnabled: boolean;
	smsNotificationsEnabled: boolean;
}

const Gate = createGate();

const $notificationData = profileModel.$profileDraft.map((profile) => {
	if (profile) {
		const { emailNotificationsEnabled, smsNotificationsEnabled } = profile;

		return {
			emailNotificationsEnabled,
			smsNotificationsEnabled,
		};
	}

	return null;
});

const form = createForm<FormValues>({
	initialValues: {
		emailNotificationsEnabled: false,
		smsNotificationsEnabled: false,
	},
});

sample({
	clock: [Gate.open, profileModel.resetDraft],
	source: $notificationData,
	filter: Boolean,
	target: form.setValues,
});

sample({
	source: profileModel.$profileDraft,
	filter: Boolean,
	clock: form.$values,
	fn: (draft, { emailNotificationsEnabled, smsNotificationsEnabled }) => ({
		...draft,
		emailNotificationsEnabled,
		smsNotificationsEnabled,
	}),
	target: profileModel.$profileDraft,
});

export const changeNotificationDataModel = {
	Gate,
	form,
};
