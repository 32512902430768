// import { fetcher } from 'shared/api/config';
// import { ACCOUNT_URL } from 'shared/config';
// import { Cruise, Ship } from 'shared/lib/types/account/models';

import { fetcher } from "api/setupAxios";
import { ACCOUNT_URL } from "store/auth/constants";
import { Cruise } from "store/auth/types/account/cruise";
import { Ship } from "store/auth/types/account/ship";

export const getListOfShips = () =>
	fetcher<Ship[]>({
		url: `${ACCOUNT_URL}/bonus-card/recovery/ships`,
		method: 'GET',
		authorization: true,
	});

export interface GetCruisesTransferPointsRequest {
	ship?: string;
	dateStart?: string;
	dateEnd?: string;
}

export const getCruises = (params: GetCruisesTransferPointsRequest) =>
	fetcher<Cruise[]>({
		url: `${ACCOUNT_URL}/bonus-card/recovery/cruises`,
		method: 'GET',
		authorization: true,
		body: { ...params },
	});

export const handbooksRequests = {
	getListOfShips,
	getCruises,
};
