export const geCabinName = (name?: string) => {
	if (!name) {
		return 'Каюта';
	}

	if (Number.parseInt(name)) {
		return `Каюта № ${name}`;
	}

	return name;
};
