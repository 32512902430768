import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useOverlayTriggerState } from 'react-stately';
import { useGate } from 'effector-react';
// import { useForm } from 'effector-react-form/scope';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Request } from 'shared/lib/types/account/request';
// import { dateIsOverdue } from 'shared/lib/utils';
// import { Alert, Button, ButtonProps, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { getPaymentMessage } from '../lib';
import { requestPaymentModel } from '../model';
import { ModalCruiseInfo } from './modal-cruise-info';
import { ModalFooter } from './modal-footer';
import { ModalForm } from './modal-form';
import styles from './styles.module.scss';
import { useForm } from 'effector-react-form';
import { ButtonProps, Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { Alert } from 'layout/components/view/alert';
import { dateIsOverdue } from 'utils/date';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
import { Request } from 'store/auth/types/account/request';

interface RequestPaymentModalProps extends Request, Pick<ButtonProps, 'btnType' | 'size'> {
	buttonContent: string;
	buttonClassName?: string;
	modalTitle?: string;
	modalSumLabel?: string;
}

export const RequestPaymentModal = ({
	buttonContent,
	buttonClassName,
	btnType,
	size,
	cruise,
	cabins,
	expirationDate,
	amount,
	payed,
	prepaymentDate,
	prepayment,
	modalTitle = 'Оплатить круиз',
	modalSumLabel,
}: RequestPaymentModalProps) => {
	const state = useOverlayTriggerState({});
	const shouldMount = useDelayUnmount(state.isOpen, 600);

	useGate(requestPaymentModel.Gate, {
		id: cruise?.id as number,
	});

	const { form } = requestPaymentModel; //{ form: null }
	const { submit, setValue } = useForm({ form }); // { submit: () => null, setValue: (paramet: any) => null } //

	const prepaymentIsOverdue = dateIsOverdue(prepaymentDate);

	useEffect(() => {
		if (!state.isOpen) {
			return;
		}

		const amountValue = amount ?? 0;
		const payedValue = payed ?? 0;

		const params = {
			field: 'amount',
			value: amountValue,
		};

		if ((prepayment && !prepaymentIsOverdue) || prepaymentIsOverdue) {
			params.value = amountValue - payedValue;
		}

		setValue(params);
	}, [prepayment, prepaymentDate, amount, payed, prepaymentIsOverdue, state.isOpen, setValue]);

	return (
		<>
			<Button size={size} btnType={btnType} onClick={state.open} className={buttonClassName}>
				{buttonContent}
			</Button>

			{shouldMount && (
				<Modal
					state={state}
					isShown={state.isOpen}
					className={styles.modalOverlay}
					classNameModal={styles.modalBox}
					classNameModalContent={styles.modalBox_content}
					footer={<ModalFooter onSubmit={submit} onClose={state.close} />}
					verticalAlign="bottom"
					isDismissable
				>
					<Typo design="headline-xl">{modalTitle}</Typo>
					<ModalCruiseInfo
						cruise={cruise}
						expirationDate={expirationDate}
						amount={amount}
						payed={payed}
						cabins={cabins}
						sumLabel={modalSumLabel}
					/>
					<Alert
						design="text"
						className={classnames(styles.paymentModal_paymentMessage, {
							[styles.paymentModal_paymentMessageDanger]:
								Boolean(!prepayment && !prepaymentDate) || prepaymentIsOverdue,
						})}
					>
						{getPaymentMessage(Boolean(prepayment && prepaymentDate), payed, expirationDate)}
					</Alert>
					<ModalForm
						prepayment={prepayment}
						prepaymentDate={prepaymentDate}
						amount={amount}
						payed={payed}
						expirationDate={expirationDate}
					/>
				</Modal>
			)}
		</>
	);
};
