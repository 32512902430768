// import { HeaderMultiLevelItemType, LinkType } from 'shared/lib/types';

import { LinkType } from "config/commonTypes";
import { HeaderMultiLevelItemType } from "config/types/header";

export const HEADER_CLUB_LINK: LinkType = {
	label: 'Клуб любителей круизов',
	href: '/bonus',
};

export const HEADER_MENU_RUSSIAN_RIVERS: HeaderMultiLevelItemType[] = [
	{
		label: 'Подборки круизов',
		hideDesktopLabel: true,
		children: [
			{
				label: 'Теплоходы круизной \n компании «Созвездие»',
				children: [
					{ href: '/ship/981', label: 'Россия' },
					{ href: '/ship/970', label: 'Огни большого города' },
					{ href: '/ship/963', label: 'Маленький принц' },
					{ href: '/ship/498', label: 'Лунная соната' },
					{ href: '/ship/478', label: 'Лебединое озеро' },
					{ href: '/ship/83', label: 'Симфония севера' },
					{ href: '/ship/4', label: 'Н.А. Некрасов' },
					{ href: '/ship/38', label: 'Александр Бенуа' },
					{ href: '/ship/571', label: 'Северная сказка' },
					// { href: '/ship/462', label: 'Экспедиции Созвездия' },
					{ href: '/news/363', label: 'Открываем мир с «Созвездием»' },
				],
				href: '/news/221',
				moreLink: {
					label: 'Показать все',
					href: '/news/221',
				},
			},
			{
				id: 'extraordinary',
				label: 'Необыкновенные круизы',
				children: [],
				href: '/news/320',
				moreLink: {
					label: 'Показать все',
					href: '/news/320',
				},
			},
			{
				id: 'recommendations',
				label: '«Созвездие» рекомендует',
				children: [],
				href: '/news/cruise-recommendations',
				moreLink: {
					label: 'Показать все',
					href: '/news/cruise-recommendations',
				},
			},
			{
				label: 'Круизы по регионам',
				href: '/news/cruises-rus',
				children: [
					{ label: 'Из Москвы', href: '/news/400' },
					{ label: 'по Золотому кольцу', href: '/news/174' },
					{ label: 'Из Нижнего Новгорода', href: '/news/405' },
					{ label: 'Из Санкт-Петербурга', href: '/news/404' },
					{ label: 'Из Самары', href: '/news/353' },
					{ label: 'Из Казани', href: '/news/406' },
					{ label: 'Из Ростова-на-Дону', href: '/news/407' },
					{ label: 'Из Волгограда', href: '/news/408' },
					{ label: 'Из Перми', href: '/news/409' },
					{ label: 'Из Ярославля', href: '/news/410' },
					{ label: 'В Астрахань', href: '/news/206' },
					{ label: 'В Казань', href: '/news/207' },
					{ label: 'В Санкт-Петербург', href: '/news/208' },
					{ label: 'В Москву', href: '/news/209' },
					{ label: 'В Пермь', href: '/news/210' },
					{ label: 'По Оке', href: '/news/212' },
					{ label: 'По Карелии', href: '/news/211' },
					{ label: 'В Татарстан', href: '/news/213' },
					{ label: 'В Ростов-на-Дону', href: '/news/214' },
					{ label: 'из Саратова', href: '/news/223' },
					{ label: 'В Уфу', href: '/news/215' },
				],
				moreLink: {
					label: 'Показать все',
					href: '/news/cruises-rus',
				},
			},
		],
	},
	{
		label: 'Каталог теплоходов',
		href: '/ships',
		children: [],
	},
	// {
	// 	label: 'Города и стоянки',
	// 	href: '/cities',
	// 	children: [],
	// },
	{
		label: 'Полезная информация о круизах',
		mainLinkPlacedChildren: true,
		children: [
			{ label: 'по России', href: '/pages/informaciia-turistam-recnye-kruizy-po-rossii' },
			{ label: 'по Зарубежью', href: '/pages/informaciia-turistam-recnye-kruizy-po-zarubeziu' },
		],
	},
	{
		label: 'Города и стоянки',
		href: '/cities/river',
		children: [],
	},
];

export const HEADER_MENU_RIVERS: HeaderMultiLevelItemType[] = [
	{
		label: 'Подборки круизов по зарубежью',
		children: [
			{
				id: 'rivers:selections',
				label: 'Круизы по зарубежью',
				href: '/',
				children: [],
				moreLink: {
					label: 'Показать все',
					href: '/',
				},
			},
			{
				id: 'rivers:popularRoutes',
				label: 'Популярные маршруты',
				href: '/',
				children: [],
				moreLink: {
					label: 'Показать все',
					href: '/',
				},
			},
		],
	},
	{
		label: 'Речные теплоходы',
		href: '/ships/foreign-rivers',
		children: [],
	},
	{
		label: 'Информация туристам',
		href: '/',
		children: [],
	},
];

export const HEADER_MENU_SEA: HeaderMultiLevelItemType[] = [
	{
		label: 'Из Сочи в Турцию на лайнере Astoria Grande',
		href: '/news/403',
		children: [],
	},
	{
		label: 'Турецкая ривьера на большой круизной яхте «Гранд Адмирал»',
		href: '/news/224',
		children: [],
	},
	{
		label: 'Персидский залив на лайнерах MSC Cruises',
		href: 'https://old.infoflot.com/Search.html?dateFrom=21.12.2023&dateUntil=22.11.2024&routes%5B%5D=62&length=1-122&name=&shipTypes%5B%5D=22&shipTypes%5B%5D=44&shipTypes%5B%5D=3&shipTypes%5B%5D=50&submit=%D0%9F%D0%BE%D0%BA%D0%B0%D0%B7%D0%B0%D1%82%D1%8C+%D0%BA%D1%80%D1%83%D0%B8%D0%B7%D1%8B',
		children: [],
	},
	{
		label: 'Экспедиции по Дальнему Востоку',
		href: '/news/318',
		children: [],
	},
];

export const HEADER_MENU_AGENTS: HeaderMultiLevelItemType[] = [
	{
		label: 'Условия сотрудничества',
		href: '/info/agentstvam-1',
		children: [],
	},
	{
		label: 'Партнерская программа',
		href: 'https://cruiselines.pro/',
		children: [],
	},
];

export const HEADER_MENU = [
	{
		name: 'Поиск круиза',
		compact: true,
		to: '/search',
		icon: 'search',
	},
	{
		name: 'Речные круизы',
		children: HEADER_MENU_RUSSIAN_RIVERS,
		activeChildren: 'Подборки круизов',
	},
	{
		name: 'Морские круизы',
		children: HEADER_MENU_SEA,
		target: '_blank',
	},
	{
		disabled: true,
		name: 'Речные круизы по зарубежью',
		children: HEADER_MENU_RIVERS,
	},
	{
		name: 'Акции и скидки',
		compactName: 'Акции и спецпредложения',
		to: '/news/promotions',
		compact: true,
	},
	{
		id: 'tourists',
		name: 'Туристам',
		compact: true,
	},
	{
		id: 'companies',
		name: 'Агентствам',
	},
	{
		name: 'Корпоративным клиентам',
		to: '/info/arenda-dlia-meropriiatii',
	},
	{
		id: 'about',
		name: 'О нас',
	},
].filter(({ disabled }) => !disabled);

export const COMPACT_HEADER_MENU = HEADER_MENU.filter(({ compact }) => compact);
