import { useMemo } from 'react';
import classnames from 'classnames';
import { PLACES } from 'entities/passengers/passengers-card/lib';
// import { getPlaceTypes } from 'shared/config';
// import { RequestPassenger } from 'shared/lib/types/account/request-passenger';
// import { Price, Typo } from 'shared/ui/atoms';
import styles from './styles.module.scss';
import { getPlaceTypes } from 'config/constants/cabin';
import { RequestPassenger } from 'config/types/prices/request-passenger';
import { Typo } from 'layout/components/typo/ui';
import { Price } from '../price';

interface PlaceInfoProps extends Pick<RequestPassenger, 'type' | 'placeType' | 'amount'> {
	itemDirection?: 'column' | 'row';
	childrenAge?: number;
}

export const PlaceInfo = ({
	type,
	placeType,
	amount,
	childrenAge,
	itemDirection = 'row',
}: PlaceInfoProps) => {
	const placeName = useMemo(() => PLACES.find(({ id }) => id === placeType)?.name, [placeType]);
	// const placeTypeName = type ? useMemo(() => getPlaceTypes(childrenAge, true)[type], [childrenAge, type]) : ""
	const placeTypeName = useMemo(() => type ? getPlaceTypes(childrenAge, true)[type] : "", [childrenAge, type])

	const itemClassName = classnames(styles.placeInfoItem, styles[`placeInfoItem_${itemDirection}`]);

	return (
		<div className={styles.placeInfo}>
			{placeName && (
				<div className={itemClassName}>
					<Typo className={styles.placeInfoItem__name} design="text-m">
						Место
					</Typo>
					<Typo design="headline-s">{placeName}</Typo>
				</div>
			)}
			{placeTypeName && (
				<div className={itemClassName}>
					<Typo className={styles.placeInfoItem__name} design="text-m">
						Тип места
					</Typo>
					<Typo design="headline-s">{placeTypeName}</Typo>
				</div>
			)}
			{amount ? (
				<div className={itemClassName}>
					<Typo className={styles.placeInfoItem__name} design="text-m">
						Цена
					</Typo>
					<Price total={amount} as="span" design="headline-s" bold />
				</div>
			) : null}
		</div>
	);
};
