import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
// import {
// 	NOTIFICATIONS_DECLENSION,
// 	BONUS_DECLENSION,
// 	SHIPS_DECLENSION,
// 	NEWS_DECLENSION,
// 	REQUESTS_ACCUSATIVE_DECLENSION,
// } from 'shared/lib/constants';
// import { getNumDeclension } from 'shared/lib/utils';
// import { useViewport } from 'shared/lib/viewport';
// import { Button } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { EmptyButton, LeftButton, PageButton, RightButton } from './button-types';
import { getPagination } from './lib/lib';
import { AccountPaginationProps } from './lib/types';
import styles from './styles.module.scss';
import { REQUESTS_ACCUSATIVE_DECLENSION, NOTIFICATIONS_DECLENSION, BONUS_DECLENSION, SHIPS_DECLENSION, NEWS_DECLENSION } from 'config/constants';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { useViewport } from 'layout/viewport';
import { getNumDeclension } from 'utils/string';

const PAGES_TO_DISPLAY = 3;

const PAGE_ITEMS = {
	requests: REQUESTS_ACCUSATIVE_DECLENSION,
	notifications: NOTIFICATIONS_DECLENSION,
	bonuses: BONUS_DECLENSION,
	ships: SHIPS_DECLENSION,
	news: NEWS_DECLENSION,
};

export const AccountPagination: FC<AccountPaginationProps> = React.memo(
	({
		total,
		perPage,
		page: activePage,
		pageName,
		onPageChange,
		onLoadMore,
		loadedNotifications,
		loadMorePending,
		loadMoreMobileTheme,
		className,
	}) => {
		const { isMob } = useViewport();
		const loadMoreOutline = isMob && loadMoreMobileTheme === 'outline';

		const pagesNumber = useMemo(() => (total ? Math.ceil(total / perPage) : 1), [total, perPage]);

		const itemsLeftToShow = useMemo(() => {
			if (activePage + 1 === pagesNumber) {
				return total - perPage * activePage;
			}

			return perPage;
		}, [activePage, total, pagesNumber, perPage]);

		if (pagesNumber === 1) {
			return null;
		}

		const loadMoreText = [
			'Показать ещё',
			itemsLeftToShow,
			getNumDeclension(itemsLeftToShow, PAGE_ITEMS[pageName]),
		];

		return (
			<div className={classnames(styles.paginationWrap, className)}>
				{loadedNotifications !== total && activePage !== pagesNumber && (
					<div className={styles.showMoreButtonWrap}>
						<Button
							loading={loadMorePending}
							onClick={() => onLoadMore(activePage + 1)}
							className={styles.showMoreButton}
							size={loadMoreOutline ? 'large' : 'middle'}
							btnType={loadMoreOutline ? 'outline' : 'primary'}
						>
							{loadMoreText.slice(0, loadMoreOutline ? 2 : 3).join(' ')}
							{loadMoreOutline && <Icon id="chevron-down" width={20} height={20} />}
						</Button>
					</div>
				)}
				<ul className={styles.paginationList}>
					{getPagination(pagesNumber, PAGES_TO_DISPLAY, activePage).map((page) => {
						switch (page[0]) {
							case 'left-button':
								return (
									<LeftButton key={page[0]} activePage={activePage} onPageChange={onPageChange} />
								);
							case 'right-button':
								return (
									<RightButton
										key={page[0]}
										activePage={activePage}
										onPageChange={onPageChange}
										pagesNumber={pagesNumber}
									/>
								);
							case 'empty-button':
								return <EmptyButton key={page[0] + page[1]} />;
							case 'page-button':
								return (
									<PageButton
										key={page[1]}
										activePage={activePage}
										index={page[1] as number}
										onPageChange={onPageChange}
									/>
								);
							default:
								return null;
						}
					})}
				</ul>
			</div>
		);
	},
);
