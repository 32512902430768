import React, { FC, ReactNode, useState } from 'react';
import classnames from 'classnames';
import { useOverlayTriggerState } from 'react-stately';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Button, ButtonProps } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { ExcursionsModalContent } from '../excursions-modal-content';
import { PassengerDataModalContent } from '../passenger-data-modal-content';
import styles from './styles.module.scss';
import { ButtonProps, Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';

interface PassengerCardModalProps {
	buttonContent: ReactNode;
	modalType: 'excursions' | 'passengerData';
	buttonProps?: ButtonProps;
	buttonClassName?: string;
	buttonContextClassName?: string;
	passengerId: number;
}

export const PassengerCardModal: FC<PassengerCardModalProps> = ({
	buttonContent,
	modalType,
	buttonProps,
	buttonClassName,
	buttonContextClassName,
	passengerId,
}) => {
	const [modalName, setModalName] = useState<'all-services' | 'excursion-details'>('all-services');

	const state = useOverlayTriggerState({
		onOpenChange: (isOpen) => {
			if (!isOpen) {
				setModalName('all-services');
			}
		},
	});
	const shouldMount = useDelayUnmount(state.isOpen, 600);

	return (
		<>
			<Button
				size="large"
				btnType="text"
				onClick={state.toggle}
				className={classnames(styles.button, buttonClassName)}
				contextClassName={buttonContextClassName}
				{...buttonProps}
			>
				{buttonContent}
			</Button>
			{shouldMount && (
				<Modal
					state={state}
					isShown={state.isOpen}
					className={styles.modalOverlay}
					classNameModal={styles.modalBox}
					classNameModalContent={classnames(styles.modalBox_content, {
						[styles.modalBox_contentNoPadding]:
							modalType === 'excursions' && modalName === 'excursion-details',
					})}
					scrollerClassName={styles.modalBox_scroller}
					closeButtonClassName={styles.modalBox_closeButton}
					verticalAlign="bottom"
					withScroll={modalType === 'excursions'}
					isDismissable
				>
					{modalType === 'excursions' && (
						<ExcursionsModalContent
							modalName={modalName}
							setModalName={setModalName}
							passengerId={passengerId}
						/>
					)}
					{modalType === 'passengerData' && <PassengerDataModalContent />}
				</Modal>
			)}
		</>
	);
};
