import React, { FC } from 'react';
import { DateDuration } from '@internationalized/date';
import { DateValue } from '@react-types/datepicker';
import { AriaRangeCalendarProps, useDateFormatter } from 'react-aria';
import { CalendarState, RangeCalendarState } from 'react-stately';
// import { capitalizeFirstLetter } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
import styles from './style.module.scss';
import { Typo } from 'layout/components/typo/ui';
import { capitalizeFirstLetter } from 'utils/string';

interface CalendarMonthListProps extends AriaRangeCalendarProps<DateValue> {
	months?: number;
	state: CalendarState | RangeCalendarState;
	onMonthClick: (index: number) => void;
	offset?: DateDuration;
}

export const CalendarMonthList: FC<CalendarMonthListProps> = ({
	state,
	months,
	onMonthClick,
	offset = {},
}) => {
	const startDate = state.visibleRange.start.add(offset);
	// console.log('startDate', startDate);

	const onClickHandler = () => {
		if (offset.months) {
			onMonthClick(offset.months);
		} else {
			onMonthClick(0);
		}
	};

	const monthDateFormatter = useDateFormatter({
		month: 'long',
		year: 'numeric',
		timeZone: state.timeZone,
	});

	const monthCaption = capitalizeFirstLetter(
		monthDateFormatter.format(startDate.toDate(state.timeZone)).split(' ')[0],
	);

	const yearCaption = monthDateFormatter.format(startDate.toDate(state.timeZone)).split(' ')[1];

	return (
		<button className={styles.monthButton} onClick={onClickHandler}>
			<Typo as="span" design="text-s">
				{monthCaption}
			</Typo>
			{(offset.months === 0 || monthCaption === 'Январь') && (
				<Typo as="span" className={styles.monthButton__year}>
					{yearCaption}
				</Typo>
			)}
		</button>
	);
};
