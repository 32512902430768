/* eslint-disable react/no-array-index-key */
import React from 'react';
// import { useUnit } from 'effector-react/ssr';
// import { detailedRequest } from 'widgets/account/model';
// import { RequestDocument } from 'shared/lib/types/account/request-document';
// import { capitalizeFirstLetter } from 'shared/lib/utils';
// import { Button, ButtonProps } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { documentNames } from '../lib';
import styles from './styles.module.scss';
import { useUnit } from 'effector-react';
import { detailedRequest } from 'entities/account/model';
import { ButtonProps, Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { RequestDocument } from 'store/auth/types/account/request-document';
import { capitalizeFirstLetter } from 'utils/string';

interface RequestDocProps extends ButtonProps {
	document: RequestDocument;
	showIcon?: boolean;
}

export const RequestDoc = ({ document, showIcon = true, ...buttonProps }: RequestDocProps) => {
	const [openDoc, docUrl, docStatus, docFails] = useUnit([
		detailedRequest.openDoc,
		detailedRequest.$openDocUrl,
		detailedRequest.$openDocStatus,
		detailedRequest.$openDocFails,
	]);

	const failCode = document.url && docFails[document.url];

	if (!document.type) {
		return null;
	}

	return (
		<Button
			size="large"
			btnType="outline"
			contextClassName={styles.docsButton_ctx}
			onClick={() => openDoc(document.url ?? '')}
			loading={docStatus === 'pending' && document.url === docUrl}
			disabled={failCode === 404}
			{...buttonProps}
		>
			{showIcon && (docStatus !== 'pending' || document.url !== docUrl) && (
				<Icon id="pdf" width={20} height={20} />
			)}
			<span>
				{failCode === 404
					? 'Файл не найден'
					: capitalizeFirstLetter(documentNames[document.type] || document.type)}
			</span>
		</Button>
	);
};
