import { createFileRoute, redirect } from '@tanstack/react-router'
import { useCheckAuth } from 'layout/components/auth/load-profile'
import { AccountRoutes } from 'store/auth/constants'
import { routerModel } from 'utils/router'

export const Route = createFileRoute('/account/')({
  component: () => <Account />,
})

const Account = () => {
  const profile = useCheckAuth()


  if (!profile.authorized) {
    routerModel.pushAsA(AccountRoutes.LoginPage)
  }
  else {
    routerModel.pushAsA(AccountRoutes.Profile)
  }

  return null
}