// import { AccountRequestsStatuses } from 'shared/config';

import { AccountRequestsStatuses } from "store/auth/constants";

export const STATUSES_FOR_AMOUNT = [
	AccountRequestsStatuses.Confirmed,
	AccountRequestsStatuses.Prepayment,
	AccountRequestsStatuses.AwaitingPayment,
];

export const STATUSES_FOR_RETURN = [
	AccountRequestsStatuses.AwaitingReturn,
	AccountRequestsStatuses.Return,
];

export const getReturnInfoLabel = (status?: number) => {
	switch (status) {
		case AccountRequestsStatuses.AwaitingReturn:
			return 'К возврату';
		default:
			return 'Осуществлен возврат';
	}
};
