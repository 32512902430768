// import { message } from 'shared/lib/message';

import { message } from "config/constants/message";

export const getError = (code: string) => {
	if (code === 'AC2') {
		return message.bonusRecovery.AC2;
	}

	if (code === 'AC5') {
		return message.bonusRecovery.AC5;
	}

	return message.bonusRecovery.error;
};
