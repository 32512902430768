// import { createFileRoute } from '@tanstack/react-router'

// export const Route = createFileRoute('/account/my-requests/$bidId/')({
//   component: () => <div>Hello /account/my-requests/$bidId/!</div>,
// })

import { createFileRoute, useParams } from '@tanstack/react-router'
import { useUnit } from 'effector-react'
import { detailedRequest } from 'entities/account/model'
import { AccountLayout } from 'layout/layouts/account'
import { Main } from 'layout/layouts/main'
import { DetailedRequest } from 'layout/layouts/widgets/account'
import { useEffect } from 'react'
import accountStyles from './../../styles.module.scss'
//accountStyles.marginTopSmMd

// const currentRoute = '/account/my-requests/$bidId'
const currentRoute = '/account/my-requests/$bidId/'

export const Route = createFileRoute(currentRoute)({
  component: () => <BidPage />,
})

const BidPage = () => {
  const params = useParams({ from: currentRoute })
  const getData = useUnit(detailedRequest.getData);

  useEffect(() => {
    getData({ params: { exid: params.bidId, id: params.bidId, } })
  }, [])

  return (
    <Main>
      <AccountLayout>
        <DetailedRequest />
      </AccountLayout>
    </Main>
  )
}

// import type { NextPage } from 'next';
// import { DetailedRequest } from 'widgets/account';
// import { detailedRequest } from 'widgets/account/model';
// import { AccountLayout } from 'widgets/layouts/account';
// import { Main } from 'widgets/layouts/main';
// import { ISR_REGENERATION_TIME } from 'shared/config';
// import { staticPropsFactory } from 'shared/lib/ssr';

// const DetailedRequestPage: NextPage = () => (
// 	<Main>
// 		<AccountLayout>
// 			<DetailedRequest />
// 		</AccountLayout>
// 	</Main>
// );

// export const getStaticProps = staticPropsFactory(
// 	detailedRequest.getData,
// 	ISR_REGENERATION_TIME,
// ).bind({
// 	__logName: 'DetailedRequestPage',
// });

// export const getStaticPaths = async () => ({
// 	paths: [],
// 	fallback: 'blocking',
// });

// export default DetailedRequestPage;

// import { createFileRoute } from '@tanstack/react-router'

// export const DetailedRequestPage = createFileRoute(
//   currentRoute,
// )({
//   component: () => (
//     <div>/account/my-requests/$bidId/</div>
//     // <Main>
//     //   <AccountLayout>
//     //     <Requests />
//     //   </AccountLayout>
//     // </Main>
//   ),
// })
