import { useMemo } from 'react';
import { useGate, useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { Button, Typo } from 'shared/ui/atoms';
import { changeAdditionalPhoneModel, changeMainPhoneModel } from '../../model';
import { ModalForm } from '../modal-form';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';

interface ChangePhoneModalProps {
	additionalPhone?: boolean;
	addMode?: boolean;
}

export const ChangePhoneModal = ({ additionalPhone, addMode }: ChangePhoneModalProps) => {
	const model = additionalPhone ? changeAdditionalPhoneModel : changeMainPhoneModel;
	const { codeForm, phoneForm, Gate, $state, $phonePending } = model;
	const [state, { phone }] = useUnit([$state, phoneForm.$values, codeForm.$values]);
	const { submit } = useForm({ form: phoneForm });

	useGate(Gate, { additionalPhone });
	const pending = useUnit($phonePending);

	const text = useMemo(() => {
		switch (state) {
			case 'phone':
				if (additionalPhone && addMode) {
					return 'На данный номер будет приходить информация об актуальных акциях, спецпредложениях и других мероприятиях, посвященных круизам.';
				}

				return `Укажите ${
					addMode ? '' : 'новый'
				} номер телефона. На этот номер Вам будет отправлен код подтверждения.`;
			case 'code':
				return `Вам был отправлен код подтверждения на указанный ${
					addMode ? '' : 'новый'
				} номер ${phone}`;
			case 'success':
				return `Вы успешно подтвердили свой номер телефона ${phone}`;
			default:
				return '';
		}
	}, [addMode, additionalPhone, phone, state]);

	const title = useMemo(() => {
		switch (state) {
			case 'phone':
				return addMode
					? `Укажите номер телефона ${additionalPhone ? 'для смс-рассылки' : ''}`
					: 'Изменение номера телефона';
			case 'success':
				return addMode
					? `Номер телефона${additionalPhone ? ' для смс-рассылки ' : ' '}добавлен`
					: `Номер телефона${additionalPhone ? ' для смс-рассылки ' : ' '}изменен`;
			default:
				return addMode ? 'Добавление номера телефона' : 'Изменение номера телефона';
		}
	}, [addMode, additionalPhone, state]);

	return (
		<div>
			<Typo className={styles.title} design="headline-xl">
				{title}
			</Typo>
			<Typo className={styles.text} design="text-m">
				{text}
			</Typo>

			<ModalForm additionalPhone={additionalPhone} />
			{state === 'code' && (
				<Button
					loading={pending}
					btnType="text"
					size="large"
					className={styles.button}
					onClick={submit}
				>
					Получить новый код
				</Button>
			)}
		</div>
	);
};
