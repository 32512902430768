export const canRequestBePayed = (value?: Nullable<string>) => {
	if (!value) {
		return null;
	}

	try {
		const now = new Date();
		const date = new Date(value);
		const minutesDiff = (date.getTime() - now.getTime()) / (1000 * 60);
		const hoursDiff = minutesDiff / 60;

		return Boolean(hoursDiff > 72);
	} catch {
		return null;
	}
};

export const getReversedIndexes = (mainReqsLength?: number, otherReqsLength?: number) => {
	const indexArr = [];
	const start = (mainReqsLength ?? 0) + (otherReqsLength ?? 0) + 1;

	for (let i = start; i > 0; i--) {
		indexArr.push(i);
	}

	return indexArr;
};
