import React, { useRef, useState } from 'react';
import type { ComboBoxProps } from '@react-types/combobox';
import { useComboBox, useFilter } from 'react-aria';
import { useComboBoxState } from 'react-stately';
import type { LoadingState } from '@react-types/shared';
// import { Input, Popover, Title, Typo } from 'shared/ui/atoms';
// import { ListBox } from 'shared/ui/molecules/list-box';
import style from './style.module.scss';
import { Popover } from 'layout/components/special/popover';
import { Typo } from 'layout/components/typo/ui';
import { ListBox } from 'layout/components/view/list-box';
import { Input } from '../input/input';
import { Title } from 'layout/components/view/title';

interface AutocompleteProps<T> extends ComboBoxProps<T> {
	loadingState?: LoadingState;
	onLoadMore?: () => void;
	className?: string;
	secondPlaceholder?: string;
	required?: boolean;
	error?: Nullable<string>;
	showError?: boolean;
}

export const InputSearch = <T extends object>({
	className,
	secondPlaceholder,
	required,
	error,
	showError,
	...props
}: AutocompleteProps<T>) => {
	const [blurred, setBlurred] = useState(false);

	const inputRef = useRef(null);
	const listBoxRef = useRef(null);
	const popoverRef = useRef(null);
	const inputSearchContainerRef = useRef(null);

	const { contains } = useFilter({ sensitivity: 'base' });
	const state = useComboBoxState({
		...props,
		defaultFilter: contains,
	});

	const { label } = props;
	const { isOpen } = state;

	const { inputProps, listBoxProps, labelProps } = useComboBox(
		{
			...props,
			inputRef,
			listBoxRef,
			popoverRef,
		},
		state,
	);

	return (
		<div className={style.inputSearch}>
			<label {...labelProps}>
				{/* <Title type="h6">{label}</Title> */}
				<p>{label}</p>
			</label>
			<div ref={inputSearchContainerRef} role="search">
				<input
					{...inputProps}
					ref={inputRef}
					style={{
						width: 0,
						height: 0,
						opacity: 0,
					}}
					className={style.inputSearch__input}
				/>
				<Input
					{...inputProps}
					onBlur={(e) => {
						setBlurred(true);
						inputProps?.onBlur?.(e);
					}}
					size="lg"
					secondPlaceholder={secondPlaceholder}
					withLabel
					required={required}
					className={className}
					error={
						error && Boolean(blurred || showError) ? (
							<Typo design="input-text-s" className="errormessage">
								{error}
							</Typo>
						) : undefined
					}
				/>
			</div>
			{isOpen && (
				<Popover
					state={state}
					triggerRef={inputSearchContainerRef}
					popoverRef={popoverRef}
					placement="bottom"
					isNonModal
					className={style.inputSearch__popover}
				>
					<ListBox {...listBoxProps} listBoxRef={listBoxRef} state={state} />
				</Popover>
			)}
		</div>
	);
};
