import { delay, status } from 'patronum';
import { OverlayTriggerState } from 'react-stately';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { detailedRequest } from 'entities/account/model';
import { accountNavigationModel } from 'layout/layouts/header/account-navigation';
import { accountRequests } from 'store/account/api/requests';
import { AccountRequestsStatuses } from 'store/auth/constants';
// import { detailedRequest } from 'widgets/account/model';
// import { accountNavigationModel } from 'features/account-navigation';
// import { accountRequests } from 'shared/api';
// import { AccountRequestsStatuses } from 'shared/config';

const Gate = createGate<OverlayTriggerState>();

const $setOpenModal = createStore<Nullable<Pick<OverlayTriggerState, 'setOpen'>>>(null);
const setOpenFx = createEffect((cb: () => void) => cb());

const openCancelModal = createEvent();
const closeCancelModal = createEvent();
const resetModalStatuses = createEvent();

const cancelRequest = createEvent<number>();
const cancelRequestFx = createEffect(accountRequests.cancelRequest);
const $requestId = createStore<Nullable<number>>(null);
const $cancelStatus = createStore<Nullable<number>>(null).reset(resetModalStatuses);
const $status = status({ effect: cancelRequestFx }).reset(resetModalStatuses);

sample({
	clock: Gate.open,
	filter: (source) => Boolean(source),
	fn: (clock) => ({ setOpen: clock.setOpen }),
	target: $setOpenModal,
});

sample({
	clock: openCancelModal,
	source: $setOpenModal,
	fn: (source) => () => source?.setOpen(true),
	target: setOpenFx,
});

sample({
	clock: closeCancelModal,
	source: $setOpenModal,
	fn: (source) => () => source?.setOpen(false),
	target: setOpenFx,
});

delay({
	source: closeCancelModal,
	timeout: () => 600,
	target: resetModalStatuses,
});

sample({
	clock: cancelRequest,
	target: [cancelRequestFx, $requestId],
});

sample({
	clock: cancelRequestFx.doneData,
	filter: ({ status: statusNumber }) => statusNumber === AccountRequestsStatuses.Canceled,
	target: accountNavigationModel.getCounts,
});

sample({
	clock: cancelRequestFx.doneData,
	fn: (clock) => clock.status,
	target: $cancelStatus,
});

sample({
	clock: cancelRequestFx.doneData,
	source: $requestId,
	target: [detailedRequest.getDetailedRequest, detailedRequest.getDetailedChildRequest],
});

export const model = {
	Gate,
	openCancelModal,
	closeCancelModal,
	cancelRequest,
	$status,
	$cancelStatus,
};
