// import { KLK_RULES_LINK } from 'shared/config';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { setAnalytics } from 'shared/lib/utils';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { ANALYTICS_TARGETS } from 'config/constants';
import { KLK_RULES_LINK } from 'config/constants/contacts';
import { Button } from 'layout/components/buttons/button';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import styles from './styles.module.scss';

interface BonusRecoveryInfoProps {
	close: () => void;
}

export const BonusRecoveryInfo = ({ close }: BonusRecoveryInfoProps) => {
	const handleClickConditionsForScoring = () => {
		close();
		// setAnalytics(ANALYTICS_TARGETS.account.account_points_rules);
	};

	return (
		<>
			<Typo className={styles.title} design="headline-xl">
				Восстановить баллы
			</Typo>

			<Typo design="text-m" className={styles.description}>
				<p>
					Если на Ваш счет не начислены баллы за круиз, который Вы планируете совершить (т.е.
					путевка приобретена) или уже совершили после официального открытия Клуба Любителей Круизов
					&quot;Созвездие&quot; (22 ноября 2012 года), Вы можете отправить запрос на их
					восстановление.
				</p>
				<p>Перед тем как отправить запрос убедитесь, что:</p>
				<ul className={styles.list}>
					<li className={styles.listItem}>заполнены все поля приведенной ниже формы;</li>
					<li className={styles.listItem}>
						круиз (речной или морской) будет (или был) совершен после 22&nbsp;ноября 2012 года;
					</li>
					<li className={styles.listItem}>выполнены условия начисления баллов;</li>
					<li className={styles.listItem}>
						круиз приобретался в компании &quot;Созвездие&quot; (в том числе через агентство);
					</li>
					<li className={styles.listItem}>
						круиз состоялся в текущий год или позднее года регистрации в&nbsp;Клубе. Баллы за года
						предшествующие году регистрации в программе не восстанавливаются;
					</li>
					<li className={styles.listItem}>
						Ваши персональные данные в путевке (Ф.И.О., номер паспорта) совпадают с Вашими данными в
						личном кабинете Клуба Любителей Круизов &quot;Созвездие&quot;.
					</li>
				</ul>
			</Typo>

			<Typo className={styles.important} design="headline-m">
				Внимание!
			</Typo>

			<Typo design="text-m" className={styles.description}>
				<p>
					За круизы, совершенные до официального открытия Клуба Любителей Круизов
					&quot;Созвездие&quot; (22 ноября 2012 года), баллы не начисляются.
				</p>
				<p>
					Для проверки информации и начисления баллов понадобится от 3 до 10 дней с момента Вашего
					обращения.
				</p>
			</Typo>

			<Button
				className={styles.buttonLink}
				href={KLK_RULES_LINK}
				onClick={handleClickConditionsForScoring}
				btnType="text"
				size="large"
			>
				Условия начисления баллов
				<Icon id="chevron-right" width={20} height={20} />
			</Button>
		</>
	);
};
