import React from 'react';
import classnames from 'classnames';
import { Item } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
// import { accountNavigationModel } from 'features/account-navigation';
// import { Logout } from 'features/logout';
import { CollectionChildren } from '@react-types/shared';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { ProfileModel } from 'shared/lib/types';
// import { setAnalytics } from 'shared/lib/utils';
// import { toggleTextEllipsis } from 'shared/lib/utils/dom';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
// import { MenuButton } from 'shared/ui/molecules/menu/ui/menu-button';
// import { TopLink } from '../top-link';
import { getLabel } from './lib';
import { model } from './model';
import styles from './styles.module.scss';
import { ProfileModel } from 'config/types/personal';
import { Typo } from 'layout/components/typo/ui';
import { Icon } from 'layout/components/icons/icon';
import { Logout } from 'layout/components/auth/logout';
import { accountNavigationModel } from '../../account-navigation';
import { TopLink } from '../../top-link';
import { MenuButton } from 'layout/components/menues/menu';
import { toggleTextEllipsis } from 'utils/dom/toggle-text-ellipsis';
import { AccountRoutes } from 'store/auth/constants';

interface ProfileMenuProps {
	profile: Nullable<ProfileModel>;
	compact?: boolean;
	compactActive?: boolean;
}

export const ProfileMenu = ({ profile, compact = false, compactActive }: ProfileMenuProps) => {
	const [onAction, onActionAsA, requestsCount, notificationsCount] = useUnit([
		model.handleProfileMenuAction,
		model.handleProfileMenuActionAsA,
		accountNavigationModel.$requestsCount,
		accountNavigationModel.$notificationsCount,
	]);

	useGate(accountNavigationModel.Gate);

	// const handleClickProfileNav = () => setAnalytics(ANALYTICS_TARGETS.header.header_account_click);

	if (!profile) {
		return (
			<div className={styles.profileNav}
			// onClick={handleClickProfileNav}
			>
				<TopLink href={AccountRoutes.LoginPage} icon="user" asA>
					{!compact && 'Войти'}
				</TopLink>
			</div>
		);
	}

	const items = [
		{
			key: 'profile',
			name: 'Профиль',
			content: <Typo design="input-text-m">Профиль</Typo>,
			asA: true,
		},
		{
			key: 'personal',
			name: 'Личные данные',
			content: <Typo design="input-text-m">Личные данные</Typo>,
			asA: true,
		},
		{
			key: 'my-bonuses',
			name: 'Мои бонусы',
			content: <Typo design="input-text-m">Мои бонусы</Typo>,
			asA: true,
		},
		{
			key: 'my-requests?status=active',
			name: 'Мои заявки',
			content: (
				<>
					<Typo design="input-text-m">Мои заявки</Typo>
					<Typo design="headline-s" className={styles.count}>
						{requestsCount}
					</Typo>{' '}
				</>
			),
			asA: true,
		},
		{
			key: 'notifications',
			name: 'Уведомления от операторов',
			hidden: notificationsCount < 1,
			content: (
				<>
					<Typo design="input-text-m">Уведомления от операторов</Typo>
					<Typo
						design="description-features"
						className={classnames(styles.count, styles.count_red)}
					>
						{notificationsCount}
					</Typo>{' '}
				</>
			),
			asA: true,
		},
		{
			key: 'logout',
			name: 'Выйти из профиля',
			content: (
				<Typo className={styles.logout} design="input-text-m">
					Выйти из профиля
				</Typo>
			),
			asA: true,
		},
	];

	const itemsCollection: CollectionChildren<any> = items
		.filter(({ hidden }) => !hidden)
		.map((item) => (
			<Item key={item.key} textValue={item.name}>
				{item.content}
			</Item>
		));

	return (
		<div
			className={styles.profileNav}
		// style={{ flexDirection: 'row', 'alignItems': 'center', 'justifyContent': 'center' }}
		// onClick={handleClickProfileNav}
		>
			<MenuButton
				onAction={(key) => {
					const asA = items.find(i => i.key === key)?.asA
					// console.log('Logout onAction asA', asA)
					if (asA)
						onActionAsA(String(key))
					else
						onAction(String(key));
				}}
				label={
					<div
						className={styles.label}
						ref={(node) => {
							toggleTextEllipsis(node);
						}}
					>
						<Icon id="user" />
						{!compact && getLabel(profile)}
					</div>
				}
				hiddenChevron={compact}
				fixedPosition={compact}
				active={compactActive}
				classNamePopover={styles.profileNav__popover}
			>
				{itemsCollection}
			</MenuButton>

			<Logout />
		</div>
	);
};
