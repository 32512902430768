import { createFileRoute } from '@tanstack/react-router'
import { useGate, useUnit } from 'effector-react'
import { bonusHistoryModel, profileScreenModel, requestModel } from 'entities/account/model'
import { bonusCardModel } from 'entities/bonus-card'
import { loadProfileModel } from 'layout/components/auth/load-profile'
import { profileModel } from 'layout/components/auth/profile'
import { ProfileRequests } from 'layout/components/profile/profile-requests'
import { ProfileVerification } from 'layout/components/profile/profile-verification'
import { Typo } from 'layout/components/typo/ui'
import { BonusStars } from 'layout/components/view/bonus-starts'
import { BonusInfo } from 'layout/components/view/bonus-starts/info/bonus-info'
import { AccountLayout } from 'layout/layouts/account'
import { BonusesClub } from 'layout/layouts/widgets/account/ui/bonuses/ui/bonuses-club'
import { BonusesRatingWrapper } from 'layout/layouts/widgets/account/ui/bonuses/ui/bonuses-rating'
import { LatestBonusesCustom } from 'layout/layouts/widgets/account/ui/bonuses/ui/latest-bonuses-custom'
import { useEffect } from 'react'
import { findAndChangeInnerHtml } from 'utils/page/pageUtil'
import styles from './styles.module.scss'
import accountStyles from './../styles.module.scss'
//accountStyles.marginTopSmMd

export const Route = createFileRoute('/account/profile/')({
    component: () => <ProfileRoute />,
})


function ProfileRoute() {

    useGate(bonusHistoryModel.BonusesGate);

    const [card, profile, status, getRequests, requests] = useUnit([
        bonusCardModel.$card,
        profileModel.$profile,
        loadProfileModel.$status,
        profileScreenModel.getData,
        requestModel.$requests,
    ]);

    const isLoading = status === 'pending';

    useEffect(() => {
        const pageH1 = "Профиль"
        findAndChangeInnerHtml("h1", `<h1 id='h1'>${pageH1}</h1>`)
        getRequests()
    }, [])

    console.log('requests', requests)

    return (

        <AccountLayout sidebarContent={<ProfileVerification />}>
            <section className={accountStyles.marginTopSmMd}>
                <Typo className={styles.profileCard__header} design="headline-m">
                    Баллы созвездия
                </Typo>
                <Typo className={styles.profileCard} design="headline-m">
                    <BonusesRatingWrapper>
                        <Typo className={styles.profileCard__wrapper__info}>
                            <Typo className={styles.profileCard__wrapper__info__top}>
                                <BonusStars />
                            </Typo>
                            <Typo className={styles.profileCard__wrapper__info__bottom}>
                                <BonusInfo />
                            </Typo>
                        </Typo>
                        <Typo>
                        </Typo>
                    </BonusesRatingWrapper>
                    {/* <BonusStars /> */}
                    <BonusesClub isLoading={isLoading} />
                </Typo>
                <Typo className={styles.profileCard__header} design="headline-m">
                    <ProfileRequests isLoading={isLoading} />
                </Typo>
                <Typo className={styles.profileCard__header} design="headline-m">
                    {card && <LatestBonusesCustom noRecovery />}
                </Typo>
            </section>
            {/* <Profile /> */}
        </AccountLayout>)
}