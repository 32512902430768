import { status } from 'patronum';
import { createEffect, createEvent, sample } from 'effector';
import { modalFactory } from 'layout/components/modal';
import { accountRequests } from 'store/account/api/requests';
// import { accountRequests } from 'shared/api';
// import { modalFactory } from 'shared/model';

const request = createEvent<number>();
const requestFx = createEffect(accountRequests.requestBill);

const { ModalGate, openModal } = modalFactory();

sample({
	clock: request,
	target: requestFx,
});

sample({
	clock: [requestFx.doneData, requestFx.fail],
	target: openModal,
});

export const model = {
	request,
	ModalGate,
	openModal,
	status: status({ effect: requestFx }),
};
