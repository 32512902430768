import { ReactNode } from 'react';
import classnames from 'classnames';
import { ErrorIcon } from './ui';
import styles from './styles.module.scss';

interface PageErrorProps {
	children: ReactNode;
	className?: string;
}

export const PageError = ({ children, className }: PageErrorProps) => (
	<div className={classnames(className, styles.wrapper)}>
		<ErrorIcon />
		<div className={styles.content}>{children}</div>
	</div>
);
