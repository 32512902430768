import { CABINS_DECLENSION, REST_DECLENSION_FEMININE, VACANT_DECLENSION_FEMININE, SEATS_DECLENSION } from 'config/constants';
import { CRUISE_CARD_FEW_CABINS_LIMIT } from 'config/constants/cruises';
import React from 'react';
import { getNumDeclension, capitalizeFirstLetter } from 'utils/string';
// import { CRUISE_CARD_FEW_CABINS_LIMIT } from 'shared/config';
// import { capitalizeFirstLetter, getNumDeclension } from '..';
// import {
// 	CABINS_DECLENSION,
// 	REST_DECLENSION_FEMININE,
// 	SEATS_DECLENSION,
// 	VACANT_DECLENSION_FEMININE,
// } from '../../constants';

export const getFreeCabinsCounter = (count?: number) => {
	if (!count || count <= 0) {
		return null;
	}

	return (
		<>
			{count}&nbsp;{getNumDeclension(count, CABINS_DECLENSION)}
		</>
	);
};

export const getCabinsCaption = (count?: number) => {
	const hasFreeCabins = count !== undefined && count !== null && count !== 0;

	if (!hasFreeCabins) {
		return <>Места по запросу</>;
	}

	const fewFreeCabins = count && count <= CRUISE_CARD_FEW_CABINS_LIMIT;
	const freeCabinsCounter = getFreeCabinsCounter(count);

	if (fewFreeCabins) {
		return (
			<>
				{capitalizeFirstLetter(getNumDeclension(count, REST_DECLENSION_FEMININE))}{' '}
				{freeCabinsCounter}!
			</>
		);
	}

	return (
		<>
			{freeCabinsCounter} {getNumDeclension(count || 5, VACANT_DECLENSION_FEMININE)}
		</>
	);
};

export const getSeatsCounter = (count?: number) => {
	if (!count || count <= 0) {
		return null;
	}

	return (
		<>
			{count}&nbsp;{getNumDeclension(count, SEATS_DECLENSION)}
		</>
	);
};


export const padezhWords = {

	stars: (starsNumber: number) => {
		let sNumber = 0
		if (![11, 12, 13, 14].includes(starsNumber)) {
			const str = starsNumber.toString()
			sNumber = Number(str.slice(str.length - 1))
		}
		else {
			sNumber = starsNumber
		}

		switch (sNumber) {
			case 0: case 5: case 6: case 7: case 8: case 9: case 11: case 12: case 13: case 14: return "звёзд";
			case 2: case 3: case 4: return "звезды";
			default: return "звезда";
		}
	},
	nights: (nightsNumber: number) => {
		let nNumber = 0
		if (![11, 12, 13, 14].includes(nightsNumber)) {
			const str = nightsNumber.toString()
			nNumber = Number(str.slice(str.length - 1))
		}
		else {
			nNumber = nightsNumber
		}

		switch (nNumber) {
			case 0: case 5: case 6: case 7: case 8: case 9: case 11: case 12: case 13: case 14: return "ночей";
			case 2: case 3: case 4: return "ночи";
			default: return "ночь";
		}
	}
}