/* eslint-disable react/no-array-index-key */
import { memo } from 'react';
// import { usePassengersServices } from 'widgets/account/model';
// import { EXCURSIONS_DECLENSION } from 'shared/lib/constants';
// import { diffTimetable, getNumDeclension, getTimeRangeText } from 'shared/lib/utils';
// import { Button, Typo } from 'shared/ui/atoms';
import { ModalContentProps } from '../index';
import styles from './styles.module.scss';
import { EXCURSIONS_DECLENSION } from 'config/constants';
import { usePassengersServices } from 'entities/account/model';
import { Button } from 'layout/components/buttons/button';
import { Typo } from 'layout/components/typo/ui';
import { getTimeRangeText, diffTimetable } from 'utils/date';
import { getNumDeclension } from 'utils/string';

interface AllServicesProps extends Pick<ModalContentProps, 'onClick'> {
	passengerId: number;
}

export const AllServices = memo(({ onClick, passengerId }: AllServicesProps) => {
	const { passengerServices } = usePassengersServices(passengerId);

	return (
		<div className={styles.excursionsModal_contentWrap}>
			<Typo design="headline-xl" className={styles.excursionsModal_contentTitle}>
				Дополнительные
				<br /> экскурсии и услуги
			</Typo>
			<div className={styles.excursionsModal_content}>
				<Typo
					design="text-m"
					className={styles.excursionsModal_servicesTitle}
				>{`В заявку добавлено ${passengerServices.length} ${getNumDeclension(
					passengerServices.length,
					EXCURSIONS_DECLENSION,
				)}`}</Typo>
				<ul className={styles.excursionsModal_servicesList}>
					{passengerServices.map((service, index) => (
						<li key={index} className={styles.excursionsModal_servicesItem}>
							<Typo design="headline-s">{service.excursion?.name}</Typo>
							<div className={styles.excursionsModal_excursionDuration}>
								<Typo design="text-s" as="span">
									Продолжительность
								</Typo>
								{service.excursion?.timeStart && service.excursion?.timeEnd && (
									<Typo design="headline-xs" as="span">
										{`${getTimeRangeText(
											`1970-01-01T${service.excursion.timeStart}`,
											`1970-01-01T${service.excursion.timeEnd}`,
											true,
										)}, ${diffTimetable(
											`1970-01-01T${service.excursion.timeEnd}`,
											`1970-01-01T${service.excursion.timeStart}`,
											true,
										)}`}
									</Typo>
								)}
							</div>
							<Button
								btnType="text"
								className={styles.excursionsModal_excursionDetails}
								onClick={() => {
									onClick(service.excursion?.id ?? null);
								}}
							>
								Подробнее об экскурсии
							</Button>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
});
