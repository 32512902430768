import { useOverlayTriggerState } from 'react-stately';
import { useGate, useUnit } from 'effector-react';
// import { DownloadBill } from 'widgets/account/ui/detailed-request/ui/request-docs/ui';
// import { BILL_REQUESTED_STATUSES } from 'features/bill-request/lib';
// import { useDelayUnmount } from 'shared/lib/hooks';
// import { Request } from 'shared/lib/types/account/request';
// import { RequestDocument } from 'shared/lib/types/account/request-document';
// import { Button, Typo } from 'shared/ui/atoms';
// import { Modal } from 'shared/ui/molecules';
import { model } from '../model';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { Modal } from 'layout/components/modal';
import { Typo } from 'layout/components/typo/ui';
import { DownloadBill } from 'layout/layouts/widgets/account/ui/detailed-request/ui/request-docs';
import { RequestDocument } from 'store/auth/types/account/request-document';
import { useDelayUnmount } from 'utils/hooks/use-delay-unmount';
import { BILL_REQUESTED_STATUSES } from '../lib';
import { Request } from 'store/auth/types/account/request';

interface BillRequestProps extends Pick<Request, 'status' | 'billStatus' | 'id'> {
	document?: RequestDocument;
}

const statuses = new Set([20, 21, 32, 33]);

export const BillRequest = ({ status, billStatus, id, document }: BillRequestProps) => {
	const state = useOverlayTriggerState({});
	const shouldMount = useDelayUnmount(state.isOpen, 600);
	const [request, requestStatus] = useUnit([model.request, model.status]);

	useGate(model.ModalGate, state);

	const statusIsOk = id && status && statuses.has(status);
	const billRequested = billStatus !== undefined && BILL_REQUESTED_STATUSES.includes(billStatus);
	const billRequestSentSuccessfully = !state.isOpen && requestStatus === 'done';

	if (document) {
		return <DownloadBill document={document} />;
	}

	if (!statusIsOk || billRequested || billRequestSentSuccessfully) {
		return null;
	}

	return (
		<>
			<Button className={styles.button} size="large" onClick={() => request(id)} btnType="text">
				Запросить счёт
			</Button>

			{shouldMount && (
				<Modal
					classNameModal={styles.modal}
					footer={
						<Button onClick={state.close} size="large">
							Закрыть
						</Button>
					}
					state={state}
					isShown={state.isOpen}
				>
					<Typo className={styles.title} design="headline-xl">
						{requestStatus === 'done'
							? 'Запрос на счет успешно отправлен'
							: 'Не удалось отправить запрос на создание счёта. Попробуйте\u00A0ещё\u00A0раз'}
					</Typo>
				</Modal>
			)}
		</>
	);
};
