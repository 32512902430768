import classnames from 'classnames';
import { FC, memo } from 'react';
// import { Typo } from 'shared/ui/atoms/typo';
// import { Icon } from 'shared/ui/icons';
import { Typo } from 'layout/components/typo/ui';
import styles from './styles.module.scss';
import { padezhWords } from 'utils/text-endings';

export interface BonusInfoProps {
    totalNights?: number
    leftNights?: number
    className?: string;
}

export const BonusInfo: FC<BonusInfoProps> = memo(({ className, totalNights = 93, leftNights = 6 }) => (
    <Typo className={classnames(className, styles.bonusInfoWrapper)}>
        <Typo className={styles.bonusInfoWrapper__text}>
            <p>На текущий момент у вас: </p>
            <p><span className={classnames(styles.bonusInfoWrapper__warning)}>{totalNights}</span> {padezhWords.nights(totalNights)}</p>
        </Typo>
        <Typo className={styles.bonusInfoWrapper__text}>
            <p> До повышения статуса осталось: </p>
            <p><span className={classnames(styles.bonusInfoWrapper__warning)}>{leftNights}</span> {padezhWords.nights(leftNights)}</p>
        </Typo>
    </Typo>
));
