import classNames from 'classnames';
// import { GetBonusCardHistory200ResponseDataInner } from 'shared/lib/types/account/get-bonus-card-history-200-response-data-inner';
// import { formatDate } from 'shared/lib/utils';
// import { Typo } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { isBonusDebited } from '../../lib';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { GetBonusCardHistory200ResponseDataInner } from 'store/auth/types/account/get-bonus-card-history-200-response-data-inner';
import { formatDate } from 'utils/date';

interface BonusHistoryListItemProps extends GetBonusCardHistory200ResponseDataInner {}

export const BonusHistoryListItem = ({
	createdDate,
	expirationDate,
	comment,
	balanceChange,
}: BonusHistoryListItemProps) => (
	<li className={styles.bonusHistoryListItem}>
		<div className={styles.header}>
			<Typo className={styles.title} design="text-m">
				{comment}
			</Typo>
			<Typo
				// ToDo добавить условие для balanceChangeAwaiting
				className={classNames(
					styles.balanceChange,
					isBonusDebited(balanceChange) && styles.balanceChangeDebiting,
					false && styles.balanceChangeAwaiting,
				)}
				design="text-m"
			>
				<span>{balanceChange}</span>
				<Icon id="klk-without-text" width={20} height={20} />
			</Typo>
		</div>

		{expirationDate && (
			<Typo className={styles.expirationDate} design="description-features">
				Баллы действуют до {formatDate(expirationDate)}
			</Typo>
		)}

		{/* ToDo уточнить как выводится awaitingAccrual */}
		{createdDate ? (
			<Typo className={styles.createdDate} design="description-features">
				{formatDate(createdDate)}
			</Typo>
		) : (
			<Typo className={styles.awaitingAccrual} design="description-features">
				<Icon id="awaiting-payment" width={12} height={12} />
				<span>Ожидается начисление</span>
			</Typo>
		)}
	</li>
);
