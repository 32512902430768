import { getFailCode, resetJWT } from 'api';
import { ANALYTICS_TARGETS } from 'config/constants';
import { message } from 'config/constants/message';
import { createEffect, createEvent, createStore, sample } from 'effector';
import { createGate } from 'effector-react';
import { createForm } from 'effector-react-form';
import { profileModel } from 'layout/components/auth/profile';
import { personalRequests } from 'store/auth/api';
// import { profileModel } from 'entities/profile';
// import { personalRequests } from 'shared/api/';
// import { ANALYTICS_TARGETS } from 'shared/lib/constants';
// import { message } from 'shared/lib/message';
// import { getFailCode, resetJWT, setAnalytics } from 'shared/lib/utils';

interface FormValues {
	oldPassword: string;
	password: string;
	repeatPassword: string;
}

const Gate = createGate();
const $passwordChanged = createStore<boolean>(false);

const changePassword = createEvent<FormValues>();
const changePasswordFx = createEffect(personalRequests.changePassword);

const form = createForm<FormValues>({
	initialValues: {
		oldPassword: '',
		password: '',
		repeatPassword: '',
	},
	onSubmit: ({ values }) => changePassword(values),
});

const handleAnalyticsChangePasswordFx = createEffect(() => {}
	// setAnalytics(ANALYTICS_TARGETS.account.account_password_changed),
);

sample({
	source: profileModel.$accessToken,
	filter: Boolean,
	clock: changePassword,
	fn: (access, { password, oldPassword }) => ({
		access,
		data: {
			password,
			oldPassword,
		},
	}),
	target: changePasswordFx,
});

sample({
	source: $passwordChanged,
	filter: Boolean,
	clock: Gate.close,
	fn: () => false,
	target: $passwordChanged,
});

sample({
	clock: changePasswordFx.failData,
	fn: (error) => {
		const code = getFailCode(error);
		const messages = message.profile.changePassword;

		return {
			field: 'formError',
			error: messages[code || 'error'] || messages.error,
		};
	},
	target: form.setOrDeleteError,
});

sample({
	clock: changePasswordFx.doneData,
	fn: () => true,
	target: $passwordChanged,
});

sample({
	clock: changePasswordFx.doneData,
	target: createEffect(resetJWT),
});

/**
 * Обработка аналитики
 */
sample({
	clock: changePasswordFx.doneData,
	target: handleAnalyticsChangePasswordFx,
});

export const model = {
	form,
	Gate,
	$passwordChanged,
};
