import { useMemo } from 'react';
// import { AccountRequestsStatuses } from 'shared/config';
// import { RequestCardChild } from 'shared/lib/types/account/request-card-child';
import { getReversedIndexes } from '../../../lib';
import { AdditionalRequestCard } from '../card';
import styles from './styles.module.scss';
import { AccountRequestsStatuses } from 'store/auth/constants';
import { RequestCardChild } from 'store/auth/types/account/request-card-child';

interface AdditionalRequestsProps {
	parentId: number;
	additionalRequests?: Array<RequestCardChild>;
	expirationDate?: string;
}

export const AdditionalRequestsList = ({
	parentId,
	additionalRequests,
	expirationDate,
}: AdditionalRequestsProps) => {
	const awaitingPaymentRequests = useMemo(
		() =>
			additionalRequests?.filter((elem) => elem.status === AccountRequestsStatuses.AwaitingPayment),
		[additionalRequests],
	);
	const otherRequests = useMemo(
		() =>
			additionalRequests?.filter((elem) => elem.status !== AccountRequestsStatuses.AwaitingPayment),
		[additionalRequests],
	);

	const reversedIndexes = getReversedIndexes(additionalRequests?.length, otherRequests?.length);

	return (
		<div className={styles.additionalRequestsList}>
			{awaitingPaymentRequests?.map((req, index) => (
				<AdditionalRequestCard
					{...req}
					expirationDate={req.expirationDate ?? expirationDate}
					parentId={parentId}
					key={req.id}
					index={reversedIndexes[index]}
				/>
			))}

			{otherRequests?.map((req, index) => (
				<AdditionalRequestCard
					{...req}
					expirationDate={req.expirationDate ?? expirationDate}
					parentId={parentId}
					key={req.id}
					index={
						reversedIndexes[
							awaitingPaymentRequests?.length ? awaitingPaymentRequests.length + index : index
						]
					}
				/>
			))}
		</div>
	);
};
