// import { SendBonusSupportRequest } from 'shared/api';
// import { message } from 'shared/lib/message';
// import { ValidationOptions } from 'shared/ui/organisms/form/lib/validation';

import { message } from "config/constants/message";
import { ValidationOptions } from "layout/components/inputs/form/lib/validation";
import { SendBonusSupportRequest } from "store/account/api/bonuses";

export type SendBonusSupportRequestKey = keyof SendBonusSupportRequest;

export const bonusSupportScheme: Record<SendBonusSupportRequestKey, ValidationOptions> = {
	message: {
		required: true,
		messages: {
			validateType: message.form.validation.format.max2000AccountSupport,
		},
		validateType: 'max2000AccountSupport',
	},
	type: {
		required: true,
		messages: {
			validateType: message.form.validation.required,
		},
	},
};
