/* eslint-disable no-mixed-spaces-and-tabs */
import { memo } from 'react';
import classNames from 'classnames';
// import {
// 	GetBonusCardHistory200ResponseDataInner,
// 	RequestWaitingBonus,
// } from 'shared/lib/types/account/models';
// import { formatDate } from 'shared/lib/utils';
// import { Typo, DesktopView, MobileView } from 'shared/ui/atoms';
// import { Icon } from 'shared/ui/icons';
import { isBonusDebited, getDischarged, getBonusCardTitle } from '../../lib';
import styles from './styles.module.scss';
import { Icon } from 'layout/components/icons/icon';
import { Typo } from 'layout/components/typo/ui';
import { DesktopView, MobileView } from 'layout/layouts/device-view';
import { GetBonusCardHistory200ResponseDataInner } from 'store/auth/types/account/get-bonus-card-history-200-response-data-inner';
import { RequestWaitingBonus } from 'store/auth/types/account/request-waiting-bonus';
import { formatDate } from 'utils/date';

interface BonusHistoryCardProps
	extends GetBonusCardHistory200ResponseDataInner,
		RequestWaitingBonus {
	isWaitingBonuses?: boolean;
}

export const BonusHistoryCard = memo(
	({
		createdDate,
		expirationDate,
		comment,
		balanceChange,
		cruise,
		bonusCount,
		isWaitingBonuses,
	}: BonusHistoryCardProps) => {
		const title = getBonusCardTitle({ comment, isWaitingBonuses, cruise });

		return (
			<li className={styles.bonusHistoryCard}>
				<div className={styles.header}>
					{!isWaitingBonuses && (
						<Typo className={styles.createdDate} design="text-m">
							{formatDate(createdDate)}
						</Typo>
					)}

					{isWaitingBonuses && (
						<Typo className={styles.awaitingAccrual} design="input-text-m">
							<Icon id="awaiting-payment" width={14} height={14} />
							<span>Ожидается начисление</span>
						</Typo>
					)}
				</div>

				<div className={styles.body}>
					<Typo className={styles.title} design="headline-m">
						{title}
					</Typo>

					<DesktopView>
						<div className={styles.bodyRightColumn}>
							<Typo
								className={classNames(
									styles.balanceChange,
									isBonusDebited(balanceChange) && styles.balanceChangeDebiting,
									isWaitingBonuses && styles.balanceChangeAwaiting,
								)}
								design="headline-m"
							>
								{isWaitingBonuses ? (
									<>
										<span>{getDischarged(bonusCount)}</span>
										<Icon id="klk-without-text-grey" width={20} height={20} />
									</>
								) : (
									<>
										<span>{getDischarged(balanceChange)}</span>
										<Icon id="klk-without-text" width={20} height={20} />
									</>
								)}
							</Typo>

							{expirationDate && (
								<Typo className={styles.expirationDate} design="description-features">
									Баллы действуют до {formatDate(expirationDate)}
								</Typo>
							)}
						</div>
					</DesktopView>
				</div>

				{(cruise?.ship?.name || (cruise?.startDate && cruise?.endDate) || cruise?.route) && (
					<div className={styles.cruise}>
						<div className={styles.cruiseRow}>
							{cruise?.ship?.name && (
								<Typo className={styles.cruiseParam} design="text-s">
									<Icon className={styles.icon} id="frigate" width={20} height={20} />
									<span>{cruise?.ship?.name}</span>
								</Typo>
							)}

							{cruise?.startDate && cruise?.endDate && (
								<Typo className={styles.cruiseParam} design="text-s">
									<Icon className={styles.icon} id="month" width={20} height={20} />
									<span>{`${formatDate(cruise?.startDate, 'D MMMM YYYY')} – ${formatDate(
										cruise?.endDate,
										'D MMMM YYYY',
									)}`}</span>
								</Typo>
							)}
						</div>

						{cruise?.route && (
							<Typo className={styles.cruiseParam} design="text-s">
								<Icon className={styles.icon} id="location" width={20} height={20} />
								<span>{cruise?.route}</span>
							</Typo>
						)}
					</div>
				)}

				<MobileView>
					<div className={styles.mobileFooter}>
						<Typo
							className={classNames(
								styles.balanceChange,
								isBonusDebited(balanceChange) && styles.balanceChangeDebiting,
								isWaitingBonuses && styles.balanceChangeAwaiting,
							)}
							design="headline-m"
						>
							{isWaitingBonuses ? (
								<>
									<span>{bonusCount}</span>
									<Icon id="klk-without-text-grey" width={20} height={20} />
								</>
							) : (
								<>
									<span>{balanceChange}</span>
									<Icon id="klk-without-text" width={20} height={20} />
								</>
							)}
						</Typo>

						{expirationDate && (
							<Typo className={styles.expirationDate} design="description-features">
								Баллы действуют до {formatDate(expirationDate)}
							</Typo>
						)}
					</div>
				</MobileView>
			</li>
		);
	},
);
