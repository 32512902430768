import { useUnit } from 'effector-react';
import { useForm } from 'effector-react-form';
// import { model } from 'features/personal/change-password/model';
// import { AccountRoutes } from 'shared/config';
// import { routerModel } from 'shared/model';
// import { Button } from 'shared/ui/atoms';
import { model } from './../../model';
import styles from './styles.module.scss';
import { Button } from 'layout/components/buttons/button';
import { AccountRoutes } from 'store/auth/constants';
import { routerModel } from 'utils/router';

interface ModalFooterProps {
	close?: () => void;
}

export const ModalFooter = ({ close }: ModalFooterProps) => {
	const { form, $passwordChanged } = model;
	const { submit } = useForm({ form });

	const [passwordChanged, setAuthRedirectTarget] = useUnit([
		$passwordChanged,
		routerModel.storeAuthRedirectTargetFx,
	]);

	return (
		<div className={styles.footer}>
			{passwordChanged && (
				<Button
					className={styles.footer__button}
					size="large"
					href={AccountRoutes.LoginPage}
					onClick={(e) => {
						setAuthRedirectTarget(AccountRoutes.PersonalData);
					}}
				>
					Войти
				</Button>
			)}
			{!passwordChanged && (
				<>
					<Button onClick={() => submit(null)} className={styles.footer__button} size="large">
						Сохранить
					</Button>
					<Button onClick={close} btnType="text" size="large">
						Отменить
					</Button>
				</>
			)}
		</div>
	);
};
